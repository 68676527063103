import { FormHelperText, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { EMPTY_STRING } from '../../../../../../shared/constants/constants';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import { useQuote } from '../../../../redux/insurance/quote/quoteCustomHooks';
import { usePlans } from '../../../../redux/plans/plansCustomHooks';
import PlansCard from './card/PlansCard';
import MonthlyClasses from './classes/MonthlyClasses';
import HeaderPlans from './HeaderPlans';

const Monthly = ({ coveragesSettings, getQuotePremium }) => {
  const quote = useQuote();
  const errors = useErrors();
  const plans = usePlans();
  const classes = MonthlyClasses();
  const [selectedPlan, setSelectedPlan] = useState(0);
  const selectedPlanSettings = {
    selectedPlan: selectedPlan,
    setSelectedPlan: setSelectedPlan
  };

  return (
    <>
      <Grid container className={classes.container}>
        <HeaderPlans coveragesSettings={coveragesSettings} getQuotePremium={getQuotePremium} />
        {plans?.plans?.map(rentalPlan => (
          <Grid
            id={`plansGrid-${rentalPlan.id}`}
            key={`plansGrid-${rentalPlan.id}`}
            item
            xs
            className={`${classes.card} ${selectedPlan === rentalPlan.id ||
              (quote?.id && quote?.plan?.id === rentalPlan.id)
              ? classes.activated
              : EMPTY_STRING
              } `}
          >
            <PlansCard
              id={`plansCard-${rentalPlan.id}`}
              key={`plansCard-${rentalPlan.id}`}
              rentalPlan={rentalPlan}
              selectedPlanSettings={selectedPlanSettings}
              coveragesSettings={coveragesSettings}
            />
          </Grid>
        ))}
      </Grid>
      {errors?.plan?.id && (
        <FormHelperText error>{errors.plan.id}</FormHelperText>
      )}
    </>
  );
};

Monthly.propTypes = {
  coveragesSettings: PropTypes.object.isRequired,
  getQuotePremium: PropTypes.func.isRequired
};

export default Monthly;
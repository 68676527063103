import React from 'react';
import HelperButton from '../components/HelperButton';
import { zIndex } from '../constants/zIndex';

export default function WithHelperButton(WrappedComponent) {
  return function() {
    return (
      <>
        <WrappedComponent />
        <HelperButton zIndex={zIndex.FLOATIN_BUTTON} />
      </>
    );
  };
}

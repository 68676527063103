import { Box, Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutoCompleteForm from '../../../../../../../shared/components/form/AutocompleteForm';
import RadioButton from '../../../../../../../shared/components/form/RadioButton';
import { moneyFormat } from '../../../../../../../shared/components/format/MoneyFormat';
import { EMPTY_STRING, INVOICE } from '../../../../../../../shared/constants/constants';
import { REQUIRED } from '../../../../../../../shared/constants/formConstants';
import {
  INITIAL_INSTALLMENT,
  MONTHLY,
  TRADITIONAL
} from '../../../../../../../shared/constants/planConstants';
import { TRANSMITTED } from '../../../../../../../shared/enums/statusEnum';
import calculateManager from '../../../../../manager/calculate/calculateManager';
import paymentManager from '../../../../../manager/insurance/proposal/payment/paymentManager';
import insuranceDataManager from '../../../../../manager/insurance/quote/insuranceData/insuranceDataManager';
import { errorsActions, proposalActions, quoteActions } from '../../../../../redux';
import { useErrors } from '../../../../../redux/errors/errorsCustomHooks';
import {
  useAnalyze,
  useAnalyzePolicyOwner
} from '../../../../../redux/insurance/analyze/analyzeCustomHooks';
import { getAnalyzePolicyOwner } from '../../../../../redux/insurance/analyze/analyzeSelectors';
import { usePolicy } from '../../../../../redux/insurance/policy/policyCustomHooks';
import { useProposal } from '../../../../../redux/insurance/proposal/proposalCustomHooks';
import { useInstallments, useQuote } from '../../../../../redux/insurance/quote/quoteCustomHooks';
import calculateServiceManager from '../../../../../services/calculate/calculateServiceManager';
import PaymentClasses from '../classes/PaymentClasses';
import Installment from './Installment';

const PaymentMethodCard = ({ months, setMonths }) => {
  const classes = PaymentClasses();
  const dispatch = useDispatch();
  const quote = useQuote();
  const proposal = useProposal();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const errors = useErrors();
  const installments = useInstallments();
  const [maxInstallment, setMaxInstallment] = useState(INITIAL_INSTALLMENT);
  const [paymentMethod, setPaymentMethod] = useState(true);
  const hasPolicyOwner = useAnalyzePolicyOwner();
  const optionsAutocomplete = paymentManager.getOptionsInstallments(
    maxInstallment,
    quote?.plan?.grossPremium
  );
  const disabled = (analyze?.status === TRANSMITTED || policy?.id);
  let [monthText, setMonthText] = useState(
    months
      .toString()
      .concat(paymentManager.getOptionsInstallmentsText(quote?.plan?.grossPremium, months))
  );
  const newErrors = paymentManager.getPaymentCardErrors(errors);

  const getPayment = useCallback(
    async (type, installments) => {
      let firstDueDate = new Date(quote?.startInsuranceContract);
      firstDueDate.setDate(firstDueDate.getDate() + 15);
      dispatch(
        proposalActions.setProposal({
          ...proposal,
          payment: {
            firstDueDate: firstDueDate,
            currency: 'BRL',
            amount: quote?.plan?.grossPremium,
            payerId: getAnalyzePolicyOwner(analyze?.commissionedAgents)?.personId,
            payerDocumentNumber: getAnalyzePolicyOwner(analyze?.commissionedAgents)?.documentNumber,
            installments:
              type === INVOICE
                ? insuranceDataManager.getInstallMents(
                  insuranceDataManager.getDays(
                    quote?.startInsuranceContract,
                    quote?.endInsuranceContract
                  )
                )
                : installments,
            paymentType: type,
            correctionIndex: proposal?.payment?.correctionIndex
          }
        })
      );
    },
    [dispatch, analyze, quote, proposal]
  );

  useEffect(() => {
    (async () => {
      let installmentRequest = 1;

      async function calculateMaxInstallment() {
        installmentRequest = await calculateServiceManager.getMaximumInstallment(
          quote,
          quote?.plan?.grossPremium
        );
        setMaxInstallment(installmentRequest);
      }

      if (!proposal?.payment?.currency && quote?.plan?.type) {
        const type = paymentManager.getPaymentMethodOptions(hasPolicyOwner, quote);
        if (quote?.plan?.type === MONTHLY) {
          getPayment(type[0].description, maxInstallment);
        } else {
          getPayment(type[0].description, months);
        }
      }

      if (maxInstallment === INITIAL_INSTALLMENT) {
        calculateMaxInstallment();
      }
    })();
  }, [proposal, analyze, quote, dispatch, getPayment, hasPolicyOwner, months, maxInstallment]);

  const cleanInstallments = () => {
    setMonthText(EMPTY_STRING);
    setMonths(0);
    dispatch(
      proposalActions.setProposal({
        ...proposal,
        payment: { ...proposal?.payment, installments: null }
      })
    );
  };

  const setError = msg => {
    dispatch(
      errorsActions.setErrors({
        ...newErrors,
        payment: {
          ...newErrors.payment,
          paymentCard: { ...newErrors.payment?.paymentCard, installments: msg }
        }
      })
    );
  };

  const changeAutocomplete = value => {
    if (value) {
      setMonths(parseInt(value.split('x')[0]));
      const type = paymentManager.getPaymentMethodOptions(hasPolicyOwner, quote);
      const numberInstallments = paymentManager.getNumberInstallmentsSelected(
        optionsAutocomplete,
        value
      );
      getPayment(type[0].description, numberInstallments);
      dispatch(quoteActions.setQuote({ ...quote, installments: parseInt(value.split('x')[0]) }));

      setError(EMPTY_STRING);
    } else {
      cleanInstallments();
      setError(REQUIRED);
    }
  };

  const onInputChangeAutocomplete = value => {
    if (value) {
      const option = optionsAutocomplete.find(o => o === value);
      setMonthText(option ? option : value);
    } else {
      cleanInstallments();
    }
  };

  return (
    <Grid item xs={12} margin={20}>
      <Paper className={classes.rightCards}>
        <Grid item xs={12} align="left">
          <Box pt={3} px={3} pb={3} className={classes.titleCards}>
            Pagamento
          </Box>
          <Grid container spacing={3} justify="space-around">
            <Grid item md={3} xs={6}>
              <Box fontWeight="bold">IOF</Box>
              <Box>
                {moneyFormat(
                  calculateManager
                    .getSubtraction(quote?.plan?.grossPremium, quote?.plan?.commercialPremium)
                    .toString()
                )}
              </Box>
            </Grid>
            <Grid item md={3} xs={6}>
              <Box fontWeight="bold">
                <Box>Prêmio Líquido</Box>
              </Box>
              <Box>{moneyFormat(quote?.plan?.commercialPremium).toString()}</Box>
            </Grid>
            <Grid item md={3} xs={6}>
              <Box fontWeight="bold">Valor Total</Box>
              <Box>{moneyFormat(quote?.plan?.grossPremium)}</Box>
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="space-between">
            <Grid item md={5} xs={6}>
              <Box pl={3} mt={2}>
                <RadioButton
                  id="radio-PaymentMethod"
                  label="Forma de pagamento"
                  itemsRadio={paymentManager.getPaymentMethodOptions(hasPolicyOwner, quote)}
                  onChange={(_event, value) => {
                    setPaymentMethod(value);
                  }}
                  valueRadio={
                    quote?.plan?.type === TRADITIONAL && hasPolicyOwner ? paymentMethod : true
                  }
                  bool
                  bold
                  disabled={disabled}
                />
              </Box>
              <Box marginTop={3} marginLeft={3}>
                {quote?.plan?.type !== MONTHLY && (
                  <>
                    <Box fontWeight="bold" mb={1}>
                      Parcelamento
                    </Box>
                    <AutoCompleteForm
                      value={optionsAutocomplete.find(option => option === monthText)}
                      defaultValue={optionsAutocomplete[0]}
                      options={optionsAutocomplete}
                      id={'autocompleteInstallment'}
                      onInputChange={onInputChangeAutocomplete}
                      onChange={changeAutocomplete}
                      label={'Digite ou selecione o número de parcelas'}
                      width={550}
                      error={errors?.payment?.paymentCard?.installments}
                      disabled={disabled}
                    />
                  </>
                )}
              </Box>
            </Grid>
            <Grid item md={5} xs={6}>
              <Installment
                months={quote?.plan?.type !== MONTHLY ? parseInt(months) : installments}
                value={quote?.plan?.grossPremium}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

PaymentMethodCard.propTypes = {
  months: PropTypes.number.isRequired,
  setMonths: PropTypes.func.isRequired
};

export default PaymentMethodCard;

import Button from '@material-ui/core/Button';
import PropType from 'prop-types';
import React from 'react';
import HelperButtonClasses from './classes/HelperButtonClasses';

const HelperButton = function({ zIndex }) {
  const classes = HelperButtonClasses(zIndex)();

  return (
    <Button className={classes.root} variant="contained" color="secondary">
      AJUDA
    </Button>
  );
};

HelperButton.propTypes = {
  zIndex: PropType.number.isRequired
};

export default HelperButton;

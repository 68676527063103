import axios from 'axios';

class PolicyService {
  create = async policicyRequest => {
    const response = await axios.post(`${window.env.RENTAL_SURETY}/Policies`, policicyRequest);

    return response.data;
  };
}

export default new PolicyService();

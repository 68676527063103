import PropTypes from 'prop-types';
import React from 'react';
import InternalInfoHeader from '../../analyze/cardsAnalyzeResult/InternalInfoHeader';
import InternalBodyAcceptModal from './InternalBodyAcceptModal';
import InternalBodySubmittedModal from './InternalBodySubmittedModal';
import InternalFooterAcceptModal from './InternalFooterAcceptModal';
import InternalFooterSubmittedModal from './InternalFooterSubmittedModal';

const InfoAcceptModal = ({ continueClick, closeClick, submittedModal, returnToHome }) => {
  return submittedModal ? (
    <>
      <InternalInfoHeader title={'Proposta transmitida com sucesso!'} />
      <InternalBodySubmittedModal />
      <InternalFooterSubmittedModal
        returnToHome={returnToHome}
      />
    </>
  ) : (
      <>
        <InternalInfoHeader title={'Aceite de assinatura eletrônica'} />
        <InternalBodyAcceptModal />
        <InternalFooterAcceptModal continueClick={continueClick} closeClick={closeClick} />
      </>
    );
};

InfoAcceptModal.propTypes = {
  continueClick: PropTypes.func.isRequired,
  closeClick: PropTypes.func.isRequired,
  submittedModal: PropTypes.bool,
  returnToHome: PropTypes.func.isRequired
};

export default InfoAcceptModal;

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DateInput from '../../../../../../shared/components/form/DateInput';
import NumberInput from '../../../../../../shared/components/form/NumberInput';
import {
  EMPTY_STRING,
  ENDINSURANCECONTRACT,
  ENDLEASECONTRACT,
  STARTINSURANCECONTRACT,
  STARTLEASECONTRACT
} from '../../../../../../shared/constants/constants';
import { MULTIPLE_BASIC } from '../../../../../../shared/constants/coverageConstants';
import { dateValidator } from '../../../../../../shared/helper/formValidators';
import insuranceDataManager from '../../../../manager/insurance/quote/insuranceData/insuranceDataManager';
import { errorsActions, quoteActions } from '../../../../redux';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import {
  useAnalyze,
  useAnalyzePolicyOwner
} from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import { useQuote } from '../../../../redux/insurance/quote/quoteCustomHooks';
import FormClasses from '../../classes/FormClasses';

const InsuranceData = ({ getQuotePremium, disabled }) => {
  const dispatch = useDispatch();
  const quote = useQuote();
  const analyze = useAnalyze();
  const policyOwner = useAnalyzePolicyOwner();
  const errors = useErrors();
  const classes = FormClasses();
  const [insuranceDays, setInsuranceDays] = useState();
  const [contractDays, setContractDays] = useState();
  useEffect(() => {
    if (
      !dateValidator(quote?.startInsuranceContract) &&
      !dateValidator(quote?.endInsuranceContract)
    ) {
      setInsuranceDays(
        insuranceDataManager.getDays(quote?.startInsuranceContract, quote?.endInsuranceContract)
      );
    } else {
      setInsuranceDays(EMPTY_STRING);
    }

    if (!dateValidator(quote?.startLeaseContract) && !dateValidator(quote?.endLeaseContract)) {
      setContractDays(
        insuranceDataManager.getDays(quote?.startLeaseContract, quote?.endLeaseContract)
      );
    } else {
      setContractDays(EMPTY_STRING);
    }
  }, [quote]);

  const calculateDifference = (startDate, endDate, value, start) => {
    let difference = 0,
      installments = 0;

    if (
      !dateValidator(value) &&
      ((start && !dateValidator(endDate)) || (!start && !dateValidator(startDate)))
    ) {
      difference = insuranceDataManager.getDateDifference(startDate, endDate, value, start);
      installments = insuranceDataManager.getInstallMents(parseInt(difference));

      if (policyOwner) {
        getQuotePremium(installments, !quote?.plan?.multiple ? MULTIPLE_BASIC : null, null);
      }
    }

    return {
      difference: difference,
      installments: installments
    };
  };

  const updateValuesInsuranceContract = (name, value, start) => {
    dispatch(quoteActions.setQuote({ ...quote, [name]: value }));

    if (!dateValidator(value)) {
      let quoteModified = { ...quote, [name]: value };
      dispatchFormErrors(quoteModified);
    }
  };

  const updateValuesLeaseContract = (name, value, start) => {
    dispatch(quoteActions.setQuote({ ...quote, [name]: value }));
    if (!dateValidator(value)) {
      let quoteModified = { ...quote, [name]: value };

      const differenceResponse = calculateDifference(
        quoteModified?.startLeaseContract,
        quoteModified?.endLeaseContract,
        value,
        start
      );

      if (name === ENDLEASECONTRACT) {
        quoteModified = { ...quoteModified, [ENDINSURANCECONTRACT]: value };
      } else {
        if (quoteModified?.startLeaseContract >= new Date(analyze?.underwritingResponseDate)) {
          quoteModified = {
            ...quoteModified,
            [STARTINSURANCECONTRACT]: quoteModified?.startLeaseContract
          };

          dispatch(
            errorsActions.setErrors({
              ...errors,
              insuranceData: { ...errors.insuranceData, [STARTINSURANCECONTRACT]: EMPTY_STRING }
            })
          );
        }
      }

      dispatch(
        quoteActions.setQuote({
          ...quoteModified,
          installments: differenceResponse.installments
        })
      );
      dispatchFormErrors(quoteModified);
    }
  };

  const dispatchFormErrors = (quoteModified = quote) => {
    dispatch(
      errorsActions.setErrors({
        ...errors,
        insuranceData: insuranceDataManager.validateInsurance(quoteModified, analyze)
      })
    );
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={8}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <DateInput
                id={STARTLEASECONTRACT}
                className={classes.datePicker}
                label="Início da vigência do contrato"
                value={quote?.startLeaseContract}
                error={errors?.insuranceData?.startLeaseContract}
                onChange={(_event, value) => {
                  updateValuesLeaseContract(
                    STARTLEASECONTRACT,
                    new Date(value?.setHours(0, 0, 0, 0)),
                    true
                  );
                }}
                required
                start
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateInput
                id={ENDLEASECONTRACT}
                className={classes.datePicker}
                label="Fim da vigência do contrato"
                value={quote?.endLeaseContract}
                error={errors?.insuranceData?.endLeaseContract}
                onChange={(_event, value) => {
                  updateValuesLeaseContract(
                    ENDLEASECONTRACT,
                    new Date(value?.setHours(0, 0, 0, 0))
                  );
                }}
                required
                initialDate={quote?.startInsuranceContract}
                finalDate={quote?.endInsuranceContract}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <NumberInput
                id="contractDays"
                value={contractDays}
                label="Total de dias do contrato"
                error={errors?.insuranceData?.contractDays}
                onChange={() => {}}
                disabled
                disableUnderline
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateInput
                id={STARTINSURANCECONTRACT}
                className={classes.datePicker}
                label="Início da vigência do seguro"
                value={quote?.startInsuranceContract}
                error={errors?.insuranceData?.startInsuranceContract}
                required
                start
                onBlur={_ => dispatchFormErrors()}
                onChange={(_event, value) => {
                  updateValuesInsuranceContract(
                    STARTINSURANCECONTRACT,
                    value ? new Date(value.setHours(0, 0, 0, 0)) : EMPTY_STRING,
                    true
                  );
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateInput
                id={ENDINSURANCECONTRACT}
                className={classes.datePicker}
                label="Fim da vigência do seguro"
                value={quote?.endInsuranceContract}
                error={errors?.insuranceData?.endInsuranceContract}
                required
                onChange={(_event, value) => {
                  updateValuesInsuranceContract(
                    ENDINSURANCECONTRACT,
                    new Date(value?.setHours(0, 0, 0, 0))
                  );
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <NumberInput
                id="insuranceDays"
                value={insuranceDays}
                label="Total de dias do seguro"
                error={errors?.insuranceData?.insuranceDays}
                onChange={() => {}}
                disabled
                disableUnderline
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

InsuranceData.propTypes = {
  getQuotePremium: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default InsuranceData;
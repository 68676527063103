import { APPROVAL_LETTER, REFUSAL_LETTER } from '../../../../shared/constants/constants';
import { BROKER } from '../../../../shared/constants/personConstants';
import { SubscriptionStatus } from '../../../../shared/enums/statusEnum';
import { getAnalyzePolicyOwner } from '../insurance/analyze/analyzeSelectors';

function mapperPerson(person) {
  return {
    name: person.name,
    documentNumber: person.documentNumber
  };
}

export function mapperDocumentLetter(analyze, quoteSequential, expenses) {
  let broker = mapperPerson(
    analyze?.commissionedAgents.find(c => c.lead && c.role.toUpperCase() === BROKER)
  );
  broker.licenseNumber = analyze?.commissionedAgents.find(
    c => c.lead && c.role.toUpperCase() === BROKER
  )?.licenseNumber;

  let endsAt = new Date(analyze?.underwritingResponseDate);
  endsAt.setDate(endsAt.getDate() + 30);

  const policyOwner = getAnalyzePolicyOwner(analyze?.commissionedAgents);

  return {
    insuranceId: analyze?.id,
    key: analyze?.status === SubscriptionStatus.DENIED ? REFUSAL_LETTER : APPROVAL_LETTER,
    templateData: {
      quoteSequential: quoteSequential,
      creditLine: analyze?.approvedLimit,
      expensesValue: expenses.reduce((total, expense) => total + expense.value, 0),
      solidaryPolicyHolders: [],
      policyHolder: mapperPerson(analyze?.policyHolder),
      broker: broker,
      policyOwner: policyOwner ? mapperPerson(policyOwner) : {},
      createdAt: analyze?.createdAt,
      approvedAt: analyze?.underwritingResponseDate,
      recusedAt: analyze?.underwritingResponseDate,
      validadeAt: endsAt.toLocaleString('en-Us')
    }
  };
}

export function mapperDocumentQuoteRequest(quote) {
  return {
    id: quote?.id,
    startLeaseContract: quote?.startLeaseContract,
    endLeaseContract: quote?.endLeaseContract
  };
}

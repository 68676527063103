import { Box, Button, Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import WelcomeCardClasses from './classes/WelcomeCardClasses';

const WelcomeCard = function({ nameCard, logo, textButton, titleCard, path }) {
  const classes = WelcomeCardClasses();

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Box px={3} py={4} color="#303E59">
          <Grid item xs={12} p={20}>
            <Box
              pb={2}
              textAlign="center"
              fontSize={17}
              fontWeight="fontWeightBold"
              fontFamily="'Poppins', sans-serif"
            >
              {titleCard}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Box py={2}>
                <img
                  className={classes.image}
                  alt="Pottencial"
                  src={`${process.env.PUBLIC_URL}/assets/images/${logo}`}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Box pb={2} textAlign="center" fontSize={14} fontWeight={500} fontFamily="Open Sans">
                {nameCard}
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Box pb={2}>
                <Link to={path} className={classes.link}>
                  <Button variant="contained" className={classes.buttonCard}>
                    {textButton}
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Box fontSize={13} fontStyle="italic" color="#fd5d2b">
                Saber mais
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default WelcomeCard;

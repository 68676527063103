import * as userActions from './userActions';

export const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  if (action.type === userActions.SET_USER) {
    return {
      ...state,
      ...action.payload
    };
  } else {
    return state;
  }
};

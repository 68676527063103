import { EMPTY_STRING } from '../../constants/constants';

const formatMoney = value => {
  const valueFormated = parseFloat(value).toLocaleString();
  const separatePosition = valueFormated.toString().indexOf(',');
  const numbersAfterSeparate = valueFormated.substring(separatePosition).length;

  return separatePosition < 0
    ? valueFormated + ',00'
    : numbersAfterSeparate < 3
    ? valueFormated + '0'
    : valueFormated;
};

export const moneyFormat = (value, notReal) => {
  return !isNaN(value)
    ? (notReal ? EMPTY_STRING : 'R$') + formatMoney(value)
    : (notReal ? EMPTY_STRING : 'R$') + formatMoney(0);
};

import * as plansActions from '../plans/plansActions';

export const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case plansActions.SET_PLANS:
      return {
        ...state,
        ...action.payload
      };
    case plansActions.SET_COVERAGE_INSURED_AMOUNT:
      return {
        ...state,
        plans: state.plans?.map((plan, index) =>
          index === action.indexPlan
            ? {
                ...plan,
                coverages: plan.coverages.map((coverage, indexCoverage) =>
                  indexCoverage === action.indexCoverage
                    ? {
                        ...coverage,
                        insuredAmount: { ...coverage.insuredAmount, value: action.payload }
                      }
                    : { ...coverage }
                )
              }
            : { ...plan }
        )
      };
    case plansActions.SET_MULTIPLE_BASIC:
      return {
        ...state,
        plans: state?.plans?.map(plan =>
          plan
            ? {
                ...plan,
                coverages: plan.coverages.map(coverage =>
                  coverage.basicCoverage
                    ? {
                        ...coverage,
                        multiple: action.payload
                      }
                    : { ...coverage }
                )
              }
            : { ...plan }
        )
      };
    default:
      return state;
  }
};

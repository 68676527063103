import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React from 'react';
import { Styles, theme } from './classes/SearchInputClasses';

export default function SearchInput({ value, onChange, onBlur, label, id }) {
  const classes = Styles()();

  return (
    <ThemeProvider theme={theme}>
      <FormControl className={classes.formControl}>
        <Input
          id={id}
          placeholder={label}
          className={classes.input}
          value={value}
          label={label}
          startAdornment={
            <InputAdornment className={classes.icon} position="start">
              <SearchIcon />
            </InputAdornment>
          }
          onChange={e => {
            onChange(e.target.value);
          }}
          onBlur={() => {
            if (onBlur) {
              onBlur(value);
            }
          }}
        />
      </FormControl>
    </ThemeProvider>
  );
}

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string
};

import { makeStyles } from '@material-ui/core/styles';

const Styles = zIndex =>
  makeStyles(theme => ({
    root: {
      position: 'fixed',
      bottom: 30,
      right: 30,
      width: 115,
      heigth: 103,
      borderRadius: 50,
      zIndex,
      [theme.breakpoints.down('sm')]: {
        right: 5,
        bottom: 5,
        width: 95
      }
    }
  }));

export default Styles;

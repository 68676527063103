import { Box, Grid, IconButton, Paper } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import React from 'react';
import { useAnalyze } from '../../features/quiz/redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../features/quiz/redux/insurance/policy/policyCustomHooks';
import { EMPTY_STRING } from '../constants/constants';
import { POLICYOWNER } from '../constants/personConstants';
import { TRANSMITTED } from '../enums/statusEnum';
import ShowDataClasses from './classes/ShowDataClasses';
import { nameFormat } from './format/NameFormat';

const ShowData = ({ data, index, textTitle, deleteClick, openClick, type, block, deleteBtn }) => {
  const analyze = useAnalyze();
  const policy = usePolicy();
  const classes = ShowDataClasses();
  const edit = type === POLICYOWNER && !(analyze?.status === TRANSMITTED || policy?.id);

  return (
    <>
      <Box pb={1} key={`boxShowData-${index}`}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={10}>
              <Box p={2} pl={5} textAlign="left">
                <p className={classes.header}>{textTitle}</p>
                <p className={classes.title}>{data?.name ? nameFormat(data.name) : EMPTY_STRING}</p>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box p={2} pt={4.5} textAlign="end">
                {deleteBtn && (
                  <IconButton
                    id="btn-delete"
                    onClick={() => {
                      deleteClick(index, type);
                    }}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                )}
                {edit && (
                  <IconButton
                    id="btn-edit"
                    onClick={() => {
                      openClick(index, type);
                    }}
                  >
                    <EditIcon className={classes.deleteIcon} />
                  </IconButton>
                )}
                {block && (
                  <IconButton id="btn-block">
                    <LockIcon className={classes.deleteIcon} />
                  </IconButton>
                )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box p={2} pt={4.5} textAlign="left">
                <IconButton
                  id="btn-open"
                  onClick={() => {
                    openClick(index, type);
                  }}
                >
                  <ArrowForwardIosIcon className={classes.deleteIcon} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

ShowData.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  textTitle: PropTypes.string.isRequired,
  deleteClick: PropTypes.func.isRequired,
  openClick: PropTypes.func.isRequired,
  block: PropTypes.bool,
  deleteBtn: PropTypes.bool
};

export default ShowData;

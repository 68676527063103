import { Box, Grid, Paper, Step, StepLabel, Stepper } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ToastError from '../../../../shared/components/modal/toasts/ToastError';
import {
  EMPTY_STRING, TOAST_QUIZ_VALIDATION_ERROR
} from '../../../../shared/constants/constants';
import { POLICY } from '../../../../shared/constants/quizConstants';
import analyzeManager from '../../manager/insurance/analyze/analyzeManager';
import proposalManager from '../../manager/insurance/proposal/proposalManager';
import quoteManager from '../../manager/insurance/quote/quoteManager';
import { errorsActions, quizOperations } from '../../redux';
import { useErrors } from '../../redux/errors/errorsCustomHooks';
import { useAnalyze } from '../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../redux/insurance/policy/policyCustomHooks';
import { useProposal } from '../../redux/insurance/proposal/proposalCustomHooks';
import { useQuote } from '../../redux/insurance/quote/quoteCustomHooks';
import { useSteps } from '../../redux/quizCustomHooks';
import ImageStepperClasses from './classes/ImageStepperClasses';
import ImageStepperCard from './ImageStepperCard';

const ImageStepper = function () {
  const classes = ImageStepperClasses()();
  const [toastOpen, setToastOpen] = useState(false);
  const steps = useSteps();
  const errors = useErrors();
  const analyze = useAnalyze();
  const quote = useQuote();
  const proposal = useProposal();
  const dispatch = useDispatch();
  const policy = usePolicy();

  function getFormErrors(errors) {
    const index = steps.find(step => step.activated === true).id;

    switch (index) {
      case 1:
        return analyzeManager.formValidate(analyze, proposal, errors);
      case 2:
        return quoteManager.formValidate(quote, analyze);
      case 3:
        return proposalManager.formValidate(proposal, quote);
      default:
    }
  }

  const changeStep = (completed, index) => {
    const hasError = getFormErrors(errors);
    dispatch(errorsActions.setErrors(getFormErrors(errors)));

    if (completed && !hasError) {
      dispatch(quizOperations.activateStep(index));
    } else {
      setToastOpen(true);
    }
  };

  return (
    <>
      <ToastError open={toastOpen} text={TOAST_QUIZ_VALIDATION_ERROR} setOpenToast={setToastOpen} />
      <Grid container item justify="center">
        <Grid item xs={12} md={8}>
          <Box id="stepperImage" justify="center" alignItems="center" mt={10} height="auto">
            <Grid container justify="space-around" alignItems="center">
              {steps.map(
                (
                  { id, title, name, statusTitle, statusDescription, activated, completed },
                  index
                ) => (
                  <Paper
                    key={`paper-${title}`}
                    className={`${classes.paper} ${activated ? classes.activated : classes.card} ${completed ? classes.completed : classes.card
                      }}`}
                    elevation={3}
                  >
                    <ImageStepperCard
                      imageStepperSettings={{
                        title: policy?.id && id === 3 ? POLICY.title : title,
                        statusTitle: policy?.id && id === 3 ? POLICY.statusTitle : statusTitle,
                        image: activated || completed ? `${name}_active.svg` : `${name}.svg`,
                        statusDescription:
                          policy?.id && id === 3
                            ? POLICY.statusDescription.concat(
                              new Date(policy?.issuedAt).toLocaleDateString()
                            )
                            : statusDescription,
                        completed: completed,
                        activated: activated,
                        index: index,
                        id: id
                      }}
                      changeStep={changeStep}
                    />
                  </Paper>
                )
              )}
            </Grid>
          </Box>
          <Grid container justify="center">
            <Grid item xs={12} md={10}>
              <Stepper orientation="horizontal">
                {steps.map(({ id, title, completed, activated }, index) => (
                  <Step key={title}>
                    <StepLabel
                      completed={completed && !activated}
                      active={activated}
                      id={`labelMenu-${id}`}
                      onClick={e => {
                        changeStep(completed, index);
                      }}
                    >
                      {completed && (
                        <p className={classes.completedText}>
                          {completed && !activated ? index + 1 : EMPTY_STRING}
                        </p>
                      )}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ImageStepper;

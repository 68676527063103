import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { moneyFormat } from '../../../../../../shared/components/format/MoneyFormat';
import { TITLE_MAX_LIMIT, TITLE_USED_LIMIT } from '../../../../../../shared/constants/constants';
import calculateManager from '../../../../manager/calculate/calculateManager';
import { useAnalyze } from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import InternalClasses from '../../classes/InternalClasses';

const ApprovedLimitInfo = ({ lineWidth, componentWidth, componentMargin }) => {
  const classes = InternalClasses();
  const analyze = useAnalyze();

  return (
    <>
      <Box pt={1} className={classes.limits} width={componentWidth} marginLeft={componentMargin}>
        <Box>
          <div className={classes.text}>{TITLE_USED_LIMIT}</div>
          <b className={classes.usedLimit}>
            {moneyFormat(calculateManager.getReduce(analyze?.expenses, 'value')).toString()}
          </b>
        </Box>
        <Box display="flex" flexDirection="row" height="39px">
          <Box className={classes.dot} />
          <Box className={classes.line} width={lineWidth} />
          <Box className={`${classes.dot} ${classes.dotFilled}`} />
        </Box>
        <Box>
          <div className={classes.text}>{TITLE_MAX_LIMIT}</div>
          <b className={classes.approvedLimit}>{moneyFormat(analyze?.approvedLimit).toString()}</b>
        </Box>
      </Box>
    </>
  );
};

ApprovedLimitInfo.propTypes = {
  lineWidth: PropTypes.number.isRequired,
  componentWidth: PropTypes.number.isRequired,
  componentMargin: PropTypes.number.isRequired
};

export default ApprovedLimitInfo;

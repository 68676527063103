import { batch } from 'react-redux';
import { proposalActions } from '../..';
import { PROPOSAL_STEP } from '../../../../../shared/constants/constants';
import policiesServiceManager from '../../../services/policies/policiesServiceManager';
import proposalsServiceManager from '../../../services/proposals/proposalsServiceManager';
import * as quizActions from '../../quizActions';
import { useCreatePolicyRequest } from '../policy/policyCustomHooks';
import { useCreateProposalRequest } from './proposalCustomHooks';

export const create = () => async (dispatch, getState) => {
  const {
    insurance: { analyze, quote, proposal },
    user,
    quiz: { steps }
  } = getState();
  dispatch(quizActions.setLoading(true));

  try {
    const createProposalResponse = await proposalsServiceManager.create(
      useCreateProposalRequest(analyze, user?.personId, quote, proposal)
    );

    await policiesServiceManager.create(
      useCreatePolicyRequest(analyze.id, createProposalResponse.id, user?.personId)
    );

    batch(() => {
      dispatch(quizActions.setStep({ ...steps[PROPOSAL_STEP], submitted: true }));
      dispatch(
        proposalActions.setProposal({
          ...proposal,
          id: createProposalResponse.id,
          status: createProposalResponse.status,
          createdAt: createProposalResponse.createdAt,
          proposalDocumentName: createProposalResponse.proposalDocumentName,
          proposalSequential: createProposalResponse.proposalSequential
        })
      );
    });
  } catch (err) {
    dispatch(quizActions.setError(true));
  }
};

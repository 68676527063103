import axios from 'axios';

class FilesService {
  create = async file => {
    const response = await axios.post(`${window.env.RENTAL_SURETY}/Files`, file);

    return response.data;
  };

  get = async fileId => {
    const response = await axios.get(`${window.env.RENTAL_SURETY}/Files/${fileId}`);

    return response.data;
  };

  put = async file => {
    const response = await axios.put(`${window.env.RENTAL_SURETY}/Files/${file.id}`, file);

    return response.data;
  };

  delete = async fileId => {
    const response = await axios.delete(`${window.env.RENTAL_SURETY}/Files/${fileId}`);

    return response.data;
  };
}

export default new FilesService();

import { Box, Button, Grid } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { QUOTE_STEP } from '../../../../../../../shared/constants/constants';
import { TRANSMITTED } from '../../../../../../../shared/enums/statusEnum';
import { quizOperations } from '../../../../../redux';
import { useAnalyze } from '../../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../../redux/insurance/policy/policyCustomHooks';
import ModalCoveragesPaymentClasses from '../classes/ModalCoveragesPaymentClasses';

const FooterPaymentCard = function () {
  const classes = ModalCoveragesPaymentClasses();
  const dispatch = useDispatch();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const disabled = (analyze?.status === TRANSMITTED || policy?.id);
  const changeStep = () => {
    dispatch(quizOperations.activateStep(QUOTE_STEP));
  };

  return (
    <>
      <Box pb={5} textAlign="center">
        <Grid item xs={12}>
          <Box pb={2}>
            <Button
              id="btn-editPlan"
              variant="contained"
              disabled={disabled}
              className={`${classes.button} ${classes.buttonEmpty} ${classes.buttonCard}`}
              onClick={() => changeStep()}
            >
              Editar
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default FooterPaymentCard;

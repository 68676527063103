import * as intermediatePersonActions from './intermediatePersonActions';

export const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  if (action.type === intermediatePersonActions.SET_INTERMEDIATE_PERSONS) {
    return action.payload;
  }

  return state;
};

import { Box, Button, Grid, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { COVERAGE_GENERAL_CONDITIONS } from '../../../constants/coverageConstants';
import InternalInfoClasses from '../classes/InternalComponentClasses';

const InternalComponentCoverage = ({ coverage, cancelClick }) => {
  const classes = InternalInfoClasses();

  const cancelOnClick = () => {
    cancelClick()
  };

  return (
    <>
      <Box px={5} pt={2} pb={0} className={classes.header}>
        {coverage.required ? 'Cobertura Básica' : 'Cobertura Adicional'}
      </Box>
      <Box px={5} pt={1} pb={0} className={classes.title}>
        {coverage.name}
      </Box>
      <Box px={5} pt={0} >
        <hr className={classes.lineTitle}></hr>
      </Box>
      <Box px={5} pt={2} justify="center" className={classes.text} overflow="auto">
        {coverage.description}
        <br />
        <Link rel="noopener noreferrer" target="_blank" id="btn-details" className={classes.link} href={COVERAGE_GENERAL_CONDITIONS}>
          Veja também nossas condições gerais.
        </Link>
      </Box>
      <Grid item xs={12} className={classes.gridButton}>
        <Box px={3} py={3} display="flex" flexDirection="row-reverse">
          <Button
            id="btn-back"
            variant="contained"
            className={`${classes.button} ${classes.buttonCardSelect}`}
            onClick={cancelOnClick}
          >
            Voltar
        </Button>
        </Box>
      </Grid>
    </>
  );
};

InternalComponentCoverage.propTypes = {
  coverage: PropTypes.object.isRequired,
  cancelClick: PropTypes.func
};

export default InternalComponentCoverage;

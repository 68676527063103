import { routes } from './routes';

export const EMPTY_OBJECT = {};
export const LIST_HEADER = [
  { id: 0, description: 'Nova Solicitação', path: routes.home.path },
  { id: 1, description: 'Minhas Solicitações', path: routes.requests.path },
  { id: 2, description: 'Sinistro', path: routes.sinister.path }
];

export const HEADER_FILTER_REQUEST_PAGE = [
  { id: 0, name: 'Etapa', xs: 1, orderBy: false, pl: 2 },
  { id: 1, name: 'Data', xs: 2, orderBy: true, pl: 1 },
  { id: 2, name: 'Locatário', xs: 2, orderBy: false, pl: 0 },
  { id: 3, name: 'Doc. Locatário', xs: 2, orderBy: false, pl: 0 },
  { id: 4, name: 'Estipulante', xs: 2, orderBy: false, pl: 0 },
  { id: 5, name: 'Produto', xs: 1, orderBy: false, pl: 0 },
  { id: 6, name: 'Status da Solicitação', xs: 2, orderBy: false, pl: 0 }
];
export const BANK_SLIP = 'Boleto';
export const INVOICE = 'Fatura';
export const STARTLEASECONTRACT = 'startLeaseContract';
export const ENDINSURANCECONTRACT = 'endInsuranceContract';
export const STARTINSURANCECONTRACT = 'startInsuranceContract';
export const ENDLEASECONTRACT = 'endLeaseContract';
export const EXPENSES_TITLE = 'Aluguel e Encargos';
export const ANALYZE_STEP = 0;
export const QUOTE_STEP = 1;
export const PROPOSAL_STEP = 2;
export const NAME = 'name';
export const TYPE = 'type';
export const PERSONID = 'personId';
export const DOCUMENT = 'documentNumber';
export const REFUSAL_LETTER = 'produto-fiancalocaticia-letter-recused';
export const APPROVAL_LETTER = 'produto-fiancalocaticia-letter-approved';
export const APPROVAL_TEMPLATE = 'fiancalocaticia-letter-approved';
export const REFUSAL_TEMPLATE = 'fiancalocaticia-letter-approved';
export const PROPOSAL_TEMPLATE = 'fiancalocaticia-proposal';
export const QUOTE_TEMPLATE = 'plataforma-fiancalocaticia-quote';
export const RENT_EXPENSE = 'VALOR_ALUGUEL';
export const EMPTY_STRING = '';
export const STATUS_TITLE_SUBSCRIPTION = 'Aprovado';
export const STATUS_DESCRIPTION_SUBSCRIPTION = 'Limite Aprovado: ';
export const TITLE_USED_LIMIT = 'Limite Utilizado: ';
export const TITLE_MAX_LIMIT = 'Limite Máximo: ';
export const INPUT_EXPENSES_RENTAL = 'expenses-0';
export const ANALYZE_STEP_ID = 1;
export const PROPOSAL_STEP_ID = 3;
export const TEXT_ANALYZE = 'ANÁLISE DE CRÉDITO';
export const TEXT_QUOTE = 'COTAÇÃO';
export const TEXT_PROPOSAL = 'PROPOSTA';
export const TEXT_POLICY = 'APÓLICE';
export const ISSUED = 'Issued';
export const DOSENT_HAVE = 'Não Possui';
export const ID_STATUS_INPUT = 'status';
export const PRODUCT = 'Aluguel';
export const SAVE_AND_GO_BACK = 'SALVAR E VOLTAR AO INÍCIO';
export const GO_BACK_TO_REQUESTS = 'VOLTAR PARA MINHAS SOLICITAÇÕES';
export const FORWARD_PROPOSAL = 'TRANSMITIR PROPOSTA';
export const GENERATE_ENDORSEMENT = 'GERAR ENDOSSO';
export const OK = 'OK';
export const GO_BACK = 'VOLTAR AO INÍCIO';
export const DOWNLOAD_PROPOSAL = 'BAIXAR PROPOSTA';
export const CANCEL = 'CANCELAR';
export const READ_AND_ACCEPT = 'LI E ACEITO';
export const WARNING_ICON = 'icon_warningmodal.svg';
export const CORRECTION_INDEX_OPTIONS = [
  { key: 1, itemValue: 'IGP-DI' },
  { key: 2, itemValue: 'IGP-M' },
  { key: 3, itemValue: 'IPC' },
  { key: 4, itemValue: 'IPCA' },
  { key: 5, itemValue: 'INPC' },
  { key: 6, itemValue: 'ICV' }
];
export const COMPLIANCE_FOLDER = 'cartaadimp';
export const TEXT_ACCEPT_MODAL =
  'Confirmo que foram conferidas as condições e coberturas para a contratação do seguro fiança referente ao contrato de locação em nome de {insuredNames} e solicito a emissão da apólice em conformidade com a proposta transmitida eletronicamente neste dia e horário.';
export const INSURED_NAMES = '{insuredNames}';
export const TEXT_SUBMITTED_MODAL_REICEIVED = 'Recebemos a proposta de número';
export const TEXT_SUBMITTED_MODAL_AWAIT_EMISSION =
  'A apólice esta sendo emitida e estará disponível em breve na sessão "minhas solicitações".';
export const CURRENT_LEASE = 'hasComplianceLetter';
export const CORRECTION_INDEX = 'correctionIndex';
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const DISABLE_ALL_FIELDS = 1;
export const DISABLE_SOME_FIELDS = 2;
export const HUNDRED_MULTIPLIER = 100;
export const LIMIT_EXPOSURE_EXCEEDED =
  'Com os valores definidos para este documento o locatário ultrapassa o limite de acumulo previsto para uma única pessoa, por favor reduza o valor segurado para até ';
export const TOAST_QUIZ_VALIDATION_ERROR = 'Por favor, verifique o formulário antes de prosseguir.';
export const RESIDENTIAL = 'Residencial';
export const COMMERCIAL = 'Comercial';
export const DAY_IN_MILISECONDS = 86400000;

import Grid from '@material-ui/core/Grid';
import React from 'react';
import Quiz from './Quiz';

const QuizPage = function () {
  return (
    <>
      <Grid container alignItems="center" spacing={4} key="quiz">
        <Quiz />
      </Grid>
    </>
  );
};

export default QuizPage;

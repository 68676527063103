import makeStyles from '@material-ui/styles/makeStyles';

const Styles = makeStyles({
  icon: {
    color: '#c4dce1'
  },
  hidden: {
    display: 'none'
  },
  description: {
    font: 'normal normal normal 13px/16px Open Sans;',
    letterSpacing: 0,
    color: '#282F3C'
  },
  info: {
    height: 15,
    color: '#505661',
    fontWeight: 'normal'
  }
});

export default Styles;

export const HTML_LOADING =
  '<style>' +
  '.stage {' +
  'display: flex;' +
  'justify-content: center;' +
  'align-items: center;' +
  'overflow: hidden;' +
  'padding-top: 25%;' +
  '}' +
  '.container{' +
  'align-items: center;' +
  'vertical-align: center;' +
  'position: relative;' +
  'overflow: hidden;' +
  'height: 100%;' +
  '}' +
  '.dot-flashing {' +
  'position: relative;' +
  'width: 20px;' +
  'height: 20px;' +
  'border-radius: 10px;' +
  'background-color: #f8b144;' +
  'color: #f8b144;' +
  'animation: dotFlashing 1s infinite linear alternate;' +
  'animation-delay: .5s;' +
  '}' +
  '@keyframes dotFlashing {' +
  '0% {' +
  'background-color: #f8b144;' +
  '}' +
  '50%,' +
  '100% {' +
  'background-color: #fd742b;' +
  '}' +
  '}' +
  '.dot-flashing::before {' +
  'left: -25px;' +
  'width: 20px;' +
  'height: 20px;' +
  'border-radius: 10px;' +
  'background-color: #fd742b;' +
  'color: #fd742b;' +
  'animation: dotFlashing 1s infinite alternate;' +
  'animation-delay: 0s;' +
  '}' +
  '.dot-flashing::after {' +
  'left: 25px;' +
  'width: 20px;' +
  'height: 20px;' +
  'border-radius: 10px;' +
  'background-color: #d35b08;' +
  'color: #d35b08;' +
  'animation: dotFlashing 1s infinite alternate;' +
  'animation-delay: 1s;' +
  '}' +
  '.dot-flashing::before, .dot-flashing::after {' +
  "content: '';" +
  'display: inline-block;' +
  'position: absolute;' +
  'top: 0;' +
  '}' +
  '</style>' +
  '<main>' +
  '<div class="container">' +
  '<div class="stage">' +
  '<div class="dot-flashing"></div>' +
  '</div>' +
  '</div>' +
  '</main>';

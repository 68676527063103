import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { EMPTY_STRING } from '../../../../../../../shared/constants/constants';
import coverageManager from '../../../../../manager/insurance/quote/plan/coverageManager';
import plansManager from '../../../../../manager/insurance/quote/plan/plansManager';
import { errorsActions, quoteActions } from '../../../../../redux';
import { useErrors } from '../../../../../redux/errors/errorsCustomHooks';
import { useAnalyze } from '../../../../../redux/insurance/analyze/analyzeCustomHooks';
import { useQuote } from '../../../../../redux/insurance/quote/quoteCustomHooks';
import BodyPlansCard from './BodyPlansCard';
import FooterPlansCard from './FooterPlansCard';
import HeaderPlansCard from './HeaderPlansCard';


const PlansCard = function ({
  rentalPlan,
  selectedPlanSettings,
  coveragesSettings
}) {
  const quote = useQuote();
  const analyze = useAnalyze();
  const dispatch = useDispatch();
  const initialCoverage = coverageManager.getInitialCoverage(
    rentalPlan.coverages,
    quote
  );
  const errors = useErrors();
  const [coverageSelected, setCoverageSelected] = useState(initialCoverage);

  const planCoveragesSettings = {
    showCoverages: coveragesSettings.showCoverages,
    setShowCoverages: coveragesSettings.setShowCoverages,
    coverages: rentalPlan.coverages,
    coverageSelected: coverageSelected,
    setCoverageSelected: setCoverageSelected
  };

  const choosePlan = async () => {
    plansManager.choosePlan(
      selectedPlanSettings.setSelectedPlan,
      rentalPlan
    );

    const newPlan = await plansManager.getPlanSelected(quote, analyze, rentalPlan, rentalPlan.coverages, coverageSelected);

    dispatch(quoteActions.setQuote({ ...quote, plan: newPlan }));
    dispatch(errorsActions.setErrors({
      ...errors,
      plan: { ...errors.plan, id: EMPTY_STRING }
    }));
  };

  return (
    <>
      <Grid container justify="center" alignItems="center" direction="column">
        <HeaderPlansCard rentalPlan={rentalPlan} />

        <BodyPlansCard
          id={'bodyPlansCard'}
          coveragesSettings={planCoveragesSettings}
          rentalPlan={rentalPlan}
          choosePlan={choosePlan}
        />

        <FooterPlansCard
          rentalPlan={rentalPlan}
          showCoverages={coveragesSettings.showCoverages}
          choosePlan={choosePlan}
        />
      </Grid>
    </>
  );
};

PlansCard.propTypes = {
  rentalPlan: PropTypes.object.isRequired,
  selectedPlanSettings: PropTypes.object.isRequired,
  coveragesSettings: PropTypes.object.isRequired
};

export default PlansCard;

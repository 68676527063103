import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles({
  paper: {
    position: 'relative',
    background: '#FFFFFF',
    borderRadius: 15,
    boxShadow: '0px 2px 4px #00000029'
  },
  imgCoverage: {
    width: 25,
    height: 25
  },
  fontTitle: {
    fontFamily: 'Open Sans',
    color: '#393F4A',
    letterSpacing: 0.9,
    fontSize: 10,
    opacity: 1
  },
  description: {
    color: '#393F4A',
    fontFamily: 'Open Sans',
    fontSize: 14
  },
  descriptionCoverage: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#282F3C'
  },
  icon: {
    fontSize: '1.2rem'
  }
});

export default Styles;

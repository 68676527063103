import { EMPTY_STRING } from '../../../../../../shared/constants/constants';
import {
  BENEFICIARY_ID,
  EMPTY_INSURED,
  ID_DOCUMENT_INPUT,
  ID_EMAIL_INPUT,
  ID_NAME_INPUT,
  ID_PHONE_INPUT,
  INSURED_ID
} from '../../../../../../shared/constants/personConstants';
import {
  emailValidator,
  requiredValidator,
  validateDocument,
  validateMinCaracteres,
  validatePhone
} from '../../../../../../shared/helper/formValidators';
import utilManager from '../../../../../util/services/utilManager';
import { errorsActions } from '../../../../redux';
import personManager from '../../../persons/personsManager';

const validatesDocument = document => {
  let msg = EMPTY_STRING;
  msg = validateDocument(document, msg);
  msg = requiredValidator(document, msg);
  return msg;
};

class InsuredManager {
  validateInsureds = proposal => {
    let errors = EMPTY_STRING;
    if (!proposal?.insureds || proposal?.insureds?.length === 0) {
      errors = 'Necessário pelo menos um Locador';
    }

    return errors;
  };
  validateInsured = (insured, proposal, index, policyholder) => {
    const errors = { insureds: { [`${INSURED_ID}${index}`]: {} } };

    const sameDocuments = personManager.verifySameDocument(
      insured.documentNumber,
      proposal,
      policyholder
    );

    if (!insured) {
      insured = EMPTY_INSURED;
    }
    errors.insureds[`${INSURED_ID}${index}`][`${ID_NAME_INPUT}${index}`] = validateMinCaracteres(
      insured.name,
      5
    );
    errors.insureds[`${INSURED_ID}${index}`][`${ID_NAME_INPUT}${index}`] = requiredValidator(
      insured.name
    );
    errors.insureds[`${INSURED_ID}${index}`][`${ID_EMAIL_INPUT}${index}`] = emailValidator(
      insured?.email?.emailAddress
    );
    errors.insureds[`${INSURED_ID}${index}`][`${ID_EMAIL_INPUT}${index}`] = requiredValidator(
      insured?.email?.emailAddress
    );
    errors.insureds[`${INSURED_ID}${index}`][`${ID_PHONE_INPUT}${index}`] = validatePhone(
      insured?.phone?.number,
      EMPTY_STRING
    );
    errors.insureds[`${INSURED_ID}${index}`][`${ID_PHONE_INPUT}${index}`] = requiredValidator(
      insured?.phone?.number
    );
    errors.insureds[`${INSURED_ID}${index}`][`${ID_DOCUMENT_INPUT}${index}`] = validatesDocument(
      insured.documentNumber
    );
    errors.insureds[`${INSURED_ID}${index}`][`${ID_DOCUMENT_INPUT}${index}`] = sameDocuments
      ? sameDocuments
      : errors.insureds[`${INSURED_ID}${index}`][`${ID_DOCUMENT_INPUT}${index}`];

    return utilManager.validErrors(errors.insureds[`${INSURED_ID}${index}`]) ? null : errors;
  };

  closeModal = (index, setOpenModal, setDisabledForm, setIndex) => {
    setOpenModal(false);
    setDisabledForm(true);
    setIndex(index + 1);
  };

  save = (
    insured,
    proposal,
    policyHolder,
    index,
    setOpenModal,
    setDisabledForm,
    setIndex
  ) => async dispatch => {
    const validationErrors = this.validateInsured(insured, proposal, index, policyHolder);
    if (!validationErrors) {
      this.closeModal(index, setOpenModal, setDisabledForm, setIndex);
      return true;
    } else {
      dispatch(errorsActions.setErrors(validationErrors));
      return false;
    }
  };

  add = (setIndex, setLastIndex, setOpenModal, setinsuredShow, lastIndex) => {
    setIndex(lastIndex);
    setLastIndex(lastIndex + 1);
    setOpenModal(true);
    setinsuredShow(false);
  };

  delete = (setLastIndex, lastIndex, setOpenModal) => {
    setLastIndex(lastIndex === 0 ? lastIndex : lastIndex - 1);
    setOpenModal(false);
  };

  show = (setinsuredShow, setIndex, setOpenModal, index) => {
    setinsuredShow(true);
    setIndex(index);
    setOpenModal(true);
  };

  cancel = (setOpenModal, setDisabledForm) => {
    setOpenModal(false);
    setDisabledForm(true);
  };

  getInsuresUpdated = (proposal, person, index) => {
    if (proposal?.insureds && proposal?.insureds.length > 0) {
      if (proposal?.insureds[index]) {
        return proposal?.insureds.map((p, idx) => (idx === index ? { ...p, person } : { ...p }));
      } else {
        return proposal?.insureds.concat([person]);
      }
    } else {
      return [person];
    }
  };

  getBeneficiaries = insureds => {
    if (insureds && insureds.length > 0) {
      return insureds.map((p, idx) => (idx > -1 ? { ...p, role: BENEFICIARY_ID } : { ...p }));
    } else {
      return [];
    }
  };
}

export default new InsuredManager();

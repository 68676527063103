
import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../../../../../../shared/components/loading/Loading';
import { useLoading } from '../../../../redux/quizCustomHooks';
import InternalInfoBody from './InternalInfoBody';
import InternalInfoFooter from './InternalInfoFooter';
import InternalInfoHeader from './InternalInfoHeader';

const InternalInfo = ({ continueClick, modalSettings }) => {
  const isLoading = useLoading()

  return (
    <>
      {isLoading && <Loading />}
      <InternalInfoHeader title={modalSettings.title} />
      <InternalInfoBody status={modalSettings.status} />
      <InternalInfoFooter continueClick={continueClick} modalSettings={modalSettings} />
    </>
  );
};

InternalInfo.propTypes = {
  continueClick: PropTypes.func,
  modalSettings: PropTypes.object.isRequired
};

export default InternalInfo;

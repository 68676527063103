import axios from 'axios';
import { WebStorageStateStore } from 'oidc-client';
import { makeUserManager } from 'react-oidc';
import { useDispatch } from 'react-redux';
import * as userActions from '../../shared/components/header/redux/userActions';
import userService from '../../shared/components/header/services/userService';
import { BROKER } from '../../shared/constants/personConstants';
import { analyzeActions } from '../quiz/redux';

const userManagerConfig = {
  client_id: window.env.CLIENT_ID,
  redirect_uri: window.env.REACT_APP_REDIRECT_URI,
  response_type: 'token id_token',
  scope: window.env.SCOPE,
  authority: window.env.AUTHORITY,
  silent_redirect_uri: window.env.REACT_APP_SILENT_RENEW_REDIRECT_URI,
  post_logout_redirect_uri: window.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

const userManager = makeUserManager(userManagerConfig);

userManager.events.addAccessTokenExpired(() => {
  userManager.signinSilent();
});

class AuthManager {
  getUserManager = () => userManager;

  setAxiosAuthorizationToken = async () => {
    const user = await userManager.getUser();
 
    if (user) {
      axios.interceptors.request.use(
        async config => {
          let headers = config.headers ? { ...config.headers } : {};
          headers = { ...headers, Authorization: `Bearer ${user.access_token}` };
          config.headers = headers;
          return config;
        },
        error => {
          Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        response => response,
        async error => {
          if (error?.response?.status === 401) {
            await userManager.signoutRedirect();
          } else {
            return Promise.reject(error);
          }
        }
      );
    }
  };

  getUser = async () => {
    const dispatch = useDispatch();
    userManagerConfig.userStore = { ...userManagerConfig.userStore };
    const user = await userManager.getUser(); 
   
    let broker = JSON.parse(localStorage.getItem('broker'));
    if (user?.profile?.person_id && !broker) {
      try {
        broker = await userService.getUserByPersonId(window.env.REACT_APP_ENV === 'Production' ? "298d6420-0f92-48f0-be1c-093f73f4f012" : user?.profile?.person_id);
        localStorage.setItem('broker', JSON.stringify(broker));
      } catch {
        this.getUserManager().signoutRedirect();
      }
    }

    if (broker) {
      dispatch(userActions.setUser({...broker, name: user?.profile?.name}));
      dispatch(
        analyzeActions.setUserCommissionedAgent(
          {
            name: broker.name,
            personId: broker.personId,
            commission: broker.commission,
            commissionPercentage: broker.commission.defaultValue,
            licenseNumber: broker.licenseNumber,
            documentNumber: broker.documentNumber,
            role: BROKER.charAt(0).toUpperCase() + BROKER.slice(1),
            lead: true
          },
          0
        )
      );
    }

    return user;
  };
}

export const authManager = new AuthManager();

import makeStyles from '@material-ui/styles/makeStyles';

const Styles = makeStyles({
  card: {
    position: 'relative',
    margin: 15,
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 4px #00000029',
    borderRadius: 15
  },
  rightCards: {
    borderRadius: 15,
    marginBottom: 15
  },
  link: {
    textDecoration: 'underline'
  },
  IconButton: {
    width: 12,
    height: 12
  },
  gridFile: {
    width: '100%',
    borderRadius: 10,
    border: '1px dashed #95989A',
    font: 'normal normal normal 14px/17px Open Sans',
    letterSpacing: 0,
    color: '#222B3CD4',
    opacity: 1
  },
  errorGrid: {
    border: '1px solid #C3274C'
  },
  grodDash: {
    border: '1px dashed #95989A'
  },
  input: {
    display: 'none',
  },
  fileTitle: {
    font: 'normal normal 600 12px/14px Open Sans',
    letterSpacing: 0,
    color: '#393F4A',
    textTransform: 'uppercase',
    opacity: 1
  },
  icon: {
    width: 40,
    height: 40,
    color: '#F26522',
    cursor: 'pointer'
  },
  fileName: {
    textDecoration: 'underline',
    font: 'normal normal normal 12px/17px Open Sans',
    letterSpacing: 0,
    color: '#F26522',
    cursor: 'pointer',
    opacity: 1
  },
  button: {
    fontFamily: 'Open Sans',
    borderRadius: 30,
    fontSize: 12,
    width: '90%',
    textTransform: 'none',
    fontWeight: 'normal',
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none'
    },
    background: 'transparent',
    border: '1px solid #FD5D2B',
    color: '#FD5D2B',
    maxHeight: 35,
    lineHeight: 1
  },
  inputFile: {
    width: '100%',
    height: '100%',

  },
  dropzone: {
    border: 'none'
  },
  label: {
    textAlign: "left",
    fontWeight: "normal",
    fontFamily: "'Open Sans', sans-serif",
    letterSpacing: 0,
    fontSize: 12,
    color: "#393F4A"
  },
  titleCards: {
    textAlign: "left",
    fontSize: 17,
    fontWeight: 800,
    fontFamily: "'Poppins', sans-serif"
  },
  type: {
    textAlign: 'left',
    font: 'normal normal bold 16px/16px Open Sans',
    letterSpacing: 0,
    color: '#393F4A',
    opacity: 1
  },
  name: {
    textAlign: 'left',
    font: 'normal normal normal 16px/16px Open Sans',
    letterSpacing: 0,
    color: '#393F4A',
    opacity: 1
  },
  labelComission: {
    textAlign: 'left',
    font: 'normal normal 600 Open Sans',
    letterSpacing: 0,
    color: '#393F4A',
    textTransform: 'uppercase',
    opacity: 1
  },
  totalCommission: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  autoCompletePayment: {
    width: 475,  
    marginBottom: 15 
  }
});

export default Styles;

import { useSelector } from 'react-redux';
import { BROKER, POLICYOWNER } from '../../../../../shared/constants/personConstants';

export const useAnalyze = () => {
  return useSelector(state => state.insurance?.analyze);
};

export const useAnalizePolicyHolder = () => {
  return useSelector(state => state.insurance?.analyze?.policyHolder);
};

export const useAnalyzePolicyOwner = () => {
  return useSelector(state => state.insurance?.analyze?.commissionedAgents)?.find(
    c => c.role.toUpperCase() === POLICYOWNER
  );
};

export const useAnalyzeBroker = () => {
  return useSelector(state => state.insurance?.analyze?.commissionedAgents)?.find(
    c => c.role.toUpperCase() === BROKER
  );
};

export const useAnalyzeCommissionedAgents = () => {
  return useSelector(state => state.insurance?.analyze?.commissionedAgents);
};

export const mapperResponseCreateToAnalyzeReducer = (analyze, analyzeCreated, approvedLimit) => {
  return {
    ...analyze,
    id: analyzeCreated.id,
    approvedLimit: approvedLimit,
    status: analyzeCreated.status,
    underwritingResponseDate: analyzeCreated.underwritingResponseDate,
    createdAt: analyzeCreated.createdAt
  };
};

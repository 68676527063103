export const SET_LIST_INSURANCES = 'quiz/SET_LIST_INSURANCES';
export const CLEAR_LIST_INSURANCES = 'quiz/CLEAR_LIST_INSURANCES';

export const setListInsurances = insurances => ({
  type: SET_LIST_INSURANCES,
  payload: insurances
});

export const clearListInsurances = () => ({
  type: CLEAR_LIST_INSURANCES
});

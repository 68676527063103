export const SET_MULTIPLE_BASIC_PLAN_SELECTED = 'insurance/SET_MULTIPLE_BASIC_PLAN_SELECTED';
export const SET_INSURANCE_COVERAGE_INSUREDAMOUNT =
  'insurance/SET_INSURANCE_COVERAGE_INSUREDAMOUNT';
export const SET_PLANS = 'plans/SET_PLANS';
export const SET_MULTIPLE_BASIC = 'plans/SET_MULTIPLE_BASIC';
export const SET_COVERAGE_INSURED_AMOUNT = 'plans/SET_COVERAGE_INSURED_AMOUNT';

export const setInsuredAmount = (coverage, index) => ({
  type: SET_INSURANCE_COVERAGE_INSUREDAMOUNT,
  payload: coverage,
  index: index
});

export const setInsuredAmountinPlans = (insuredAmount, indexPlan, indexCoverage) => ({
  type: SET_COVERAGE_INSURED_AMOUNT,
  payload: insuredAmount,
  indexCoverage: indexCoverage,
  indexPlan: indexPlan
});

export const setPlans = plans => ({
  type: SET_PLANS,
  payload: plans
});

export const setMultipleBasicSelectedPlan = multipleBasic => ({
  type: SET_MULTIPLE_BASIC_PLAN_SELECTED,
  payload: multipleBasic
});

export const setMultipleBasic = multipleBasic => ({
  type: SET_MULTIPLE_BASIC,
  payload: multipleBasic
});

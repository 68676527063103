import { STEPS } from '../../../shared/constants/quizConstants';
import * as quizActions from './quizActions';

export const INITIAL_STATE = {
  steps: STEPS,
  isLoading: false,
  hasError: false,
  notFound: false,
  intermediateChanged: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case quizActions.SET_INTERMEDIATE_CHANGED:
      return {
        ...state,
        intermediateChanged: action.payload
      };
    case quizActions.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case quizActions.SET_NOTFOUND:
      return {
        ...state,
        notFound: action.payload
      };
    case quizActions.SET_ERROR:
      return {
        ...state,
        hasError: action.payload
      };
    case quizActions.SET_STEPS:
      return {
        ...state,
        steps: [...action.payload]
      };
    case quizActions.SET_STEP:
      return {
        ...state,
        steps: state.steps.map(step =>
          step.id === action.payload.id ? { ...step, ...action.payload } : { ...step }
        )
      };
    case quizActions.SET_INITIAL_STEP:
      return INITIAL_STATE;
    default:
      return state;
  }
};

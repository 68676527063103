import makeStyles from '@material-ui/styles/makeStyles';

const Styles = width =>
  makeStyles({
    formControl: {
      width: width
    },
    select: {
      border: '1px solid #BEBEBE',
      borderRadius: 4
    },
    error: {
      width: 200
    }
  });

export default Styles;

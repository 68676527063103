import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { moneyFormat } from '../../../../../../../shared/components/format/MoneyFormat';
import calculateManager from '../../../../../manager/calculate/calculateManager';
import { useInstallments } from '../../../../../redux/insurance/quote/quoteCustomHooks';
import HeaderPlansCardClasses from '../classes/HeaderPlansCardClasses';

const HeaderPlansCard = ({ rentalPlan }) => {
  const classes = HeaderPlansCardClasses();
  const installments = useInstallments();

  return (
    <>
      {rentalPlan && (
        <Box px={3} py={0} color="#303E59">
          <Grid item xs={12}>
            <Box
              pt={5}
              pb={2}
              textAlign="center"
              fontSize={17}
              fontWeight="fontWeightBold"
              fontFamily="'Poppins', sans-serif"
            >
              {rentalPlan?.title}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Box py={2}>
                <img
                  className={classes.image}
                  alt="Pottencial"
                  src={`${process.env.PUBLIC_URL}/assets/images/${rentalPlan?.image}.svg`}
                />
              </Box>
            </Grid>
          </Grid>
          {installments > 0 && rentalPlan.grossPremium && rentalPlan.grossPremium > 0 && (
            <Grid item xs={12} p={20}>
              <Box
                pb={5}
                textAlign="center"
                letterSpacing={0.6}
                fontSize={17}
                fontWeight="500"
                fontFamily="Open Sans"
                flexDirection="row"
                display="inline"
              >
                <big className={classes.bigMoney}>
                  {moneyFormat(calculateManager.getDivision(rentalPlan.grossPremium, installments))}
                </big>
                /mês
              </Box>
              <Box
                pt={2}
                pb={4}
                textAlign="center"
                letterSpacing={0.1}
                fontSize={12}
                fontWeight="500"
                fontFamily="Open Sans"
              >
                Total de {moneyFormat(rentalPlan.grossPremium)} em{' '}
                {installments}x
              </Box>
            </Grid>
          )}
        </Box>
      )}
    </>
  );
};

HeaderPlansCard.propTypes = {
  rentalPlan: PropTypes.object.isRequired
};

export default HeaderPlansCard;

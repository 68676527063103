import { Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AccountCircle, ExitToApp, ExpandLess, ExpandMore } from '@material-ui/icons';
import PropType from 'prop-types';
import React from 'react';
import { authManager } from '../../../features/auth/authManager.ig';
import UserDataClasses from './classes/UserDataClasses';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    backgroundColor: '#FFFFFF',
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: '##575F6E'
    }
  }
})(MenuItem);

const UserData = function ({ user }) {
  const classes = UserDataClasses()();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const clickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      item
      xs={12}
      md={3}
      direction="row"
      justify="space-evenly"
      alignItems="center"
      className={classes.root}
    >
      <AccountCircle />
      <p>{user.name}</p>
      <IconButton
        id="btn-open"
        className={classes.icon}
        aria-label="open menu"
        onClick={clickMenu}
        component="span"
      >
        {anchorEl && <ExpandLess />}
        {!anchorEl && <ExpandMore />}
      </IconButton>
      <StyledMenu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <StyledMenuItem
          id="btn-logout"
          className={classes.logout}
          onClick={() => {
            localStorage.removeItem('broker');
            authManager.getUserManager().signoutRedirect()
          }}
        >
          <ListItemIcon>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </StyledMenuItem>
      </StyledMenu>
    </Grid>
  );
};
UserData.propTypes = {
  user: PropType.object.isRequired
};
export default UserData;

import { makeStyles } from '@material-ui/core';

const Styles = makeStyles({
    header: {
        font: 'normal normal 600 11px Open Sans',
        letterSpacing: 1.2,
        color: '#393F4A',
        textTransform: 'uppercase',
        opacity: 1
    },
    title: {
        font: 'normal normal bold 30px Poppins',
        letterspacing: -0.84,
        color: '#282F3C',
        opacity: 1
    },
    text: {
        font: 'normal normal normal 16px/24px Open Sans',
        letterSpacing: 0,
        color: '#5F6675',
        textAlign: 'justify',
        height: '55%'
    },
    line: {
        height: "80%",
        borderBottom: '2px dotted',
        color: '#FD5D2B',
        opacity: 0.5
    },
    dot: {
        width: 6,
        height: 6,
        borderRadius: '50%',
        border: '2px solid #FD5D2B',
        marginTop: 27
    },
    dotFilled: {
        backgroundColor: '#FD5D2B'
    },
    approvedLimit: {
        font: 'normal normal bold 600 Open Sans',
        fontSize: 19,
        letterspacing: -0.84,
        color: '#FD5D2B',
    },
    usedLimit: {
        font: 'normal normal bold 600 Open Sans',
        fontSize: 19,
        letterspacing: -0.84,
        color: '#5F6675',
    },
    dates: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 35,
        marginBottom: 35
    },
    limits: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: "left"
    }
});

export default Styles;

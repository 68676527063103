import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles({
  title: {
    font: 'normal normal normal 14px/24px Open Sans',
    letterSpacing: 0,
    color: '#393F4A'
  },
  container: {
    position: 'relative'
  }
});

export default Styles;
import axios from 'axios';

class DocumentsService {
  createDocumentLetter = async document => {
    const response = await axios.post(`${window.env.RENTAL_SURETY}/Documents/letters`, document);

    return response.data;
  };

  createQuotePdf = async documentQuoteRequest => {
    const response = await axios.post(
      `${window.env.RENTAL_SURETY}/Documents/quote/download`,
      documentQuoteRequest
    );

    return response.data;
  };
}

export default new DocumentsService();

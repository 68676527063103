import { Error, Warning } from '@material-ui/icons';
import { moneyFormat } from '../../../shared/components/format/MoneyFormat';
import { EMPTY_STRING, GO_BACK, OK } from '../../../shared/constants/constants';
import { STEPS } from '../../../shared/constants/quizConstants';
import {
  ANALYZE,
  InsuranceStepsTitle,
  PROPOSAL,
  QUOTE,
  STEP_POLICY
} from '../../../shared/enums/statusEnum';
import analyzeManager from './insurance/analyze/analyzeManager';

class QuizManager {
  getInitialModalProps = () => {
    return {
      icon: { component: Warning },
      actions: [],
      message: EMPTY_STRING,
      isOpen: false
    };
  };

  getModalProps = (message, returnHome) => {
    return {
      actions: [
        {
          text: returnHome ? GO_BACK : OK,
          isConfirm: true,
          returnHome: returnHome
        }
      ],
      message: message,
      icon: { component: Error },
      isOpen: true
    };
  };

  updateSteps = (steps, index, statusTitle, statusDescription) => {
    const id = steps[index].id;
    let newSteps = steps.map(step =>
      step.id === id
        ? {
            ...step,
            activated: false,
            completed: true,
            statusTitle: statusTitle,
            statusDescription: statusDescription
          }
        : step
    );
    newSteps = newSteps.map(step =>
      step.id === id + 1 && id < steps.length ? { ...step, activated: true, completed: true } : step
    );
    return newSteps;
  };

  activateStep = (steps, index) => {
    const id = steps[index].id;
    let newSteps = steps.map(step =>
      step.id === id ? { ...step, activated: true, completed: true } : step
    );
    newSteps = newSteps.map(step => (step.id !== id ? { ...step, activated: false } : step));
    return newSteps;
  };

  getTextStep = (actualStep, analyze, quote) => {
    const text = InsuranceStepsTitle.find(insuranceStep => insuranceStep.step === actualStep.name)
      ?.text;

    switch (actualStep.name) {
      case ANALYZE:
        return {
          statusTitle: text,
          statusDescription: analyzeManager.getStatusDescription(analyze)
        };
      case QUOTE:
        return {
          statusTitle: text + moneyFormat(analyze?.approvedLimit),
          statusDescription: 'Plano ' + quote?.plan.type
        };

      default:
        return {
          statusTitle: actualStep.statusTitle,
          statusDescription: actualStep.statusDescription
        };
    }
  };

  getNewSteps = (steps, analyze, quote) => {
    const step = analyze.step.toUpperCase() === STEP_POLICY.toUpperCase() ? PROPOSAL : analyze.step;
    const actualStep = steps.find(
      insuranceStep => insuranceStep.name.toUpperCase() === step.toUpperCase()
    );

    return steps.map((step, idx) =>
      idx + 1 < actualStep.id
        ? {
            ...step,
            activated: false,
            completed: true,
            statusTitle: this.getTextStep(step, analyze, quote).statusTitle,
            statusDescription: this.getTextStep(step, analyze, quote).statusDescription
          }
        : idx + 1 === actualStep.id
        ? {
            ...step,
            activated: true,
            completed: step.name !== PROPOSAL ? true : false
          }
        : { ...step }
    );
  };

  getStepUpdatedActions = (currentStep, saveAndGoBack) => {
    return STEPS.map(step =>
      step.id === currentStep
        ? {
            ...step,
            submitted: !saveAndGoBack,
            saved: saveAndGoBack
          }
        : {
            ...step,
            submitted: step.id < currentStep ? true : step.submitted,
            saved: step.id < currentStep ? true : step.saved
          }
    );
  };
}

export default new QuizManager();

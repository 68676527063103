import _ from 'lodash';

class UtilManager {
  validErrors = errors => {
    const fields = [...Object.values(_.omit(errors))];
    return fields.every(field => !field);
  };

  validErrorsArray = errors => {
    const fields = [];
    errors.forEach(error => {
      fields.push(...Object.values(_.omit(error)));
    });
    return fields.every(field => !field);
  };

  sleep = (delay = 0) => {
    return new Promise(resolve => {
      setTimeout(resolve, delay);
    });
  };

  compareArrays = (arr1, arr2) => {
    return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
  };
}

export default new UtilManager();

import { FormControlLabel, FormLabel, RadioGroup } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import React from 'react';
import { EMPTY_STRING } from '../../constants/constants';
import { requiredValidator } from '../../helper/formValidators';
import InputClasses from './InputClasses';

const validateRadioButton = (value, required) => {
  if (required) return requiredValidator(value, EMPTY_STRING);
  return EMPTY_STRING;
};

const RadioButton = function ({
  label,
  required,
  disabled,
  onChange,
  onBlur,
  itemsRadio,
  valueRadio,
  id,
  bool,
  error,
  row,
  bold,
  alignCenter
}) {
  const classes = InputClasses();
  const hasError = !!error;

  return (
    <FormControl className={classes.formControl} direction={`${row ? 'row' : 'column'}`}>
      <Grid container direction={`${row ? 'row' : 'column'}`} xs={12} md={12} item alignItems={!alignCenter ? 'flex-start' : 'center'}>
        <FormLabel component="legend" className={bold ? classes.formLabelBold : classes.formLabel}>
          {label} {required ? '*' : EMPTY_STRING}{EMPTY_STRING}
        </FormLabel>
        <RadioGroup row className={`${row ? classes.radioGroup : EMPTY_STRING}`}>
          {itemsRadio.map(({ description, value }) => (
            <FormControlLabel
              value={valueRadio}
              key={value}
              label={description}
              className={classes.formLabel}
              control={
                <Radio
                  id={id}
                  error={hasError ? 1 : 0}
                  required={required}
                  disabled={disabled}
                  classes={{ root: `${row ? classes.radio : EMPTY_STRING}`, checked: classes.checked }}
                  name={description}
                  value={value}
                  onChange={e => {
                    onChange(id, bool ? JSON.parse(e.target.value) : e.target.value);
                  }}
                  checked={valueRadio === value}
                  onBlur={() => {
                    if (onBlur) {
                      onBlur(id, validateRadioButton(value, required));
                    }
                  }}
                />
              }
            />
          ))}
        </RadioGroup>
        {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
      </Grid>
    </FormControl>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  itemsRadio: PropTypes.array.isRequired,
  valueRadio: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  bool: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.string,
  row: PropTypes.bool,
  bold: PropTypes.bool,
  alignCenter: PropTypes.bool
};

export default RadioButton;

import { Box, Grid, IconButton, Paper } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import React from 'react';
import { EMPTY_STRING } from '../../../constants/constants';
import ModalInfoClasses from '../classes/ModalInfoClasses';

const ModalInfo = ({ children, open, image, blur, showExitButton, exitButtonOnClick, ...props }) => {
  const valueBlur = blur ? 'linear-gradient(rgba(145,145,145,0.25),rgba(145,145,145,0.25)),' : EMPTY_STRING;
  const classes = ModalInfoClasses(image, valueBlur)();

  const exitButtonClick = () => {
    exitButtonOnClick()
  };

  return (
    <>
      <Modal
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        open={open}
        {...props}
      >
        <Fade in={open}>
          <Box className={classes.modal}>
            <Paper className={`${classes.paper} ${classes.image}`}>
              <Grid className={classes.grid} item>
                {children}
              </Grid>
              {showExitButton && (
                <Grid item xs={12} className={classes.gridClose}>
                  <Box px={2} py={2} display="flex" flexDirection="row-reverse">
                    <IconButton
                      id="btn-close"
                      className={classes.buttonExit}
                      color="primary"
                      onClick={exitButtonClick}
                    >
                      <CancelIcon className={classes.iconButtonExit} />
                    </IconButton>
                  </Box>
                </Grid>
              )}
            </Paper>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

ModalInfo.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.element.isRequired,
  image: PropTypes.string.isRequired,
  blur: PropTypes.bool,
  showExitButton: PropTypes.bool,
  exitButtonOnClick: PropTypes.func
};

export default ModalInfo;

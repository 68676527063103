import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loading from '../../../../../shared/components/loading/Loading';
import Modal from '../../../../../shared/components/modal/Modal';
import InternalComponentGoBack from '../../../../../shared/components/modal/modalInfo/InternalComponentGoBack';
import ModalInfo from '../../../../../shared/components/modal/modalInfo/ModalInfo';
import WarningModal from '../../../../../shared/components/modal/warning/WarningModal';
import {
  EMPTY_GUID,
  SAVE_AND_GO_BACK,
  WARNING_ICON
} from '../../../../../shared/constants/constants';
import { GOBACK_IMAGE } from '../../../../../shared/constants/imagesconstants';
import {
  CHANGED_INTERMEDIATE_MESSAGE,
  ERR_DEFAULT_MESSAGE
} from '../../../../../shared/constants/modalMessagesConstants';
import {
  EXPENSES_DESCRIPTION_VALUE,
  RENTAL_DESCRIPTION
} from '../../../../../shared/constants/planConstants';
import { routes } from '../../../../../shared/constants/routes';
import { PROPOSAL, QUOTE, TRANSMITTED } from '../../../../../shared/enums/statusEnum';
import quoteManager from '../../../manager/insurance/quote/quoteManager';
import quizManager from '../../../manager/quizManager';
import {
  analyzeActions,
  errorsActions,
  insuranceOperations,
  plansActions, quizActions
} from '../../../redux';
import {
  useAnalyze,
  useAnalyzeBroker,
  useAnalyzePolicyOwner
} from '../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../redux/insurance/policy/policyCustomHooks';
import { useInstallments, useQuote } from '../../../redux/insurance/quote/quoteCustomHooks';
import { mapperCoveragesAndPlanValues, usePlans } from '../../../redux/plans/plansCustomHooks';
import { useHasError, useLoading, useSteps } from '../../../redux/quizCustomHooks';
import calculateServiceManager from '../../../services/calculate/calculateServiceManager';
import FormClasses from '../classes/FormClasses';
import Footer from '../Footer';
import QuestionFormHeader from '../QuestionFormHeader';
import QuestionHeader from '../QuestionHeader';
import InsuranceData from './insuranceData/InsuranceData';
import Plans from './plans/Plans';

function getFormErrors(quote, analyze) {
  return quoteManager.formValidate(quote, analyze);
}

const Quote = ({ quizStep }) => {
  const classes = FormClasses();
  const quote = useQuote();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const dispatch = useDispatch();
  const [goBack, setGoBack] = useState(false);
  const [modalProps, setModalProps] = useState(quizManager.getInitialModalProps());
  const intermediateChanged = useSelector(state => state.quiz.intermediateChanged);
  const history = useHistory();
  const hasError = useHasError();
  const isLoading = useLoading();
  const steps = useSteps();
  const installments = useInstallments();
  const policyOwner = useAnalyzePolicyOwner();
  const plans = usePlans();
  const broker = useAnalyzeBroker();
  const [notPremiumCalculated, setNotPremiumCalculated] = useState(
    (quote?.plan?.id && quote?.plan?.id !== EMPTY_GUID) ||
      (quote?.startInsuranceContract && quote?.endInsuranceContract)
      ? false
      : true
  );
  const disabled = analyze?.status === TRANSMITTED || policy?.id;
  const getQuotePremium = useCallback(
    async (valueInstallments, valueMultiple, valueCommission) => {
      var commission =
        valueCommission > 0
          ? valueCommission
          : quote?.plan?.commissionPercentage && quote?.plan?.commissionPercentage !== 0
            ? quote?.plan?.commissionPercentage
            : broker?.commission?.defaultValue;

      if (
        (installments > 0 || valueInstallments) &&
        (valueMultiple || quote?.plan?.multiple) &&
        commission > 0
      ) {
        var rental = analyze?.expenses.find(
          expense => expense.description === EXPENSES_DESCRIPTION_VALUE + RENTAL_DESCRIPTION
        ).value;

        var getPremiumReponse = await calculateServiceManager.getPremium(
          policyOwner,
          valueMultiple > 0 ? valueMultiple : quote?.plan.multiple,
          valueInstallments > 0 ? valueInstallments : installments,
          rental,
          commission
        );

        dispatch(
          plansActions.setPlans({
            ...plans,
            plans: mapperCoveragesAndPlanValues(plans.plans, getPremiumReponse)
          })
        );
        setNotPremiumCalculated(true);
      }
    },
    [dispatch, analyze, broker, installments, plans, policyOwner, quote]
  );

  const saveAndReturnToHome = async () => {
    batch(async () => {
      dispatch(quizActions.setLoading(true));
      await dispatch(insuranceOperations.put(true));
      history.push(routes.home.path);
    });
  };

  const goToNextStep = () => {
    const stepSettings = quizManager.getTextStep({ name: QUOTE }, analyze, quote);
    dispatch(
      quizActions.setSteps(
        quizManager.updateSteps(
          steps,
          quizStep.index,
          stepSettings.statusTitle,
          stepSettings.statusDescription
        )
      )
    );
    dispatch(analyzeActions.setAnalyze({ ...analyze, step: PROPOSAL }));
    document.getElementById('header').scrollIntoView();
  };

  const scrollToPlans = () => {
    if (document.getElementById('Planos e coberturas')) {
      document.getElementById('Planos e coberturas').scrollIntoView();
    }
  };

  useEffect(() => {
    if (intermediateChanged) {
      scrollToPlans();
    }

    if (hasError) {
      setModalProps(quizManager.getModalProps(ERR_DEFAULT_MESSAGE));
    }

    if (quizStep.step.saved) {
      dispatch(quizActions.setStep({ ...quizStep.step, saved: false }));
    }

    if (
      (quote?.plan?.id !== EMPTY_GUID ||
        (quote?.startInsuranceContract && quote?.endInsuranceContract)) &&
      plans &&
      !notPremiumCalculated
    ) {
      getQuotePremium(0, quote?.plan.multiple, quote?.plan.commissionPercentage);
    }
  }, [
    notPremiumCalculated,
    plans,
    installments,
    quote,
    hasError,
    intermediateChanged,
    getQuotePremium,
    dispatch,
    quizStep.step,
    history
  ]);

  const submitForm = async () => {
    if (!getFormErrors(quote, analyze)) {
      goToNextStep();
    } else {
      dispatch(errorsActions.setErrors(getFormErrors(quote, analyze)));
      dispatch(analyzeActions.setAnalyze({ ...analyze, step: PROPOSAL }));
    }
  };

  return (
    <>
      {(isLoading || !notPremiumCalculated) && <Loading />}
      <WarningModal
        id={'modalWarning'}
        image={WARNING_ICON}
        message={CHANGED_INTERMEDIATE_MESSAGE}
        actions={[
          {
            text: 'Ok',
            isConfirm: true
          }
        ]}
        title="Estipulante Alterado"
        open={intermediateChanged}
        onClick={() => {
          dispatch(quizActions.setIntermediateChanged(false));
        }}
        onClose={() => {
          dispatch(quizActions.setIntermediateChanged(false));
        }}
      />

      <Grid item xs={12} className={classes.container}>
        <QuestionHeader title="Cotação" />
        <Box id={'insuranceData'} key={'insuranceData'} xs={12} className={classes.form}>
          <QuestionFormHeader title={'Dados do seguro'} />
          <Box pt={3}>
            <InsuranceData getQuotePremium={getQuotePremium} disabled={disabled} />
          </Box>
        </Box>
        <Box id={'Plans'} key={'Plans'} xs={12} className={classes.form}>
          <QuestionFormHeader title={'Planos e coberturas'} />
          <Box pt={3}>
            <Plans getQuotePremium={getQuotePremium} disabled={disabled} />
          </Box>
        </Box>

        <Footer
          id="footer-quote"
          titleButton="CONTINUAR PARA PROPOSTA"
          titleGoBackButton={SAVE_AND_GO_BACK}
          onClick={submitForm}
          goBackClick={() => {
            setGoBack(true);
          }}
          textButton="Salvar e Baixar Cotação"
          show
        />

        <Modal
          id={'modalHasError'}
          icon={modalProps.icon}
          message={modalProps.message}
          actions={modalProps.actions}
          open={modalProps.isOpen}
          onClose={() => {
            setModalProps({ isOpen: false });
            dispatch(quizActions.setError(false));
          }}
        />
        <ModalInfo
          id={'modalGoBack'}
          open={goBack}
          exitButtonOnClick={() => {
            setGoBack(false);
          }}
          image={GOBACK_IMAGE}
          blur
          showExitButton={true}
          children={
            <InternalComponentGoBack
              id={'internalComponentGoBack'}
              cancelClick={() => {
                setGoBack(false);
              }}
              goBackClick={saveAndReturnToHome}
              openDenied={false}
            />
          }
        />
      </Grid>
    </>
  );
};

Quote.propTypes = {
  quizStep: PropTypes.object.isRequired
};

export default Quote;

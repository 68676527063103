import { quoteActions } from '..';
import { isNaNValidator, requiredValidator } from '../../../../shared/helper/formValidators';
import calculateServiceManager from '../../services/calculate/calculateServiceManager';
import * as planActions from '../plans/plansActions';
import * as quizActions from '../quizActions';

export const getCommission = () => async (dispatch, getState) => {
  try {
    const {
      insurance: { quote }
    } = getState();
    let comissionResponse = 0;

    if (
      !requiredValidator(quote?.plan?.id) &&
      !requiredValidator(quote?.plan?.commissionPercentage) &&
      !isNaNValidator(quote?.plan?.commissionPercentage)
    ) {
      comissionResponse = await calculateServiceManager.getCommission(quote?.plan);
    }

    dispatch(
      quoteActions.setQuote({
        ...quote,
        plan: {
          ...quote?.plan,
          commission: comissionResponse
        }
      })
    );
  } catch {
    dispatch(quizActions.setError(true));
  } finally {
  }
};

export const getInsuredAmount = coverage => async (dispatch, getState) => {
  try {
    const {
      insurance: { quote, analyze }
    } = getState();

    const index = quote?.plan?.coverages.findIndex(
      insuranceCoverage => insuranceCoverage.id === coverage.id
    );

    if (index >= 0) {
      let insuredAmountResponse = 0;

      if (!requiredValidator(coverage.multiple) && !isNaNValidator(coverage.multiple)) {
        insuredAmountResponse = await calculateServiceManager.getInsuredAmount(
          analyze?.expenses,
          coverage,
          quote?.plan
        );
      }

      dispatch(planActions.setInsuredAmount(insuredAmountResponse, index));
    }
  } catch {
    dispatch(quizActions.setError(true));
  }
};

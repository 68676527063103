import { EMPTY_STRING } from './constants';

export const INSURANCE = {
  id: null,
  startInsuranceContract: null,
  endInsuranceContract: null,
  startLeaseContract: null,
  endLeaseContract: null,
  address: {
    zipCode: EMPTY_STRING,
    street: EMPTY_STRING,
    number: EMPTY_STRING,
    complement: EMPTY_STRING,
    district: EMPTY_STRING,
    state: EMPTY_STRING,
    city: EMPTY_STRING
  },
  policyHolders: [
    {
      personId: null,
      name: EMPTY_STRING,
      email: EMPTY_STRING,
      emailId: null,
      inhabited: null,
      composeIncome: true,
      main: true,
      documentNumber: EMPTY_STRING
    }
  ],
  expenses: [
    {
      title: 'Valor do aluguel',
      description: 'VALOR_ALUGUEL',
      value: 0,
      mandatory: true
    },
    {
      title: 'Gasto do condomínio',
      description: 'VALOR_CONDOMINIO',
      value: 0,
      mandatory: false
    },
    {
      title: 'Gasto mensal com IPTU',
      description: 'VALOR_IPTU',
      value: 0,
      mandatory: false
    },
    {
      title: 'Gasto mensal com gás',
      description: 'VALOR_GAS',
      value: 0,
      mandatory: false
    },
    {
      title: 'Gasto mensal com água',
      description: 'VALOR_AGUA',
      value: 0,
      mandatory: false
    },
    {
      title: 'Gasto mensal com luz',
      description: 'VALOR_LUZ',
      value: 0,
      mandatory: false
    }
  ],
  commissionedAgents: [
    {
      name: EMPTY_STRING,
      personId: EMPTY_STRING,
      commissionPercentage: 0,
      documentNumber: EMPTY_STRING,
      lead: false,
      role: EMPTY_STRING
    }
  ],
  plan: {
    id: null,
    name: EMPTY_STRING,
    type: EMPTY_STRING,
    coverages: [],
    commissionPercentage: 0,
    commission: 0,
    rate: 10,
    multiple: 30
  },
  insureds: [],
  ComplianceLetter: null,
  hasComplianceLetter: null,
  payment: {
    currency: null,
    correctionIndex: null,
    file: null
  },
  installments: 0
};

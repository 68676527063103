export const SET_STEPS = 'quiz/SET_STEPS';
export const SET_STEP = 'quiz/SET_STEP';
export const SET_LOADING = 'quiz/SET_LOADING';
export const SET_NOTFOUND = 'quiz/SET_NOTFOUND';
export const SET_ERROR = 'quiz/SET_ERROR';
export const SET_INTERMEDIATE_CHANGED = 'quiz/INTERMEDIATE_CHANGED';
export const SET_SHOW_SOLIDARIES = 'quiz/SET_SHOW_SOLIDARIES';
export const SET_INITIAL_STEP = 'quiz/SET_INITIAL_STEP';

export const setSteps = steps => ({
  type: SET_STEPS,
  payload: steps
});

export const setStep = step => ({
  type: SET_STEP,
  payload: step
});

export const setInitialStep = () => ({
  type: SET_INITIAL_STEP
});

export const setLoading = loading => ({
  type: SET_LOADING,
  payload: loading
});

export const setNotFound = notFound => ({
  type: SET_NOTFOUND,
  payload: notFound
});

export const setError = hasError => ({
  type: SET_ERROR,
  payload: hasError
});

export const setIntermediateChanged = intermediateChanged => ({
  type: SET_INTERMEDIATE_CHANGED,
  payload: intermediateChanged
});

import * as documentSelectors from '../../redux/document/documentSelectors';
import documentsService from './documentsService';

class DocumentsServiceManager {
  createDocumentLetter = async (analyze, quoteSequential, expenses) => {
    const document = documentSelectors.mapperDocumentLetter(analyze, quoteSequential, expenses);
    return await documentsService.createDocumentLetter(document);
  };

  downloadQuotePdf = async quote => {
    const documentQuoteRequest = documentSelectors.mapperDocumentQuoteRequest(quote);
    return await documentsService.createQuotePdf(documentQuoteRequest);
  };
}
export default new DocumentsServiceManager();

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { EMPTY_STRING } from '../../constants/constants';
import { MAX_PERCENTAGE, REQUIRED } from '../../constants/formConstants';
import {
  requiredValidator,
  validateMaxPercentValue,
  validateMaxValue,
  validateMinPercentValue,
  validateMinValue
} from '../../helper/formValidators';
import NumberInputClasses from './classes/NumberInputClasses';
import InputClasses from './InputClasses';

const justNumbers = value => {
  return value
    .replace(/[a-z]/g, EMPTY_STRING)
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, EMPTY_STRING);
};

const validateNumberInput = (value, required, min, max) => {
  let msg = EMPTY_STRING;
  if (required) msg = requiredValidator(value, EMPTY_STRING);
  if (min && !msg)
    msg = validateMinValue(parseFloat(value).toLocaleString('en-Us'), EMPTY_STRING, min);
  if (max && !msg)
    msg = validateMaxValue(parseFloat(value).toLocaleString('en-Us'), EMPTY_STRING, max);
  return msg;
};

const verifyCompleteDecimal = value => {
  return (
    typeof value !== 'undefined' &&
    value !== null &&
    value !== EMPTY_STRING &&
    (value.toString().includes('.') < 0 ||
      (value.toString().includes('.') > 0 &&
        value.toString().includes('.') !== value.toString().length - 1))
  );
};

export const validadePercentage = (value, required, min, max) => {
  let msg = EMPTY_STRING;
  if (required) msg = requiredValidator(value, EMPTY_STRING);

  if (isNaN(value)) {
    return REQUIRED;
  }

  if (!msg && min) msg = validateMinPercentValue(parseFloat(value), min, EMPTY_STRING);
  if (max && !msg) msg = validateMaxPercentValue(parseFloat(value), MAX_PERCENTAGE, EMPTY_STRING);
  if (max && !msg) msg = validateMaxPercentValue(parseFloat(value), max, EMPTY_STRING);

  return msg;
};

const getHaveValue = value => {
  return value || EMPTY_STRING;
};

const getPercentageOnChange = value => {
  return verifyCompleteDecimal(value) ? parseFloat(value) : value;
};

const getBorderClass = (border, classes) => {
  return border ? classes.formControlNumber : classes.formControl;
};

const updateValue = (values, onChange) => {
  onChange({
    target: {
      value: values.value
    }
  });
};

const NumberFormatCustom = function (props) {
  const { onChange, value, inputRef, ...other } = props;

  return (
    <form onSubmit={e => e.preventDefault()} autoComplete="off">
      <NumberFormat
        {...other}
        value={getHaveValue(value)}
        allowLeadingZeros
        format={values => justNumbers(values)}
        onValueChange={values => {
          updateValue(values, onChange);
        }}
        isNumericString
        getInputRef={inputRef}
      />
    </form>
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const PercentageFormatCustom = function (props) {
  const { onChange, value, inputRef, ...other } = props;

  return (
    <NumberFormat
      data-testid="number-format"
      {...other}
      value={getHaveValue(value)}
      getInputRef={inputRef}
      decimalSeparator="."
      suffix={'%'}
      decimalScale={2}
      onValueChange={values => {
        updateValue(values, onChange);
      }}
    />
  );
};

PercentageFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function NumberInput({
  value,
  onChange,
  onBlur,
  label,
  disabled,
  required,
  id,
  error,
  percentage,
  min,
  max,
  border,
  disableUnderline
}) {
  const classes = InputClasses();
  const classesPercentage = NumberInputClasses()();
  const hasError = !!error;

  return (
    <FormControl
      className={`${percentage ? classesPercentage.formControl : getBorderClass(border, classes)}`}
    >
      {label && (
        <InputLabel error={hasError} required={required}>
          {label}
        </InputLabel>
      )}
      <Input
        id={id}
        className={`${border ? classesPercentage.input : EMPTY_STRING}`}
        error={hasError}
        value={value}
        onChange={e => {
          onChange(id, percentage ? getPercentageOnChange(e.target.value) : Number(e.target.value));
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur(
              id,
              percentage
                ? validadePercentage(value, required, min, max)
                : validateNumberInput(value, required, min, max)
            );
          }
        }}
        inputComponent={percentage ? PercentageFormatCustom : NumberFormatCustom}
        disabled={disabled}
        disableUnderline={disableUnderline}
      />
      {hasError && (
        <FormHelperText className={classesPercentage.error} error={hasError}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

NumberInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.string,
  percentage: PropTypes.bool,
  border: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number
};

import quizManager from '../manager/quizManager';
import * as quizActions from './quizActions';

export const setNextStep = (index, statusTitle, statusDescription) => (dispatch, getState) => {
  const {
    quiz: { steps }
  } = getState();

  const newSteps = quizManager.updateSteps(steps, index, statusTitle, statusDescription);
  dispatch(quizActions.setSteps(newSteps));
};

export const activateStep = index => async (dispatch, getState) => {
  const {
    quiz: { steps }
  } = getState();

  const newSteps = await quizManager.activateStep(steps, index);
  dispatch(quizActions.setSteps(newSteps));
};

export const updateStep = (index, statusTitle, statusDescription) => async (dispatch, getState) => {
  const {
    quiz: { steps }
  } = getState();

  try {
    const newSteps = await quizManager.updateSteps(steps, index, statusTitle, statusDescription);
    dispatch(quizActions.setSteps(newSteps));
  } catch (err) {
    dispatch(quizActions.setError(true));
  }
};

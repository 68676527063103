import { createMuiTheme } from '@material-ui/core/styles';

export const palette = {
  primary: {
    main: '#ff6f0b',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#A9CBD3',
    color: '#FFFFFF'
  },
  error: {
    main: '#f44336'
  },
  background: {
    main: '#fafafa'
  }
};

export const muiTheme = createMuiTheme({
  palette: {
    primary: palette.primary,
    secondary: palette.secondary,
    error: palette.error
  },
  overrides: {
    MuiBottomNavigationAction: {
      root: {
        color: '#fea285',
        '&$selected': {
          color: '#FFF',
          paddingTop: 30,
          paddingBottom: 30
        }
      },
      iconOnly: {
        paddingTop: 30
      }
    },

    MuiTypography: {
      body1: {
        fontFamily: 'Open Sans'
      }
    },
    MuiButtonBase: {
      root: {
        fontFamily: 'Open Sans',
        background: '#A9CBD3 0% 0% no-repeat padding-box',
        color: '#FFFFFF'
      }
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: '#FE6231'
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#FE6231'
        }
      }
    },
    MuiButton: {
      containedSecondary: {
        color: '#FFFFFF',
        font: 'Bold 12px/17px Open Sans',
        letterspacing: 0,
        opacity: 1,
        textTransform: 'capitalize',
        height: 43,
        width: 105
      }
    },
    MuiInputLabel: {
      root: {
        font: '16px Open Sans',
        letterSpacing: 0,
        color: 'black',
        opacity: 1,
        '&$disabled': {
          color: '#7d7d7d'
        }
      }
    },
    MuiInput: {
      underline: {
        borderBottom: '1px solid #E2E3E5',
        '&:before': {
          borderBottom: 0
        },
        '&$error:after': {
          color: '#C3274C',
          borderBottomColor: '#C3274C'
        },
        '&:hover:not($disabled):before': {
          borderBottom: '1px solid #FF825C'
        }
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: 'Open Sans',
        color: '#FF825C'
      },
      input: {
        color: '#222B3C',
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px #FAFAFA inset'
        }
      }
    },
    MuiFormLabel: {
      asterisk: {
        color: 'transparent',
        '&$error': {
          color: 'transparent'
        }
      },
      root: {
        fontFamily: 'open Sans',
        '&$error': {
          color: '#C3274C'
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: 6,
        color: '#545E6F'
      }
    },
    MuiCardContent: {
      root: {
        padding: '11px 7px 11px 13px !important'
      }
    },
    MuiStepper: {
      root: {
        position: 'relative',
        backgroundColor: 'transparent',

        '@media (min-width:1200px)': {
          paddingLeft: '8%',
          width: '85%'
        },
        '@media (min-width:1400px)': {
          paddingLeft: '8%',
          width: '85%'
        },
        '@media (min-width:1800px)': {
          paddingLeft: '9%',
          width: '82%'
        },
        '@media (min-width:2500px)': {
          paddingLeft: '9%',
          width: '82%'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: '#C3274C',
          fontWeight: 500,
          letterSpacing: '0.07em'
        }
      }
    },
    MuiStepLabel: {
      label: {
        '&$active': {
          color: '#f26623',
          fontWeight: 'bold'
        },
        '&$completed': {
          color: 'transparent',
          fontWeight: 'bold',
          cursor: 'pointer'
        },
        color: '#FFFFFF',
        font: '14px/16px Open Sans',
        letterSpacing: 0.8,
        whiteSpace: 'nowrap',
        textTransform: 'uppercase'
      },
      horizontal: {
        height: 30,
        width: 30
      }
    },
    MuiStepIcon: {
      root: {
        color: '#b2b2b2',
        border: 0,
        borderRadius: 20,
        top: 0,
        width: 30,
        height: 30,
        '&$active': {
          color: '#f26623',
          border: 0,
          width: 30,
          height: 30
        },
        '&$completed': {
          color: 'transparent',
          border: '1px solid #b5b5b5',
          width: 30,
          height: 30,
          backgroundColor: 'transparent'
        }
      },
      text: {
        fill: '#FFF'
      }
    },
    MuiBreadcrumbs: {
      separator: {
        fontSize: 20,
        fontWeight: 'bold'
      },
      li: {
        pointerEvents: 'none'
      }
    },
    MuiStep: {
      root: {
        '&$horizontal': {
          padding: 0,
          left: 0,
          right: 0
        }
      }
    },
    MuiStepConnector: {
      lineHorizontal: {
        borderColor: '#e4e4e4'
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'transparent',
        color: '#91949a',
        font: 'normal normal 600 14px/24px Open Sans',
        letterSpacing: 0,
        boxShadow: 'none',
        borderBottom: '2px solid #f2f4f5'
      }
    },
    MuiTab: {
      root: {
        borderBottom: 'solid 5px transparent',
        '&$selected': {
          color: '#4d535e',
          border: 'none'
        }
      }
    },
    MuiTabs: {
      flexContainer: {
        cursor: 'pointer !important'
      }
    },
    MuiListItem: {
      root: {
        font: 'normal normal 600 14px/21px Poppins',
        letterSpacing: 0.13,
        color: '#393F4A'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 25
      }
    }
  }
});

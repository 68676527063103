export const SET_PROPOSAL = 'insurance/SET_PROPOSAL';
export const SET_INITIAL_STATE_PROPOSAL = 'insurance/SET_INITIAL_STATE_PROPOSAL';

export const setProposal = proposal => ({
  type: SET_PROPOSAL,
  payload: proposal
});

export const setInitialStateProposal = () => ({
  type: SET_INITIAL_STATE_PROPOSAL
});

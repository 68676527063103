import { makeStyles } from '@material-ui/core/styles';

const Styles = () =>
  makeStyles(theme => ({
    root: {
      height: '55%',
      backgroundColor: 'transparent',
      font: ' 10px/14px Open Sans',
      fontWeight: 600,
      letterSpacing: 0.8,
      color: '#FFFFFF',
      textTransform: 'uppercase',
      opacity: 1,
      marginRight: '2%',
      lineHeight: 0,
      [theme.breakpoints.down('sm')]: {
        marginTop: 10
      }
    },
    icon: {
      color: '#FFFFFF'
    },
    logout: {
      width: 120
    }
  }));

export default Styles;

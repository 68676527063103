import { AppBar, Grid, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loading from '../../../../../../shared/components/loading/Loading';
import { EMPTY_GUID } from '../../../../../../shared/constants/constants';
import { MULTIPLE_BASIC } from '../../../../../../shared/constants/coverageConstants';
import { MONTHLY, TRADITIONAL } from '../../../../../../shared/constants/planConstants';
import coverageManager from '../../../../manager/insurance/quote/plan/coverageManager';
import plansManager from '../../../../manager/insurance/quote/plan/plansManager';
import { plansActions } from '../../../../redux';
import {
  useAnalyze,
  useAnalyzeBroker,
  useAnalyzePolicyOwner
} from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import { useQuote } from '../../../../redux/insurance/quote/quoteCustomHooks';
import { usePlans } from '../../../../redux/plans/plansCustomHooks';
import * as plansOperations from '../../../../redux/plans/plansOperations';
import PlansClasses from './classes/PlansClasses';
import Monthly from './Monthly';
import Traditional from './Traditional';
import StyledTab from '../../../../../../shared/components/tabs/StyledTab';
import StyledTabs from '../../../../../../shared/components/tabs/StyledTabs';

function TabPanel(properties) {
  const { children, value, index, ...other } = properties;

  return (
    <div
      key={`children-${index}`}
      role="tabpanel"
      hidden={value !== index}
      id={`plans-tabpanel-${index}`}
      aria-labelledby={`plans-tab-${index}`}
      {...other}
    >
      {value === index && <Grid>{children}</Grid>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function props(index) {
  return {
    id: `plans-tab-${index}`,
    'aria-controls': `plans-tabpanel-${index}`
  };
}

const Plans = ({ getQuotePremium }) => {
  const classes = PlansClasses()();
  const quote = useQuote();
  const analyze = useAnalyze();
  const broker = useAnalyzeBroker();
  const policyOwner = useAnalyzePolicyOwner();
  const plans = usePlans();
  const [showCoverages, setShowCoverages] = useState(false);
  const types = analyze ? plansManager.getPlanTypesByPolicyOwner(quote, analyze, policyOwner) : [];
  const [multipleBasic, setMultipleBasic] = useState(
    quote?.plan?.id !== EMPTY_GUID && quote?.plan?.coverages?.length > 0
      ? quote?.plan?.coverages.find(c => c.basicCoverage)?.multiple
      : MULTIPLE_BASIC
  );
  const [previousMultipleBasic, setPreviousMultipleBasic] = useState(0);
  const [insuredAmountCalculated, setinsuredAmountCalculated] = useState();
  const dispatch = useDispatch();
  const calculated = coverageManager.getInsuredAmountCalculated(plans?.plans);

  const [planSelected, setPlanSelected] = useState({});

  const plansSettings = {
    planSelected: planSelected,
    setPlanSelect: setPlanSelected,
    plans: plans?.plans
  };

  const coveragesSettings = {
    showCoverages: showCoverages,
    setShowCoverages: setShowCoverages,
    multipleBasic: multipleBasic,
    setMultipleBasic: setMultipleBasic
  };

  useEffect(() => {
    if (!plans && quote?.plan?.type) {
      dispatch(plansOperations.getPlans(quote?.plan.type));
    }

    if (
      (((policyOwner && !insuredAmountCalculated) || policyOwner) &&
        !coverageManager.getInsuredAmountCalculated(plans?.plans)) ||
      previousMultipleBasic !== multipleBasic
    ) {
      coverageManager.getInsuredAmount(
        plans?.plans,
        multipleBasic,
        analyze?.expenses,
        setinsuredAmountCalculated,
        plansActions.setInsuredAmountinPlans,
        dispatch
      );

      setPreviousMultipleBasic(multipleBasic);
    }
  }, [
    calculated,
    previousMultipleBasic,
    plansSettings,
    plans,
    multipleBasic,
    coveragesSettings.showCoverages,
    insuredAmountCalculated,
    policyOwner,
    analyze,
    quote,
    types,
    broker,
    dispatch
  ]);

  return (
    <>
      <Grid container justify="center">
        <Grid container item xs={12} md={8}>
          <Grid item xs={12}>
            <AppBar position="static">
              <StyledTabs
                id="tabs-plan"
                indicatorColor="secondary"
                value={0}
                aria-label="plans tabs"
              >
                {types.map(({ description }) => (
                  <StyledTab
                    key={`tab-${description}`}
                    icon={
                      <p className={classes.titleTab}>
                        {description}
                        <Tooltip
                          className={classes.icon}
                          title={`Plano ${description}`}
                          aria-label={`Plano ${description}`}
                        >
                          <HelpOutline />
                        </Tooltip>
                      </p>
                    }
                    {...props(0)}
                  />
                ))}
              </StyledTabs>
            </AppBar>
            {!quote?.plan?.type && <Loading />}
            {plans?.type === MONTHLY && (
              <TabPanel key={`tabPanel-${MONTHLY}`} value={0} index={0}>
                {coveragesSettings.showCoverages && !calculated && <Loading />}
                {
                  <Monthly
                    coveragesSettings={coveragesSettings}
                    getQuotePremium={getQuotePremium}
                  />
                }
              </TabPanel>
            )}
            {plans?.type === TRADITIONAL && (
              <TabPanel key={`tabPanel-Tradicional`} value={0} index={0}>
                <Traditional />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Plans.propTypes = {
  getQuotePremium: PropTypes.func.isRequired
};

export default Plans;

import { Box, Button, Grid } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import FormClasses from './classes/FormClasses';

const PolicyFooter = ({ goBackClick, endpointPolicyDocument }) => {
  const classes = FormClasses();

  return (
    <Box id="footerQuestions" key="footerQuestions" xs={12} className={classes.form}>
      <Grid container item justify="center">
        <Grid item xs={12} md={8}>
          <Box textAlign="end" fontSize={14} color="#4E596E" pb={4} pt={4}>
            <Button
              id="btn-back"
              variant="contained"
              className={classes.buttonEndorsement}
              onClick={() => { }}
              disabled
            >
              CANCELAR APÓLICE
            </Button>
            <Button
              disabled={!endpointPolicyDocument}
              id="btn-download"
              variant="contained"
              className={`${classes.buttonCardDownload}`}
              onClick={() => window.open(endpointPolicyDocument)}
            >
              <GetAppOutlinedIcon className={classes.iconButtonCardDownload} />
              BAIXAR APÓLICE
            </Button>
            <Button
              id="submitForm"
              variant="contained"
              className={classes.buttonOrange}
              onClick={() => goBackClick()}
            >
              VOLTAR PARA MINHAS SOLICITAÇÕES
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

PolicyFooter.propTypes = {
  goBackClick: PropTypes.func,
  endpointPolicyDocument: PropTypes.string
};

export default PolicyFooter;

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { EMPTY_STRING } from '../../constants/constants';
import { CNPJ_SIZE, CPF_SIZE } from '../../constants/formConstants';
import { formatCnpj, formatCpf } from '../../helper/cpfCnpjImportHelper.ig';
import { requiredValidator, validateDocument } from '../../helper/formValidators';
import InputClasses from './InputClasses';

export const validateCpfCnpjInput = (value, required) => {
  let msg = EMPTY_STRING;
  if (required) msg = requiredValidator(value, EMPTY_STRING);
  if (!msg) msg = validateDocument(value, EMPTY_STRING);
  return msg;
};

export const getFormatCpfOrCnpj = value => {
  if (!value) return;
  if (value.length <= CPF_SIZE) {
    return formatCpf(value);
  } else if (value.length > CPF_SIZE && value.length <= CNPJ_SIZE) {
    return formatCnpj(value);
  } else {
    return value;
  }
};

const NumberFormatCustom = function(props) {
  const { onChange, value, inputRef, ...other } = props;

  return (
    <form onSubmit={e => e.preventDefault()} autoComplete="off">
      <NumberFormat
        {...other}
        value={value || EMPTY_STRING}
        allowLeadingZeros
        format={values => getFormatCpfOrCnpj(values)}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        isAllowed={values => values.value.length <= CNPJ_SIZE}
        getInputRef={inputRef}
      />
    </form>
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function CpfCnpjInput({
  value,
  onChange,
  onBlur,
  label,
  disabled,
  required,
  id,
  error
}) {
  const classes = InputClasses();
  const hasError = !!error;
  return (
    <FormControl className={classes.formControl}>
      {label && (
        <InputLabel error={hasError} required={required} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <Input
        id={id}
        error={hasError}
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur(id, validateCpfCnpjInput(value, required));
          }
        }}
        inputComponent={NumberFormatCustom}
        disabled={disabled}
      />
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </FormControl>
  );
}

CpfCnpjInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.string
};

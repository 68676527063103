import { EMPTY_STRING } from '../constants/constants';

export const StatusModalTitle = {
  APPROVED: 'Locatário Aprovado',
  DENIED: 'Locatário não aprovado...',
  UNDERANALYSIS: 'Iremos avaliar sua solicitação'
};

export const StatusModalTextButton = {
  APPROVED: 'Baixar carta de aprovação',
  DENIED: 'Baixar carta de recusa',
  UNDERANALYSIS: EMPTY_STRING
};

export const POLICY_STATUS = {
  ISSUED: 'Apólice emitida com sucesso!',
  DOWNLOAD_BUTTON: 'Baixar apólice'
};
import { Box, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { EMPTY_STRING } from '../../../../../../../shared/constants/constants';
import { TRANSMITTED } from '../../../../../../../shared/enums/statusEnum';
import { useAnalyze } from '../../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../../redux/insurance/policy/policyCustomHooks';
import { useInstallments, useQuote } from '../../../../../redux/insurance/quote/quoteCustomHooks';
import FooterPlansCardClasses from '../classes/FooterPlansCardClasses';

const FooterPlansCard = ({
  rentalPlan,
  showCoverages,
  choosePlan
}) => {
  const classes = FooterPlansCardClasses();
  const quote = useQuote();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const installments = useInstallments();
  const disabled = (analyze?.status === TRANSMITTED || policy?.id || installments === null || installments <= 0 || !rentalPlan.grossPremium || rentalPlan.grossPremium < 0);

  return (
    <>
      <Box pb={5} textAlign="center" className={` ${showCoverages ? classes.footerCoverages : EMPTY_STRING}`}>
        <Grid item xs={12}>
          <Box pb={2}>
            <Button
              id="btn-choosePlan"
              variant="contained"
              disabled={disabled}
              className={`${classes.button} ${quote?.plan?.id === rentalPlan.id
                ? classes.buttonCardSelected
                : classes.buttonCard
                }`}
              onClick={() => choosePlan()}
            >
              {quote?.plan?.id === rentalPlan.id
                ? 'SELECIONADO'
                : 'SELECIONAR'}
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

FooterPlansCard.propTypes = {
  rentalPlan: PropTypes.object.isRequired,
  showCoverages: PropTypes.bool.isRequired,
  choosePlan: PropTypes.func.isRequired
};

export default FooterPlansCard;

import axios from 'axios';

class RentalAgreementService {
  get = async id => {
    const response = await axios.get(`${window.env.RENTAL_SURETY}/RentalAgreement/${id}`);

    return response.data;
  };

  put = async rentalAgreement => {
    const response = await axios.put(
      `${window.env.RENTAL_SURETY}/RentalAgreement/${rentalAgreement.id}`,
      rentalAgreement
    );

    return response.data;
  };

  create = async () => {
    const response = await axios.post(`${window.env.RENTAL_SURETY}/RentalAgreement`);

    return response.data;
  };

  delete = async id => {
    const response = await axios.delete(`${window.env.RENTAL_SURETY}/RentalAgreement/${id}`);

    return response.data;
  };
}

export default new RentalAgreementService();

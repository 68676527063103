import makeStyles from '@material-ui/styles/makeStyles';

const Styles = makeStyles({
  image: {
    height: 60
  },
  bigMoney: {
    fontSize: 25
  }
});

export default Styles;

import { Box, Grid, Paper } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import utilManager from '../../../../../../../features/util/services/utilManager';
import NumberInput from '../../../../../../../shared/components/form/NumberInput';
import { moneyFormat } from '../../../../../../../shared/components/format/MoneyFormat';
import {
  EMPTY_STRING,
  HUNDRED_MULTIPLIER
} from '../../../../../../../shared/constants/constants';
import { TRANSMITTED } from '../../../../../../../shared/enums/statusEnum';
import calculateManager from '../../../../../manager/calculate/calculateManager';
import coverageManager from '../../../../../manager/insurance/quote/plan/coverageManager';
import { calculateOperations, errorsActions, quoteActions } from '../../../../../redux';
import { useErrors } from '../../../../../redux/errors/errorsCustomHooks';
import {
  useAnalyze,
  useAnalyzeBroker
} from '../../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../../redux/insurance/policy/policyCustomHooks';
import { useQuote } from '../../../../../redux/insurance/quote/quoteCustomHooks';
import ListCoverageTraditionalClasses from '../classes/ListCoverageTraditionalClasses';

const TraditionalCoverageTotal = () => {
  const dispatch = useDispatch();
  const classes = ListCoverageTraditionalClasses();
  const broker = useAnalyzeBroker();
  const quote = useQuote();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const errors = useErrors();
  const disabled = (analyze?.status === TRANSMITTED || policy?.id);

  const totalCoverages =
    quote?.plan?.coverages?.length > 0
      ? coverageManager.getTotalLiquidCoverages(quote?.plan.coverages)
      : 0;

  const updatecommissionPercentage = async (name, value) => {
    dispatch(
      quoteActions.setInitialStateQuote({
        ...quote,
        plan: {
          ...quote?.plan,
          [name]: calculateManager.getDivision(parseFloat(value), HUNDRED_MULTIPLIER)
        }
      })
    );
    await utilManager.sleep(1e3);
    dispatch(calculateOperations.getCommission());
  };

  const updateErrorsOnBlur = async (name, value) => {
    dispatch(
      errorsActions.setErrors({
        ...errors,
        plan: { ...errors.plan, [name]: value }
      })
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Box>
          <Paper className={`${classes.paper} ${classes.totalPaper}`}>
            <Grid container item justify="center">
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row-reverse">
                  <Grid item xs={12} md={2}>
                    <Box pb={0} pt={2} className={classes.fontTitle}>
                      Valor do Seguro
                    </Box>
                    <Box pt={2} pb={1} className={classes.description}>
                      {moneyFormat(totalCoverages)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Box pb={0} pt={2} className={classes.fontTitle}>
                      Valor da Comissão
                    </Box>
                    <Box pt={2} pb={1} className={classes.description}>
                      {moneyFormat(quote?.plan?.commission)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Box px={2} pb={0} pt={2} className={classes.fontTitle}>
                      Comissão Total
                    </Box>
                    <Box px={3} pt={1} className={classes.description}>
                      <NumberInput
                        id="commissionPercentage"
                        value={calculateManager
                          .getMultiply(quote?.plan?.commissionPercentage ?? 0, HUNDRED_MULTIPLIER)
                          ?.toString()}
                        label={EMPTY_STRING}
                        error={errors?.plan?.commissionPercentage}
                        onChange={updatecommissionPercentage}
                        onBlur={updateErrorsOnBlur}
                        percentage
                        required
                        border
                        min={calculateManager.getMultiply(
                          broker?.commission.minValue ?? 0,
                          HUNDRED_MULTIPLIER
                        )}
                        max={calculateManager.getMultiply(
                          broker?.commission.maxValue ?? 0,
                          HUNDRED_MULTIPLIER
                        )}
                        disabled={disabled}
                      />
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </>
  );
};

export default TraditionalCoverageTotal;

import filesService from './filesService';

class FilesServiceManager {
  create = async file => {
    return await filesService.create(file);
  };

  get = async fileId => {
    return await filesService.get(fileId);
  };

  put = async file => {
    return await filesService.put(file);
  };

  delete = async fileId => {
    return await filesService.delete(fileId);
  };

  fileManagement = async proposal => {
    let newFile = proposal.complianceLetterFile;
    if (proposal.hasComplianceLetter && !proposal.complianceLetterFile?.id == null) {
      const documentId = await this.create(proposal.complianceLetterFile);
      newFile = { ...newFile, id: documentId };
    } else if (!proposal.complianceLetterFile?.name && proposal.complianceLetterFile?.id) {
      await this.delete(proposal.complianceLetterFile.id);
      newFile = null;
    } else if (proposal.complianceLetterFile?.id && !proposal.complianceLetterFile?.link) {
      await this.put(proposal.complianceLetterFile);
    }

    return newFile;
  };
}
export default new FilesServiceManager();

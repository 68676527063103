import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useUser } from '../../../../../../shared/components/header/redux/userCustomHooks';
import { INSURED_NAMES, TEXT_ACCEPT_MODAL } from '../../../../../../shared/constants/constants';
import { usePorposalInsuredsNames } from '../../../../redux/insurance/proposal/proposalCustomHooks';
import InternalClasses from '../../classes/InternalClasses';

const InternalBodyAcceptModal = () => {
  const classes = InternalClasses();
  const user = useUser();
  const insuredsNames = usePorposalInsuredsNames();

  return (
    <Grid item xs={12}>
      <Box px={5} pt={8} justify="center" className={classes.text} overflow="auto" marginRight={3}>
        <b>Nome de usuário:</b>
        <br />
        {user.name}
        <Box pt={1}>
          <Box className={classes.dates}>
            <Box>{TEXT_ACCEPT_MODAL.replace(INSURED_NAMES, insuredsNames)}</Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default InternalBodyAcceptModal;

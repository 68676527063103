import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  icon: {
    width: 35,
    height: 35,
    [theme.breakpoints.up('xl')]: {
      width: 45,
      height: 45
    }
  },
  button: {
    width: 24,
    height: 24,
    marginTop: 40,
    [theme.breakpoints.up('xl')]: {
      width: 45,
      height: 45
    }
  },
  buttonCardDownload: {
    font: 'normal normal bold 10px/14px Open Sans',
    borderRadius: 30,
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none'
    },
    background: 'transparent',
    border: '1px solid #FD5D2B',
    color: '#FD5D2B',
    minWidth: 250,
    marginRight: 40,
    letterSpacing: 1.2,
    fontWeight: 'bold'
  },
  iconButtonCardDownload: {
    color: '#F26522',
    marginRight: 5
  },
  footer: {
    textAlign: 'center',
    position: 'fixed',
    bottom: 40
  },
  buttonOrange: {
    font: 'normal normal bold 10px/14px Open Sans',
    letterSpacing: 0.25,
    color: '#FFFFFF',
    opacity: 1,
    background: '#FD5D2B 0% 0% no-repeat padding-box',
    borderRadius: 20,
    '&:hover, &:focus': {
      backgroundColor: '#FF825C'
    },
    height: 38,
    paddingLeft: 32,
    paddingRight: 32
  },
  buttonEndorsement: {
    marginRight: 40,
    font: 'normal normal bold 10px/14px Open Sans',
    letterSpacing: 0.25,
    color: '#FFFFFF',
    opacity: 1,
    background: '#c3284c 0% 0% no-repeat padding-box',
    borderRadius: 20,
    '&:hover, &:focus': {
      backgroundColor: '#c4536d'
    },
    height: 38,
    paddingLeft: 32,
    paddingRight: 32
  },
  buttonBack: {
    font: 'normal normal bold 10px/14px Open Sans',
    letterSpacing: 0.25,
    color: '#FD5D2B',
    opacity: 1,
    background: 'transparent',
    height: 30,
    border: 0,
    boxShadow: 'none',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      color: '#FF825C',
      border: 0,
      boxShadow: 'none'
    },
    marginRight: 30
  },
  form: {
    width: '100%',
    alignItems: 'center'
  },
  container: {
    textAlign: 'center',
    flexDirection: 'column'
  },
  titleForm: {
    textAlign: 'left',
    font: 'normal normal bold 19px/109px Poppins',
    letterSpacing: -0.54,
    color: '#282F3C',
    opacity: 1,
    margin: 0,
    marginTop: '5%',
    bottom: 0,
    height: 60
  },
  lineTitle: {
    height: 3,
    border: 0,
    backgroundColor: '#f26623',
    width: '8%',
    marginLeft: 0,
    marginTop: 20,
    marginBottom: '5%'
  },
  checkbox: {
    paddingLeft: 0
  },
  valueGrid: {
    backgroundColor: '#e3e3e3',
    font: 'normal normal bold 12px/14px Poppins',
    letterSpacing: 0.5,
    width: '90%',
    height: 50,
    color: '#f26623',
    marginLeft: '5%',
    marginTop: '20%'
  },
  approved: {
    color: '#282f3c'
  },
  grid: {
    height: 30
  },
  cepInput: {
    textAlign: 'start'
  }
}));

export default Styles;

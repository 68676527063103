import makeStyles from '@material-ui/styles/makeStyles';

const Styles = makeStyles({
  paper: {
    maxWidth: 290,
    height: 370,
    margin: '0 15',
    borderRadius: 15,
    boxShadow: '0px 6px 8px 0px rgba(0,0,0,0.18)'
  },
  gridContainer: {
    zIndex: 0,
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'grid'
  },
  gridCenter: {
    '@media (min-width:1200px)': {
      width: '90%'
    },
    '@media (min-width:1400px)': {
      width: '80%'
    },
    '@media (min-width:1800px)': {
      width: '60%'
    },
    margin: 'auto',
    backgroundColor: 'red'
  }
});

export default Styles;

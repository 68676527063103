import { Box, Card, CardContent, IconButton, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { EMPTY_STRING } from '../constants/constants';

const useStyles = makeStyles({
  root: {
    margin: '14px 0px',
    borderRadius: '15PX'
  },
  errorCard: {
    border: '1px solid #C3274C'
  }
});

const AddData = ({ text, addClick, error }) => {
  const classes = useStyles();
  return (
    <Card className={`${classes.root} ${error ? classes.errorCard : EMPTY_STRING}`}>
      <CardContent padding="0px">
        <Typography component={'span'} variant={'body2'}>
          <Box textAlign="right" fontSize={14} color="#4E596EDE">
            {text}
            <IconButton
              id="btn-add"
              onClick={addClick}
              style={{ color: '#FFF', background: '#fd5d2b', margin: '0px 0px 0px 13px' }}
              aria-label={text}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Typography>
      </CardContent>
    </Card>
  );
};

AddData.propTypes = {
  text: PropTypes.string.isRequired,
  addClick: PropTypes.func.isRequired,
  error: PropTypes.bool
};

export default AddData;

import axios from 'axios';

class UserService {
  getUserByPersonId = async personId => {
    const response = await axios.get(`${window.env.RENTAL_SURETY}/Users/${personId}`);

    return response.data;
  };
}

export default new UserService();

import { makeStyles } from '@material-ui/core';

const Styles = () =>
  makeStyles(theme => ({
    titlePage: {
      textAlign: 'center',
      font: 'normal normal bold 22px/108px Poppins',
      letterSpacing: -0.66,
      color: '#282F3C',
      opacity: 1,
      marginBottom: 0,
      height: 72,
      marginTop: 50
    },
    icon: {
      width: 18,
      height: 18,
      marginBottom: 5,
      marginLeft: 5,
      [theme.breakpoints.down('sm')]: {
        marginRight: 30
      }
    }
  }));

export default Styles;

import { INSURANCE } from '../../../../../shared/constants/insuranceConstants';
import utilManager from '../../../../util/services/utilManager';
import documentsServiceManager from '../../../services/documents/documentsServiceManager';
import documentsManager from '../../documents/documentsManager';
import insuranceDataManager from './insuranceData/insuranceDataManager';
import plansManager from './plan/plansManager';

class quoteManager {
  formValidate = (quote, analyze) => {
    const errors = {};
    errors.insuranceData = insuranceDataManager.validateInsurance(quote, analyze);
    errors.plan = plansManager.validatePlan(quote?.plan);
    return utilManager.validErrors(errors) ? null : errors;
  };

  getQuoteExpenses = expenses => {
    const newExpenses = INSURANCE.expenses.map(expense =>
      expenses.find(ie => ie.description === expense.description)
        ? {
            ...expense,
            value: expenses.find(ie => ie.description === expense.description).value
          }
        : expense
    );

    return newExpenses;
  };

  getQuotePdf = async quote => {
    var document = await documentsServiceManager.downloadQuotePdf(quote);
    const fileUrl = documentsManager.getFileUrl(document, 'application/pdf');
    return fileUrl;
  };
}

export default new quoteManager();

import { Box, IconButton, List, ListItem, ListItemIcon } from '@material-ui/core';
import { Done, HelpOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { moneyFormat } from '../../../../../../../shared/components/format/MoneyFormat';
import InternalComponentCoverage from '../../../../../../../shared/components/modal/modalInfo/InternalComponentCoverage';
import ModalInfo from '../../../../../../../shared/components/modal/modalInfo/ModalInfo';
import {
  BASE_IMG,
  COVERAGES_IMAGES,
  COVERAGE_DEFAULT_IMAGE
} from '../../../../../../../shared/constants/coverageConstants';
import PlanCoveragesClasses from '../classes/PlanCoveragesClasses';

const PlanCoverages = function ({ coveragesSettings }) {
  const [openModal, setOpenModal] = useState(false);
  const classes = PlanCoveragesClasses();
  const [planCoverage, setPlanCoverage] = useState({});

  const helpCoverageClick = coverage => {
    setPlanCoverage(coverage);
    setOpenModal(true);
  };

  const exitButtonOnClick = async () => {
    setOpenModal(false);
  };

  return (
    <>
      <ModalInfo
        open={openModal}
        children={
          <InternalComponentCoverage coverage={planCoverage} cancelClick={exitButtonOnClick} />
        }
        image={`${BASE_IMG}${COVERAGES_IMAGES.find(coverageImage => coverageImage.name === planCoverage.name)
            ? COVERAGES_IMAGES.find(coverageImage => coverageImage.name === planCoverage.name).img
            : COVERAGE_DEFAULT_IMAGE
          }.png`}
        blur={false}
        showExitButton
        exitButtonOnClick={exitButtonOnClick}
      />
      <List>
        {coveragesSettings.coverages.map(coverage => (
          <Box key={`coverageInfo-${coverage.id}`}>
            {(coverage.id === coveragesSettings.coverageSelected.id || coverage.required) && (
              <Box key={`coverageItem-${coverage.id}`}>
                <ListItem key={coverage.name}>
                  <ListItemIcon>
                    <Done
                      className={`${coverage.id === coveragesSettings.coverageSelected.id || coverage.required
                          ? classes.icon
                          : classes.hidden
                        }`}
                    />
                  </ListItemIcon>
                  <p className={classes.description}>
                    {coverage.name}
                    <IconButton id="btn-info-coverage" onClick={() => helpCoverageClick(coverage)}>
                      <HelpOutline className={classes.info} />
                    </IconButton>
                  </p>
                </ListItem>
                <Box
                  pl={9}
                  pb={2}
                  letterSpacing={0}
                  textAlign="left"
                  fontSize={12}
                  fontWeight="fontWeightBold"
                  fontFamily="Open Sans"
                  key={`commercialPremium-${coverage.id}`}
                >
                  <b>{moneyFormat(coverage.insuredAmount?.value)} cobertura/ano</b>
                </Box>
              </Box>
            )}{' '}
          </Box>
        ))}
      </List>
    </>
  );
};

PlanCoverages.propTypes = {
  coveragesSettings: PropTypes.object.isRequired
};

export default PlanCoverages;

import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import {
  APPROVAL_TEMPLATE,
  GO_BACK,
  REFUSAL_TEMPLATE
} from '../../../../../../shared/constants/constants';
import { SubscriptionStatus } from '../../../../../../shared/enums/statusEnum';
import { useAnalyze } from '../../../../redux/insurance/analyze/analyzeCustomHooks';

const useStyles = makeStyles({
  gridButton: {
    position: 'absolute',
    bottom: 20,
    width: '100%'
  },
  button: {
    fontFamily: 'Open Sans',
    borderRadius: 30,
    fontSize: 12,
    textTransform: 'none',
    fontWeight: 'normal',
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none'
    }
  },
  buttonCardSelect: {
    background: '#FD5D2B',
    color: '#FFFFFF',
    minWidth: 155,
    marginLeft: 25
  },
  buttonCardDownload: {
    background: 'transparent',
    border: '1px solid #FD5D2B',
    color: '#FD5D2B',
    minWidth: 250,
    marginLeft: 115,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  buttonCardCancel: {
    background: 'transparent',
    color: '#FD5D2B',
    marginLeft: 115,
    letterSpacing: 1.2,
    fontWeight: 'bold'
  },
  icon: {
    color: '#F26522',
    marginRight: 5
  }
});

const InternalInfoFooter = ({ continueClick, modalSettings }) => {
  const classes = useStyles();
  const analyze = useAnalyze();
  const templateKey =
    modalSettings.status === SubscriptionStatus.APPROVED ? APPROVAL_TEMPLATE : REFUSAL_TEMPLATE;

  const getButtonTitle = () => {
    return modalSettings.completed ? 'VOLTAR' : 'CONTINUAR';
  };

  return (
    <>
      <Grid item xs={12} className={classes.gridButton}>
        <Box px={3} py={3} display="flex" flexDirection="row-reverse">
          {modalSettings.textButton ? (
            <>
              <Button
                id="btn-continue"
                variant="contained"
                onClick={continueClick}
                className={`${classes.button} ${classes.buttonCardSelect}`}
              >
                {getButtonTitle()}
              </Button>
              <Button
                id="btn-download"
                variant="contained"
                className={`${classes.button} ${classes.buttonCardDownload}`}
                onClick={() =>
                  window.open(
                    `${window.env.RENTAL_SURETY}/Documents/download?DocumentName=${analyze?.documentLetter}&TemplateKey=${templateKey}`
                  )
                }
                disabled={!analyze?.documentLetter}
              >
                <GetAppOutlinedIcon className={classes.icon} />
                {modalSettings.textButton}
              </Button>
            </>
          ) : (
            <Button
              id="btn-back"
              variant="contained"
              onClick={continueClick}
              className={`${classes.button} ${classes.buttonCardSelect}`}
            >
              {GO_BACK}
            </Button>
          )}
        </Box>
      </Grid>
    </>
  );
};

InternalInfoFooter.propTypes = {
  continueClick: PropTypes.func,
  modalSettings: PropTypes.object.isRequired
};

export default InternalInfoFooter;

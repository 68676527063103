import isValid from 'date-fns/isValid';
import _ from 'lodash';
import { EMPTY_STRING } from '../constants/constants';
import {
  CELLPHONE_SIZE,
  CNPJ_SIZE,
  COVERAGE_MAXIMUM,
  COVERAGE_MINIMUM,
  CPF_SIZE,
  PHONE_SIZE,
  REQUIRED
} from '../constants/formConstants';
import { isValidCnpj, isValidCpf } from './cpfCnpjImportHelper';

const getCustomText = customText => {
  return customText ? customText : REQUIRED;
};

export const requiredValidator = (value, textError, customText) => {
  return value === null || _.isEmpty(value?.toString()) || value === 0
    ? getCustomText(customText)
    : textError;
};

export const isNaNValidator = (value, textError) => {
  return isNaN(value) ? REQUIRED : textError;
};

export const requiredCheckValidator = (value, textError) => {
  return value === null || _.isEmpty(value?.toString()) || value === 0 || value === false
    ? REQUIRED
    : textError;
};

export const validateMinCaracteres = (value, textError, min) => {
  return value && value.length < min
    ? 'Campo deve ter no mínimo ' + min + ' caracteres'
    : textError;
};

export const validateJustLetters = (value, textError, min) => {
  return /[0-9]/.test(value) ? 'Campo deve possuir apenas letras' : textError;
};

export const validateDocument = (value, textError) => {
  let returnValue = { type: EMPTY_STRING, value: false };
  if (!value) returnValue = { type: EMPTY_STRING, value: true };
  if (value?.length <= CPF_SIZE) returnValue = { ...returnValue, type: 'CPF' };
  else if (value?.length > CPF_SIZE && value?.length <= CNPJ_SIZE)
    returnValue = { ...returnValue, type: 'CNPJ' };
  if (isValidCpf(value) || isValidCnpj(value)) returnValue = { ...returnValue, value: true };
  const { value: validatorValue, type: validatorType } = returnValue;

  return !validatorValue ? `${validatorType} inválido` : textError;
};

export const validatePhone = (value, textError) => {
  let returnValue = { type: 'Telefone', value: false };
  if (value) {
    if (value.length === PHONE_SIZE) returnValue = { type: 'Telefone', value: true };
    else if (value.length === CELLPHONE_SIZE) returnValue = { type: 'Celular', value: true };
  }
  const { value: validatorValue, type: validatorType } = returnValue;
  return !validatorValue ? `${validatorType} inválido` : textError;
};

const getRealString = (money, value) => {
  return (money ? 'R$ ' : EMPTY_STRING) + value + (money ? ',00' : EMPTY_STRING);
};

export const validateMinValue = (value, textError, min, money) => {
  const newValue =
    value.toString().indexOf(',') >= 0
      ? parseFloat(value.replace('.', EMPTY_STRING))
      : parseFloat(value);
  return newValue < min ? 'Valor deve ser a partir de ' + getRealString(money, min) : textError;
};

export const validateMaxValue = (value, textError, max, money) => {
  return parseFloat(value) > max ? 'Valor deve ser até ' + getRealString(money, max) : textError;
};

export const validateMinPercentValue = (value, min, textError) => {
  return parseFloat(value) < min ? 'Min. ' + min + '%' : textError;
};

export const validateMaxPercentValue = (value, max, textError) => {
  return parseFloat(value) > max ? 'Max. ' + max + '%' : textError;
};

export const emailValidator = (value, textError) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'E-mail Inválido'
    : textError;
};

export const dateValidator = (value, textError) => {
  return !value || !isValid(value) || value >= new Date('2100-01-01') ? 'Data Inválida' : textError;
};

const getValueGreaterThanDate = (start, initial, final, textError) => {
  return start && initial > final ? 'Data inicial deve ser menor que final' : textError;
};

export const diferenceDates = (initial, final, start, textError) => {
  return !start && final < initial
    ? 'A Data final deve ser maior que inicial'
    : getValueGreaterThanDate(start, initial, final, textError);
};

export const equalDates = (value, date, textError) => {
  return value.setHours(0, 0, 0, 0) === date?.setHours(0, 0, 0, 0)
    ? 'As Datas não podem ser iguais'
    : textError;
};

export const validateMultiple = (multiple, insuranceCoverage) => {
  let msg = EMPTY_STRING;

  if (multiple) {
    if (insuranceCoverage.limitMultiple.multipleMax < multiple) {
      msg = `${COVERAGE_MAXIMUM} ${insuranceCoverage.limitMultiple.multipleMax}x`;
    } else if (insuranceCoverage.limitMultiple.multipleMin > multiple) {
      msg = `${COVERAGE_MINIMUM} ${insuranceCoverage.limitMultiple.multipleMin}x`;
    }
  } else {
    msg = REQUIRED;
  }

  return msg;
};

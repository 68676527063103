import React from 'react';
import { authManager } from '../authManager.ig';

const SilentRenewPage = () => {
  authManager.getUserManager().signinSilentCallback();

  return <></>;
};

export default SilentRenewPage;

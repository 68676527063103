import { makeStyles } from '@material-ui/core';

const Styles = makeStyles({
    box: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        outlineColor: 'transparent',
        overflow: 'auto',
        width: '100%',
        height: '100%'
    },
    header: {
        fontFamily: 'Open Sans',
        color: '#8D939F',
        letterSpacing: '0.9px',
        fontSize: 10,
        opacity: 1
    },
    title: {
        fontFamily: "'Poppins', sans-serif",
        opacity: '1',
        letterSpacing: '-0.84px',
        color: '#282F3C',
        marginTop: 0
    }
});

export default Styles;

import { Box, Breadcrumbs, Grid, IconButton } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { BusinessCenter, ExpandMore } from '@material-ui/icons';
import PropType from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import IntermediatePerson from '../../../features/intermediatePerson/views/IntermediatePerson';
import { useAnalyzePolicyOwner } from '../../../features/quiz/redux/insurance/analyze/analyzeCustomHooks';
import { EMPTY_STRING } from '../../constants/constants';
import { INTERMEDIATE_PERSON_IMAGE, ROLE_POLICYOWNER } from '../../constants/personConstants';
import { routes } from '../../constants/routes';
import BreadCrumbClasses from '../classes/BreadCrumbClasses';
import ModalInfo from '../modal/modalInfo/ModalInfo';

const BreadCrumb = ({ pages }) => {
  const classes = BreadCrumbClasses();
  const [openModal, setOpenModal] = useState(false);
  const policyOwner = useAnalyzePolicyOwner();
  const history = useHistory();
  const showPolicyOwner = history.location.pathname.indexOf(routes.quiz.path) >= 0;

  const exitButtonOnClick = () => {
    setOpenModal(false);
  };

  return (
    <Grid className={classes.breadcrumb}>
      <ModalInfo
        open={openModal}
        image={INTERMEDIATE_PERSON_IMAGE}
        blur
        showExitButton={false}
        exitButtonOnClick={exitButtonOnClick}
        children={<IntermediatePerson setOpenModal={setOpenModal} />}
      />
      <Grid container item justify="center" direction="row">
        <Grid item xs={12} md={8}>
          <Grid className={classes.root}>
            <Box pl={12}>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                {pages.map((page, index) => (
                  <Link
                    className={`${classes.title} ${index === pages.length - 1 ? classes.active : EMPTY_STRING
                      }`}
                    key={`link-${index}`}
                    to={page.route}
                  >
                    {page.title}
                  </Link>
                ))}
              </Breadcrumbs>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={3}
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          {showPolicyOwner && (
            <>
              {policyOwner && (
                <>
                  <BusinessCenter className={classes.icon} />
                  <p className={classes.person}>{ROLE_POLICYOWNER}</p>
                </>
              )}
              <p className={classes.text}>
                {policyOwner ? policyOwner.name : 'Sem estipulante'}
                <IconButton
                  id="open-btn"
                  className={classes.iconButton}
                  aria-label="open intermetiatePerson"
                  onClick={() => setOpenModal(true)}
                  component="span"
                >
                  <ExpandMore />
                </IconButton>
              </p>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

BreadCrumb.propTypes = {
  pages: PropType.array.isRequired
};

export default BreadCrumb;

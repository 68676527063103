import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    background: '#FFFFFF',
    borderRadius: 15,
    boxShadow: '0px 2px 4px #00000029'
  },
  header: {
    fontFamily: 'Open Sans',
    color: '#8D939F',
    letterSpacing: '0.9px',
    fontSize: 10,
    opacity: 1
  },
  name: {
    fontFamily: 'Open Sans',
    color: '#282F3C',
    letterSpacing: 0,
    fontSize: 16,
    opacity: 1
  },
  deleteIcon: {
    color: '#959ba6'
  }
}));

export default Styles;

import { insurancesActions } from '..';
import { ITEMNOTFOUND_STATUS } from '../../../../shared/constants/errorConstants';
import insurancesServiceManager from '../../services/insurances/insurancesServiceManager';
import * as quizActions from '../quizActions';

export const getInsurances = (params = {}) => async dispatch => {
  try {
    dispatch(quizActions.setLoading(true));
    const insurances = await insurancesServiceManager.getInsurances(params);
    dispatch(insurancesActions.setListInsurances(insurances));
    return insurances;
  } catch (err) {
    if (err?.response?.status === ITEMNOTFOUND_STATUS) {
      dispatch(quizActions.setLoading(false));
      dispatch(quizActions.setNotFound(true));
    } else {
      dispatch(quizActions.setError(true));
    }
  } finally {
    dispatch(quizActions.setLoading(false));
  }
};

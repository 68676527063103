import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {
  TEXT_SUBMITTED_MODAL_AWAIT_EMISSION, TEXT_SUBMITTED_MODAL_REICEIVED
} from '../../../../../../shared/constants/constants';
import { useProposal } from '../../../../redux/insurance/proposal/proposalCustomHooks';
import InternalClasses from '../../classes/InternalClasses';

const InternalBodySubmittedModal = () => {
  const classes = InternalClasses();
  const proposal = useProposal();

  return (
    <Grid item xs={12}>
      <Box px={5} pt={8} justify="center" className={classes.text} overflow="auto" marginRight={3}>
        <Box pt={1}>
          <Box className={classes.dates}>
            {`${TEXT_SUBMITTED_MODAL_REICEIVED} ${proposal?.proposalSequential}!`}
            <br />
            {TEXT_SUBMITTED_MODAL_AWAIT_EMISSION}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default InternalBodySubmittedModal;

import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import ToastErrorClasses from '../classes/ToastErrorClasses';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ToastError = function({ open, text, setOpenToast }) {
  const classes = ToastErrorClasses();

  const handleClose = () => {
    setOpenToast(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar id={'snackBar'} open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
};

ToastError.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};

export default ToastError;

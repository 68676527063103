import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles({
  box: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    outlineColor: 'transparent',
    overflow: 'auto',
    width: '100%',
    height: '100%'
  },
  paper: {
    marginTop: 10,
    background: '#FFFFFF',
    borderRadius: 15,
    height: 'auto',
    width: 1000
  },
  button: {
    fontFamily: 'Open Sans',
    fontSize: 13,
    minWidth: 155,
    textTransform: 'none',
    fontWeight: 'normal',
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none'
    }
  },
  buttonSelect: {
    background: '#FD5D2B',
    color: '#FFF',
    borderRadius: 30,
    '&:hover, &:focus': {
      backgroundColor: '#FD5D2B'
    }
  },
  buttonDisabled: {
    background: '#CFD5E2',
    color: '#FFF',
    borderRadius: 30,
    '&:disabled': {
      color: '#FFF'
    }
  },
  buttonReturn: {
    color: '#F26522',
    border: 'none',
    backgroundColor: 'transparent',
    opacity: '1',
    '&:hover, &:focus': {
      backgroundColor: '#FFF'
    }
  },
  buttonDelete: {
    color: '#F26522',
    minWidth: 100,
    backgroundColor: 'transparent',
    border: '1px solid #F26522',
    borderRadius: 30,
    opacity: '1',
    '&:hover, &:focus': {
      backgroundColor: '#FFF'
    }
  },
  buttonClose: {
    borderRadius: 100
  },
  icon: {
    fontSize: '2rem'
  }
});

export default Styles;

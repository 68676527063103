import _ from 'lodash';

export const validLocation = location => {
  const fields = [
    ...Object.values(_.omit(location, ['complement', 'neighborhood', 'number', 'streetName']))
  ];
  return fields.every(field => field);
};

class LocationManager {
  getValidLocationSearch = location => {
    return validLocation(location);
  };
}

export default new LocationManager();

import { useSelector } from 'react-redux';

export const useLoading = () => {
  return useSelector(state => state.quiz.isLoading);
};

export const useHasError = () => {
  return useSelector(state => state.quiz.hasError);
};

export const useSteps = () => {
  return useSelector(state => state.quiz.steps);
};

import * as policyActions from '../policy/policyActions';

export const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case policyActions.SET_POLICY:
      return {
        ...state,
        ...action.payload
      };
    case policyActions.SET_INITIAL_STATE_POLICY:
      return null;
    default:
      return state;
  }
};

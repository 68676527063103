import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { moneyFormat } from '../../../../../shared/components/format/MoneyFormat';
import Loading from '../../../../../shared/components/loading/Loading';
import HasErrorModal from '../../../../../shared/components/modal/Modal';
import InternalComponentGoBack from '../../../../../shared/components/modal/modalInfo/InternalComponentGoBack';
import ModalInfo from '../../../../../shared/components/modal/modalInfo/ModalInfo';
import WarningModal from '../../../../../shared/components/modal/warning/WarningModal';
import {
  FORWARD_PROPOSAL,
  GO_BACK,
  // FORWARD_PROPOSAL,
  // GENERATE_ENDORSEMENT,
  // GO_BACK_TO_REQUESTS,
  LIMIT_EXPOSURE_EXCEEDED,
  SAVE_AND_GO_BACK,
  // SAVE_AND_GO_BACK,
  WARNING_ICON
} from '../../../../../shared/constants/constants';
import {
  ACCEPT_PROPOSAL_IMAGE,
  GOBACK_IMAGE,
  SUCCESS_SUBMITTED_IMAGE
} from '../../../../../shared/constants/imagesconstants';
import { ERR_DEFAULT_MESSAGE } from '../../../../../shared/constants/modalMessagesConstants';
import { routes } from '../../../../../shared/constants/routes';
import { TRANSMITTED } from '../../../../../shared/enums/statusEnum';
import proposalManager from '../../../manager/insurance/proposal/proposalManager';
import quizManager from '../../../manager/quizManager';
// import { Status } from '../../../../../shared/enums/statusEnum';
import {
  exposureOperations,
  insuranceOperations, proposalOperations,
  quizActions
} from '../../../redux';
import { useAnalyze } from '../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../redux/insurance/policy/policyCustomHooks';
// import { useErrors } from '../../../redux/errors/errorsCustomHooks';
import { useProposal } from '../../../redux/insurance/proposal/proposalCustomHooks';
import { useQuote } from '../../../redux/insurance/quote/quoteCustomHooks';
import { useHasError, useLoading } from '../../../redux/quizCustomHooks';
// import proposalManager from '../../../services/proposal/proposalManager';
import FormClasses from '../classes/FormClasses';
import Footer from '../Footer';
import PolicyFooter from '../PolicyFooter';
import QuestionFormHeader from '../QuestionFormHeader';
// import Footer from '../Footer';
import QuestionHeader from '../QuestionHeader';
import InfoAcceptModal from './acceptModal/InfoAcceptModal';
import Insured from './insured/Insured';
import ProposalIntermediates from './intermediates/ProposalIntermediates';
import Payment from './payment/Payment';
import SolidaryPolicyHolder from './solidaryPolicyHolder/SolidaryPolicyHolder';

function getFormErrors(proposal, quote) {
  return proposalManager.formValidate(proposal, quote);
}

const Proposal = function ({ quizStep }) {
  const history = useHistory();
  const classes = FormClasses();
  const analyze = useAnalyze();
  const [openModal, setOpenModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const dispatch = useDispatch();
  const proposal = useProposal();
  const [modalSubmitted, setModalSubmitted] = useState(false);
  const isLoading = useLoading();
  const policy = usePolicy();
  const [goBack, setGoBack] = useState(false);
  const quote = useQuote();
  const [exposure, setExposure] = useState(null);
  const hasError = useHasError();
  const [modalProps, setModalProps] = useState(quizManager.getInitialModalProps());

  useEffect(() => {
    if (proposal?.id && quizStep.step.completed) {
      setModalSubmitted(true);
      setOpenModal(true);
    }
  }, [proposal, quizStep, dispatch, hasError]);

  const scrollTo = id => {
    document.getElementById(id).scrollIntoView();
  };

  const submitProposal = async () => {
    if (!getFormErrors(proposal, quote)) {
      dispatch(quizActions.setLoading(true));
      const insuredAmount = proposalManager.getInsuredAmount(quote?.plan.coverages);
      const exposureResult = await dispatch(
        exposureOperations.getExposure(analyze?.policyHolder?.documentNumber, insuredAmount)
      );
      if (!exposureResult?.exposureValid) {
        setExposure(exposureResult);
        setOpenWarningModal(true);
      } else {
        setOpenModal(true);
      }
      dispatch(quizActions.setLoading(false));
    } else {
      if (getFormErrors(proposal, quote)?.insureds?.required) {
        scrollTo('Locador');
      }
    }
  };

  const acceptProposal = async () => {
    setOpenModal(false);
    try {
      dispatch(quizActions.setLoading(true));
      await dispatch(insuranceOperations.put());
      dispatch(proposalOperations.create());
    } catch {
      dispatch(quizActions.setError(true));
      setModalProps(quizManager.getModalProps(ERR_DEFAULT_MESSAGE));
    }
  };

  const setInitialState = useCallback(async () => {
    await dispatch(insuranceOperations.clearInsurance());
  }, [dispatch]);

  const returnToHome = useCallback(async () => {
    await setInitialState();
    dispatch(quizActions.setInitialStep());
    history.push(routes.home.path);
  }, [dispatch, setInitialState, history]);

  const exitModal = () => {
    setOpenModal(false);
    setModalSubmitted(false);
    dispatch(quizActions.setStep({ ...quizStep.step, submitted: false }));
  };

  const saveAndReturnToHome = async () => {
    batch(async () => {
      dispatch(quizActions.setLoading(true));
      await dispatch(insuranceOperations.put(true));
      dispatch(insuranceOperations.clearInsurance());
      history.push(routes.home.path);
    });
  };

  return (
    <>
      <ModalInfo
        id={'acceptedModal'}
        open={openModal}
        exitButtonOnClick={exitModal}
        image={modalSubmitted ? SUCCESS_SUBMITTED_IMAGE : ACCEPT_PROPOSAL_IMAGE}
        blur
        children={
          <InfoAcceptModal
            id={'internal-acceptedModal'}
            continueClick={acceptProposal}
            closeClick={exitModal}
            submittedModal={modalSubmitted}
            returnToHome={returnToHome}
          />
        }
      />
      <ModalInfo
        id={'modalGoBack'}
        open={goBack}
        exitButtonOnClick={() => {
          setGoBack(false);
        }}
        image={GOBACK_IMAGE}
        blur
        showExitButton={true}
        children={
          <InternalComponentGoBack
            id={'internalComponentGoBack'}
            cancelClick={() => {
              setGoBack(false);
            }}
            goBackClick={saveAndReturnToHome}
            openDenied={false}
          />
        }
      />
      <WarningModal
        id={'modalWarning'}
        image={WARNING_ICON}
        message={`${LIMIT_EXPOSURE_EXCEEDED} ${moneyFormat(
          exposure?.approvedLimitExposure,
          false
        )}`}
        actions={[
          {
            text: 'OK',
            isConfirm: true
          }
        ]}
        title="Limite de acumulo ultrapassado"
        open={openWarningModal}
        onClick={() => {
          setOpenWarningModal(false);
        }}
        onClose={() => {
          setOpenWarningModal(false);
        }}
      />
      <HasErrorModal
        id={'proposalHasErrorModal'}
        icon={modalProps.icon}
        message={modalProps.message}
        actions={modalProps.actions}
        open={modalProps.isOpen}
        onClose={() => {
          setModalProps({ isOpen: false });
          dispatch(quizActions.setError(false));
        }}
      />
      {isLoading && <Loading />}
      <Grid item xs={12} className={classes.container}>
        <QuestionHeader title="Proposta" />
        <Box id={0} key={0} xs={12} className={classes.form}>
          <Grid container item={true} justify="center">
            <QuestionFormHeader title={'Locatários Solidários'} />
          </Grid>
          <Box pt={3}>
            <SolidaryPolicyHolder />
          </Box>
        </Box>
        <Box id={'Locador'} key={1} xs={12} className={classes.form}>
          <Grid container item={true} justify="center">
            <QuestionFormHeader title={'Locador'} />
          </Grid>
          <Box pt={3}>
            <Insured />
          </Box>
        </Box>
        <Box id={1} key={2} xs={12} className={classes.form}>
          <Grid container item={true} justify="center">
            <QuestionFormHeader title={'Intermediários'} />
          </Grid>
          <Box pt={3}>
            <ProposalIntermediates />
          </Box>
        </Box>
        <Box id={1} key={3} xs={12} className={classes.form}>
          <Grid container item={true} justify="center">
            <QuestionFormHeader title={'Valores e Pagamento'} />
          </Grid>
          <Box pt={3}>
            <Payment />
          </Box>
        </Box>
        {policy?.id && (
          <PolicyFooter
            goBackClick={() => {
              history.push(routes.requests.path);
            }}
            endpointPolicyDocument={`${window.env.RENTAL_SURETY}/Files/download/${policy.policyDocumentId}`}
          />
        )}
        {!policy?.id && (
          <Footer
            id="footer-proposal"
            titleButton={FORWARD_PROPOSAL}
            titleGoBackButton={analyze.status !== TRANSMITTED ? SAVE_AND_GO_BACK : GO_BACK}
            goBackClick={() => {
              analyze.status !== TRANSMITTED ? setGoBack(true) : history.push(routes.home.path);;
            }}
            onClick={submitProposal}
            quote
          />
        )}
      </Grid>
    </>
  );
};

Proposal.propTypes = {
  quizStep: PropTypes.object.isRequired
};

export default Proposal;

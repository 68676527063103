import { Box, Grid, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import { ANALYZE_STEP_ID, EMPTY_STRING, PROPOSAL_STEP_ID } from '../../../../shared/constants/constants';
import { APPROVED_IMAGE } from '../../../../shared/constants/imagesconstants';
import { POLICY } from '../../../../shared/constants/quizConstants';
import { Status, SubscriptionStatus } from '../../../../shared/enums/statusEnum';
import analyzeManager from '../../manager/insurance/analyze/analyzeManager';
import ImageStepperCardClasses from './classes/ImageStepperCardClasses';

const ImageStepperCard = function ({ imageStepperSettings, changeStep }) {
  const classes = ImageStepperCardClasses();
  const [modalStatus, setModalStatus] = useState(EMPTY_STRING);

  const continueClick = async () => {
    setModalStatus(EMPTY_STRING);
  };

  const modalSettings = {
    exitButtonOnClick: continueClick,
    completed: imageStepperSettings.completed
  }

  const approvedModal = imageStepperSettings.id === ANALYZE_STEP_ID ?
    analyzeManager.getModal(SubscriptionStatus.APPROVED, modalStatus, APPROVED_IMAGE, continueClick, modalSettings) :
    (imageStepperSettings.id === PROPOSAL_STEP_ID ?
      analyzeManager.getModal(Status[8].status, modalStatus, APPROVED_IMAGE, continueClick, modalSettings) : EMPTY_STRING);

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Box px={2} py={2} id="clickable-card"
          className={imageStepperSettings.completed && !imageStepperSettings.activated ? classes.clickableCard : classes.nonClickableCard}
          onClick={e => { changeStep(imageStepperSettings.completed, imageStepperSettings.index) }}>
          <Grid container direction="row">
            <Grid item xs={imageStepperSettings.completed ? 11 : 12}>
              <Box
                textAlign="center"
                fontSize={17}
                fontWeight="fontWeightBold"
                fontFamily="'Poppins', sans-serif"
              >
                {imageStepperSettings.title}
              </Box>
            </Grid>
            {imageStepperSettings.completed && imageStepperSettings.id === 1 && (
              <Grid item xs={1}>
                <IconButton
                  className={classes.IconButton}
                  id="btn-status"
                  onClick={imageStepperSettings.title === POLICY.title ? () => setModalStatus(Status[8].status) : () => setModalStatus(SubscriptionStatus.APPROVED)}
                  color="primary"
                >
                  <MoreVertIcon className={classes.IconButton} />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Box py={1}>
              <img
                className={classes.image}
                alt="Pottencial"
                src={`${process.env.PUBLIC_URL}/assets/images/${imageStepperSettings.image}`}
              />
            </Box>
          </Grid>
          <Grid item xs={12} className={imageStepperSettings.completed ? classes.completed : EMPTY_STRING}>
            <Box textAlign="center" fontSize={14} fontWeight={500} fontFamily="Open Sans">
              <p className={classes.title}>{imageStepperSettings.statusTitle}</p>
              <p className={classes.text}>{imageStepperSettings.statusDescription}</p>
            </Box>
          </Grid>
        </Box>
      </Grid>
      {approvedModal}
    </>
  );
};

export default ImageStepperCard;

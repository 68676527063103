import { EMPTY_GUID } from '../../../../shared/constants/constants';
import personsService from './personsService';

class PersonsServiceManager {
  changePhone = async person => {
    return await personsService.changePhone(person);
  };

  savePhone = async person => {
    return await personsService.savePhone(person);
  };

  changeEmail = async person => {
    return await personsService.changeEmail(person);
  };

  saveEmail = async person => {
    return await personsService.saveEmail(person);
  };

  getPersonByDocument = async document => {
    let person = await personsService.getPersonByDocument(document);
    if (!person.personId || person.personId === EMPTY_GUID) {
      person.emails = [];
      person.phones = [];
      person.personId = await personsService.savePerson(person);
    }
    return person;
  };

  getLinkedOrganizationsByBrokerId = async idBroker => {
    let intermediatePersons = await personsService.getLinkedOrganizationsByBrokerId(idBroker);

    return intermediatePersons;
  };

  getPersonById = async personId => {
    let intermediatePersons = await personsService.getPersonById(personId);

    return intermediatePersons;
  };
}

export default new PersonsServiceManager();

import axios from 'axios';

class CalculateService {
  getCommission = async calculateCommissionRequest => {
    const response = await axios.post(
      `${window.env.RENTAL_SURETY}/Calculate/commission/`,
      calculateCommissionRequest
    );

    return response.data;
  };

  getInsuredAmount = async calculateInsuredAmountRequest => {
    const response = await axios.get(
      `${window.env.RENTAL_SURETY}/Calculate/insuredAmount?expenseValue=${calculateInsuredAmountRequest.expenseValue}&multiple=${calculateInsuredAmountRequest.multiple}&idPlan=${calculateInsuredAmountRequest.idPlan}&IdCoverage=${calculateInsuredAmountRequest.idCoverage}`
    );

    return response.data;
  };

  getMaximumInstallment = async calculateMaximumInstallmentRequest => {
    const response = await axios.get(
      `${window.env.RENTAL_SURETY}/Calculate/maximumInstallment?startDate=${calculateMaximumInstallmentRequest.startDate}&endDate=${calculateMaximumInstallmentRequest.endDate}&totalValue=${calculateMaximumInstallmentRequest.totalValue}`
    );

    return response.data;
  };

  getPremium = async calculatePremiumRequest => {
    const response = await axios.get(
      `${window.env.RENTAL_SURETY}/Calculate/premium?personId=${calculatePremiumRequest.personId}&commission=${calculatePremiumRequest.commission}&multiplePlan=${calculatePremiumRequest.multiplePlan}&leaseTime=${calculatePremiumRequest.leaseTime}&rental=${calculatePremiumRequest.rental}`
    );

    return response.data;
  };

  getApprovedLimit = async getApprovedLimitRequest => {
    const response = await axios.get(
      `${window.env.RENTAL_SURETY}/Calculate/incomeCommitmentLimit?Document=${getApprovedLimitRequest.documentNumber}&IsResident=${getApprovedLimitRequest.isResident}&IsResidential=${getApprovedLimitRequest.isResidential}`
    );

    return response.data;
  };
}

export default new CalculateService();

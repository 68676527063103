import { Box, Button, Grid } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { TEXT_QUOTE } from '../../../../shared/constants/constants';
import { HTML_LOADING } from '../../../../shared/constants/loadingConatants';
import { TRANSMITTED } from '../../../../shared/enums/statusEnum';
import quoteManager from '../../manager/insurance/quote/quoteManager';
import { insuranceOperations, quizActions, quoteActions } from '../../redux';
import { useAnalyze } from '../../redux/insurance/analyze/analyzeCustomHooks';
import { useQuote } from '../../redux/insurance/quote/quoteCustomHooks';
import { useHasError, useSteps } from '../../redux/quizCustomHooks';
import FormClasses from './classes/FormClasses';

const Footer = ({
  titleButton,
  titleGoBackButton,
  onClick,
  goBackClick,
  documentName,
  endpointRequest,
  textButton,
  show
}) => {
  const classes = FormClasses();
  const steps = useSteps();
  const quoteStep = steps.find(s => s.title.toUpperCase() === TEXT_QUOTE);
  const quote = useQuote();
  const analyze = useAnalyze();
  const dispatch = useDispatch();
  const disabled = quoteManager.formValidate(quote, analyze) !== null;
  const hasError = useHasError();

  const openWindow = async () => {
    const w = window.open('', '_blank');
    if (w) {
      w.document.write(HTML_LOADING);
      w.document.close();
      const url = await quoteManager.getQuotePdf(quote);
      w.location = url;
      dispatch(quizActions.setLoading(false));
      dispatch(quoteActions.setQuote({ ...quote, quotePdf: url }));
    }
  };

  const saveAndDownloadQuote = async () => {
    dispatch(quizActions.setLoading(true));
    await dispatch(insuranceOperations.put());
    if (!hasError) {
      await openWindow();
    }
  };

  return (
    <Box id="footerQuestions" key="footerQuestions" xs={12} className={classes.form}>
      <Grid container item justify="center">
        <Grid item xs={12} md={8}>
          <Box textAlign="end" fontSize={14} color="#4E596E" pb={4} pt={4}>
            <Button
              id="btn-back"
              variant="contained"
              className={classes.buttonBack}
              onClick={() => goBackClick()}
            >
              {titleGoBackButton}
            </Button>
            {endpointRequest && documentName && show && (
              <Button
                id="btn-download"
                variant="contained"
                className={`${classes.buttonCardDownload}`}
                onClick={() => window.open(endpointRequest)}
              >
                <GetAppOutlinedIcon className={classes.iconButtonCardDownload} />
                {textButton}
              </Button>
            )}
            {show && quoteStep.activated && (
              <Button
                disabled={disabled}
                id="btn-download"
                variant="contained"
                className={`${classes.buttonCardDownload}`}
                onClick={() => saveAndDownloadQuote()}
              >
                <GetAppOutlinedIcon className={classes.iconButtonCardDownload} />
                {textButton}
              </Button>
            )}
            {analyze.status !== TRANSMITTED && (
              <Button
                id="submitForm"
                variant="contained"
                className={classes.buttonOrange}
                onClick={() => onClick()}
              >
                {titleButton}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Footer.propTypes = {
  titleButton: PropTypes.string.isRequired,
  titleGoBackButton: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  goBackClick: PropTypes.func,
  documentName: PropTypes.string,
  endpointRequest: PropTypes.string,
  textButton: PropTypes.string
};

export default Footer;

import { moneyFormat } from '../../../../../../shared/components/format/MoneyFormat';
import {
  EMPTY_STRING,
  INPUT_EXPENSES_RENTAL,
  RENT_EXPENSE
} from '../../../../../../shared/constants/constants';
import { ERR_EXPENSES } from '../../../../../../shared/constants/formConstants';
import { requiredValidator } from '../../../../../../shared/helper/formValidators';
import utilManager from '../../../../../util/services/utilManager';

class ExpensesManager {
  verifyApprovedLimitSubmit = (expenses, approvedLimit) => {
    const totalExpenses = expenses.reduce((total, expense) => total + expense.value, 0);

    if (totalExpenses > approvedLimit) {
      return `${ERR_EXPENSES}  ${moneyFormat(approvedLimit)}`;
    }

    return EMPTY_STRING;
  };

  validateExpenses = analyze => {
    const errors = {};
    errors.expenses = {};
    errors.expenses[INPUT_EXPENSES_RENTAL] = requiredValidator(
      analyze?.expenses.find(e => e.description === RENT_EXPENSE).value,
      EMPTY_STRING
    );

    if (analyze?.id) {
      const error = this.verifyApprovedLimitSubmit(analyze?.expenses, analyze?.approvedLimit);

      if (error) {
        errors.expenses[INPUT_EXPENSES_RENTAL] = error;
      }
    }

    return utilManager.validErrors(errors.expenses) ? null : errors.expenses;
  };

  getUpdatedExpenses = (analyze, riskObject) => {
    return analyze?.expenses.map(e =>
      riskObject.expenses.some(re => re.description === e.description)
        ? { ...e, value: riskObject.expenses.find(re => re.description === e.description).value }
        : { ...e }
    );
  };
}

export default new ExpensesManager();

import { quizActions } from '..';
import personsServiceManager from '../../services/persons/personsServiceManager';

export const getPersonByDocument = document => async dispatch => {
  try {
    return await personsServiceManager.getPersonByDocument(document);
  } catch {
    dispatch(quizActions.setError(true));
  }
};

export const setEmailPerson = person => async dispatch => {
  try {
    if (person.email?.id) {
      const updated = await personsServiceManager.changeEmail(person);
      return updated ? person.email?.id : null;
    } else {
      const emailId = await personsServiceManager.saveEmail(person);
      return emailId;
    }
  } catch {
    dispatch(quizActions.setError(true));
  }
};

export const setPhonePerson = person => async dispatch => {
  try {
    if (person?.phone?.id) {
      await personsServiceManager.changePhone(person);
      return person?.phone?.id;
    } else {
      const phoneId = await personsServiceManager.savePhone(person);
      return phoneId;
    }
  } catch {
    dispatch(quizActions.setError(true));
  }
};

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import MoneyInput from '../../../../../../shared/components/form/MoneyInput';
import { moneyFormat } from '../../../../../../shared/components/format/MoneyFormat';
import { DISABLE_ALL_FIELDS, EMPTY_STRING } from '../../../../../../shared/constants/constants';
import { ERR_EXPENSES } from '../../../../../../shared/constants/formConstants';
import { analyzeActions, errorsActions } from '../../../../redux';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import { useAnalyze } from '../../../../redux/insurance/analyze/analyzeCustomHooks';

const Expenses = ({ disabled }) => {
  const dispatch = useDispatch();
  const analyze = useAnalyze();
  const errors = useErrors();

  const verifyApprovedLimit = (value, name, blur) => {
    const totalExpenses = blur
      ? analyze?.expenses.reduce((total, expense) => total + expense.value, 0)
      : analyze?.expenses.reduce((total, expense) => total + expense.value, 0) -
        analyze?.expenses[parseInt(name.replace('expenses-', EMPTY_STRING))].value;

    if (analyze?.id && totalExpenses + value > analyze?.approvedLimit) {
      return `${ERR_EXPENSES}  ${moneyFormat(analyze?.approvedLimit)}`;
    }
    return EMPTY_STRING;
  };

  const updateValuesExpenses = async (name, value) => {
    if (value) {
      if (analyze?.approvedLimit > 0 && !verifyApprovedLimit(value, name)) {
        dispatch(
          errorsActions.setErrors({
            ...errors,
            expenses: { ...errors.expenses, [name]: EMPTY_STRING }
          })
        );
      } else {
        dispatch(
          errorsActions.setErrors({
            ...errors,
            expenses: { ...errors.expenses, [name]: verifyApprovedLimit(value, name) }
          })
        );
      }
    }

    const index = parseInt(name.replace('expenses-', EMPTY_STRING));
    const oldValue = analyze?.expenses[index].value;
    const newExpenses = analyze?.expenses.map((expense, idx) =>
      idx === index ? { ...expense, value: value } : { ...expense }
    );

    dispatch(
      analyzeActions.setAnalyze({
        ...analyze,
        expenses: newExpenses,
        veracity: oldValue !== EMPTY_STRING && oldValue !== value ? false : analyze?.veracity
      })
    );
  };

  const updateErrorsOnBlur = async (name, value) => {
    if (analyze?.approvedLimit > 0 && !verifyApprovedLimit(value, name, true)) {
      dispatch(
        errorsActions.setErrors({ ...errors, expenses: { ...errors.expenses, [name]: value } })
      );
    }
  };

  return (
    <>
      <Grid container item justify="center">
        <Grid item xs={12} md={8}>
          <Grid container spacing={4}>
            {analyze?.expenses?.map(
              (expense, index) =>
                expense.title && (
                  <Grid key={`grid-${index}`} item xs={12} sm={6}>
                    <MoneyInput
                      id={`expenses-${index.toString()}`}
                      label={expense.title}
                      required={expense.mandatory}
                      value={
                        analyze?.expenses[index]?.value
                          ? moneyFormat(analyze?.expenses[index].value).replace('R$', EMPTY_STRING)
                          : EMPTY_STRING
                      }
                      onChange={updateValuesExpenses}
                      onBlur={updateErrorsOnBlur}
                      error={
                        errors?.expenses && errors?.expenses?.[`expenses-${index}`]
                          ? errors?.expenses?.[`expenses-${index}`]
                          : EMPTY_STRING
                      }
                      showInputLimits
                      min={10}
                      disabled={disabled === DISABLE_ALL_FIELDS}
                    />
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Expenses.propTypes = {
  disabled: PropTypes.number
};

export default Expenses;

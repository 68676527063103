import { makeStyles } from '@material-ui/core/styles';
import { Orange, White } from '../../../shared/constants/colorConstants';
const openSans = 'Open Sans';

export const Styles = () =>
  makeStyles({
    gridContainer: {
      left: 0,
      position: 'fixed',
      width: '100%',
      height: '100%',
      display: 'grid',
      zIndex: 10
    },
    title: {
      fontFamily: "'Poppins', sans-serif",
      marginLeft: 40,
      fontSize: 30,
      marginTop: 0,
      opacity: '1',
      letterSpacing: -0.84,
      color: '#282F3C'
    },
    header: {
      fontFamily: openSans,
      marginLeft: 40,
      marginTop: 40,
      color: '#8D939F',
      letterSpacing: 1.2,
      fontSize: 10
    },
    Grid: {
      display: 'flex',
      marginLeft: 15,
      marginRight: 60
    },
    formName: {
      marginTop: 30,
      marginLeft: 40,
      marginRight: 60
    },
    gridButton: {
      textAlign: 'end',
      marginRight: '7%'
    },
    buttonCardSelect: {
      background: Orange,
      color: White,
      minWidth: 200,
      marginLeft: 25,
      fontFamily: openSans,
      borderRadius: 30,
      fontSize: 12,
      textTransform: 'none',
      fontWeight: 'normal',
      boxShadow: 'none',
      '&:hover, &:focus': {
        boxShadow: 'none',
        background: Orange,
        color: White
      }
    },
    buttonCardBack: {
      boxShadow: 'none',
      background: 'transparent',
      border: `1px solid ${Orange}`,
      color: Orange,
      fontSize: 12,
      borderRadius: 30,
      minWidth: 200,
      marginLeft: 115,
      '&:hover, &:focus': {
        boxShadow: 'none'
      }
    },
    buttonWithEstipulante: {
      background: White,
      boxShadow: '0px 3px 6px #00000029',
      fontFamily: openSans,
      height: 80,
      width: 260,
      borderRadius: 10,
      fontSize: 12,
      fontWeight: 1000,
      '&:hover, &:focus': {
        background: White,
        boxShadow: '0px 3px 6px #00000029'
      }
    },
    buttonWithEstipulanteActive: {
      boxShadow: 'none',
      color: '#F26522',
      background: White,
      border: '1px solid #F26522'
    }
  });

export default Styles;

import rentalAgreementService from './rentalAgreementService';

class RentalAgreementServiceManager {
  get = async id => {
    return await rentalAgreementService.get(id);
  };

  put = async rentalAgreement => {
    return await rentalAgreementService.put(rentalAgreement);
  };

  create = async () => {
    return await rentalAgreementService.create();
  };

  delete = async id => {
    return await rentalAgreementService.delete(id);
  };

  putOrCreated = async (updateRentalAgreement, rentalAgreement) => {
    if (updateRentalAgreement) {
      await this.put(rentalAgreement);
    } else {
      updateRentalAgreement = await this.create();
    }

    return updateRentalAgreement;
  };
}

export default new RentalAgreementServiceManager();

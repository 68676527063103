import * as analyzeActions from '../analyze/analyzeActions';

export const INITIAL_STATE = {
  commissionedAgents: [],
  policyHolder: { role: 'PolicyHolder' },
  expenses: [
    {
      title: 'Valor do aluguel',
      description: 'VALOR_ALUGUEL',
      value: 0,
      mandatory: true
    },
    {
      title: 'Gasto do condomínio',
      description: 'VALOR_CONDOMINIO',
      value: 0,
      mandatory: false
    },
    {
      title: 'Gasto mensal com IPTU',
      description: 'VALOR_IPTU',
      value: 0,
      mandatory: false
    },
    {
      title: 'Gasto mensal com gás',
      description: 'VALOR_GAS',
      value: 0,
      mandatory: false
    },
    {
      title: 'Gasto mensal com água',
      description: 'VALOR_AGUA',
      value: 0,
      mandatory: false
    },
    {
      title: 'Gasto mensal com luz',
      description: 'VALOR_LUZ',
      value: 0,
      mandatory: false
    }
  ]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case analyzeActions.SET_ANALYZE:
      return {
        ...state,
        ...action.payload
      };
    case analyzeActions.SET_INITIAL_STATE_ANALYZE:
      return {
        expenses: INITIAL_STATE.expenses,
        commissionedAgents: [state.commissionedAgents[0]]
      };
    case analyzeActions.SET_USER_COMMISSIONED_AGENT:
      return {
        ...state,
        commissionedAgents: [
          ...state.commissionedAgents.slice(0, action.index),
          {
            ...state.commissionedAgents[action.index],
            ...action.payload
          },
          ...state.commissionedAgents.slice(action.index + 1)
        ]
      };
    default:
      return state;
  }
};

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { EMPTY_STRING } from '../../constants/constants';
import { CELLPHONE_SIZE, PHONE_SIZE } from '../../constants/formConstants';
import { getFormattedCellPhoneNumber, getFormattedPhoneNumber } from '../../helper/formFormat';
import { requiredValidator, validatePhone } from '../../helper/formValidators';
import InputClasses from './InputClasses';

export const validatePhoneInput = (value, required) => {
  let msg = EMPTY_STRING;
  if (required) msg = requiredValidator(value, EMPTY_STRING);
  if (!msg) msg = validatePhone(value, EMPTY_STRING);
  return msg;
};

export const getFormatPhone = value => {
  if (!value) return;
  if (value.length <= PHONE_SIZE) {
    return getFormattedPhoneNumber(value);
  } else if (value.length > PHONE_SIZE && value.length <= CELLPHONE_SIZE) {
    return getFormattedCellPhoneNumber(value);
  } else {
    return value;
  }
};

const NumberFormatCustom = function (props) {
  const { onChange, value, inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      value={value || EMPTY_STRING}
      allowLeadingZeros={true}
      format={values => getFormatPhone(values)}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      isAllowed={values => values.value.length <= CELLPHONE_SIZE}
      getInputRef={inputRef}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function PhoneInput({
  value,
  onChange,
  onBlur,
  label,
  disabled,
  required,
  id,
  error
}) {
  const classes = InputClasses();
  const hasError = !!error;

  return (
    <FormControl className={classes.formControl}>
      {label && (
        <InputLabel error={hasError} required={required}>
          {label}
        </InputLabel>
      )}
      <Input
        id={id}
        error={hasError}
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur(id, validatePhoneInput(value, required));
          }
        }}
        inputComponent={NumberFormatCustom}
        disabled={disabled}
      />
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </FormControl>
  );
}

PhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.string
};

import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CepInput from '../../../../../../shared/components/form/CepInput';
import InputForm from '../../../../../../shared/components/form/InputForm';
import NumberInput from '../../../../../../shared/components/form/NumberInput';
import RadioButton from '../../../../../../shared/components/form/RadioButton';
import {
  COMMERCIAL,
  DISABLE_ALL_FIELDS,
  DISABLE_SOME_FIELDS,
  EMPTY_STRING,
  RESIDENTIAL
} from '../../../../../../shared/constants/constants';
import riskLocationManager from '../../../../manager/insurance/analyze/riskLocation/riskLocationManager';
import locationManager from '../../../../manager/location/locationManager';
import { analyzeActions, errorsActions } from '../../../../redux';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import { useAnalyze } from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import locationServiceManager from '../../../../services/location/locationServiceManager';
import FormClasses from '../../classes/FormClasses';

const RiskLocation = ({ disabled }) => {
  const classes = FormClasses();
  const analyze = useAnalyze();
  const errors = useErrors();
  const [addressIncomplete, setAddressIncomplete] = useState(false);
  const validAddress = locationManager.getValidLocationSearch(analyze?.riskLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !_.isEqual(errors?.riskLocation, riskLocationManager.validateRiskLocation(analyze)) &&
      validAddress &&
      analyze?.riskLocation?.zipCode
    ) {
      dispatch(
        errorsActions.setErrors({
          ...errors,
          riskLocation: riskLocationManager.validateRiskLocation(analyze)
        })
      );
    }
  }, [analyze, addressIncomplete, errors, validAddress, dispatch]);

  const updateErrorsOnBlur = async (name, value) => {
    dispatch(
      errorsActions.setErrors({
        ...errors,
        riskLocation: { ...errors.riskLocation, [name]: value }
      })
    );
  };

  const updateValuesRiskLocation = async (name, value) => {
    dispatch(
      analyzeActions.setAnalyze({
        ...analyze,
        riskLocation: { ...analyze?.riskLocation, [name]: value },
        veracity:
          analyze?.riskLocation &&
            analyze?.riskLocation[name] !== EMPTY_STRING && analyze?.riskLocation[name] !== value
            ? false
            : analyze?.veracity
      })
    );
  };

  const updateValuesPolicyHolder = async (name, value) => {
    const oldValue = analyze?.policyHolder?.inhabited;

    dispatch(
      analyzeActions.setAnalyze({
        ...analyze,
        policyHolder: { ...analyze?.policyHolder, [name]: value },
        veracity: oldValue !== null && oldValue !== value ? false : analyze?.veracity
      })
    );
  };

  const updateValuesInsurance = async (name, value) => {
    if (value === "Comercial") {
      dispatch(
        analyzeActions.setAnalyze({
          ...analyze,
          [name]: value,
          policyHolder: { ...analyze?.policyHolder, inhabited: false },
        })
      );
    }
    else {
      dispatch(
        analyzeActions.setAnalyze({
          ...analyze,
          [name]: value,
          policyHolder: { ...analyze?.policyHolder, inhabited: null },
        })
      );
    }
  };

  const handleZipCodeBlurInput = async (name, msg, value) => {
    updateErrorsOnBlur(name, msg);
    var address = await locationServiceManager.getLocation(value);
    if (address.city && (!address.street || !address.district)) {
      setAddressIncomplete(true);
    }
    dispatch(analyzeActions.setAnalyze({ ...analyze, riskLocation: address }));
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={8}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <RadioButton
                id="occupation"
                label="Tipo de ocupação?"
                required
                itemsRadio={[
                  { description: RESIDENTIAL, value: RESIDENTIAL },
                  { description: COMMERCIAL, value: COMMERCIAL }
                ]}
                onChange={updateValuesInsurance}
                onBlur={updateErrorsOnBlur}
                error={errors?.riskLocation?.occupation}
                valueRadio={analyze?.occupation}
                disabled={disabled === DISABLE_SOME_FIELDS || disabled === DISABLE_ALL_FIELDS}
                row
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {analyze?.occupation === RESIDENTIAL && (
                <RadioButton
                  id="inhabited"
                  label="O locatário irá morar no imóvel?"
                  required
                  itemsRadio={[
                    { description: 'Sim', value: true },
                    { description: 'Não', value: false }
                  ]}
                  onChange={updateValuesPolicyHolder}
                  onBlur={updateErrorsOnBlur}
                  error={errors?.riskLocation?.inhabited}
                  valueRadio={analyze?.policyHolder?.inhabited}
                  disabled={disabled === DISABLE_SOME_FIELDS || disabled === DISABLE_ALL_FIELDS}
                  bool
                  row
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={6} mt={20}>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6} className={classes.cepInput}>
                <CepInput
                  id="zipCode"
                  label="Qual é o CEP do imóvel?"
                  onChange={updateValuesRiskLocation}
                  value={analyze?.riskLocation?.zipCode ?? EMPTY_STRING}
                  onBlur={handleZipCodeBlurInput}
                  error={errors?.riskLocation?.zipCode}
                  required
                  left
                  disabled={disabled === DISABLE_ALL_FIELDS}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputForm
                id="street"
                value={analyze?.riskLocation?.street ?? EMPTY_STRING}
                label="Endereço"
                onChange={updateValuesRiskLocation}
                onBlur={updateErrorsOnBlur}
                error={errors?.riskLocation?.street}
                numberRestriction
                required
                disabled={!addressIncomplete}
                caractersRestriction={3}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NumberInput
                id="number"
                value={analyze?.riskLocation?.number?.toString() ?? EMPTY_STRING}
                label="Número"
                onChange={updateValuesRiskLocation}
                onBlur={updateErrorsOnBlur}
                error={errors?.riskLocation?.number}
                required
                disabled={disabled === DISABLE_ALL_FIELDS}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputForm
                id="complement"
                value={analyze?.riskLocation?.complement ?? EMPTY_STRING}
                label="Complemento"
                onChange={updateValuesRiskLocation}
                onBlur={updateErrorsOnBlur}
                error={errors?.riskLocation?.complement}
                caractersRestriction={3}
                disabled={disabled === DISABLE_ALL_FIELDS}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputForm
                id="city"
                value={analyze?.riskLocation?.city ?? EMPTY_STRING}
                label="Cidade"
                onChange={updateValuesRiskLocation}
                onBlur={updateErrorsOnBlur}
                error={errors?.riskLocation?.city}
                numberRestriction
                required
                disabled={disabled === DISABLE_ALL_FIELDS}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputForm
                id="district"
                value={analyze?.riskLocation?.district ?? EMPTY_STRING}
                label="Bairro"
                onChange={updateValuesRiskLocation}
                onBlur={updateErrorsOnBlur}
                error={errors?.riskLocation?.district}
                required
                disabled={!addressIncomplete}
                caractersRestriction={2}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputForm
                id="state"
                disabled
                value={analyze?.riskLocation?.state ?? EMPTY_STRING}
                label="Estado"
                required
                onChange={updateValuesRiskLocation}
                onBlur={updateErrorsOnBlur}
                error={errors?.riskLocation?.state}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

RiskLocation.propTypes = {
  disabled: PropTypes.number
};

export default RiskLocation;

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useSteps } from '../../../features/quiz/redux/quizCustomHooks';
import BreadCrumb from '../../../shared/components/breadcrumb/BreadCrumb';
import { useUser } from '../../../shared/components/header/redux/userCustomHooks';
import Loading from '../../../shared/components/loading/Loading';
import ModalInfo from '../../../shared/components/modal/modalInfo/ModalInfo';
import { QUIZ_BREADCRUMB } from '../../../shared/constants/breadCrumbConstants';
import { BROKER, INTERMEDIATE_PERSON_IMAGE } from '../../../shared/constants/personConstants';
import IntermediatePerson from '../../intermediatePerson/views/IntermediatePerson';
import QuizClasses from './classes/QuizClasses';
import ImageStepper from './imageStepper/ImageStepper';
import QuestionBase from './questions/QuestionBase';

const Quiz = function () {
  const user = useUser();
  const steps = useSteps();
  const classes = QuizClasses()();
  const { insuranceId } = useParams();
  const [openModal, setOpenModal] = useState(user.role.toUpperCase() === BROKER && !insuranceId);

  return (
    <Grid container direction="row" item className={classes.gridContainer}>
      {!user.personId && <Loading />}
      {user.personId && (
        <>
          <ModalInfo
            open={openModal}
            image={INTERMEDIATE_PERSON_IMAGE}
            blur
            showExitButton={false}
            children={<IntermediatePerson setOpenModal={setOpenModal} />}
          />
          <BreadCrumb pages={QUIZ_BREADCRUMB} />
          <Grid container direction="column" className={classes.gridTitle} item>
            <p className={classes.description}>Nova Solicitação</p>
            <p className={classes.title}>Pottencial Aluguel</p>
          </Grid>
          <Grid container item justify="center">
            <ImageStepper />
          </Grid>
          <Grid item xs={12} id="formContainer">
            {steps.map((step, index) => (
              <Box key={`step-${index}`}>
                {step.activated && (
                  <Box id={`step-${index}`} key={`stepBox-${index}`} xs={12}>
                    <QuestionBase
                      key={`questionBase-${index}`}
                      quizStep={{ step: step, index: index }}
                    />
                  </Box>
                )}
              </Box>
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Quiz;

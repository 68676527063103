import React from 'react';
import { moneyFormat } from '../../../../../shared/components/format/MoneyFormat';
import ModalInfo from '../../../../../shared/components/modal/modalInfo/ModalInfo';
import { EMPTY_STRING, TITLE_USED_LIMIT } from '../../../../../shared/constants/constants';
import { ERR_DEFAULT_MESSAGE } from '../../../../../shared/constants/modalMessagesConstants';
import {
  POLICY_STATUS,
  StatusModalTextButton,
  StatusModalTitle
} from '../../../../../shared/enums/documentEnums';
import { Status, SubscriptionStatus } from '../../../../../shared/enums/statusEnum';
import { requiredCheckValidator } from '../../../../../shared/helper/formValidators';
import utilManager from '../../../../util/services/utilManager';
import InternalInfo from '../../../views/questions/analyze/cardsAnalyzeResult/InternalInfo';
import calculateManager from '../../calculate/calculateManager';
import quizManager from '../../quizManager';
import expensesManager from './expenses/expensesManager';
import policyHolderManager from './policyHolder/policyHolderManager';
import riskLocationManager from './riskLocation/riskLocationManager';

class AnalyzeManager {
  formValidate = (analyze, proposal) => {
    const errors = {};
    errors.policyHolder = policyHolderManager.validatePolicyHolder(
      analyze?.policyHolder,
      proposal,
      true
    );
    errors.riskLocation = riskLocationManager.validateRiskLocation(analyze);
    errors.expenses = expensesManager.validateExpenses(analyze);

    errors.veracity = requiredCheckValidator(analyze?.veracity, EMPTY_STRING);
    return utilManager.validErrors(errors) ? null : errors;
  };

  getVeracityError = checkConfirm => {
    return requiredCheckValidator(checkConfirm, EMPTY_STRING);
  };

  getStatusDescription = analyze => {
    return `${TITLE_USED_LIMIT} ${moneyFormat(
      calculateManager.getReduce(analyze?.expenses, 'value')
    )} / ${moneyFormat(analyze?.approvedLimit)}`;
  };

  getModalSettings = (status, modalSettings) => {
    switch (status) {
      case SubscriptionStatus.APPROVED:
        return {
          status: status,
          title: StatusModalTitle.APPROVED,
          textButton: StatusModalTextButton.APPROVED,
          completed: modalSettings.completed
        };
      case SubscriptionStatus.DENIED:
        return {
          status: status,
          title: StatusModalTitle.DENIED,
          textButton: StatusModalTextButton.DENIED
        };
      case SubscriptionStatus.UNDERANALYSIS:
        return {
          status: status,
          title: StatusModalTitle.UNDERANALYSIS,
          textButton: StatusModalTextButton.UNDERANALYSIS
        };
      case Status[8].status:
        return {
          status: status,
          title: POLICY_STATUS.ISSUED,
          textButton: POLICY_STATUS.DOWNLOAD_BUTTON
        };
      default:
        break;
    }
  };

  getModal = (status, open, image, continueClick, modalSettings) => {
    return (
      <ModalInfo
        id={status}
        open={open === status}
        exitButtonOnClick={modalSettings.exitButtonOnClick}
        image={image}
        blur
        showExitButton={!SubscriptionStatus.UNDERANALYSIS}
        children={
          <InternalInfo
            id={`internal-${status}`}
            continueClick={continueClick}
            modalSettings={this.getModalSettings(status, modalSettings)}
          />
        }
      />
    );
  };

  exit = statusSettings => {
    statusSettings.setModalStatus(EMPTY_STRING);

    if (statusSettings.goBack) {
      statusSettings.setGoBack(false);
      if (statusSettings.openDenied) {
        statusSettings.setModalStatus(SubscriptionStatus.DENIED);
      }
    }
  };

  continueDeniedClick = statusSettings => {
    statusSettings.setGoBack(true);
    statusSettings.setOpenDenied(true);
    statusSettings.setModalStatus(EMPTY_STRING);
  };

  goBackCancelClick = statusSettings => {
    if (statusSettings.openDenied) {
      statusSettings.setModalStatus(SubscriptionStatus.DENIED);
      statusSettings.setOpenDenied(false);
    }
    statusSettings.setGoBack(false);
  };

  getInitialErrorModal = (hasError, isOpen, setModalProps) => {
    if (hasError && !isOpen) {
      setModalProps(quizManager.getModalProps(ERR_DEFAULT_MESSAGE, true));
    }
  };

  getInitialStatusModal = (analyze, quoteId, step, statusSettings, modalClosed) => {
    if (quoteId && !step.completed && !modalClosed && step.submitted) {
      switch (analyze?.status) {
        case SubscriptionStatus.APPROVED:
          statusSettings.setModalStatus(SubscriptionStatus.APPROVED);
          return;
        case SubscriptionStatus.DENIED:
          statusSettings.setModalStatus(SubscriptionStatus.DENIED);
          return;
        case SubscriptionStatus.UNDERANALYSIS:
          statusSettings.setModalStatus(SubscriptionStatus.UNDERANALYSIS);
          return;
        default:
          break;
      }
    }
  };
}

export default new AnalyzeManager();

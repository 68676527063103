import {
  useCreateInsuranceRequest,
  useUpdateInsuranceRequest
} from '../../redux/insurance/insuranceCustomHooks';
import insurancesService from './insurancesService';

class InsurancesServiceManager {
  getInsurances = async params => await insurancesService.getInsurances(params);

  get = async insuranceId => {
    const insurance = await insurancesService.get(insuranceId);
    return { ...insurance, veracity: true };
  };

  getStatus = async insuranceId => {
    const insuranceStatus = await insurancesService.getStatus(insuranceId);
    return insuranceStatus;
  };

  getPlan = async rentalInsurance => {
    const insurancePlan = await insurancesService.getPlan(rentalInsurance);
    return insurancePlan;
  };

  create = async (analyze, userId, riskObjectId, coverage) => {
    return await insurancesService.create(
      useCreateInsuranceRequest(analyze, userId, riskObjectId, coverage)
    );
  };

  put = async (analyze, quote, proposal, userId, riskObjectId) => {
    return await insurancesService.put(
      useUpdateInsuranceRequest(analyze, quote, proposal, userId, riskObjectId),
      analyze?.id
    );
  };
}

export default new InsurancesServiceManager();

import * as quoteActions from '../quote/quoteActions';

export const INITIAL_STATE = {
  startInsuranceContract: null,
  endInsuranceContract: null,
  startLeaseContract: null,
  endLeaseContract: null,
  plan: { multiple: 30 }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case quoteActions.SET_QUOTE:
      return {
        ...state,
        ...action.payload
      };
    case quoteActions.SET_INITIAL_STATE_QUOTE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DOWNLOAD_PROPOSAL,
  PROPOSAL_TEMPLATE,
  GO_BACK
} from '../../../../../../shared/constants/constants';
import { useProposal } from '../../../../redux/insurance/proposal/proposalCustomHooks';

const useStyles = makeStyles({
  gridButton: {
    position: 'absolute',
    bottom: 20,
    width: '100%'
  },
  button: {
    fontFamily: 'Open Sans',
    borderRadius: 30,
    fontSize: 12,
    textTransform: 'none',
    fontWeight: 'normal',
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none'
    }
  },
  buttonCardDownload: {
    background: '#FD5D2B',
    color: '#FFFFFF',
    minWidth: 250,
    marginLeft: 25,
    fontWeight: 'bold'
  },
  buttonCardSelect: {
    background: 'transparent',
    color: '#FD5D2B',
    minWidth: 155,
    marginLeft: 25
  },
  buttonCardCancel: {
    background: 'transparent',
    color: '#FD5D2B',
    marginLeft: 115,
    letterSpacing: 1.2,
    fontWeight: 'bold'
  },
  icon: {
    color: '#FFFFFF',
    marginRight: 5
  }
});

const InternalFooterSubmittedModal = ({ returnToHome }) => {
  const classes = useStyles();
  const proposal = useProposal();

  return (
    <>
      <Grid item xs={12} className={classes.gridButton}>
        <Box px={3} py={3} display="flex" flexDirection="row-reverse">
          <>
            <Button
              id="btn-download"
              variant="contained"
              className={`${classes.button} ${classes.buttonCardDownload}`}
              onClick={() =>
                window.open(
                  `${window.env.RENTAL_SURETY}/Documents/download?DocumentName=${proposal?.proposalDocumentName}&TemplateKey=${PROPOSAL_TEMPLATE}`
                )
              }
              disabled={!proposal?.proposalDocumentName}
            >
              <GetAppOutlinedIcon className={classes.icon} />
              {DOWNLOAD_PROPOSAL}
            </Button>
            <Button
              id="btn-continue"
              variant="contained"
              onClick={returnToHome}
              className={`${classes.button} ${classes.buttonCardSelect}`}
            >
              {GO_BACK}
            </Button>
          </>
        </Box>
      </Grid>
    </>
  );
};

InternalFooterSubmittedModal.propTypes = {
  returnToHome: PropTypes.func.isRequired
};

export default InternalFooterSubmittedModal;

import { EMPTY_STRING } from '../../../../shared/constants/constants';

class DocumentsManager {
  getFileUrl = (base64, type) => {
    const byteCharacters = atob(base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: type });

    return URL.createObjectURL(file);
  };

  getDateForName = () => {
    return (
      new Date()
        .toISOString()
        .replace(/[:-]/g, EMPTY_STRING)
        .split('.')[0] + '_'
    );
  };

  toBase64 = (id, file, setBase64, updateFileValues, folder) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    const dateForName = this.getDateForName();
    reader.onload = function() {
      const base64 = reader.result.replace('data:' + file.type + ';base64,', EMPTY_STRING);
      updateFileValues({
        id: id,
        base64File: base64,
        name: dateForName + file.name,
        description: file.name,
        contentType: file.type,
        fileName: file.name,
        folder: folder,
        link: null,
        type: file.type + ';base64'
      });

      if (base64) {
        setBase64(base64);
      }
    };
  };
}
export default new DocumentsManager();

import { Button, Grid, Paper, Typography } from '@material-ui/core';
import MuiModal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { insuranceOperations } from '../../../features/quiz/redux';
import { routes } from '../../../shared/constants/routes';
import ModalClasses from './classes/ModalClasses';

const ActionButton = function ({ text, onClick, btnDisabled }) {
  const classes = ModalClasses();
  return (
    <Button
      disabled={btnDisabled}
      className={classes.actionButton}
      variant="contained"
      color="primary"
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

ActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  isConfirm: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

const Modal = function ({ icon, message, actions, open, onClose }) {
  const classes = ModalClasses();
  const Icon = icon && icon.component;
  const style = icon ? icon.style : {};
  const history = useHistory();
  const dispatch = useDispatch();

  const returnToHome = useCallback(() => {
    dispatch(insuranceOperations.clearInsurance());
    history.push(routes.home.path);
  }, [dispatch, history]);

  return (
    <MuiModal
      id="modalError"
      open={open}
      onClose={onClose}
      disableBackdropClick
      data-testid="modal"
    >
      <Paper className={classes.paper} elevation={8}>
        <Close className={classes.closeButton} onClick={onClose} data-testid="modal-close-btn" />
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid className={classes.iconContainer} container justify="center" alignItems="center">
              <Grid item>{icon && <Icon className={classes.icon} style={style} />}</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Typography className={classes.message} align="center">
                  {message}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              className={classes.actionsContainer}
              container
              justify="flex-end"
              alignItems="center"
              spacing={1}
            >
              {actions &&
                actions.map(action => (
                  <Grid item key={_.kebabCase(action.text)}>
                    <ActionButton
                      {...action}
                      id={'buttonClose'}
                      onClick={action.returnHome ? returnToHome : onClose}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </MuiModal>
  );
};

Modal.propTypes = {
  icon: PropTypes.object,
  message: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default Modal;

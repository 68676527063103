import { makeStyles } from '@material-ui/core';

const Styles = () =>
  makeStyles(theme => ({
    icon: {
      width: 18,
      height: 18,
      marginBottom: 5,
      marginLeft: 5,
      [theme.breakpoints.down('sm')]: {
        marginRight: 30
      }
    },
    titleTab: {
      font: 'normal normal 600 14px/24px Open Sans'
    },
    link: {
      textDecoration: 'underline'
    }
  }));

export default Styles;

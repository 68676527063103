import { EMPTY_STRING } from '../../constants/constants';

export const nameFormat = value => {
  return value
    ? value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, EMPTY_STRING)
        .toUpperCase()
    : EMPTY_STRING;
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const nameFormatCapitalize = value => {
  return value
    .split(' ')
    ?.map(v => capitalizeFirstLetter(v))
    ?.toString()
    .replace(/,/g, ' ');
};

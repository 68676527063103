import { Box, IconButton, Link } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ModalPlan from '../../../../../../../shared/components/modal/ModalPlan';
import PlansClasses from '../classes/PlansClasses';
import PlanCoverages from './PlanCoverages';

const BodyPlansCard = ({
  coveragesSettings,
  rentalPlan,
  choosePlan
}) => {
  const classes = PlansClasses();
  const [openModalPlan, setOpenModalPlan] = useState(false);

  return (
    <>
      <ModalPlan
        open={openModalPlan}
        rentalPlan={rentalPlan}
        coveragesSettings={coveragesSettings}
        choosePlan={choosePlan}
        setOpenModalPlan={setOpenModalPlan}
      />
      <Box px={3} pt={0} pb={0} color="#303E59">
        {!coveragesSettings.showCoverages && (
          <>
            <Box
              pb={0}
              letterSpacing={0.09}
              textAlign="center"
              fontSize={11}
              fontWeight="fontWeightBold"
              fontFamily="Open Sans"
            >
              COMPARAR PLANOS
            </Box>

            <Box
              pb={1}
              textAlign="center"
              fontSize={12}
              fontWeight="fontWeightBold"
              fontFamily="Open Sans"
            >
              <IconButton
                id="showCoverages"
                aria-label="expand coverages"
                component="span"
                onClick={() => {
                  coveragesSettings.setShowCoverages(!coveragesSettings.showCoverages);
                }}
              >
                <ExpandMore />
              </IconButton>
            </Box>
          </>
        )}

        {coveragesSettings.showCoverages && (
          <>
            <Box
              pb={0}
              textAlign="center"
              fontSize={12}
              fontWeight="fontWeightBold"
              fontFamily="Open Sans"
            >
              <IconButton
                id="dontShowCoverages"
                aria-label="expand coverages"
                component="span"
                onClick={() => {
                  coveragesSettings.setShowCoverages(!coveragesSettings.showCoverages);
                }}
              >
                <ExpandLess />
              </IconButton>
            </Box>
            <Box
              pb={0}
              letterSpacing={0.09}
              textAlign="center"
              fontSize={11}
              fontWeight="bold"
              fontFamily="Open Sans"
              color={'#282F3C'}
              opacity={1}
            >
              VER MENOS
            </Box>
            <PlanCoverages
              key={`coverages-plan-${rentalPlan.id}`}
              coveragesSettings={coveragesSettings}
            />
          </>
        )}
      </Box>
      <Box
        pb={coveragesSettings.showCoverages ? 25 : 5}
        letterSpacing={0}
        textAlign="center"
        fontSize={11}
        fontWeight="fontWeightBold"
        fontFamily="Open Sans"
      >
        <Link
          id="btn-details"
          className={classes.link}
          onClick={() => setOpenModalPlan(true)}
          href="#"
        >
          Ver detalhes
        </Link>
      </Box>
    </>
  );
};

BodyPlansCard.propTypes = {
  coveragesSettings: PropTypes.object.isRequired,
  rentalPlan: PropTypes.object.isRequired,
  choosePlan: PropTypes.func.isRequired
};

export default BodyPlansCard;

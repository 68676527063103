import { Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import QuestionHeaderClasses from './classes/QuestionHeaderClasses';

const QuestionHeader = ({ title }) => {
  const classes = QuestionHeaderClasses()();

  return (
    <p className={classes.titlePage}>
      {title}
      <Tooltip className={classes.icon} title={title} aria-label={`Inserir ${title}`}>
        <HelpOutline />
      </Tooltip>
    </p>
  );
};

QuestionHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default QuestionHeader;

import { makeStyles } from '@material-ui/core/styles';

const InputClasses = makeStyles( ({
  formControl: {
    width: '100%',
    fontWeight: 400
  },
  formControlNumber: {
    width: 65,
    fontWeight: 400
  },
  helperText: {
    fontSize: '0.585rem',
    marginTop: 10,
    textAlign: 'center',
    letterSpacing: 0
  },
  select: {
    textAlign: 'initial'
  },
  formLabel: {
    textAlign: 'start'
  },
  formLabelBold: {
    textAlign: 'start',
    font: 'normal normal bold 14px/20px Open Sans',
    letterSpacing: 0,
    color: '#282F3C',
    opacity: 1
  },
  datePickerControl: {
    marginTop: -15
  },
  input: {
    width: '100%'
  },
  radioGroup: {
    marginTop: '-2.5%'
  },
  radio: {
    marginLeft: 10
  },
  formControlGridContainer: {
    width: '96%',
    fontWeight: 400
  }
}));

export default InputClasses;

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { EMPTY_STRING } from '../../constants/constants';
import { requiredValidator } from '../../helper/formValidators';
import InputClasses from './InputClasses';

export const validadeSelectForm = (value, required) => {
  if (required) return requiredValidator(value, EMPTY_STRING);
  return EMPTY_STRING;
};

const NumberFormatCustom = function (props) {
  const { onChange, value, inputRef, ...other } = props;

  return (
    <form onSubmit={e => e.preventDefault()} autoComplete="off">
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        value={value || EMPTY_STRING}
        allowLeadingZeros
        format="#####-###"
        placeholder="00000-000"
        onValueChange={values => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
      />
    </form>
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function CepInput({
  value,
  label,
  onChange,
  onBlur,
  disabled,
  required,
  id,
  error
}) {
  const classes = InputClasses();
  const hasError = !!error;

  return (
    <FormControl className={classes.formControlGridContainer}>
      {label && (
        <InputLabel error={hasError} required={required}>
          {label}
        </InputLabel>
      )}
      <Input
        id={id}
        error={!!error}
        className={classes.input}
        inputComponent={NumberFormatCustom}
        value={value}
        onChange={e => {
          onChange(id, e.target.value);
        }}
        onBlur={e => {
          if (onBlur) {
            onBlur(id, validadeSelectForm(value, required), e.target.value);
          }
        }}
        disabled={disabled}
      />
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </FormControl>
  );
}

CepInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.string
};

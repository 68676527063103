import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import coverageManager from '../../../../manager/insurance/quote/plan/coverageManager';
import { calculateOperations } from '../../../../redux';
import { useAnalyze } from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePlans } from '../../../../redux/plans/plansCustomHooks';
import TraditionalClasses from './classes/TraditionalClasses';
import ListCoverageTraditional from './listCoverageTraditional/ListCoverageTraditional';
import TraditionalCoverageTotal from './listCoverageTraditional/TraditionalCoverageTotal';

const Traditional = () => {
  const analyze = useAnalyze();
  const plans = usePlans();
  const coverages = plans?.plans[0]?.coverages;
  const classes = TraditionalClasses();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(calculateOperations.getCommission());
  }, [dispatch]);

  return (
    <Grid container className={classes.container}>
      <Box py={3} className={classes.title}>
        Selecione as coberturas desejadas e os múltiplos do valor do aluguel:
      </Box>
      {coverages.map(coverage => (
        <ListCoverageTraditional
          key={coverage.id}
          coverage={coverage}
          coveragesByExpenses={coverageManager.getCoveragesByExpenses(
            analyze?.expenses,
            coverages
          )}
        />
      ))}
      <TraditionalCoverageTotal />
    </Grid>
  );
};

export default Traditional;

import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { Status, SubscriptionStatus } from '../../../../../../shared/enums/statusEnum';
import { useAnalyze } from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import InternalClasses from '../../classes/InternalClasses';
import ApprovedLimitInfo from './ApprovedLimitInfo';

const renderSwitch = (status, analyze) => {
  const classes = InternalClasses();
  const startsAt = new Date(analyze?.underwritingResponseDate);
  let endsAt = new Date(analyze?.underwritingResponseDate);
  endsAt.setDate(endsAt.getDate() + 30);

  switch (status) {
    case SubscriptionStatus.APPROVED:
      return (
        <>
          <Box
            px={5}
            pt={8}
            justify="center"
            className={classes.text}
            overflow="auto"
            marginRight={3}
          >
            <b>Locatário:</b>
            <br />
            {analyze?.policyHolder.name}
            <Box pt={1}>
              <Box className={classes.dates}>
                <Box>
                  <b>Data de aprovação:</b>
                  <br />
                  {startsAt.toLocaleDateString()}
                </Box>
                <Box>
                  <b>Validade da aprovação:</b>
                  <br />
                  {endsAt.toLocaleDateString()}
                </Box>
              </Box>
            </Box>
            <ApprovedLimitInfo lineWidth={250} componentWidth={524} componentMargin={0} />
          </Box>
        </>
      );
    case SubscriptionStatus.DENIED:
      return (
        <>
          <Box px={5} pt={10} justify="center" className={classes.text} overflow="auto">
            Agradecemos o envio dos dados para análise, mas, infelizmente,
            <br /> para esse processo, de acordo com os parâmetros internos da seguradora, <br />o
            locatário não atingiu a pontuação mínima necessária para aprovação do seguro.
            <Box pt={2}>Ficamos a disposição para futuras análises.</Box>
          </Box>
        </>
      );
    case SubscriptionStatus.UNDERANALYSIS:
      return (
        <>
          <Box px={5} pt={10} justify="center" className={classes.text} overflow="auto">
            Iremos avaliar sua solicitação, você poderá checar nosso retorno em até 1 dia útil na
            seção "Minhas Solicitações"
          </Box>
        </>
      );
    case Status[8].status:
      return (
        <>
          <Box px={5} pt={10} justify="center" className={classes.text} overflow="auto">
            A apólice de número 1234567881234 foi emitida e está disponível para consulta, assim
            como a proposta, na sessão “meus seguros”.
          </Box>
        </>
      );
    default:
      break;
  }
};

const InternalInfoBody = ({ status }) => {
  const analyze = useAnalyze();

  return (
    <Grid item xs={12}>
      {renderSwitch(status, analyze)}
    </Grid>
  );
};

InternalInfoBody.propTypes = {
  status: PropTypes.string.isRequired
};

export default InternalInfoBody;

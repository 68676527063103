import { makeStyles } from '@material-ui/core/styles';

const Styles = () =>
  makeStyles(theme => ({
    containerForm: {
      marginLeft: '18%',
      minHeight: '90%',
      zIndex: 0,
      width: '100%',
      position: 'absolute',
      textAlign: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0
      }
    },
    text: {
      margin: 0,
      marginLeft: 32,
      width: 'auto',
      marginTop: 20
    },
    gridTitle: {
      font: '25px Poppins',
      fontWeight: 'bold',
      letterSpacing: -0.72,
      color: '#282F3C',
      opacity: 1,
      textAlign: 'center',
      flexDirection: 'column',
      marginTop: 50
    },
    description: {
      font: '11px Open Sans',
      fontWeight: 'bold',
      letterSpacing: 1.2,
      color: '#FE6231',
      textTransform: 'uppercase',
      opacity: 1,
      position: 'relative',
      marginTop: 40,
      marginBottom: 0
    },
    title: {
      marginTop: 0
    },
    gridContainer: {
      width: '100%',
      height: '100%',
      display: 'grid'
    }
  }));

export default Styles;
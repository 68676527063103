import { routes } from './routes';

export const QUIZ_BREADCRUMB = [
  { title: 'Portal Imobiliário', route: routes.home.path },
  { title: 'Pottencial Aluguel', route: routes.home.path },
  { title: 'Nova Solicitação', route: routes.quiz.path }
];

export const WELCOME_BREADCRUMB = [
  { title: 'Portal Imobiliário', route: routes.home.path },
  { title: 'Nova Solicitação', route: routes.quiz.path }
];

export const REQUEST_BREADCRUMB = [
  { title: 'Portal Imobiliário', route: routes.home.path },
  { title: 'Minhas solicitações', route: routes.requests.path }
];

export const SUBSCRIPTION_LIST_BREADCRUMB = [
  { title: 'Portal Subscritor', route: routes.home.path },
  { title: 'Subscrições', route: routes.subscriptionList.path }
];

import axios from 'axios';

class LocationService {
  getLocation = async cep => {
    const response = await axios.create().get(`https://viacep.com.br/ws/${cep}/json`);

    return response.data;
  };
}

export default new LocationService();

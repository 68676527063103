import { Box, Button, Grid, IconButton, Paper } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import React from 'react';
import { CANCEL } from '../../constants/constants';
import ModalAddDataClasses from './classes/ModalAddDataClasses';

const ModalAddData = ({
  open,
  textButton,
  children,
  disabledForm,
  cancelClick,
  saveClick,
  deleteClick,
  index,
  deleteBtn,
  block
}) => {
  const classes = ModalAddDataClasses();

  return (
    <Modal className={classes.modal} open={open} BackdropProps={{ timeout: 500 }}>
      <Fade in={open}>
        <Box className={classes.box}>
          <Paper className={classes.paper}>
            <Box p={1} textAlign="end">
              {block && (
                <IconButton id="btn-block">
                  <LockIcon className={classes.deleteIcon} />
                </IconButton>
              )}
              <IconButton
                id="btn-close"
                className={classes.buttonClose}
                color="primary"
                onClick={() => {
                  cancelClick();
                }}
              >
                <CancelIcon className={classes.icon} />
              </IconButton>
            </Box>
            <Box pb={4} pr={1} pl={3}>
              {children}
            </Box>
            <Grid item xs={12}>
              <Box pr={3} pb={3} display="flex" flexDirection="row-reverse"  >
                <Box px={1}>
                  <Button
                    id="btn-save"
                    className={
                      disabledForm
                        ? `${classes.button} ${classes.buttonDisabled}`
                        : `${classes.button} ${classes.buttonSelect}`
                    }
                    onClick={saveClick}
                    disabled={disabledForm}
                  >
                    {textButton}
                  </Button>
                </Box>
                {deleteBtn && (
                  <Box pr={2}>
                    <Button
                      id="btn-delete"
                      className={`${classes.button} ${classes.buttonDelete}`}
                      onClick={() => {
                        deleteClick(index);
                      }}
                    >
                      <DeleteIcon className={classes.deleteIcon} /> {'Excluir'}
                    </Button>
                  </Box>
                )}
                <Box px={1}>
                  <Button
                    id="btn-cancel"
                    className={`${classes.button} ${classes.buttonReturn}`}
                    onClick={cancelClick}
                  >
                    {CANCEL}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Paper>
        </Box>
      </Fade>
    </Modal>
  );
};

ModalAddData.propTypes = {
  open: PropTypes.bool.isRequired,
  textButton: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  disabledForm: PropTypes.bool,
  cancelClick: PropTypes.func.isRequired,
  saveClick: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  deleteBtn: PropTypes.bool,
  block: PropTypes.bool
};

export default ModalAddData;

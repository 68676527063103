import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import utilManager from '../../../../../../features/util/services/utilManager';
import NumberInput, {
  validadePercentage
} from '../../../../../../shared/components/form/NumberInput';
import SelectForm from '../../../../../../shared/components/form/SelectForm';
import { moneyFormat } from '../../../../../../shared/components/format/MoneyFormat';
import {
  EMPTY_GUID,
  EMPTY_STRING,
  HUNDRED_MULTIPLIER
} from '../../../../../../shared/constants/constants';
import { MULTIPLE_BASIC } from '../../../../../../shared/constants/coverageConstants';
import { BROKER } from '../../../../../../shared/constants/personConstants';
import { MULTIPLES } from '../../../../../../shared/constants/planConstants';
import { TRANSMITTED } from '../../../../../../shared/enums/statusEnum';
import calculateManager from '../../../../manager/calculate/calculateManager';
import { calculateOperations, errorsActions, plansActions, quoteActions } from '../../../../redux';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import {
  useAnalyze,
  useAnalyzeBroker
} from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../redux/insurance/policy/policyCustomHooks';
import { useQuote } from '../../../../redux/insurance/quote/quoteCustomHooks';
import {
  setMultipleBasic,
  setMultipleBasicSelectedPlan
} from '../../../../redux/plans/plansActions';
import { usePlans } from '../../../../redux/plans/plansCustomHooks';
import HeaderPlansClasses from './classes/HeaderPlansClasses';

const HeaderPlans = function ({ coveragesSettings, getQuotePremium }) {
  const classes = HeaderPlansClasses();
  const dispatch = useDispatch();
  const quote = useQuote();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const errors = useErrors();
  const broker = useAnalyzeBroker();
  const plans = usePlans();
  const multiple =
    quote?.plan?.id !== EMPTY_GUID && quote?.plan?.coverages?.length > 0
      ? quote?.plan?.multiple
      : coveragesSettings?.multipleBasic;
  const commissionValue =
    quote?.plan?.id !== EMPTY_GUID
      ? moneyFormat(plans?.plans?.find(rentalPlan => rentalPlan.id === quote?.plan?.id)?.commission)
      : EMPTY_STRING;
  const min = calculateManager.getMultiply(broker?.commission?.minValue ?? 0, HUNDRED_MULTIPLIER);
  const max = calculateManager.getMultiply(broker?.commission?.maxValue ?? 0, HUNDRED_MULTIPLIER);
  const disabled = (analyze?.status === TRANSMITTED || policy?.id);

  const updateValuesPlans = async (_name, value) => {
    coveragesSettings.setMultipleBasic(parseInt(value));
    dispatch(setMultipleBasic(parseInt(value)));
    dispatch(setMultipleBasicSelectedPlan(parseInt(value)));
    dispatch(
      quoteActions.setQuote({
        ...quote,
        plan: { ...quote?.plan, multiple: parseInt(value), id: null }
      })
    );
    getQuotePremium(null, parseInt(value), null);
  };

  const updatecommissionPercentage = async (_name, value) => {
    const commission = value ? calculateManager.getDivision(value, HUNDRED_MULTIPLIER) : value;
    const newBrokers = analyze?.commissionedAgents?.map(b =>
      b?.role?.toUpperCase() === BROKER && b.personId === broker.personId
        ? { ...b, commissionPercentage: commission }
        : { ...b }
    );

    dispatch(
      quoteActions.setQuote({
        ...quote,
        plan: { ...quote?.plan, commissionPercentage: commission },
        brokers: newBrokers
      })
    );

    if (quote?.plan?.id !== EMPTY_GUID) {
      await utilManager.sleep(1e3);
      dispatch(calculateOperations.getCommission());
    }

    if (!validadePercentage(value, true, min, max)) {
      getQuotePremium(null, !quote?.plan?.multiple ? MULTIPLE_BASIC : null, commission);
    } else {
      const newplans = plans.plans.map(rentalPlan =>
        rentalPlan.id === quote?.plan?.id ? { ...rentalPlan, commission: 0 } : { ...rentalPlan }
      );
      dispatch(plansActions.setPlans({ ...plans, plans: newplans }));
    }
  };

  const updateErrorsOnBlur = async (name, value) => {
    dispatch(
      errorsActions.setErrors({
        ...errors,
        plan: { ...errors.plan, [name]: value }
      })
    );
  };

  return (
    <>
      <Grid container direction="row">
        <Box px={4} py={3} color="#303E59" className={classes.text}>
          Multiplicador de cobertura principal
        </Box>
        <Grid item>
          <SelectForm
            value={multiple + ' x'}
            onChange={updateValuesPlans}
            onBlur={updateErrorsOnBlur}
            label={EMPTY_STRING}
            required
            itemsSelect={MULTIPLES}
            id={'multiple'}
            width={70}
            error={errors?.plan?.multiple}
            disabled={disabled}
          />
        </Grid>
        <Box pl={17} py={3} color="#303E59" className={classes.text}>
          Comissão total
        </Box>
        <Grid item className={classes.percent}>
          <Box pt={2.5}>
            <NumberInput
              id="commissionPercentage"
              value={
                quote?.plan?.commissionPercentage && quote?.plan?.commissionPercentage !== 0
                  ? calculateManager
                    .getMultiply(quote?.plan?.commissionPercentage ?? 0, HUNDRED_MULTIPLIER)
                    .toString()
                  : calculateManager
                    .getMultiply(broker?.commission?.defaultValue ?? 0, HUNDRED_MULTIPLIER)
                    .toString()
              }
              label={EMPTY_STRING}
              disabled
              error={errors?.plan?.commissionPercentage}
              onChange={updatecommissionPercentage}
              onBlur={updateErrorsOnBlur}
              percentage
              required
              min={min}
              max={max}
              border
            />
          </Box>
        </Grid>
        <Box px={1} py={3} color="#303E59" className={classes.textComisison}>
          {commissionValue}
        </Box>
      </Grid>
    </>
  );
};

HeaderPlans.propTypes = {
  coveragesSettings: PropTypes.object.isRequired,
  getQuotePremium: PropTypes.func.isRequired
};

export default HeaderPlans;

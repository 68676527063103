import { FormHelperText, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddData from '../../../../../../shared/components/AddData';
import ModalAddData from '../../../../../../shared/components/modal/ModalAddData';
import WarningModal from '../../../../../../shared/components/modal/warning/WarningModal';
import ShowData from '../../../../../../shared/components/ShowData';
import { EMPTY_OBJECT, WARNING_ICON } from '../../../../../../shared/constants/constants';
import { CONFIRM_DELETE } from '../../../../../../shared/constants/modalMessagesConstants';
import {
  ADD_HOLDER,
  ADD_MORE_INSURED,
  INSURED,
  INSURED_TITLE
} from '../../../../../../shared/constants/personConstants';
import { TRANSMITTED } from '../../../../../../shared/enums/statusEnum';
import insuredManager from '../../../../../quiz/manager/insurance/proposal/insured/insuredManager';
import { errorsActions, personsOperations, proposalActions } from '../../../../redux';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import { useAnalyze } from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../redux/insurance/policy/policyCustomHooks';
import { useProposal } from '../../../../redux/insurance/proposal/proposalCustomHooks';
import { useLoading } from '../../../../redux/quizCustomHooks';
import InsuredCard from './InsuredCard';

const Insured = () => {
  const proposal = useProposal();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const errors = useErrors();
  const [openModal, setOpenModal] = useState(false);
  const [lastIndex, setLastIndex] = useState(0);
  const [index, setIndex] = useState(0);
  const [insuredShow, setinsuredShow] = useState(false);
  const [disabledForm, setDisabledForm] = useState(true);
  const [person, setPerson] = useState(EMPTY_OBJECT);
  const isLoading = useLoading();
  const [deleteInsured, setDeleteInsured] = useState(false);
  const [indexDelete, setIndexDelete] = useState(0);
  const disabled = (analyze?.status === TRANSMITTED || policy?.id)
  const dispatch = useDispatch();

  const deleteClick = value => {
    const newProposal = {
      ...proposal,
      insureds: [...proposal.insureds.slice(0, value), ...proposal.insureds.slice(value + 1)]
    };
    dispatch(proposalActions.setProposal(newProposal));
    setPerson(EMPTY_OBJECT);
    insuredManager.delete(setLastIndex, lastIndex, setOpenModal);
  };

  const addClick = () => {
    dispatch(
      errorsActions.setErrors({
        ...errors,
        insureds: { required: errors?.insureds?.required }
      })
    );
    insuredManager.add(setIndex, setLastIndex, setOpenModal, setinsuredShow, lastIndex);
  };

  const showClick = position => {
    insuredManager.show(setinsuredShow, setIndex, setOpenModal, position);
    setPerson(proposal?.insureds[position]);
  };

  const cancelClick = () => {
    insuredManager.cancel(setOpenModal, setDisabledForm);

    dispatch(
      errorsActions.setErrors({
        ...errors,
        insureds: { required: errors?.insureds?.required }
      })
    );

    if (!disabledForm) {
      deleteClick(index);
    }
  };

  const saveClick = async () => {
    dispatch(errorsActions.setErrors({ ...errors, insureds: {} }));

    const isValid = await dispatch(
      insuredManager.save(
        person,
        proposal,
        analyze?.policyHolder,
        index,
        setOpenModal,
        setDisabledForm,
        setIndex
      )
    );
    if (isValid) {
      const emailId = await dispatch(personsOperations.setEmailPerson(person));
      const phoneId = await dispatch(personsOperations.setPhonePerson(person));
      const newPerson = {
        ...person,
        email: { ...person.email, id: emailId },
        phone: { ...person.phone, id: phoneId }
      };
      const insureds = insuredManager.getInsuresUpdated(proposal, newPerson, index);
      const newProposal = {
        ...proposal,
        insureds: insureds,
        beneficiaries: insuredManager.getBeneficiaries(insureds)
      };
      setPerson(EMPTY_OBJECT);
      dispatch(proposalActions.setProposal(newProposal));
    }
  };

  return (
    <>
      <WarningModal
        id={'modalWarning'}
        image={WARNING_ICON}
        message={CONFIRM_DELETE}
        actions={[
          {
            text: 'Excluir',
            isConfirm: true
          }
        ]}
        title="Excluir locador?"
        open={deleteInsured}
        onClick={() => {
          deleteClick(indexDelete);
          setDeleteInsured(false);
        }}
        onClose={() => {
          setDeleteInsured(false);
        }}
        cancel
      />
      {!deleteInsured && (
        <ModalAddData
          id={`ModalAddInsured-${index}`}
          open={openModal}
          textButton={ADD_HOLDER}
          children={
            <InsuredCard
              index={index}
              insuredShow={insuredShow}
              disabledForm={disabledForm}
              person={person}
              setPerson={setPerson}
              setDisabledForm={setDisabledForm}
            />
          }
          disabledForm={disabledForm || isLoading}
          cancelClick={cancelClick}
          saveClick={saveClick}
          deleteClick={() => {
            setIndexDelete(index);
            setDeleteInsured(true);
          }}
          index={index}
          type={INSURED}
          deleteBtn={insuredShow && !disabled}
          block={disabled}
        />
      )}
      <Grid container item={true} justify="center" id="insured-section">
        <Grid item xs={12} md={8}>
          <Grid item xs={12}>
            {proposal?.insureds &&
              proposal?.insureds.length > 0 &&
              proposal?.insureds[0]?.name?.length > 0 &&
              proposal?.insureds?.map((insured, idx) => (
                <ShowData
                  id={`insured-${insured.documentNumber}`}
                  key={`insured-${insured.documentNumber}`}
                  data={insured}
                  index={idx}
                  textTitle={INSURED_TITLE}
                  deleteClick={() => {
                    setIndexDelete(idx);
                    setDeleteInsured(true);
                  }}
                  openClick={showClick}
                  type={INSURED}
                  block={disabled}
                  deleteBtn={!disabled}
                />
              ))}
            {!disabled && (
              <AddData
                id={`AddDataInsured-${index}`}
                text={ADD_MORE_INSURED}
                addClick={addClick}
                error={errors?.insureds?.required ? true : false}
              />
            )}
            {errors?.insureds?.required && (
              <FormHelperText error>{errors.insureds.required}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Insured;

import { batch } from 'react-redux';
import { analyzeActions, quizActions, quoteActions } from '..';
import rentalAgreementServiceManager from '../../services/rentalAgreement/rentalAgreementServiceManager';
import riskObjectsServiceManager from '../../services/riskObjects/riskObjectsServiceManager';

export const create = () => async (dispatch, getState) => {
  const {
    insurance: { analyze, quote }
  } = getState();
  try {
    const expenses = analyze?.expenses.filter(e => e.value > 0);

    const newRiskObject = {
      tenantDocumentNumber: analyze?.policyHolder.documentNumber,
      riskUF: analyze?.riskLocation.state,
      riskObject: { rentalAgreement: await rentalAgreementServiceManager.create() },
      riskLocation: analyze?.riskLocation,
      expenses: expenses,
      occupation: analyze?.occupation,
      inhabited: analyze?.policyHolder.inhabited
    };

    const riskObjectResponse = await riskObjectsServiceManager.create(newRiskObject);

    batch(async () => {
      dispatch(
        analyzeActions.setAnalyze({
          ...analyze,
          riskObjectId: riskObjectResponse.id
        })
      );
      dispatch(
        quoteActions.setQuote({
          ...quote,
          rentalAgreementId: newRiskObject.riskObject.rentalAgreement
        })
      );
    });
    return riskObjectResponse.id;
  } catch {
    dispatch(quizActions.setError(true));
  }
};

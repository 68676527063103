import { EMPTY_STRING } from '../../../../../../shared/constants/constants';
import {
  EMPTY_SOLIDARY,
  ID_DOCUMENT_INPUT,
  ID_INCOME_INPUT,
  ID_INHABITED_INPUT,
  POLICYHOLDER_ID,
  SOLIDARY_ID
} from '../../../../../../shared/constants/personConstants';
import {
  emailValidator,
  requiredValidator,
  validateDocument,
  validateMinCaracteres
} from '../../../../../../shared/helper/formValidators';
import utilManager from '../../../../../util/services/utilManager';
import { errorsActions } from '../../../../redux';
import personsManager from '../../../persons/personsManager';

const validatesDocument = document => {
  let msg = EMPTY_STRING;
  msg = requiredValidator(document, msg);
  if (!msg) {
    msg = validateDocument(document, msg);
  }
  return msg;
};

class PolicyHolderManager {
  validatePolicyHolder = (policyHolder, proposal) => {
    const errors = {};
    errors.name = validateMinCaracteres(policyHolder?.name, errors.name, 5);
    errors.name = requiredValidator(policyHolder?.name, errors.name);
    errors.email = emailValidator(policyHolder?.email?.emailAddress, errors.email);
    errors.email = requiredValidator(policyHolder?.email?.emailAddress, errors.email);
    errors.documentNumber = validatesDocument(policyHolder?.documentNumber, errors.documentNumber);
    if (!errors.documentNumber) {
      errors.documentNumber = personsManager.verifySameDocument(
        policyHolder?.documentNumber,
        proposal,
        policyHolder,
        true
      );
    }

    return utilManager.validErrors(errors) ? null : errors;
  };

  validateSolidary = (solidary, proposal, index, policyholder) => {
    const errors = { solidaries: { [`${SOLIDARY_ID}${index}`]: {} } };

    if (!solidary) {
      solidary = EMPTY_SOLIDARY;
    }

    errors.solidaries[`${SOLIDARY_ID}${index}`][`${ID_DOCUMENT_INPUT}${index}`] = validatesDocument(
      solidary.documentNumber
    );
    errors.solidaries[`${SOLIDARY_ID}${index}`][
      `${ID_DOCUMENT_INPUT}${index}`
    ] = personsManager.verifySameDocument(solidary.documentNumber, proposal, policyholder);
    errors.solidaries[`${SOLIDARY_ID}${index}`][
      `${ID_INHABITED_INPUT}${index}`
    ] = requiredValidator(solidary.inhabited, errors.inhabited);

    errors.solidaries[`${SOLIDARY_ID}${index}`][`${ID_INCOME_INPUT}${index}`] = EMPTY_STRING;

    return utilManager.validErrors(errors.solidaries[`${SOLIDARY_ID}${index}`]) ? null : errors;
  };

  changeDocumentValidate = (document, index, insurance, solidary, errors) => async dispatch => {
    let error = EMPTY_STRING;
    if (document && !validateDocument(document, EMPTY_STRING)) {
      error = personsManager.verifySameDocument(document, insurance, index);
      dispatch(
        errorsActions.setErrors({
          ...errors,
          solidaries: {
            ...errors.solidaries,
            [solidary]: {
              ...errors?.solidaries?.[solidary],
              [`${ID_DOCUMENT_INPUT}${index}`]: error
            }
          }
        })
      );
    }

    return error;
  };

  delete = (lastIndex, setLastIndex, setOpenModal) => {
    setLastIndex(lastIndex - 1 === 0 ? 1 : lastIndex - 1);
    setOpenModal(false);
  };

  add = (lastIndex, setIndex, setLastIndex, setOpenModal, setSolidaryShow) => {
    setIndex(lastIndex);
    setLastIndex(lastIndex + 1);
    setOpenModal(true);
    setSolidaryShow(false);
  };

  show = (index, setSolidaryShow, setIndex, setOpenModal) => {
    setSolidaryShow(true);
    setIndex(index);
    setOpenModal(true);
  };

  cancel = (setOpenModal, setDisabledForm) => async dispatch => {
    setOpenModal(false);
    setDisabledForm(true);
    dispatch(errorsActions.setErrors([]));
  };

  save = (
    solidary,
    proposal,
    policyHolder,
    index,
    setOpenModal,
    setDisabledForm,
    setIndex
  ) => async dispatch => {
    if (!this.validateSolidary(solidary, proposal, index, policyHolder)) {
      setOpenModal(false);
      setDisabledForm(true);
      setIndex(index + 1);
    } else {
      dispatch(
        errorsActions.setErrors(this.validateSolidary(solidary, proposal, index, policyHolder))
      );
    }
  };

  getPolicyHoldersUpdated = (proposal, person, index) => {
    if (proposal?.policyHolders && proposal?.policyHolders.length > 0) {
      if (proposal?.policyHolders[index]) {
        return proposal?.policyHolders.map((p, idx) =>
          idx === index ? { ...p, person, role: POLICYHOLDER_ID } : { ...p }
        );
      } else {
        return proposal?.policyHolders.concat([{ ...person, role: POLICYHOLDER_ID }]);
      }
    } else {
      return [{ ...person, role: POLICYHOLDER_ID }];
    }
  };
}

export default new PolicyHolderManager();

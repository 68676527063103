import { createMuiTheme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/styles/makeStyles';

export const theme = createMuiTheme({
    overrides: {
        MuiInput: {
            input: {
                "&::placeholder": {
                    font: '14px Open Sans',
                    letterSpacing: 0,
                    color: '#313B4E80',
                    opacity: 1
                }
            },
            underline: {
                borderBottom: '1px solid transparent',
                '&:before': {
                    borderBottom: '1px solid transparent'
                },
                '&:after': {
                    borderBottom: '1px solid transparent'
                },
                '&$error:after': {
                    color: 'transparent',
                    borderBottomColor: 'transparent'
                },
                '&:hover:not($disabled):before': {
                    borderBottom: '1px solid transparent'
                }
            }
        }
    },
});

export const Styles = () =>
    makeStyles({
        formControl: {
            width: 320,
            fontWeight: 400
        },
        input: {
            backgroundColor: '#FFFFFF',
            border: '1px solid #BEBEBE',
            borderRadius: 20,
            font: 'normal normal 600 14px/21px Poppins',
            letterSpacing: 0.13,
            color: '#393F4A',
            textAlign: 'center',
            paddingLeft: 10
        },
        icon: {
            color: '#6d788a'
        }
    });
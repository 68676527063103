import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles({
    gridContainer: {
        zIndex: 0,
        position: 'fixed',
        width: '100%',
        height: '100%',
        display: 'grid'
      },
      grid: {
        boxShadow: '0px 3px 6px #00000017',
        alignItems: 'center'
      },
      text: {
        fontFamily: 'Open Sans',
        color: '#4E596E',
        letterSpacing: 0,
        fontSize: 12
      },
      icon: {
        width: 25,
        height: 20
      }
});

export default Styles;

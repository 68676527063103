import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import InsuranceItem from '../../../shared/components/list/InsuranceItem';
import Pagination from '@material-ui/lab/Pagination';

const InsuranceList = ({
  insuranceList = [],
  onChangeCurrentPage,
  numberOfPages,
  onClickInsuranceItem,
  showNumberOfItens = false
}) => {
  return (
    <Box justifyContent="space-between">
      {insuranceList && (
        <Box minHeight={560} overflow={'auto'}>
          {insuranceList?.map((insurance, idx) => (
            <InsuranceItem
              key={idx}
              insuranceView={insurance}
              onClickInsuranceItem={onClickInsuranceItem}
            />
          ))}
        </Box>
      )}
      <Box p={3} display="flex" justifyContent="center" alignItems="center">
        <Pagination id={'pagination'} count={numberOfPages} onChange={onChangeCurrentPage} />
        {showNumberOfItens && (
          <Box position="absolute" right={32}>
            <Typography color="textSecondary" top={20} variant="body2">
              {insuranceList.length} Análises em fila
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

InsuranceList.propTypes = {
  onChangeCurrentPage: PropTypes.func.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  onClickInsuranceItem: PropTypes.func.isRequired
};
export default InsuranceList;

import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  actionButton: {
    padding: '5 20',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem'
    },
    [theme.breakpoints.up('xs')]: {}
  },
  actionDeny: {
    color: '#30353B',
    '&:hover': {
      backgroundColor: '#dddddd'
    }
  },
  paper: {
    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
    [theme.breakpoints.up('sm')]: {
      width: 600
    }
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 10
    },
    [theme.breakpoints.up('sm')]: {
      padding: 30
    }
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#555A5D',
    cursor: 'pointer'
  },
  iconContainer: {
    marginTop: 20
  },
  icon: {
    color: theme.palette.primary.main,
    width: 40,
    height: 'auto'
  },
  message: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 70'
    }
  },
  actionsContainer: {
    marginTop: 20
  }
}));

export default Styles;

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import { EMPTY_STRING } from '../../constants/constants';
import {
  requiredValidator, validateJustLetters, validateMinCaracteres
} from '../../helper/formValidators';
import InputClasses from './InputClasses';

export const validateInput = (value, required, caractersRestriction, numberRestriction) => {
  let msg = EMPTY_STRING;
  if (required) msg = requiredValidator(value, EMPTY_STRING);
  if (caractersRestriction > 0 && !msg) {
    msg = validateMinCaracteres(value, EMPTY_STRING, caractersRestriction);
  }
  if (numberRestriction && !msg) msg = validateJustLetters(value, EMPTY_STRING);
  return msg;
};

export default function InputForm({
  value,
  onChange,
  onBlur,
  label,
  disabled,
  required,
  numberRestriction,
  caractersRestriction,
  id,
  error
}) {
  const classes = InputClasses();
  const hasError = !!error;

  return (
    <FormControl className={classes.formControl}>
      {label && (
        <InputLabel error={hasError} required={required}>
          {label}
        </InputLabel>
      )}
      <Input
        id={id}
        error={hasError}
        value={value}
        onChange={e => {
          onChange(id, e.target.value);
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur(id, validateInput(value, required, caractersRestriction, numberRestriction));
          }
        }}
        disabled={disabled}
      />
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </FormControl>
  );
}

InputForm.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  numberRestriction: PropTypes.bool,
  caractersRestriction: PropTypes.number,
  id: PropTypes.string,
  error: PropTypes.string
};

import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles({
  image: {
    width: '100%'
  },
  text: {
    font: 'normal normal 600 12px/15px Open Sans',
    letterSpacing: 0,
    color: '#575F6E',
    opacity: 1
  },
  title: {
    font: 'normal normal 600 14px/15px Open Sans',
    letterSpacing: 0,
    color: '#575F6E',
    opacity: 1
  },
  completed: {
    marginTop: -8
  },
  IconButton: {
    color: '#575F6E',
    paddingTop: 0
  },
  clickableCard: {
    color: "#303E59",
    '&:hover, &:focus': {
      cursor: 'pointer',
      PointerEvent: 'auto',
      opacity: 0.8
    }
  },
  nonClickableCard: {
    color: "#303E59"
  }
});

export default Styles;

import React from 'react';
import { nameFormat } from '../../../../../../shared/components/format/NameFormat';
import { EMPTY_STRING } from '../../../../../../shared/constants/constants';
import {
  COMMERCIAL_VALUE,
  EXPENSES_DESCRIPTION_VALUE,
  MONTHLY,
  RENTAL_DESCRIPTION,
  TRADITIONAL
} from '../../../../../../shared/constants/planConstants';
import calculateServiceManager from '../../../../services/calculate/calculateServiceManager';
import ListCoverage from '../../../../views/questions/quote/plans/listCoverage/ListCoverage';
import calculateManager from '../../../calculate/calculateManager';

class CoverageManager {
  getCoveragesByPlan(coverages, required) {
    return coverages?.filter(coverage => coverage.required === required);
  }

  getInitialCoverage = (coverages, insurance) => {
    return insurance.quoteId && insurance?.plan?.coverages?.length === 2
      ? insurance?.plan?.coverages[1]
      : this.getCoveragesByPlan(coverages, false)?.length > 0
      ? this.getCoveragesByPlan(coverages, false)[0]
      : {};
  };

  getRowsRequiredCoverage = coverages => {
    const requiredCoverages = this.getCoveragesByPlan(coverages, true);
    let rowsRequiredCoverage = [];

    if (requiredCoverages?.length > 0) {
      requiredCoverages.forEach(coverage => {
        rowsRequiredCoverage.push(<ListCoverage key={coverage.name} coverage={coverage} />);
      });
    }

    return rowsRequiredCoverage;
  };

  getTotalCoverages = (plan, rentalPlan) => {
    if (plan || rentalPlan) {
      let coverages = rentalPlan.coverages;

      if (plan?.coverages) {
        coverages = plan.coverages;
      }
      return calculateManager.getReduce(coverages, COMMERCIAL_VALUE);
    }
    return 0;
  };

  getTotalLiquidCoverages = coverages => {
    return calculateManager.getReduce(coverages, COMMERCIAL_VALUE);
  };

  getCoveragesByExpenses = (expenses, coverages) => {
    let traditionalCoverages = [];

    if (coverages?.length > 0) {
      const valuesExpenses = expenses?.filter(expense => expense && expense.value > 0) || [];
      valuesExpenses.map(expense => {
        return traditionalCoverages.push(
          coverages?.find(
            coverage =>
              nameFormat(coverage.name).indexOf(
                expense?.description?.replace(EXPENSES_DESCRIPTION_VALUE, EMPTY_STRING)
              ) >= 0
          )
        );
      });

      coverages.map(coverage => {
        if (
          !expenses?.some(
            expense =>
              nameFormat(coverage.name).indexOf(
                expense.description.replace(EXPENSES_DESCRIPTION_VALUE, EMPTY_STRING)
              ) >= 0
          )
        ) {
          traditionalCoverages.push(coverage);
        }
        return traditionalCoverages;
      });
    }

    return traditionalCoverages;
  };

  changeActivatedCoverages = (coverage, insurance, checked) => {
    if (!checked) {
      const index = insurance.plan.coverages.findIndex(
        insuranceCoverage => insuranceCoverage.id === coverage.id
      );
      return {
        ...insurance,
        plan: {
          ...insurance.plan,
          coverages: [
            ...insurance.plan.coverages.slice(0, index),
            ...insurance.plan.coverages.slice(index + 1)
          ]
        }
      };
    } else {
      return {
        ...insurance,
        plan: { ...insurance.plan, coverages: [...insurance.plan.coverages, coverage] }
      };
    }
  };

  changeMultipleCoverages = (coverage, insurance, value) => {
    const newCoverages = insurance.plan.coverages.map(insuranceCoverage =>
      insuranceCoverage.id === coverage.id
        ? { ...insuranceCoverage, multiple: value === 0 ? EMPTY_STRING : value }
        : insuranceCoverage
    );
    return { ...insurance, plan: { ...insurance.plan, coverages: newCoverages } };
  };

  setInitialMonthly = (quote, policyOwner, planSelected) => {
    if (!planSelected || !planSelected.type) {
      return {
        ...quote?.plan,
        type: MONTHLY,
        id: 0,
        coverages: [],
        commission: 0,
        commissionPercentage: policyOwner ? policyOwner.commission.defaultValue : 0
      };
    } else {
      return planSelected;
    }
  };

  getInitialTraditional = (quote, policyOwner, coveragesByExpenses, plansSettings) => {
    if (!plansSettings?.planSelected || !plansSettings?.planSelected.type) {
      return {
        ...quote?.plan,
        type: TRADITIONAL,
        name: TRADITIONAL,
        id: plansSettings.plans[0]?.id,
        coverages: coveragesByExpenses,
        commission: 0,
        commissionPercentage: policyOwner ? policyOwner.commission.defaultValue : 0
      };
    } else {
      return plansSettings.planSelected;
    }
  };

  getInsuredAmountCalculated = monthlyPlans => {
    let insuredAmountCalculated = false;

    if (monthlyPlans?.length > 0) {
      monthlyPlans.forEach(plan => {
        if (
          !plan.coverages.some(
            coverage => !coverage.insuredAmount?.value || coverage.insuredAmount?.value === 0
          )
        ) {
          insuredAmountCalculated = true;
        }
      });
    }

    return insuredAmountCalculated;
  };

  getInsuredAmount = (
    monthlyPlans,
    multipleBasic,
    expenses,
    setinsuredAmountCalculated,
    getInsuredAmount,
    dispatch
  ) => {
    setinsuredAmountCalculated(false);
    var expensesValue = expenses?.find(
      expense =>
        expense.description
          ?.replace(EXPENSES_DESCRIPTION_VALUE, EMPTY_STRING)
          .indexOf(RENTAL_DESCRIPTION) >= 0 && expense?.value > 0
    )?.value;

    if (monthlyPlans?.length > 0 && expensesValue > 0) {
      monthlyPlans.map(async (plan, indexPlan) => {
        if (plan.coverages.length > 0) {
          plan.coverages.map(async (coverage, indexCoverage) => {
            if (coverage.basicCoverage) {
              coverage.multiple = multipleBasic;
              coverage.insuredAmount = { min: { value: 0 } };
            }
            const response = await calculateServiceManager.getInsuredAmount(
              expenses,
              coverage,
              plan
            );
            dispatch(getInsuredAmount(response, indexPlan, indexCoverage));
          });
        } else {
          setinsuredAmountCalculated(true);
        }
      });
    }
  };
}

export default new CoverageManager();

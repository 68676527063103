import React from 'react';
import { withStyles, Tabs } from '@material-ui/core';

const StyledTabs = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  indicator: {
    backgroundColor: theme.palette.primary.light
  }
}))(props => <Tabs {...props} />);

export default StyledTabs;

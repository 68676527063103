let isValidCpf = null;
let formatCpf = null;
let isValidCnpj = null;
let formatCnpj = null;

const { isValid: _isValidCpf, format: _formatCpf } = require('@fnando/cpf');
const { isValid: _isValidCnpj, format: _formatCnpj } = require('@fnando/cnpj');
isValidCpf = _isValidCpf;
formatCpf = _formatCpf;
isValidCnpj = _isValidCnpj;
formatCnpj = _formatCnpj;

export { isValidCpf, formatCpf, isValidCnpj, formatCnpj };

import { Box, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import utilManager from '../../../features/util/services/utilManager';
import AutoCompleteForm from '../../../shared/components/form/AutocompleteForm';
import CpfCnpjInput from '../../../shared/components/form/CpfCnpjInput';
import InputForm from '../../../shared/components/form/InputForm';
import { EMPTY_STRING } from '../../../shared/constants/constants';
import {
  BROKER,
  ID_DOCUMENT_INPUT,
  INTERMEDIATE,
  POLICYOWNER
} from '../../../shared/constants/personConstants';
import { routes } from '../../../shared/constants/routes';
import {
  analyzeActions,
  errorsActions,
  intermediatePersonActions,
  proposalActions,
  quizActions,
  quoteActions
} from '../../quiz/redux';
import { useErrors } from '../../quiz/redux/errors/errorsCustomHooks';
import {
  useAnalyze,
  useAnalyzeBroker,
  useAnalyzeCommissionedAgents,
  useAnalyzePolicyOwner
} from '../../quiz/redux/insurance/analyze/analyzeCustomHooks';
import personsServiceManager from '../../quiz/services/persons/personsServiceManager';
import { useIntermediates } from '../redux/intermediatePersonCustomHooks';
import intermediatePersonManager from '../service/intermediatePersonManager';
import { Styles } from './IntermediatePersonClasses';

const IntermediatePerson = ({ setOpenModal }) => {
  const dispatch = useDispatch();
  const classes = Styles()();
  const errors = useErrors();
  const intermediates = useIntermediates();
  const [withIntermediate, setWithIntermediate] = useState(true);
  const analyze = useAnalyze();
  const leadPolicyOwner = useAnalyzePolicyOwner();
  const commissionedAgents = useAnalyzeCommissionedAgents();
  const broker = useAnalyzeBroker();
  const [policyOwner, setPolicyOwner] = useState(
    withIntermediate && leadPolicyOwner ? leadPolicyOwner : {}
  );
  const [disableButton, setDisableButton] = useState(
    (withIntermediate && leadPolicyOwner) || !withIntermediate ? false : true
  );
  const [policyOwners, setPolicyOwners] = useState([]);
  const [avaiablesPolicyOwners, setAvaiablesPolicyOwners] = useState([]);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (intermediates.length === 0) {
        const policyOwnersByBroker = await personsServiceManager.getLinkedOrganizationsByBrokerId(
          broker?.personId
        );

        dispatch(intermediatePersonActions.setIntermediatePersons(policyOwnersByBroker));
      } else {
        const personFilters = intermediates?.filter(i => i?.role?.toUpperCase() === POLICYOWNER);
        if (!utilManager.compareArrays(policyOwners, personFilters)) {
          setAvaiablesPolicyOwners(personFilters);
          setPolicyOwners(personFilters);
        }
      }
    })();
  }, [intermediates, broker, policyOwners, dispatch]);

  useEffect(() => {
    setDisableButton(
      !!intermediatePersonManager.formValidate(policyOwner?.name, policyOwner?.documentNumber)
    );
  }, [policyOwner]);

  const getIdInput = personName => {
    if (personName.indexOf(ID_DOCUMENT_INPUT) >= 0) {
      personName = personName.replace(INTERMEDIATE, EMPTY_STRING);
    }
    return personName;
  };

  const updateErrorsOnBlur = async (inputName, value) => {
    inputName = getIdInput(inputName);
    dispatch(errorsActions.setErrors({ ...errors, [inputName]: value }));
  };

  const selectClick = () => {
    setOpenModal(false);
    let newCommissionedAgents = commissionedAgents;
    if (withIntermediate) {
      newCommissionedAgents.push({
        ...policyOwner,
        commissionPercentage: 0,
        lead: false
      });
    } else {
      newCommissionedAgents = commissionedAgents.filter(
        commisionedAgent => commisionedAgent.role === BROKER
      );
    }

    dispatch(
      analyzeActions.setAnalyze({
        ...analyze,
        commissionedAgents: newCommissionedAgents
      })
    );
  };

  const getNameClassWithIntermediate = () => {
    return withIntermediate ? classes.buttonWithEstipulanteActive : EMPTY_STRING;
  };

  const onChangeAutoComplete = value => {
    if (value) {
      setPolicyOwner(value);
      setDisableButton(false);
      dispatch(
        errorsActions.setErrors({
          ...errors,
          documentNumber: EMPTY_STRING
        })
      );
    } else {
      setPolicyOwner(null);
      setDisableButton(true);
    }
  };

  return (
    <>
      <h4 className={classes.header}>POTTENCIAL SEGURADORA</h4>
      <h2 className={classes.title}>Adicione um intermediário</h2>
      <Box pt={3} />
      <Grid container className={classes.Grid} spacing={6}>
        <Grid item xs={5}>
          <Button
            id="withPolicyOwner"
            onClick={() => {
              setWithIntermediate(true);
              setDisableButton(true);
            }}
            className={`${classes.buttonWithEstipulante} ${
              withIntermediate !== null ? getNameClassWithIntermediate() : EMPTY_STRING
            }`}
          >
            {'Com estipulante'}
          </Button>
        </Grid>
      </Grid>
      <Box pt={3} />
      <Grid container className={classes.Grid} spacing={6}>
        <Grid item xs={4}>
          <CpfCnpjInput
            value={policyOwner?.documentNumber}
            onChange={value => {
              intermediatePersonManager.changeDocument(
                value,
                setPolicyOwner,
                setPolicyOwners,
                avaiablesPolicyOwners
              );
            }}
            id={`intermediate-${ID_DOCUMENT_INPUT}`}
            onBlur={updateErrorsOnBlur}
            error={errors?.documentNumber}
            disabled={!withIntermediate}
            label="CPF/CNPJ"
            required
          />
        </Grid>
        <Grid item xs={4}>
          <Box pl={6}>
            <InputForm
              id={'policyOwnerInform'}
              value={'Estipulante'}
              label="Tipo"
              disabled={true}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid className={classes.formName}>
        <AutoCompleteForm
          value={policyOwner}
          options={policyOwners}
          disabled={!withIntermediate}
          id={'autocompleteNamePolicyOwner'}
          onChange={onChangeAutoComplete}
          label={'Nome'}
          attribute={'name'}
          width={550}
        />
      </Grid>
      <Box pt={10} />
      <Grid className={classes.gridButton}>
        <Button
          id="select"
          variant="contained"
          className={classes.buttonCardBack}
          onClick={() => {
            dispatch(analyzeActions.setInitialStateAnalyze());
            dispatch(quoteActions.setInitialStateQuote());
            dispatch(proposalActions.setInitialStateProposal());
            dispatch(quizActions.setInitialStep());
            history.push(routes.home.path);
          }}
        >
          VOLTAR AO INÍCIO
        </Button>
        <Button
          id="select"
          variant="contained"
          className={classes.buttonCardSelect}
          disabled={disableButton}
          onClick={selectClick}
        >
          SELECIONAR
        </Button>
      </Grid>
    </>
  );
};

IntermediatePerson.propTypes = {
  setOpenModal: PropTypes.func.isRequired
};

export default IntermediatePerson;

import { dateFormat } from '../../../../shared/components/format/DateFormat';
import { POLICYHOLDER_ID } from '../../../../shared/constants/personConstants';
import { getBeneficiariesPercent } from './proposal/proposalCustomHooks';

export const useUpdateInsuranceRequest = (analyze, quote, proposal, userId, riskObjectId) => {
  let participants = proposal.insureds
    ? [{ ...analyze?.policyHolder, role: POLICYHOLDER_ID }].concat(proposal.insureds)
    : [{ ...analyze?.policyHolder, role: POLICYHOLDER_ID }];

  participants = proposal.policyHolders
    ? participants.concat(proposal.policyHolders)
    : participants;

  return {
    userId: userId,
    riskObjectId: riskObjectId,
    quoteId: quote?.id,
    commissionedAgents: proposal.commissionedAgents
      ? analyze?.commissionedAgents.concat(proposal.commissionedAgents)
      : analyze?.commissionedAgents,
    participants: participants,
    plan: quote?.plan.id ? quote?.plan : null,
    payment: proposal.payment,
    documentLetter: analyze?.documentLetter,
    complianceLetter: proposal.complianceLetter,
    step: analyze?.step,
    status: analyze?.status
  };
};

export const useCreateInsuranceRequest = (analyze, userId, riskObjectId, coverage) => {
  return {
    userId: userId,
    riskObjectId: riskObjectId,
    commissionedAgents: analyze?.commissionedAgents,
    policyHolder: analyze?.policyHolder,
    coverages: [coverage]
  };
};

export const mapperToAnalyze = (rentalInsurance, riskObject, expenses) => {
  return {
    id: rentalInsurance.id,
    riskObjectId: rentalInsurance.riskObjectId,
    expenses: expenses,
    policyHolder: { ...rentalInsurance.policyHolder, inhabited: riskObject.inhabited },
    veracity: true,
    occupation: riskObject.occupation,
    riskLocation: riskObject.riskLocation,
    commissionedAgents: rentalInsurance.quoteCommissionedAgents,
    status: rentalInsurance.status,
    step: rentalInsurance.step,
    underwritingResponseDate: rentalInsurance.underwritingResponseDate,
    documentLetter: rentalInsurance.documentLetter
  };
};

export const mapperToQuote = (rentalInsurance, riskObject, rentalAgreement, plan, installments) => {
  return {
    rentalAgreementId: rentalAgreement.id,
    id: rentalInsurance.quoteId,
    startInsuranceContract: riskObject.startInsuranceContract
      ? dateFormat(riskObject.startInsuranceContract)
      : null,
    endInsuranceContract: riskObject.endInsuranceContract
      ? dateFormat(riskObject.endInsuranceContract)
      : null,
    startLeaseContract: rentalAgreement.startLeaseContract
      ? dateFormat(rentalAgreement.startLeaseContract)
      : null,
    endLeaseContract: rentalAgreement.endLeaseContract
      ? dateFormat(rentalAgreement.endLeaseContract)
      : null,
    plan: plan,
    installments: installments,
    quoteSequential: rentalInsurance.quoteSequential
  };
};

export const mapperToProposal = (rentalInsurance, riskObject, solidaries) => {
  return {
    id: rentalInsurance.proposalId,
    payment: {
      correctionIndex: riskObject.correctionIndex
    },
    commissionedAgents: rentalInsurance.proposalCommissionedAgents,
    insureds: rentalInsurance.insureds,
    beneficiaries: getBeneficiariesPercent(rentalInsurance?.insureds),
    policyHolders: solidaries,
    proposalSequential: rentalInsurance.proposalSequential,
    hasComplianceLetter: rentalInsurance.hasComplianceLetter,
    complianceLetter: rentalInsurance.complianceLetter
  };
};

export const mapperToPolicy = rentalInsurance => {
  return {
    id: rentalInsurance?.policyId,
    issuedAt: rentalInsurance?.issuedAt,
    policyDocumentId: rentalInsurance?.policyDocumentId
  };
};

export const mapperToRiskObject = (analyze, quote, proposal) => {
  return {
    id: analyze?.riskObjectId,
    riskUF: analyze?.riskLocation?.state,
    riskObject: { rentalAgreement: quote?.rentalAgreementId },
    riskLocation: analyze?.riskLocation,
    expenses: analyze?.expenses?.filter(e => e.value > 0),
    tenantDocumentNumber: analyze?.policyHolder?.documentNumber,
    occupation: analyze?.occupation,
    inhabited: analyze?.policyHolder?.inhabited,
    startInsuranceContract: quote?.startInsuranceContract ?? null,
    endInsuranceContract: quote?.endInsuranceContract ?? null,
    correctionIndex: proposal?.payment?.correctionIndex,
    tenants: proposal?.policyHolders?.map(function(p) {
      return {
        personId: p.personId,
        resident: false, //MOCKADO
        composeIncome: false, //MOCKADO
        income: 1000, //MOCKADO
        documentNumber: p.documentNumber
      };
    }),
    planId: quote?.plan?.id
  };
};

import DateFnsUtils from '@date-io/date-fns';
import { FormControl, FormHelperText } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBrLocale from 'date-fns/locale/pt-BR';
import PropTypes from 'prop-types';
import React from 'react';
import { EMPTY_STRING } from '../../constants/constants';
import {
  dateValidator,
  diferenceDates,
  equalDates,
  requiredValidator
} from '../../helper/formValidators';
import InputClasses from './InputClasses';

export const validateDateInput = (value, required, start, initialDate, finalDate) => {
  let msg = EMPTY_STRING;
  if (required) msg = requiredValidator(value, EMPTY_STRING);
  if (!msg) msg = dateValidator(value, EMPTY_STRING);
  if (!msg && initialDate instanceof Date) msg = equalDates(value, initialDate, EMPTY_STRING);
  if (!msg && finalDate instanceof Date) msg = equalDates(value, finalDate, EMPTY_STRING);
  if (!msg && !start && initialDate instanceof Date)
    msg = diferenceDates(value, initialDate, start, EMPTY_STRING);
  if (!msg && start && finalDate instanceof Date)
    msg = diferenceDates(value, finalDate, start, EMPTY_STRING);
  return msg;
};
export default function DateInput({
  label,
  disabled,
  id,
  error,
  required,
  onBlur,
  value,
  onChange,
  start,
  initialDate,
  finalDate
}) {
  const classes = InputClasses();
  const hasError = !!error;

  return (
    <FormControl className={`${classes.formControl} ${classes.datePickerControl}`}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
        <KeyboardDatePicker
          autoOk
          id={id}
          label={required ? label + ' *' : label}
          value={value}
          autoComplete="off"
          invalidDateMessage=""
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          maxDateMessage="Data máxima atingida"
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          onChange={e => {
            onChange(id, e, start);
          }}
          onBlur={() => {
            if (onBlur) {
              onBlur(
                id,
                validateDateInput(value, required, start, initialDate, finalDate),
                value,
                start
              );
            }
          }}
          disabled={disabled}
          error={hasError}
        />
      </MuiPickersUtilsProvider>
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </FormControl>
  );
}

DateInput.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.string,
  start: PropTypes.bool,
  initialDate: PropTypes.instanceOf(Date),
  finalDate: PropTypes.instanceOf(Date)
};

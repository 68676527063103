import { makeStyles } from '@material-ui/core/styles';

const Styles = (color) => makeStyles({
  borderCard: {
    borderLeft: `4px solid ${color}`
  },
  iconStatus: {
    background: `${color}`,
    height: '1.8rem',
    width: '10rem',
    borderRadius: 20,
    display: 'flex'
  },
  paper: {
    position: 'relative',
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0px 2px 4px #00000029'
  },
  imgStatus: {
    width: 40,
    height: 40
  },
  fontTitle: {
    fontFamily: 'Open Sans',
    color: '#393F4A',
    letterSpacing: 0.9,
    fontSize: 10,
    opacity: 1
  },
  description: {
    color: '#393F4A',
    fontFamily: 'Open Sans',
    fontSize: 14
  },
  descriptionCoverage: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#282F3C'
  },
  icon: {
    fontSize: '1.2rem'
  },
  fontDate: {
    color: '#4E596E',
    fontFamily: 'Open Sans',
    letterSpacing: 0,
    marginBottom: 0
  },
  fontDescription: {
    color: '#555151',
    fontWeight: '1000',
    marginTop: 5,
    fontFamily: 'Open Sans',
    letterSpacing: 0,
    fontSize: 14,
    opacity: 1
  },
  textStatus: {
    color: '#ffffff',
    fontFamily: 'Open Sans',
    fontSize: 14,
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  arrowIcon: {
    fontSize: '1.7rem'
  },
  underwritingInAnalyze: {
    borderLeft: '4px solid #A0B3BE'
  },
});

export default Styles;

import { getFormatCpfOrCnpj } from '../../shared/components/form/CpfCnpjInput';
import { nameFormat } from '../../shared/components/format/NameFormat';
import { DOSENT_HAVE, ID_STATUS_INPUT, PRODUCT } from '../../shared/constants/constants';
import { ID_DOCUMENT_INPUT, ID_NAME_INPUT } from '../../shared/constants/personConstants';
import { InsuranceSteps, Status } from '../../shared/enums/statusEnum';

class RequestPageManager {
  dataPage = (pages, insurancesList, setNumberCurrentPage, setCurrentInsurances) => {
    setNumberCurrentPage(pages.numberPage);
    const endPosition = pages.numberPage * 10;
    const positionInitial = endPosition - 10;
    if (pages.numberPage === pages.numberOfPages) {
      setCurrentInsurances(insurancesList.slice(positionInitial));
    } else {
      setCurrentInsurances(insurancesList.slice(positionInitial, endPosition));
    }
  };

  setSearchFilter = (value, insurances, sets) => {
    sets.setSearch(value);
    if (value) {
      const searchQuotesResponse = this.searchQuotes(insurances, value);
      const searchNumberPages = Math.ceil(searchQuotesResponse.length / 10);
      sets.setQuotesFind(searchQuotesResponse);
      sets.setCurrentPage(searchQuotesResponse.slice(0, 10));
      sets.setNumberOfPages(searchNumberPages);
    } else {
      const initialNumberPages = Math.ceil(insurances.length / 10);

      sets.setNumberOfPages(initialNumberPages);
      this.dataPage(
        { numberPage: 1, numberOfPages: initialNumberPages },
        insurances,
        sets.setNumberCurrentPage,
        sets.setCurrentPage
      );
      sets.setInsurancesList(insurances);
    }
  };

  sizeName = (value, isOwner) => {
    if (!isOwner && value.length > 26) {
      return `${value.slice(0, 26)}...`;
    } else if (isOwner && value.length > 24) {
      return `${value.slice(0, 24)}...`;
    }
    return value;
  };

  gettypeInsuranceView = (status, insuranceStep) => {
    const statusSettings = Status.find(statusSettings => statusSettings.status === status);

    return {
      ...statusSettings,
      titleText: InsuranceSteps.find(step => step.step === insuranceStep)?.text,
      image: `${insuranceStep?.toLowerCase()}-icon`
    };
  };

  getConvertDate = date => {
    const dateConverted = new Date(date).toLocaleDateString('pt-br');
    const timeConverted = new Date(date).toLocaleTimeString('pt-br', {
      hour: '2-digit',
      minute: '2-digit'
    });
    return `${dateConverted}, ${timeConverted}`;
  };

  filterByParameter = (insurancesList, desc, parameter) => {
    return [].slice
      .call(insurancesList)
      .sort((a, b) =>
        a[parameter] > b[parameter]
          ? !desc
            ? 1
            : -1
          : b[parameter] > a[parameter]
          ? !desc
            ? -1
            : 1
          : 0
      );
  };

  filterByDate = (insurancesList, desc) => {
    return [].slice
      .call(insurancesList)
      .sort((a, b) =>
        new Date(a.createdAt) < new Date(b.createdAt)
          ? !desc
            ? 1
            : -1
          : new Date(b.createdAt) < new Date(a.createdAt)
          ? !desc
            ? -1
            : 1
          : 0
      );
  };

  filterByHolderParameter = (insurancesList, desc, parameter) => {
    return [].slice
      .call(insurancesList)
      .sort((a, b) =>
        a.policyHolder[parameter] > b.policyHolder[parameter]
          ? !desc
            ? 1
            : -1
          : b.policyHolder[parameter] > a.policyHolder[parameter]
          ? !desc
            ? -1
            : 1
          : 0
      );
  };

  filterByNameOwner = (insurancesList, desc) => {
    return [].slice
      .call(insurancesList)
      .sort((a, b) =>
        (a.policyOwner ? a.policyOwner.name : DOSENT_HAVE) >
        (b.policyOwner ? b.policyOwner.name : DOSENT_HAVE)
          ? !desc
            ? 1
            : -1
          : (b.policyOwner ? b.policyOwner.name : DOSENT_HAVE) >
            (a.policyOwner ? a.policyOwner.name : DOSENT_HAVE)
          ? !desc
            ? -1
            : 1
          : 0
      );
  };

  clickOrdenate = (filter, insurancesList, pages, HeaderFilterData, sets) => {
    const clickAtivated = HeaderFilterData.map(headerFilterData =>
      headerFilterData.id === filter.id
        ? { ...headerFilterData, orderBy: !filter.orderBy }
        : { ...headerFilterData, orderBy: false }
    );
    sets.SetHeaderFilterData(clickAtivated);
    let newQuotes;

    switch (filter.id) {
      case 0: {
        newQuotes = this.filterByParameter(insurancesList, filter.orderBy, ID_STATUS_INPUT);
        break;
      }
      case 1: {
        newQuotes = this.filterByDate(insurancesList, filter.orderBy);
        break;
      }
      case 2: {
        newQuotes = this.filterByHolderParameter(insurancesList, filter.orderBy, ID_NAME_INPUT);
        break;
      }
      case 3: {
        newQuotes = this.filterByHolderParameter(insurancesList, filter.orderBy, ID_DOCUMENT_INPUT);
        break;
      }
      case 4: {
        newQuotes = this.filterByNameOwner(insurancesList, filter.orderBy);
        break;
      }
      case 5: {
        newQuotes = insurancesList;
        break;
      }
      case 6: {
        newQuotes = this.filterByParameter(insurancesList, filter.orderBy, ID_STATUS_INPUT);
        break;
      }
      default: {
        break;
      }
    }

    sets.setInsurancesList(newQuotes);
    this.dataPage(pages, newQuotes, sets.setNumberCurrentPage, sets.setCurrentPage);
  };

  searchQuotes = (quotes, value) => {
    value = value ? value.trim() : value;

    return nameFormat(PRODUCT).indexOf(nameFormat(value)) >= 0
      ? quotes
      : quotes.filter(
          quote =>
            quote?.policyHolder.documentNumber?.indexOf(value) >= 0 ||
            getFormatCpfOrCnpj(quote?.policyHolder.documentNumber).indexOf(value) >= 0 ||
            nameFormat(quote?.policyHolder.name)?.indexOf(nameFormat(value)) >= 0 ||
            (!quote?.policyOwner
              ? nameFormat('Não Possui')?.indexOf(nameFormat(value)) >= 0
              : nameFormat(quote?.policyOwner.name)?.indexOf(nameFormat(value)) >= 0) ||
            this.getConvertDate(quote?.createdAt)?.indexOf(value) >= 0 ||
            nameFormat(this.gettypeInsuranceView(quote?.status).iconText)?.indexOf(
              nameFormat(value)
            ) >= 0 ||
            nameFormat(this.gettypeInsuranceView(quote?.status).titleText)?.indexOf(
              nameFormat(value)
            ) >= 0
        );
  };
}

export default new RequestPageManager();

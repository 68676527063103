import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import CpfCnpjInput from '../../../../../../shared/components/form/CpfCnpjInput';
import EmailInput from '../../../../../../shared/components/form/EmailInput';
import InputForm from '../../../../../../shared/components/form/InputForm';
import PhoneInput from '../../../../../../shared/components/form/PhoneInput';
import { nameFormat } from '../../../../../../shared/components/format/NameFormat';
import LoadingClasses from '../../../../../../shared/components/loading/classes/LoadingClasses';
import Loading from '../../../../../../shared/components/loading/Loading';
import { EMPTY_OBJECT, EMPTY_STRING } from '../../../../../../shared/constants/constants';
import { CPF_SIZE } from '../../../../../../shared/constants/formConstants';
import {
  ID_DOCUMENT_INPUT,
  ID_EMAIL_INPUT,
  ID_NAME_INPUT,
  ID_PHONE_INPUT,
  INSURED_ID
} from '../../../../../../shared/constants/personConstants';
import { TRANSMITTED } from '../../../../../../shared/enums/statusEnum';
import personManager from '../../../../manager/persons/personsManager';
import { errorsActions, personsOperations } from '../../../../redux';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import { useAnalyze } from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../redux/insurance/policy/policyCustomHooks';
import { useProposal } from '../../../../redux/insurance/proposal/proposalCustomHooks';
import { useLoading } from '../../../../redux/quizCustomHooks';
import InsuredCardClasses from './classes/InsuredCardClasses';

const InsuredCard = ({ index, insuredShow, disabledForm, person, setPerson, setDisabledForm }) => {
  const loadingClasses = LoadingClasses();
  const classes = InsuredCardClasses();
  const insured = INSURED_ID + index;
  const proposal = useProposal();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const errors = useErrors();
  const isLoading = useLoading();
  const dispatch = useDispatch();
  const disabled = (analyze?.status === TRANSMITTED || policy?.id || insuredShow);

  const cleanInsured = () => {
    setPerson(EMPTY_OBJECT);
  };

  const verifySameDocument = (name, document, policyHolder) => {
    const error = personManager.verifySameDocument(document, proposal, policyHolder);
    if (error) {
      cleanInsured();

      dispatch(
        errorsActions.setErrors({
          ...errors,
          insureds: {
            ...errors.insureds,
            [insured]: { ...errors?.insureds?.[insured], [name]: error }
          }
        })
      );
    }
    return error;
  };

  const updateErrorsOnBlur = async (name, value) => {
    dispatch(
      errorsActions.setErrors({
        ...errors,
        insureds: {
          ...errors.insureds,
          [insured]: { ...errors?.insureds?.[insured], [name]: value }
        }
      })
    );
  };

  const changeDocument = async value => {
    if (!value && !disabledForm) {
      cleanInsured();
    } else {
      setPerson({ ...person, documentNumber: value });
      if (value.replace(/[^0-9]/g, '').length === CPF_SIZE) {
        if (
          !verifySameDocument(`${ID_DOCUMENT_INPUT}${index}`, value, analyze?.policyHolder) &&
          personManager.validDocument(insuredShow, value, setDisabledForm)
        ) {
          const person = await dispatch(personsOperations.getPersonByDocument(value));
          setPerson({ ...person, role: INSURED_ID });
          dispatch(
            errorsActions.setErrors({
              ...errors,
              insureds: {
                ...errors.insureds,
                [insured]: {}
              }
            })
          );
        }
      }
    }
  };

  return (
    <>
      {isLoading && (
        <Grid className={loadingClasses.gridContainer}>
          <Loading />
        </Grid>
      )}
      <Grid>
        <Grid container>
          <Grid item xs={8}>
            <Box pb={1} pl={5}>
              <p className={classes.header}>LOCADOR</p>
              <h2 className={classes.title}>Adicione um locador</h2>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <Box pl={5} pr={15} pb={4}>
              <CpfCnpjInput
                id={`${ID_DOCUMENT_INPUT}${index}`}
                value={person?.documentNumber || EMPTY_STRING}
                onChange={changeDocument}
                onBlur={updateErrorsOnBlur}
                disabled={disabled}
                error={
                  errors?.insureds?.[insured] &&
                    errors.insureds?.[insured]?.[`${ID_DOCUMENT_INPUT}${index}`]
                    ? errors.insureds?.[insured]?.[`${ID_DOCUMENT_INPUT}${index}`]
                    : EMPTY_STRING
                }
                label="CPF"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box pb={4} pr={5}>
              <InputForm
                id={`${ID_NAME_INPUT}${index}`}
                label="Nome do Locador"
                value={person?.name ? nameFormat(person.name) : EMPTY_STRING}
                error={
                  errors?.insureds?.[insured] &&
                    errors.insureds?.[insured]?.[`${ID_NAME_INPUT}${index}`]
                    ? errors.insureds?.[insured]?.[`${ID_NAME_INPUT}${index}`]
                    : EMPTY_STRING
                }
                required
                numberRestriction={true}
                caractersRestriction={5}
                disabled={true}
              />
            </Box>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <Box pl={5} pr={15} pb={4}>
                <PhoneInput
                  id={`${ID_PHONE_INPUT}${index}`}
                  value={person?.phone?.number ?? EMPTY_STRING}
                  onChange={value =>
                    setPerson({
                      ...person,
                      phone: { ...person.phone, number: value },
                      role: INSURED_ID
                    })
                  }
                  onBlur={updateErrorsOnBlur}
                  error={
                    errors?.insureds?.[insured] &&
                      errors.insureds?.[insured]?.[`${ID_PHONE_INPUT}${index}`]
                      ? errors.insureds?.[insured]?.[`${ID_PHONE_INPUT}${index}`]
                      : EMPTY_STRING
                  }
                  label="Telefone do locador"
                  required
                  disabled={disabled}
                />
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box pb={4} pr={5}>
                <EmailInput
                  id={`${ID_EMAIL_INPUT}${index}`}
                  value={person?.email?.emailAddress ?? EMPTY_STRING}
                  label="Email do Locador"
                  onChange={(_target, value) => {
                    setPerson({
                      ...person,
                      email: { ...person.email, emailAddress: value },
                      role: INSURED_ID
                    });
                  }}
                  onBlur={updateErrorsOnBlur}
                  error={
                    errors?.insureds?.[insured] &&
                      errors.insureds?.[insured]?.[`${ID_EMAIL_INPUT}${index}`]
                      ? errors.insureds?.[insured]?.[`${ID_EMAIL_INPUT}${index}`]
                      : EMPTY_STRING
                  }
                  required
                  disabled={disabled}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

InsuredCard.propTypes = {
  index: PropTypes.number.isRequired,
  insuredShow: PropTypes.bool,
  disabledForm: PropTypes.bool,
  person: PropTypes.object.isRequired,
  setDisabledForm: PropTypes.func.isRequired,
  setPerson: PropTypes.func.isRequired
};

export default InsuredCard;

import { makeStyles } from '@material-ui/core';

const Styles = makeStyles(theme => ({
  box: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    outlineColor: 'transparent',
    overflow: 'auto',
    width: '100%',
    height: '100%'
  },
  paper: {
    marginTop: 10,
    background: '#FFFFFF',
    borderRadius: 15,
    height: 'auto',

    [theme.breakpoints.up('md')]: {
      width: 957
    },
    [theme.breakpoints.down('sm')]: {
      width: 750
    },
    [theme.breakpoints.down('xs')]: {
      width: 400
    }
  },
  buttonClose: {
    borderRadius: 100
  },
  icon: {
    fontSize: '1.8rem'
  },
  header: {
    fontFamily: 'Open Sans',
    color: '#8D939F',
    letterSpacing: 0.9,
    fontSize: 10,
    opacity: 1
  },
  title: {
    fontFamily: "'Poppins', sans-serif",
    opacity: '1',
    letterSpacing: -0.84,
    color: '#282F3C',
    marginTop: 0
  },
  button: {
    fontFamily: 'Open Sans',
    fontSize: 13,
    minWidth: 155,
    textTransform: 'none',
    fontWeight: 'normal',
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none'
    }
  },
  buttonSelect: {
    background: '#FD5D2B',
    color: '#FFF',
    borderRadius: 30,
    '&:hover, &:focus': {
      backgroundColor: '#FD5D2B'
    }
  },
  buttonReturn: {
    color: '#F26522',
    border: 'none',
    backgroundColor: 'transparent',
    opacity: '1',
    '&:hover, &:focus': {
      backgroundColor: '#FFF'
    }
  }
}));

export default Styles;

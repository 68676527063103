import { makeStyles } from '@material-ui/core/styles';

const Styles = () =>
  makeStyles({
    container: {
      opacity: 1,
      textAlign: 'center',
      flexDirection: 'column'
    },
    paper: {
      width: 270,
      height: 250,
      borderRadius: 15,
      boxShadow: '0px 6px 8px 0px rgba(0,0,0,0.18)'
    },
    card: {
      border: 0
    },
    activated: {
      border: '1px solid #f26623'
    },
    completedText: {
      color: '#b5b5b5',
      marginLeft: -28
    }
  });

export default Styles;

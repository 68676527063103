import axios from 'axios';

class InsurancesService {
  getInsurances = async params =>
    (
      await axios.get(`${window.env.RENTAL_SURETY}/Insurances/getInsurances`, {
        params
      })
    ).data;

  get = async insuranceId => {
    const response = await axios.get(`${window.env.RENTAL_SURETY}/Insurances/${insuranceId}`);

    return response.data;
  };

  getPlan = async rentalInsurance => {
    const response = await axios.get(
      `${window.env.RENTAL_SURETY}/Insurances/quote/${rentalInsurance.quoteId}/risk/${rentalInsurance.riskObjectId}/plan`
    );

    return response.data;
  };

  create = async insurance => {
    const response = await axios.post(`${window.env.RENTAL_SURETY}/Insurances`, insurance);

    return response.data;
  };

  put = async (updateInsuranceRequest, insuranceId) => {
    const response = await axios.put(
      `${window.env.RENTAL_SURETY}/Insurances/${insuranceId}`,
      updateInsuranceRequest
    );

    return response.data;
  };
}

export default new InsurancesService();

import { makeStyles } from '@material-ui/core/styles';

const Styles = (image) => makeStyles(theme => ({
    actionButton: {
        background: '#FD5D2B',
        color: '#FFFFFF',
        minWidth: 130
    },
    button: {
        fontFamily: 'Open Sans',
        borderRadius: 30,
        fontSize: 12,
        textTransform: 'none',
        fontWeight: 'normal',
        boxShadow: 'none',
        '&:hover, &:focus': {
            boxShadow: 'none'
        }
    },
    cancelButton: {
        background: 'transparent',
        color: '#FD5D2B',
        letterSpacing: 1.2,
        fontWeight: 'bold',
        border: '1px solid #FD5D2B',
        marginRight: 20,
        '&:hover, &:focus': {
            boxShadow: 'none',
            background: '#efeaea'
        }
    },
    paper: {
        outline: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        borderRadius: 10,
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
        [theme.breakpoints.up('sm')]: {
            width: 600
        }
    },
    iconContainer: {
        marginTop: 20
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 15,
        color: '#FFFFFF'
    },
    title: {
        font: 'normal normal bold 20px Poppins',
        letterspacing: -0.84,
        color: '#282F3C',
        opacity: 1
    },
    message: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.9rem'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 10'
        },
        [theme.breakpoints.up('sm')]: {
            padding: '0 70'
        }
    },
    actionsContainer: {
        marginTop: 20
    },
    image: {
        width: '100%',
        height: '100%',
        backgroundImage: 'url(/assets/images/' + image + ')',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10
    }
}));

export default Styles;

import axios from 'axios';

class ProposalsService {
  create = async proposalRequest => {
    const response = await axios.post(`${window.env.RENTAL_SURETY}/Proposals`, proposalRequest);

    return response.data;
  };
}

export default new ProposalsService();

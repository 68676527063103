import { batch } from 'react-redux';
import { analyzeActions, plansActions, policyActions, proposalActions, quoteActions } from '..';
import { dateFormat } from '../../../../shared/components/format/DateFormat';
import { ANALYZE_STEP_ID, EMPTY_STRING } from '../../../../shared/constants/constants';
import { MONTHLY, TRADITIONAL } from '../../../../shared/constants/planConstants';
import { ANALYZE, QUOTE } from '../../../../shared/enums/statusEnum';
import expensesManager from '../../manager/insurance/analyze/expenses/expensesManager';
import insuranceDataManager from '../../manager/insurance/quote/insuranceData/insuranceDataManager';
import plansManager from '../../manager/insurance/quote/plan/plansManager';
import personsManager from '../../manager/persons/personsManager';
import quizManager from '../../manager/quizManager';
import calculateServiceManager from '../../services/calculate/calculateServiceManager';
import documentsServiceManager from '../../services/documents/documentsServiceManager';
import filesServiceManager from '../../services/files/filesServiceManager';
import insurancesServiceManager from '../../services/insurances/insurancesServiceManager';
import plansServiceManager from '../../services/plans/plansServiceManager';
import rentalAgreementServiceManager from '../../services/rentalAgreement/rentalAgreementServiceManager';
import riskObjectsServiceManager from '../../services/riskObjects/riskObjectsServiceManager';
import * as quizActions from '../quizActions';
import { mapperResponseCreateToAnalyzeReducer } from './analyze/analyzeCustomHooks';
import { getAnalyzeBroker, getAnalyzePolicyOwner } from './analyze/analyzeSelectors';
import {
  mapperToAnalyze,
  mapperToPolicy,
  mapperToProposal,
  mapperToQuote,
  mapperToRiskObject
} from './insuranceCustomHooks';
import { mapperToRentalAgreement } from './quote/quoteCustomHooks';

export const get = insuranceId => async (dispatch, getState) => {
  const {
    insurance: { analyze },
    quiz: { steps }
  } = getState();
  try {
    dispatch(quizActions.setLoading(true));

    const rentalInsurance = await insurancesServiceManager.get(insuranceId);

    if (rentalInsurance !== null) {
      const riskObject = await riskObjectsServiceManager.get(rentalInsurance.riskObjectId);
      const rentalAgreement = await rentalAgreementServiceManager.get(
        riskObject.riskObject.rentalAgreement
      );
      const plan = riskObject.planId
        ? await insurancesServiceManager.getPlan(rentalInsurance)
        : {
            type: getAnalyzePolicyOwner(rentalInsurance.commissionedAgents) ? MONTHLY : TRADITIONAL,
            multiple: 30
          };

      const expenses = expensesManager.getUpdatedExpenses(analyze, riskObject);

      const analyzeMapped = mapperToAnalyze(rentalInsurance, riskObject, expenses);

      const installments = insuranceDataManager.getInstallMents(
        insuranceDataManager.getDays(
          dateFormat(riskObject.startInsuranceContract),
          dateFormat(riskObject.endInsuranceContract)
        )
      );

      const solidaries = await personsManager.getTenantsUpdated(riskObject);

      const quote = mapperToQuote(rentalInsurance, riskObject, rentalAgreement, plan, installments);
      const proposal = mapperToProposal(rentalInsurance, riskObject, solidaries);
      const policy = mapperToPolicy(rentalInsurance);

      const approvedLimit = await calculateServiceManager.getApprovedLimit(analyzeMapped);
      const planType = getAnalyzePolicyOwner(analyzeMapped?.commissionedAgents)
        ? MONTHLY
        : TRADITIONAL;
      const plans = await plansServiceManager.getPlan(planType);
      const analyzeLimit = { ...analyzeMapped, approvedLimit: approvedLimit };
      const newSteps = quizManager.getNewSteps(steps, analyzeLimit, quote);
      const fileInsurance = rentalInsurance.hasComplianceLetter
        ? await filesServiceManager.get(rentalInsurance.complianceLetter)
        : null;

      batch(() => {
        dispatch(quizActions.setSteps(newSteps));
        dispatch(analyzeActions.setAnalyze(analyzeLimit));
        dispatch(quoteActions.setQuote(quote));
        dispatch(policyActions.setPolicy(policy));
        dispatch(proposalActions.setProposal({ ...proposal, complianceLetter: fileInsurance }));
        dispatch(plansActions.setPlans(plans));
      });
    }
  } catch {
    dispatch(quizActions.setError(true));
  } finally {
    dispatch(quizActions.setLoading(false));
  }
};

export const create = (coverage, riskObjectId) => async (dispatch, getState) => {
  const {
    insurance: { analyze, quote },
    user
  } = getState();

  batch(async () => {
    dispatch(quizActions.setLoading(true));
    await dispatch(analyzeActions.setAnalyze({ ...analyze, underwritingStatus: EMPTY_STRING }));
  });

  try {
    const expenses = analyze?.expenses.filter(e => e.value > 0);
    const analyzeCreated = await insurancesServiceManager.create(
      analyze,
      user.personId,
      riskObjectId,
      coverage
    );
    const approvedLimit = await calculateServiceManager.getApprovedLimit(analyze);
    const newAnalyze = mapperResponseCreateToAnalyzeReducer(analyze, analyzeCreated, approvedLimit);

    const documentName = await documentsServiceManager.createDocumentLetter(
      {
        ...newAnalyze,
        approvedLimit: approvedLimit
      },
      analyzeCreated?.quoteSequential,
      expenses
    );

    batch(async () => {
      dispatch(quizActions.setSteps(quizManager.getStepUpdatedActions(ANALYZE_STEP_ID, false)));
      dispatch(
        analyzeActions.setAnalyze({ ...newAnalyze, documentLetter: documentName, step: QUOTE })
      );
      dispatch(
        quoteActions.setQuote({
          ...quote,
          id: analyzeCreated.quoteId,
          plan: {
            type: plansManager.getPlanType(quote, analyze),
            multiple: 30,
            commissionPercentage: getAnalyzeBroker(analyze?.commissionedAgents)?.commission
              ?.defaultValue
          },
          quoteSequential: analyzeCreated.quoteSequential
        })
      );
    });
  } catch (err) {
    dispatch(quizActions.setError(true));
  } finally {
    dispatch(quizActions.setLoading(false));
  }
};

export const put = saveAndGoBack => async (dispatch, getState) => {
  const {
    insurance: { analyze, quote, proposal },
    quiz: { steps },
    user
  } = getState();

  batch(async () => {
    dispatch(quizActions.setLoading(true));
  });

  try {
    await filesServiceManager.fileManagement(proposal);
    if (analyze.step.toLowerCase() !== ANALYZE) {
      await rentalAgreementServiceManager.put(mapperToRentalAgreement(quote));
    }

    var riskObjectUpdated = await riskObjectsServiceManager.put(
      mapperToRiskObject(analyze, quote, proposal)
    );

    if (riskObjectUpdated) {
      if (proposal?.hasComplianceLetter) {
        await filesServiceManager.put(proposal?.complianceLetter);
      }

      await insurancesServiceManager.put(
        analyze,
        quote,
        proposal,
        user.personId,
        analyze?.riskObjectId
      );
      const currentStep = steps.find(step => step.activated === true);
      const approvedLimit = await calculateServiceManager.getApprovedLimit(analyze);
      const expenses = analyze?.expenses.filter(e => e.value > 0);
      const documentName = await documentsServiceManager.createDocumentLetter(
        {
          ...analyze,
          approvedLimit: approvedLimit
        },
        quote?.quoteSequential,
        expenses
      );

      const newSteps = quizManager.getStepUpdatedActions(currentStep.id, saveAndGoBack);

      batch(async () => {
        if (saveAndGoBack) {
          dispatch(analyzeActions.setInitialStateAnalyze());
          dispatch(quoteActions.setInitialStateQuote());
          dispatch(proposalActions.setInitialStateProposal());
          dispatch(policyActions.setInitialStatePolicy());
          dispatch(quizActions.setSteps(newSteps));
        }

        dispatch(
          analyzeActions.setAnalyze({
            ...analyze,
            documentLetter: documentName,
            approvedLimit: approvedLimit
          })
        );
      });
    } else {
      dispatch(quizActions.setError(true));
    }
  } catch (err) {
    dispatch(quizActions.setError(true));
  } finally {
    dispatch(quizActions.setLoading(false));
  }
};

export const clearInsurance = () => dispatch => {
  batch(() => {
    dispatch(analyzeActions.setInitialStateAnalyze());
    dispatch(quoteActions.setInitialStateQuote());
    dispatch(proposalActions.setInitialStateProposal());
    dispatch(policyActions.setInitialStatePolicy());
    dispatch(quizActions.setInitialStep());
    dispatch(quizActions.setLoading(false));
  });
};

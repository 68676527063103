import makeStyles from '@material-ui/styles/makeStyles';

const Styles = makeStyles({
  button: {
    borderRadius: 30,
    minWidth: 200,
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 'normal',
    fontFamily: 'Open Sans',
    border: '1px solid #FD5D2B',
    boxShadow: 'none'
  },
  buttonCardSelected: {
    background: '#FD5D2B',
    color: '#FFF',
    '&:hover, &:focus': {
      backgroundColor: '#FF825C'
    }
  },
  buttonCard: {
    background: 'transparent',
    color: '#FD5D2B',
    '&:hover, &:focus': {
      backgroundColor: '#FF825C',
      color: '#FFF'
    }
  },
  footerCoverages: {
    position: 'absolute',
    bottom: 20,
    width: '100%'
  }
});

export default Styles;

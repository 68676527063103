import axios from 'axios';

class RiskObjectsService {
  create = async riskObject => {
    const response = await axios.post(`${window.env.RENTAL_SURETY}/RiskObjects`, riskObject);

    return response.data;
  };

  get = async id => {
    const response = await axios.get(`${window.env.RENTAL_SURETY}/RiskObjects/${id}`);

    return response.data;
  };

  put = async riskObject => {
    const response = await axios.put(
      `${window.env.RENTAL_SURETY}/RiskObjects/${riskObject.id}`,
      riskObject
    );

    return response.data;
  };
}

export default new RiskObjectsService();

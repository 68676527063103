import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import MuiModal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { CANCEL } from '../../../constants/constants';
import WarningModalClasses from '../classes/WarningModalClasses';

const ActionButton = function ({ text, onClick, onClose, cancel, btnDisabled }) {
    const classes = WarningModalClasses()();
    return (
        <>
            <Box py={2} px={3} direction="row">
                {cancel && (
                    <Button
                        id="btn-cancel"
                        disabled={btnDisabled}
                        className={`${classes.button} ${classes.cancelButton}`}
                        variant="contained"
                        color="primary"
                        onClick={onClose}
                    >
                        {CANCEL}
                    </Button>
                )}
                <Button
                    id="btn-action"
                    disabled={btnDisabled}
                    className={`${classes.button} ${classes.actionButton}`}
                    variant="contained"
                    color="primary"
                    onClick={onClick}
                >
                    {text}
                </Button>
            </Box>
        </>
    );
};

ActionButton.propTypes = {
    text: PropTypes.string.isRequired,
    isConfirm: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

const WarningModal = function ({ image, message, title, actions, open, onClose, onClick, cancel }) {
    const classes = WarningModalClasses(image)();

    return (
        <MuiModal
            id="modalError"
            open={open ?? false}
            onClose={onClose}
            disableBackdropClick
            data-testid="modal"
        >
            <Paper className={classes.paper} elevation={8}>
                <Close id="btn-close" className={classes.closeButton} onClick={onClose} data-testid="modal-close-btn" />
                <Grid container>
                    <Grid container direction="row">
                        <Grid item xs={9}>
                            <Box py={2} px={4}>
                                <Box py={2} className={classes.title}>
                                    {title}
                                </Box>
                                <Typography className={classes.message}>
                                    {message}
                                </Typography>
                            </Box>
                            <Grid item xs={12}>
                                <Grid
                                    className={classes.actionsContainer}
                                    container
                                    justify="flex-end"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    {actions &&
                                        actions.map(action => (
                                            <Grid item key={_.kebabCase(action.text)}>
                                                <ActionButton {...action} onClick={onClick} onClose={onClose} cancel={cancel} />
                                            </Grid>
                                        ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className={classes.image}>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </MuiModal>
    );
};

WarningModal.propTypes = {
    image: PropTypes.string,
    message: PropTypes.string,
    title: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onClick: PropTypes.func,
    cancel: PropTypes.bool
};

export default WarningModal;

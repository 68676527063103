import axios from 'axios';

class PlansService {
  getPlans = async type => {
    const response = await axios.get(`${window.env.RENTAL_SURETY}/Plans/${type}`);

    return response.data;
  };
}

export default new PlansService();

import { EMPTY_STRING } from '../../../shared/constants/constants';
import { BROKER, POLICYOWNER } from '../../../shared/constants/personConstants';
import {
  requiredValidator,
  validateDocument,
  validateMinCaracteres
} from '../../../shared/helper/formValidators';
import utilManager from '../../util/services/utilManager';

class IntermediatePersonManager {
  changeDocument = (value, setPolicyOwner, setPolicyOwners, avaiablesPolicyOwners) => {
    if (!value || value === EMPTY_STRING) {
      setPolicyOwner({ documentNumber: EMPTY_STRING, name: EMPTY_STRING });
      setPolicyOwners(avaiablesPolicyOwners);
    } else {
      const intermediate = avaiablesPolicyOwners.find(
        internediate => internediate.documentNumber === value
      );
      if (intermediate) {
        setPolicyOwner(intermediate);
      } else {
        setPolicyOwner({ documentNumber: value, name: EMPTY_STRING });
        setPolicyOwners(
          avaiablesPolicyOwners.filter(
            internediate => internediate.documentNumber.substring(0, value.length) === value
          )
        );
      }
    }
  };

  deleteIntermediate = (indexIntermediates, setOpenModal, setFormsBlock) => {
    setOpenModal(false);
    setFormsBlock(false);
    indexIntermediates.setIndex(indexIntermediates.index);
  };

  formValidate = (name, documentNumber) => {
    const errors = {};
    errors.name = validateMinCaracteres(name, 5);
    errors.name = requiredValidator(name);
    errors.documentNumber = validateDocument(documentNumber, errors.documentNumber);
    errors.documentNumber = requiredValidator(documentNumber);
    return utilManager.validErrors(errors) ? null : errors;
  };

  setIntermediate = (person, indexIntermediates, setOpenModal) => {
    if (person) {
      setOpenModal(false);
      indexIntermediates.setIndex(indexIntermediates.index);
      return person;
    }

    return null;
  };

  getIndexIntermediates = (index, setIndex) => {
    return {
      index: index,
      setIndex: setIndex
    };
  };

  getPersonsFilter = (leadPolicyOwner, person, intermediates, broker, brokers) => {
    return leadPolicyOwner && person?.role?.toUpperCase() === POLICYOWNER
      ? intermediates?.filter(
          i => i.role.toUpperCase() === POLICYOWNER && person.personId !== i.personId
        )
      : intermediates?.filter(
          i =>
            i.role.toUpperCase() === BROKER &&
            !brokers?.some(b => b.personId === i.personId) &&
            i.personId !== broker.personId
        );
  };
}

export default new IntermediatePersonManager();

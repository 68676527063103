import { nameFormat } from '../../../../shared/components/format/NameFormat';
import { EMPTY_GUID, EMPTY_STRING, RESIDENTIAL } from '../../../../shared/constants/constants';
import {
  EXPENSES_DESCRIPTION_VALUE,
  RENTAL_DESCRIPTION
} from '../../../../shared/constants/planConstants';
import calculateService from './calculateService';

function getExpenseDescrition(description) {
  return description?.replace(EXPENSES_DESCRIPTION_VALUE, EMPTY_STRING);
}

function validateExpenseValue(expenses, coverage) {
  return expenses?.some(
    expense =>
      getExpenseDescrition(expense.description).indexOf(nameFormat(coverage.name)) >= 0 &&
      expense?.value > 0
  );
}

function getExpenseValue(expenses, coverage) {
  if (validateExpenseValue(expenses, coverage)) {
    return expenses?.find(
      expense =>
        getExpenseDescrition(expense.description).indexOf(nameFormat(coverage.name)) >= 0 &&
        expense?.value > 0
    )?.value;
  } else {
    return expenses?.find(
      expense =>
        getExpenseDescrition(expense.description).indexOf(RENTAL_DESCRIPTION) >= 0 &&
        expense?.value > 0
    )?.value;
  }
}

class CalculateServiceManager {
  getCommission = async plan => {
    const calculateCommissionRequest = {
      commissionPercentage: parseFloat(plan?.commissionPercentage) * 100,
      values: Array.from(plan?.coverages, c => c.grossPremium)
    };

    return await calculateService.getCommission(calculateCommissionRequest);
  };

  getInsuredAmount = async (expenses, coverage, plan) => {
    const calculateInsuredAmountRequest = {
      expenseValue: getExpenseValue(expenses, coverage),
      multiple: coverage?.multiple,
      idPlan: plan?.id,
      idCoverage: coverage?.id
    };

    if (
      calculateInsuredAmountRequest.expenseValue &&
      calculateInsuredAmountRequest.multiple &&
      calculateInsuredAmountRequest.idPlan &&
      calculateInsuredAmountRequest.idPlan !== EMPTY_GUID &&
      calculateInsuredAmountRequest.idCoverage &&
      calculateInsuredAmountRequest.idCoverage !== EMPTY_GUID
    ) {
      return await calculateService.getInsuredAmount(calculateInsuredAmountRequest);
    }
  };

  getMaximumInstallment = async (quote, totalValue) => {
    if (quote?.plan?.id) {
      const calculateMaximumInstallmentRequest = {
        startDate: quote?.startLeaseContract.toLocaleDateString('en-US'),
        endDate: quote?.endLeaseContract.toLocaleDateString('en-US'),
        totalValue: totalValue
      };

      return await calculateService.getMaximumInstallment(calculateMaximumInstallmentRequest);
    }
  };

  getPremium = async (policyOwner, multiple, installments, rental, commission) => {
    const calculatePremiumRequest = {
      personId: policyOwner?.personId,
      commission: commission,
      multiplePlan: multiple,
      leaseTime: installments,
      rental: rental
    };

    return await calculateService.getPremium(calculatePremiumRequest);
  };

  getApprovedLimit = async analyze => {
    const getApprovedLimitRequest = {
      documentNumber: analyze?.policyHolder.documentNumber,
      isResident: analyze?.policyHolder.inhabited,
      isResidential: analyze?.occupation === RESIDENTIAL
    };

    return await calculateService.getApprovedLimit(getApprovedLimitRequest);
  };
}

export default new CalculateServiceManager();

import { Box, FormControlLabel, Grid, IconButton, Paper, Switch } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import NumberInput from '../../../../../../../shared/components/form/NumberInput';
import { moneyFormat } from '../../../../../../../shared/components/format/MoneyFormat';
import InternalComponentCoverage from '../../../../../../../shared/components/modal/modalInfo/InternalComponentCoverage';
import ModalInfo from '../../../../../../../shared/components/modal/modalInfo/ModalInfo';
import { EMPTY_STRING } from '../../../../../../../shared/constants/constants';
import {
  BASE_ICON,
  BASE_IMG,
  COVERAGES_IMAGES,
  COVERAGE_DEFAULT_IMAGE
} from '../../../../../../../shared/constants/coverageConstants';
import { MULTIPLE_ID } from '../../../../../../../shared/constants/planConstants';
import { TRANSMITTED } from '../../../../../../../shared/enums/statusEnum';
import { validateMultiple } from '../../../../../../../shared/helper/formValidators';
import coverageManager from '../../../../../manager/insurance/quote/plan/coverageManager';
import { calculateOperations, errorsActions, quoteActions } from '../../../../../redux';
import { useErrors } from '../../../../../redux/errors/errorsCustomHooks';
import { useAnalyze } from '../../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../../redux/insurance/policy/policyCustomHooks';
import { useQuote } from '../../../../../redux/insurance/quote/quoteCustomHooks';
import ListCoverageTraditionalClasses from '../classes/ListCoverageTraditionalClasses';

const ListCoverageTraditional = ({ coverage, coveragesByExpenses }) => {
  const quote = useQuote();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const errors = useErrors();
  const dispatch = useDispatch();
  const disableSwitch = !coveragesByExpenses.some(coverageExpense => coverageExpense.id === coverage.id);
  const disabled = disableSwitch || analyze?.status === TRANSMITTED || policy?.id;
  const coverageInsurance =
    quote?.plan?.id && quote?.plan?.coverages?.some(c => c.id === coverage.id) ? true : false;
  const insuranceCoverage = coverageInsurance
    ? quote?.plan?.coverages?.find(c => c.id === coverage.id)
    : coverage;

  useEffect(() => {
    if (quote?.plan?.coverages?.length > 0 && !insuranceCoverage.insuredAmount && !disabled) {
      dispatch(calculateOperations.getInsuredAmount(insuranceCoverage));
    }
  }, [quote, insuranceCoverage, disabled, dispatch]);

  const [openModal, setOpenModal] = useState(false);
  const classes = ListCoverageTraditionalClasses();

  const coverageUnselect =
    coveragesByExpenses.some(coverageExpense => coverageExpense.id === coverage.id) &&
    !coverageInsurance;

  const coverageImage = COVERAGES_IMAGES.find(c => c.name === coverage.name);
  const img = coverageImage ? coverageImage.img : COVERAGE_DEFAULT_IMAGE;

  const changeActivated = value => {
    const insurancePlan = coverageManager.changeActivatedCoverages(coverage, quote, value);
    dispatch(quoteActions.setQuote({ ...quote, plan: insurancePlan }));

    if (!value) {
      dispatch(errorsActions.setErrors({
        ...errors,
        plan: {
          ...errors.plan,
          multiples: {
            ...errors.plan.multiples,
            [`${MULTIPLE_ID}${coverage.id}`]: EMPTY_STRING
          }
        }
      }));
    }
    dispatch(calculateOperations.getCommission());
  };

  const helpCoverageClick = () => {
    setOpenModal(true);
  };

  const exitButtonOnClick = () => {
    setOpenModal(false);
  };

  const changeMultiple = (_name, value) => {
    validateMultiple(value, insuranceCoverage);
    const insurancePlan = coverageManager.changeMultipleCoverages(coverage, quote, value);
    dispatch(quoteActions.setQuote({ ...quote, plan: insurancePlan }));

  };

  const updateErrorsOnBlur = async () => {
    const msg = validateMultiple(insuranceCoverage.multiple, insuranceCoverage);

    dispatch(errorsActions.setErrors({
      ...errors,
      plan: {
        ...errors.plan,
        multiples: {
          ...errors.plan?.multiples,
          [`${MULTIPLE_ID}${insuranceCoverage.id}`]: msg
        }
      }
    }));

    if (!msg) {
      dispatch(calculateOperations.getInsuredAmount(insuranceCoverage));
    }
  };

  const getCoverageSelectedClass = () => {
    return coverageUnselect ? classes.disabledCoverage : EMPTY_STRING;
  };

  return (
    <>
      <ModalInfo
        open={openModal}
        children={<InternalComponentCoverage coverage={coverage} cancelClick={exitButtonOnClick} />}
        image={`${BASE_IMG}${img}.png`}
        blur={false}
        showExitButton
        exitButtonOnClick={exitButtonOnClick}
      />
      <Grid container className={disabled ? classes.disabledCard : getCoverageSelectedClass()}>
        <Grid item xs={12}>
          <Box pb={3}>
            <Paper className={`${classes.paper} ${classes.coveragePaper}`}>
              <Grid container>
                <Grid item xs={6} lg={1}>
                  <Box pt={4} textAlign={'center'}>
                    <img
                      className={disabled ? classes.imgDisabled : classes.imgCoverage}
                      alt={'Cobertura'}
                      src={
                        disabled || coverageUnselect
                          ? `${process.env.PUBLIC_URL}${BASE_ICON}${insuranceCoverage.icon}-disabled.svg`
                          : `${process.env.PUBLIC_URL}${BASE_ICON}${insuranceCoverage.icon}.svg`
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} lg={2} xl={3}>
                  <Box pb={0} pt={2} className={classes.fontTitle}>
                    {insuranceCoverage.basicCoverage ? 'COBERTURA BÁSICA' : 'COBERTURA ADICIONAL'}
                  </Box>
                  <Box pt={0} className={classes.descriptionCoverage}>
                    {insuranceCoverage.name}
                    <IconButton
                      id={`btn-InfoCoverage-${insuranceCoverage.id}`}
                      color="primary"
                      onClick={helpCoverageClick}
                    >
                      <HelpIcon
                        className={
                          disabled || coverageUnselect ? classes.iconDisabled : classes.icon
                        }
                      />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Box px={2} pb={0} pt={1.5} className={classes.fontTitle}>
                    MÚLTIPLO
                    <IconButton id="btn-InfoCoverage" color="primary">
                      <HelpIcon className={classes.blueIcon} />
                    </IconButton>
                  </Box>
                  <Box px={2} pt={0} pb={2} className={classes.description}>
                    <NumberInput
                      id={`${MULTIPLE_ID}${insuranceCoverage.id}`}
                      value={insuranceCoverage.multiple?.toString()}
                      label={EMPTY_STRING}
                      onChange={changeMultiple}
                      onBlur={updateErrorsOnBlur}
                      error={
                        errors?.plan?.multiples &&
                          errors.plan.multiples[
                          `${MULTIPLE_ID}${insuranceCoverage.id}`
                          ]
                          ? errors.plan.multiples[
                          `${MULTIPLE_ID}${insuranceCoverage.id}`
                          ]
                          : EMPTY_STRING
                      }
                      min={1}
                      max={30}
                      required
                      disabled={
                        (!insuranceCoverage.required && disabled) ||
                        (!insuranceCoverage.required && !coverageInsurance) ||
                        insuranceCoverage.fixedMultiple
                      }
                      border
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={2} xl={2}>
                  <Box pb={0} pt={2} className={classes.fontTitle}>
                    IMPORTÂNCIA SEGURADA
                  </Box>
                  <Box pt={2} className={classes.description}>
                    {moneyFormat(insuranceCoverage.insuredAmount?.value)}
                  </Box>
                </Grid>
                {/* <Grid item xs={12} lg={2} xl={1}>
                  <Box pl={2} pb={0} pt={2} className={classes.fontTitle}>
                    FRANQUIA
                  </Box>
                  <Box pl={2} pt={2} className={classes.description}>
                    {insuranceCoverage.franchise > 0
                      ? moneyFormat(insuranceCoverage.franchise)
                      : 'Sem franquia'}
                  </Box>
                </Grid> */}
                <Grid item xs={12} lg={2} xl={2}>
                  <Box pl={2} pb={0} pt={2} className={classes.fontTitle}>
                    PRÊMIO LÍQUIDO
                  </Box>
                  <Box pl={2} pt={2} className={classes.description}>
                    {moneyFormat(insuranceCoverage?.commercialPremium)}
                  </Box>
                </Grid>
                <Grid item xs={12} lg={1}>
                  {!insuranceCoverage.required && (
                    <FormControlLabel
                      className={classes.controlLabel}
                      classes={{ label: classes.label }}
                      disabled={disabled}
                      checked={coverageInsurance}
                      value="top"
                      control={
                        <Switch
                          id={`btn-activeCoverage-${insuranceCoverage.id}`}
                          color="primary"
                          onChange={e => {
                            changeActivated(e.target.checked);
                          }}
                        />
                      }
                      label={disableSwitch || coverageUnselect ? 'DESATIVA' : 'ATIVA'}
                      labelPlacement="top"
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

ListCoverageTraditional.propTypes = {
  coverage: PropTypes.object.isRequired,
  coveragesByExpenses: PropTypes.array.isRequired
};

export default ListCoverageTraditional;

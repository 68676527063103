import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import InternalInfoApprovedClasses from '../../classes/InternalClasses';

const InternalInfoHeader = ({ title }) => {
  const classes = InternalInfoApprovedClasses();

  return (
    <>
      <Box px={5} pt={8} pb={0} className={classes.header}>
        Pottencial Aluguel
      </Box>
      <Box px={5} pt={1} pb={0} className={classes.title}>
        {title}
      </Box>
    </>
  );
};

InternalInfoHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default InternalInfoHeader;

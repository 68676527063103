import { CircularProgress, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { EMPTY_STRING } from '../../constants/constants';

export default function AutoCompleteForm({
  value,
  defaultValue,
  options,
  onInputChange,
  onChange,
  disabled,
  id,
  error,
  label,
  attribute,
  width
}) {
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;
  const hasError = error ? true : false;

  useEffect(() => {
    if (!loading) {
      return undefined;
    }
  }, [loading]);

  return (
    <form onSubmit={e => e.preventDefault()} autoComplete="off">
      <Autocomplete
        id={id}
        size="small"
        value={value}
        defaultValue={defaultValue}
        options={options}
        disabled={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}
        onInputChange={(_event, val) => {
          if (onInputChange) {
            onInputChange(val);
          }
        }}
        onChange={(_event, value) => {
          if (onChange) {
            onChange(value, id);
          }
        }}
        getOptionLabel={option => {
          if (Object.values(option).length !== 0) {
            return option && attribute ? option[attribute] : option;
          }
          return EMPTY_STRING;
        }}
        style={{ width: width }}
        noOptionsText={'Nenhuma opção encontrada'}
        loadingText={'Pesquisando...'}
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            variant="standard"
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </form>
  );
}

AutoCompleteForm.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  attribute: PropTypes.string,
  width: PropTypes.number
};

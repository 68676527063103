import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import CpfCnpjInput from '../../../../../../shared/components/form/CpfCnpjInput';
import EmailInput from '../../../../../../shared/components/form/EmailInput';
import InputForm from '../../../../../../shared/components/form/InputForm';
import { nameFormat } from '../../../../../../shared/components/format/NameFormat';
import {
  DISABLE_ALL_FIELDS,
  DISABLE_SOME_FIELDS,
  EMPTY_STRING
} from '../../../../../../shared/constants/constants';
import { CPF_SIZE } from '../../../../../../shared/constants/formConstants';
import {
  ID_DOCUMENT_INPUT,
  ID_EMAIL_INPUT,
  ID_NAME_INPUT
} from '../../../../../../shared/constants/personConstants';
import { validateDocument } from '../../../../../../shared/helper/formValidators';
import policyHolderManager from '../../../../manager/insurance/analyze/policyHolder/policyHolderManager';
import personsManager from '../../../../manager/persons/personsManager';
import { analyzeActions, errorsActions, personsOperations } from '../../../../redux';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import {
  useAnalizePolicyHolder,
  useAnalyze
} from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import { useProposal } from '../../../../redux/insurance/proposal/proposalCustomHooks';
import { useLoading } from '../../../../redux/quizCustomHooks';

const PolicyHolder = ({ disabled }) => {
  const analyze = useAnalyze();
  const errors = useErrors();
  const proposal = useProposal();
  const policyHolder = useAnalizePolicyHolder();
  const index = 0;
  const loading = useLoading();
  const dispatch = useDispatch();

  const changeDocument = async value => {
    updateValuesPolicyHolder(ID_DOCUMENT_INPUT, value);

    if (value && !validateDocument(value, EMPTY_STRING) && value.length === CPF_SIZE) {
      const sameDocumentError = personsManager.verifySameDocument(
        value,
        proposal,
        analyze?.policyHolder,
        true
      );

      if (!sameDocumentError) {
        const person = await dispatch(personsOperations.getPersonByDocument(value));
        dispatch(
          analyzeActions.setAnalyze({
            ...analyze,
            policyHolder: {
              ...policyHolder,
              personId: person.personId,
              name: person.name,
              email: person.email,
              documentNumber: person.documentNumber
            }
          })
        );
      }

      dispatch(
        errorsActions.setErrors({
          ...errors,
          policyHolder: {
            ...errors.policyHolder,
            documentNumber: sameDocumentError
          }
        })
      );
    }
  };

  const getVeracity = (name, value) => {
    return analyze?.policyHolder &&
      (analyze?.policyHolder[name] !== EMPTY_STRING || analyze?.policyHolder[name] !== 0) &&
      analyze?.policyHolder[name] !== value
      ? false
      : analyze?.veracity
  }

  const updateEmailsPolicyHolder = (name, value) => {
    dispatch(
      analyzeActions.setAnalyze({
        ...analyze,
        policyHolder: { ...analyze?.policyHolder, email: { ...analyze?.policyHolder?.email, emailAddress: value } },
        veracity: getVeracity(name, value)
      })
    );
  };

  const updateValuesPolicyHolder = (name, value) => {
    dispatch(
      analyzeActions.setAnalyze({
        ...analyze,
        policyHolder: { ...analyze?.policyHolder, [name]: value },
        veracity: getVeracity(name, value)
      })
    );
  };

  const updateErrorsOnBlur = async (name, value) => {
    if (!loading) {
      if (name.indexOf(ID_DOCUMENT_INPUT) >= 0 && !value && policyHolder?.documentNumber) {
        dispatch(
          errorsActions.setErrors({
            ...errors,
            policyHolder: policyHolderManager.validatePolicyHolder(policyHolder, proposal)
          })
        );
      } else {
        dispatch(
          errorsActions.setErrors({
            ...errors,
            policyHolder: { ...errors.policyHolder, [name]: value }
          })
        );
      }

      if (name === ID_DOCUMENT_INPUT && !policyHolder?.documentNumber) {
        dispatch(
          analyzeActions.setAnalyze(
            {
              ...analyze?.policyHolder,
              name: EMPTY_STRING,
              email: EMPTY_STRING,
              personId: null,
              main: true
            },
            index
          )
        );
      }
    }
  };

  return (
    <>
      <Grid container item justify="center">
        <Grid item xs={12} md={8}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <CpfCnpjInput
                id={ID_DOCUMENT_INPUT}
                value={policyHolder?.documentNumber}
                label="CPF do Locatário"
                onChange={changeDocument}
                onBlur={updateErrorsOnBlur}
                error={errors?.policyHolder?.documentNumber}
                required
                disabled={disabled === DISABLE_SOME_FIELDS || disabled === DISABLE_ALL_FIELDS}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputForm
                id={ID_NAME_INPUT}
                value={policyHolder?.name ? nameFormat(policyHolder?.name) : EMPTY_STRING}
                label="Nome do Locatário"
                onChange={updateValuesPolicyHolder}
                onBlur={updateErrorsOnBlur}
                error={errors?.policyHolder?.name}
                required
                numberRestriction
                caractersRestriction={5}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EmailInput
                id={ID_EMAIL_INPUT}
                value={policyHolder?.email?.emailAddress ?? EMPTY_STRING}
                label="Email do Locatário"
                onChange={updateEmailsPolicyHolder}
                onBlur={updateErrorsOnBlur}
                error={errors?.policyHolder?.email}
                required
                disabled={disabled === DISABLE_ALL_FIELDS}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

PolicyHolder.propTypes = {
  disabled: PropTypes.number
};

export default PolicyHolder;

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { EMPTY_STRING } from '../../constants/constants';
import { requiredValidator, validateMinValue } from '../../helper/formValidators';
import InputClasses from './InputClasses';

export const validadeMoneyInput = (value, required, min) => {
  let msg = EMPTY_STRING;
  if (required) msg = requiredValidator(value, EMPTY_STRING);
  if (min && value && !msg) msg = validateMinValue(value, EMPTY_STRING, min, true);
  return msg;
};

const NumberFormatCustom = function(props) {
  const { inputRef, onChange, value, min, max, ...other } = props;

  return (
    <form onSubmit={e => e.preventDefault()} autoComplete="off">
      <NumberFormat
        allowNegative={false}
        data-testid="number-format"
        {...other}
        value={value || EMPTY_STRING}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        prefix="R$ "
        decimalScale={2}
      />
    </form>
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function MoneyInput({
  value,
  onChange,
  onBlur,
  min,
  max,
  label,
  disabled,
  required,
  id,
  error
}) {
  const classes = InputClasses();
  const hasError = !!error;

  return (
    <FormControl className={classes.formControl}>
      {label && (
        <InputLabel error={hasError} required={required}>
          {label}
        </InputLabel>
      )}
      <Input
        id={id}
        error={hasError}
        className={classes.input}
        label={label || EMPTY_STRING}
        value={value}
        onChange={e => {
          onChange(id, Number(e.target.value));
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur(id, validadeMoneyInput(value, required, min), required, min);
          }
        }}
        placeholder={EMPTY_STRING}
        inputComponent={NumberFormatCustom}
        inputProps={{ min: min, max: max }}
        disabled={disabled}
      />
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </FormControl>
  );
}

MoneyInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.string
};

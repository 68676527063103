import axios from 'axios';
class PersonsService {
  savePhone = async person => {
    const request = {
      personId: person.personId,
      phone: person.phone
    };
    const response = await axios.post(
      `${window.env.RENTAL_SURETY}/Persons/${person.personId}/phone`,
      request
    );
    return response.data;
  };

  changePhone = async person => {
    const request = {
      personId: person.personId,
      phone: person.phone
    };
    const response = await axios.put(
      `${window.env.RENTAL_SURETY}/Persons/${person.personId}/phone/${request.phone.id}`,
      request
    );
    return response.data;
  };

  saveEmail = async person => {
    const request = {
      personId: person.personId,
      email: person.email
    };
    const response = await axios.post(
      `${window.env.RENTAL_SURETY}/Persons/${person.personId}/email`,
      request
    );
    return response.data;
  };
  changeEmail = async person => {
    const request = {
      personId: person.personId,
      email: person.email
    };
    const response = await axios.put(
      `${window.env.RENTAL_SURETY}/Persons/${person.personId}/email/${request.email.id}`,
      request
    );
    return response.data;
  };

  getPersonByDocument = async document => {
    const response = await axios.get(`${window.env.RENTAL_SURETY}/Persons/${document}`);

    return response.data;
  };

  savePerson = async person => {
    const response = await axios.post(`${window.env.RENTAL_SURETY}/Persons`, person);

    return response.data;
  };

  getLinkedOrganizationsByBrokerId = async idBroker => {
    const response = await axios.get(
      `${window.env.RENTAL_SURETY}/Persons/linkedOrganizationsbybroker/${idBroker}`
    );

    return response.data;
  };

  getPersonById = async personId => {
    const response = await axios.get(`${window.env.RENTAL_SURETY}/Persons/${personId}`);

    return response.data;
  };
}
export default new PersonsService();

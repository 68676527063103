export const COVERAGES_IMAGES = [
  { name: 'Aluguel + Encargos (água, luz, gás, IPTU e condomínio)', img: 'rental' },
  { name: 'Danos ao Imóvel', img: 'propety_damage' },
  { name: 'Multa por rescisão cotratual', img: 'contractual' },
  { name: 'Pintura Interna', img: 'painting' },
  { name: 'Pintura Externa', img: 'painting' },
  { name: 'Aluguel', img: 'rental' },
  { name: 'Água', img: 'water' },
  { name: 'Luz', img: 'light' },
  { name: 'IPTU', img: 'iptu' },
  { name: 'Condomínio', img: 'condominium' }
];
export const COVERAGE_DEFAULT_IMAGE = 'default';
export const COVERAGE_GENERAL_CONDITIONS =
  'https://stdevsiteinst01.blob.core.windows.net/sitefiles/FiancaLocaticia/CGFiancaLocaticia12.pdf';
export const BASE_IMG = '/assets/images/coverages_images/';
export const BASE_ICON = '/assets/images/coverages_icons/';

export const MULTIPLE_BASIC = 30;

export const MULTIPLES_COVERAGES = 
{
  monthly : { 
    damage : 6,
    paintingInternal : 3,
    paintingExternal : 3,
    penalty : 3 
  },
  traditional : {
    rental: {multiple: 30, multipleMax: 30 , multipleMin: 6},
    iptu : {multiple: 30, multipleMax:30 , multipleMin: 6},
    condominium : {multiple: 30, multipleMax:30 , multipleMin: 6},
    water : {multiple: 6 , multipleMax: 30 , multipleMin: 3},
    light : {multiple: 6 , multipleMax: 30 , multipleMin: 3},
    gas : {multiple: 6 , multipleMax: 30 , multipleMin: 3},
    damage : {multiple: 6 , multipleMax: 6 , multipleMin: 2},
    paintingExternal : {multiple: 3, multipleMax: 6, multipleMin: 1},
    paintingInternal : {multiple: 3, multipleMax: 6, multipleMin: 1},
    penalty : {multiple: 3, multipleMax: 3, multipleMin: 1},
  }
}


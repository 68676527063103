import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from '../../../shared/components/breadcrumb/BreadCrumb';
import SearchInput from '../../../shared/components/form/SearchInput';
import { useUser } from '../../../shared/components/header/redux/userCustomHooks';
import HeaderFilter from '../../../shared/components/list/HeaderFilter';
import Loading from '../../../shared/components/loading/Loading';
import Modal from '../../../shared/components/modal/Modal';
import StyledTab from '../../../shared/components/tabs/StyledTab';
import StyledTabs from '../../../shared/components/tabs/StyledTabs';
import { SUBSCRIPTION_LIST_BREADCRUMB } from '../../../shared/constants/breadCrumbConstants';
import { EMPTY_STRING } from '../../../shared/constants/constants';
import { NOTFOUND_INSURANCE_MESSAGE } from '../../../shared/constants/modalMessagesConstants';
import { SubscriptionStatus } from '../../../shared/enums/statusEnum';
import quizManager from '../../quiz/manager/quizManager';
import { insurancesOperations, quizActions } from '../../quiz/redux';
import requestPageManager from '../../requests/requestPageManager';
import InsuranceList from './InsuranceList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const NUMBER_OF_INSURANCES_PER_PAGE = 10;

const SubscriptionListPage = function() {
  const dispatch = useDispatch();
  const user = useUser();
  const [totalInsurancesList, setTotalInsurancesList] = useState([]);
  const [currentInsurances, setCurrentInsurances] = useState([]);
  const [numberCurrentPage, setNumberCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [search, setSearch] = useState(EMPTY_STRING);
  const [quotesFind, setQuotesFind] = useState([]);
  const notFound = useSelector(state => state.quiz?.notFound);
  const [modalProps, setModalProps] = useState(quizManager.getInitialModalProps());
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (notFound) {
      setModalProps(quizManager.getModalProps(NOTFOUND_INSURANCE_MESSAGE));
      dispatch(quizActions.setLoading(false));
    }
  }, [notFound, dispatch, setModalProps]);

  useEffect(() => {
    (async () => {
      const fetchedInsurances = await dispatch(
        insurancesOperations.getInsurances({
          status: SubscriptionStatus.UNDERANALYSIS
        })
      );
      if (fetchedInsurances) {
        setTotalInsurancesList(fetchedInsurances);
        const initialNumberPages = Math.ceil(
          fetchedInsurances?.length / NUMBER_OF_INSURANCES_PER_PAGE
        );
        setNumberOfPages(initialNumberPages);
        setCurrentInsurances(fetchedInsurances.slice(0, NUMBER_OF_INSURANCES_PER_PAGE));
      }
    })();
  }, [dispatch]);

  const searchInsurance = async value => {
    requestPageManager.setSearchFilter(value, totalInsurancesList, {
      setSearch,
      setQuotesFind,
      setCurrentPage: setCurrentInsurances,
      setNumberOfPages,
      setNumberCurrentPage,
      setInsurancesList: setTotalInsurancesList
    });
  };

  const onClose = () => {
    setModalProps({ isOpen: false });
    dispatch(quizActions.setLoading(false));
  };

  const onChangeCurrentPage = (_event, page) => {
    requestPageManager.dataPage(
      { numberPage: page, numberOfPages: numberOfPages },
      search ? quotesFind : totalInsurancesList,
      setNumberCurrentPage,
      setCurrentInsurances
    );
  };

  const onChangeSelectedTab = (_, newTab) => {
    setSelectedTab(newTab);
  };

  const onClickInsuranceItem = insuranceId => {
    // @TODO quando tiver usuário tipo subscritor...
    // history.push(`${routes.quiz.path}/${insuranceId}`);
  };

  return (
    <Box display="flex" flexDirection="column">
      <BreadCrumb pages={SUBSCRIPTION_LIST_BREADCRUMB} />
      {totalInsurancesList?.length === 0 && !notFound && <Loading />}
      <Modal
        id={'requests-modal'}
        icon={modalProps.icon}
        message={modalProps.message}
        actions={modalProps.actions}
        open={modalProps.isOpen}
        onClose={onClose}
      />
      {user.personId ? (
        <Box px={{ sm: 1, md: 2, lg: 10 }} py={6}>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Box pb={2} color="#303E59" fontSize={26} fontWeight="fontWeightBold">
                Subscrições
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <SearchInput
                  value={search}
                  label={'Busque por termo...'}
                  id={'search-input'}
                  onChange={searchInsurance}
                  onBlur={searchInsurance}
                />
              </Box>
            </Grid>
          </Grid>
          <StyledTabs value={selectedTab} onChange={onChangeSelectedTab}>
            <StyledTab label="Em Análise" />
          </StyledTabs>
          <Box pt={3} pb={2}>
            <HeaderFilter
              insurancesList={totalInsurancesList}
              numberCurrentPage={numberCurrentPage}
              numberOfPages={numberOfPages}
              setters={{
                setNumberCurrentPage,
                setCurrentPage: setCurrentInsurances,
                setInsurancesList: setTotalInsurancesList
              }}
            />
          </Box>
          <TabPanel value={selectedTab} index={0}>
            <InsuranceList
              onChangeCurrentPage={onChangeCurrentPage}
              insuranceList={currentInsurances}
              numberOfPages={numberOfPages}
              onClickInsuranceItem={onClickInsuranceItem}
              showNumberOfItens={true}
            />
          </TabPanel>
        </Box>
      ) : (
        <Loading />
      )}
    </Box>
  );
};

export default SubscriptionListPage;

import makeStyles from '@material-ui/styles/makeStyles';

const Styles = makeStyles({
  paper: {
    maxWidth: 290,
    margin: '0 10'
  },
  image: {
    minHeight: 130
  },
  buttonCard: {
    background: '#FD5D2B',
    color: '#FFF',
    borderRadius: 30,
    minWidth: 155,
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 'normal',
    fontFamily: 'Open Sans',
    '&:hover, &:focus': {
      backgroundColor: '#FF825C'
    }
  },
  link: {
    textDecoration: 'none'
  }
});

export default Styles;

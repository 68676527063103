import { Box, Grid, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import BreadCrumb from '../../../shared/components/breadcrumb/BreadCrumb';
import { useUser } from '../../../shared/components/header/redux/userCustomHooks';
import Loading from '../../../shared/components/loading/Loading';
import { WELCOME_BREADCRUMB } from '../../../shared/constants/breadCrumbConstants';
import { analyzeActions, proposalActions, quoteActions } from '../../quiz/redux';
import { useAnalyze } from '../../quiz/redux/insurance/analyze/analyzeCustomHooks';
import * as insuracesActions from '../../quiz/redux/insurances/insurancesActions';
import { useInsurances } from '../../quiz/redux/insurances/insurancesCustomHooks';
import WelcomeClasses from './classes/WelcomeClasses';
import WelcomeCard from './WelcomeCard';

const Welcome = () => {
  const classes = WelcomeClasses();
  const user = useUser();
  const insurances = useInsurances();
  const dispatch = useDispatch();
  const analyze = useAnalyze();

  useEffect(() => {
    if (insurances?.length !== 0) {
      dispatch(insuracesActions.clearListInsurances());
    }

    if (analyze?.id) {
      dispatch(analyzeActions.setInitialStateAnalyze());
      dispatch(quoteActions.setInitialStateQuote());
      dispatch(proposalActions.setInitialStateProposal());
    }
  }, [analyze, insurances, dispatch]);

  return (
    <>
      <Grid className={classes.gridContainer}>
        {!user.personId && <Loading />}
        {user.personId && (
          <>
            <Box pt={7}>
              <BreadCrumb pages={WELCOME_BREADCRUMB} />
            </Box>
            <Box pt={0} direction="column">
              <Grid container justify="center" alignItems="center" direction="row">
                <Box px={3}>
                  <Box pb={4} color="#303E59" fontSize={26} fontWeight="fontWeightBold">
                    Nova Solicitação
                  </Box>
                  <Paper className={classes.paper} elevation={3}>
                    <WelcomeCard
                      user={user}
                      titleCard="Pottencial Aluguel"
                      nameCard="Seguro Fiança Locatícia para pessoas físicas"
                      logo="logo_seguro_fianca.svg"
                      textButton="Nova Solicitação"
                      path="/aluguel/quiz"
                    />
                  </Paper>
                </Box>
                <Box px={3} pt={8}>
                  <Paper className={classes.paper} elevation={3}>
                    <WelcomeCard
                      titleCard="Pottencial Incêndio"
                      nameCard="Seguro Incêndio para pessoas físicas"
                      logo="logo_seguro_incendio.svg"
                      textButton="Nova Solicitação"
                      path=""
                    />
                  </Paper>
                </Box>
                <Box px={3} pt={8}>
                  <Paper className={classes.paper} elevation={3}>
                    <WelcomeCard
                      titleCard="Continuar solicitação"
                      nameCard="Seleção de solicitação a ser complementada"
                      logo="logo_solicitacao_homepage.svg"
                      textButton="CONTINUAR"
                      path=""
                    />
                  </Paper>
                </Box>
              </Grid>
            </Box>
          </>
        )}
      </Grid>
    </>
  );
};

export default Welcome;

import { makeStyles } from '@material-ui/core/styles';
import { palette } from './shared/styles/theme';
const Styles = makeStyles({
  root: {
    position: 'relative',
    overflowX: 'hidden',
    height: '100%',
    backgroundColor: palette.background.main,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'repeat-x',
    backgroundSize: 1200
  }
});

export default Styles;
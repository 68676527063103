import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import intermediatePersonReducer from '../features/intermediatePerson/redux/intermediatePersonReducer';
import {
  analyzeReducer,
  insurancesReducer,
  plansReducer,
  policyReducer,
  proposalReducer,
  quizReducer,
  quoteReducer
} from '../features/quiz/redux';
import errorsReducer from '../features/quiz/redux/errors/errorsReducer';
import { userReducer } from '../shared/components/header/redux';

export default history =>
  combineReducers({
    router: connectRouter(history),
    quiz: quizReducer,
    insurance: combineReducers({
      analyze: analyzeReducer,
      quote: quoteReducer,
      proposal: proposalReducer,
      policy: policyReducer
    }),
    user: userReducer,
    intermediatePerson: intermediatePersonReducer,
    plans: plansReducer,
    insurances: insurancesReducer,
    errors: errorsReducer
  });

import { Box, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PaymentClasses from '../classes/PaymentClasses';
import ModalCoveragesPayment from '../modal/ModalCoveragesPayment';

const BodyPaymentCard = function ({ rentalPlan }) {
    const classes = PaymentClasses();
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <ModalCoveragesPayment rentalPlan={rentalPlan} open={openModal} setOpenModal={setOpenModal} />
            <Box pb={5} letterSpacing={0} textAlign="center" fontSize={11} fontWeight="fontWeightBold" fontFamily="Open Sans">
                <Link id="btn-showCoverages" className={classes.link} onClick={() => setOpenModal(true)} href="#">
                    Ver Coberturas
                </Link>
            </Box>
        </>
    );

};

BodyPaymentCard.propTypes = {
    rentalPlan: PropTypes.object.isRequired
};

export default BodyPaymentCard;

import { 
  EMPTY_STRING,
  DAY_IN_MILISECONDS
} from '../../../../../../shared/constants/constants';
import {
  ERR_DATE_CONTRACT,
  ERR_DATE_INSURANCE,
  REQUIRED
} from '../../../../../../shared/constants/formConstants';
import {
  dateValidator,
  diferenceDates,
  requiredValidator
} from '../../../../../../shared/helper/formValidators';
import utilManager from '../../../../../util/services/utilManager';

class InsuranceDataManager {
  validateDateContract = quote => {
    let msg = EMPTY_STRING;

    if (quote?.startLeaseContract && quote?.endLeaseContract) {
      const monthsContract = this.getDays(quote?.startLeaseContract, quote?.endLeaseContract);

      if (monthsContract > ERR_DATE_CONTRACT.LIMIT_MAXIMUM_DAYS_CONTRACT) {
        msg = ERR_DATE_CONTRACT.ERR_MAXIMUM_CONTRACT;
      } else if (monthsContract < ERR_DATE_CONTRACT.LIMIT_MINIMUM_DAYS_CONTRACT) {
        msg = ERR_DATE_CONTRACT.ERR_MINIMUM_CONTRACT;
      }
    }
    return msg;
  };

  validateDateInsurance = (quote, analyze) => {
    let msg = EMPTY_STRING;
    if (quote?.startInsuranceContract && quote?.endInsuranceContract) {
      const monthsInsurance = this.getDays(
        quote?.startInsuranceContract,
        quote?.endInsuranceContract
      );

      if (
        quote?.startInsuranceContract.setHours(0, 0, 0, 0) <
        new Date(analyze?.underwritingResponseDate).setHours(0, 0, 0, 0)
      ) {
        msg = ERR_DATE_INSURANCE.ERR_INITIAL_VALIDITY;
      } else if (
        quote?.startLeaseContract.toDateString() !== quote?.startInsuranceContract.toDateString() &&
        quote?.startLeaseContract > new Date(analyze?.underwritingResponseDate).setHours(0, 0, 0, 0)
      ) {
        msg = ERR_DATE_INSURANCE.ERR_DIFFERENT_CONTRACT_LEASE;
      } else if (monthsInsurance < ERR_DATE_INSURANCE.LIMIT_MINIMUM_DAYS_INSURANCE) {
        msg = ERR_DATE_INSURANCE.ERR_MINIMUM_INSURANCE;
      }
    }
    return msg;
  };

  getDateDifference = (startDate, endDate, value, start) => {
    const getValueDiff = (diff) => {
      return diff <= 0 ? EMPTY_STRING : diff.toString();
    }

    if (startDate && !start) {
      return getValueDiff(this.getDays(startDate,value));
    }

    if (endDate) {
      return getValueDiff(this.getDays(value,endDate));
    }
  };

  validateInsuranceContract = (quote, analyze) => {
    const errors = {};

    errors.startInsuranceContract = requiredValidator(quote?.startInsuranceContract);
    errors.endInsuranceContract = requiredValidator(quote?.endInsuranceContract);

    if (!errors.startInsuranceContract) {
      errors.startInsuranceContract = dateValidator(quote?.startInsuranceContract);
    }

    if (!errors.endInsuranceContract) {
      errors.endInsuranceContract = dateValidator(quote?.endInsuranceContract);
    }

    if (!errors.startInsuranceContract && !errors.endInsuranceContract) {
      if (!errors.endInsuranceContract) {
        errors.endInsuranceContract = diferenceDates(
          quote?.startInsuranceContract,
          quote?.endInsuranceContract
        );
      }

      if (!errors.endInsuranceContract) {
        errors.startInsuranceContract = this.validateDateInsurance(quote, analyze);
      }
    }
    return errors;
  };

  validateLeaseContract = quote => {
    const errors = {};

    errors.startLeaseContract = requiredValidator(quote?.startLeaseContract);
    errors.endLeaseContract = requiredValidator(quote?.endLeaseContract);

    if (!errors.startLeaseContract) {
      errors.startLeaseContract = dateValidator(quote?.startLeaseContract);
    }

    if (!errors.endLeaseContract) {
      errors.endLeaseContract = dateValidator(quote?.endLeaseContract);
    }

    if (!errors.startLeaseContract && !errors.endLeaseContract) {
      errors.startLeaseContract = this.validateDateContract(quote);
      errors.endLeaseContract = errors.startLeaseContract;
    }

    return errors;
  };

  validateInsurance = (quote, analyze) => {
    let errors = {};

    errors = this.validateLeaseContract(quote);
    errors = { ...errors, ...this.validateInsuranceContract(quote, analyze) };
    if (
      quote?.startInsuranceContract >= new Date(analyze?.underwritingResponseDate).setHours(0,0,0,0) &&
      errors?.startLeaseContract === REQUIRED
    ) {
      errors.startLeaseContract = EMPTY_STRING;
    }
    return utilManager.validErrors(errors) ? null : errors;
  };

  getDays = (startDate, endDate) => {
    return Math.round((endDate - startDate) / DAY_IN_MILISECONDS + 1);
  };

  getInstallMents = days => {
    return Math.round(days / 30.25, 0);
  };

  getInsuranceUpdated = (updateInsurance, insurance, newFile, acceptResponse) => {
    return updateInsurance
      ? {
          ...insurance,
          complianceLetterFile: newFile
        }
      : {
          ...insurance,
          id: acceptResponse.id,
          quoteId: acceptResponse.quoteId,
          payment: insurance.payment,
          complianceLetterFile: newFile,
          complianceLetter: newFile?.id,
          approvedLimit: acceptResponse.limit,
          underwritingStatus: acceptResponse.underwritingStatus,
          underwritingResponseDate: acceptResponse.underwritingResponseDate,
          createdAt: acceptResponse.createdAt,
          veracity: true
        };
  };
}

export default new InsuranceDataManager();

import plansServiceManager from '../../services/plans/plansServiceManager';
import * as quizActions from '../quizActions';
import * as plansActions from './plansActions';

export const getPlans = type => async dispatch => {
  try {
    const plans = await plansServiceManager.getPlan(type);

    dispatch(plansActions.setPlans(plans));
    dispatch(quizActions.setLoading(false));
  } catch {
    dispatch(quizActions.setError(true));
  }
};

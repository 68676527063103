import { Box, Button, Grid, IconButton, Paper } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import React from 'react';
import coverageManager from '../../../features/quiz/manager/insurance/quote/plan/coverageManager';
import plansManager from '../../../features/quiz/manager/insurance/quote/plan/plansManager';
import { useQuote } from '../../../features/quiz/redux/insurance/quote/quoteCustomHooks';
import ModalPlanClasses from './classes/ModalPlanClasses';

const ModalPlan = ({
  rentalPlan,
  open,
  coveragesSettings,
  choosePlan,
  setOpenModalPlan
}) => {
  const quote = useQuote();
  const classes = ModalPlanClasses();
  let rowsRequiredCoverage = coverageManager.getRowsRequiredCoverage(rentalPlan.coverages);

  const selectClick = () => {
    choosePlan();
    setOpenModalPlan(false);
  };

  const exitModalPlan = () => {
    plansManager.exitModalPlan(
      quote,
      setOpenModalPlan,
      coveragesSettings.setCoverageSelected
    )
  }

  return (
    <Modal className={classes.modal} open={open} BackdropProps={{ timeout: 500 }}>
      <Fade in={open}>
        <Box className={classes.box}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={8}>
                <Box p={1} pl={5}>
                  <p className={classes.header}>PLANOS / MENSALIZADO</p>
                  <h2 className={classes.title}>{rentalPlan.title}</h2>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box p={1} textAlign="end">
                  <IconButton
                    id="btn-close"
                    className={classes.buttonClose}
                    onClick={exitModalPlan}
                    color="primary"
                  >
                    <CancelIcon className={classes.icon} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Box pl={5} pr={5} pt={3} pb={3}>
                  {rowsRequiredCoverage}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box p={3} textAlign="end">
                <Button
                  id="btn-exit"
                  className={`${classes.button} ${classes.buttonReturn}`}
                  onClick={exitModalPlan}
                >
                  {'Voltar'}
                </Button>
                <Button
                  id="btn-Select"
                  className={`${classes.button} ${classes.buttonSelect}`}
                  onClick={selectClick}
                >
                  {'SELECIONAR'}
                </Button>
              </Box>
            </Grid>
          </Paper>
        </Box>
      </Fade>
    </Modal>
  );
};

ModalPlan.propTypes = {
  rentalPlan: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  coveragesSettings: PropTypes.object.isRequired,
  setOpenModalPlan: PropTypes.func.isRequired,
  choosePlan: PropTypes.func.isRequired
};

export default ModalPlan;

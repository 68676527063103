export const getFormattedPhoneNumber = input => {
  let output = '(';
  input.replace(/^\D*(\d{0,2})\D*(\d{0,4})\D*(\d{0,4})/, function(match, g1, g2, g3) {
    if (g1.length) {
      output += g1;
      if (g1.length === 2) {
        output += ')';
        if (g2.length) {
          output += ' ' + g2;
          if (g2.length === 4) {
            output += ' - ';
            if (g3.length) {
              output += g3;
            }
          }
        }
      }
    }
  });
  return output;
};

export const getFormattedCellPhoneNumber = input => {
  let output = '(';
  input.replace(/^\D*(\d{0,2})\D*(\d{0,1})\D*(\d{0,4})\D*(\d{0,4})/, function(
    match,
    g1,
    g2,
    g3,
    g4
  ) {
    if (g1.length) {
      output += g1;
      if (g1.length === 2) {
        output += ')';
        if (g2.length) {
          output += ' ' + g2;
          if (g2.length === 1) {
            if (g3.length) {
              output += ' ' + g3;
              if (g3.length === 4) {
                output += ' - ';
                if (g4.length) {
                  output += g4;
                }
              }
            }
          }
        }
      }
    }
  });
  return output;
};

export const PLAN_TYPES = [
  { id: 0, description: 'Mensalizado' },
  { id: 1, description: 'Tradicional' }
];
export const TRADITIONAL = 'Tradicional';
export const MONTHLY = 'Mensalizado';
export const MULTIPLES = [
  { key: 12, itemValue: '12 x' },
  { key: 15, itemValue: '15 x' },
  { key: 18, itemValue: '18 x' },
  { key: 24, itemValue: '24 x' },
  { key: 30, itemValue: '30 x' }
];
export const EXPENSES_DESCRIPTION_VALUE = 'VALOR_';
export const COMMERCIAL_VALUE = 'commercialPremium';
export const IOF = 'iof';
export const MULTIPLE_ID = 'multiple';
export const RENTAL_DESCRIPTION = 'ALUGUEL';
export const INITIAL_INSTALLMENT = '1';

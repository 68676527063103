import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { MONTHLY, TRADITIONAL } from '../../../../../../shared/constants/planConstants';
import { useInstallments, useQuote } from '../../../../redux/insurance/quote/quoteCustomHooks';
import { usePlan, usePlans } from '../../../../redux/plans/plansCustomHooks';
import PaymentCard from './card/PaymentCard';
import PaymentMethodCard from './card/PaymentMethodCard';
import PaymentClasses from './classes/PaymentClasses';
import Commission from './commission/Commission';
import CoverageConditions from './coverageConditions/CoverageConditions';

const Payment = () => {
  const classes = PaymentClasses();
  const plan = usePlan();
  const groupPlan = usePlans();
  let selectedPlan =
    groupPlan?.type === TRADITIONAL
      ? groupPlan?.plans[0]
      : groupPlan?.plans.find(g => g?.id === plan?.id);
  const [months, setMonths] = useState(1);
  const quote = useQuote();
  const installments = useInstallments();
  if (selectedPlan) {
    selectedPlan.grossPremium = selectedPlan?.grossPremium ? selectedPlan.grossPremium : quote?.plan?.grossPremium;
    selectedPlan.commercialPremium = selectedPlan?.commercialPremium ? selectedPlan.commercialPremium : quote?.plan?.commercialPremium;
  }

  return (
    <Grid container item justify="center">
      <Grid container item xs={12} md={8} direction="row">
        <Grid item xs={12} md={4}>
          <Grid key={selectedPlan?.id} item xs className={classes.card}>
            <PaymentCard
              key={selectedPlan?.id}
              installments={quote?.plan?.type === MONTHLY ? installments : months}
              rentalPlan={selectedPlan}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Commission />
          <CoverageConditions />
          <PaymentMethodCard months={months} setMonths={setMonths} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Payment;

export const SET_POLICY = 'insurance/SET_POLICY';
export const SET_INITIAL_STATE_POLICY = 'insurance/SET_INITIAL_STATE_POLICY';

export const setPolicy = policy => ({
  type: SET_POLICY,
  payload: policy
});

export const setInitialStatePolicy = () => ({
  type: SET_INITIAL_STATE_POLICY
});

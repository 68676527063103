import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Analyze from './analyze/Analyze';
import Proposal from './proposal/Proposal';
import Quote from './quote/Quote';

const renderSwitch = (quizStep) => {
  switch (quizStep.index) {
    case 0:
      return <Analyze quizStep={quizStep} />;
    case 1:
      return <Quote quizStep={quizStep} />;
    case 2:
      return <Proposal quizStep={quizStep} />;
    default:
      return <Analyze quizStep={quizStep} />;
  }
};

const QuestionBase = ({ quizStep }) => {
  return (
    <Grid item xs={12}>
      {renderSwitch(quizStep)}
    </Grid>
  );
};

QuestionBase.propTypes = {
  quizStep: PropTypes.object.isRequired
};

export default QuestionBase;

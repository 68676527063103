import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles({
  paper: {
    width: '100%',
    transform: 'matrix(1, 0, 0, 1, 0, 0)',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 6px 30px #00000014',
    opacity: 1,
    borderRadius: 20
  },
  coveragePaper: {
    height: 90
  },
  totalPaper: {
    height: 110
  },
  imgCoverage: {
    width: 25,
    height: 25
  },
  fontTitle: {
    font: 'normal normal 600 10px Open Sans',
    letterSpacing: 0.8,
    color: '#393F4A',
    textTransform: 'uppercase',
    textAlign: 'left'
  },
  description: {
    font: 'normal normal 600 12px Open Sans',
    textAlign: 'left',
    letterSpacing: 0,
    color: '#393F4A',
    opacity: 1
  },
  descriptionCoverage: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#282F3C',
    textAlign: 'left'
  },
  icon: {
    fontSize: '1.2rem'
  },
  blueIcon: {
    fontSize: '0.8rem',
    color: '#A9CBD3',
    background: '0% 0% no-repeat padding-box'
  },
  controlLabel: {
    marginTop: 14
  },
  label: {
    color: '#393F4A',
    fontFamily: 'Open Sans',
    fontSize: 10,
    paddingTop: 10
  },
  disabledCard: {
    pointerEvents: 'none',
    opacity: 0.7
  },
  disabledCoverage: {
    opacity: 0.7
  },
  iconDisabled: {
    fontSize: '1.2rem',
    color: '#dbdbdb'
  },
  imgDisabled: {
    width: 40
  }
});

export default Styles;

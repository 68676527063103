import { Box } from '@material-ui/core';
import React from 'react';
import LoadingClasses from './classes/LoadingClasses';

const Loading = () => {
  const classes = LoadingClasses();

  return (
    <Box className={classes.wrap}>
      <Box className={classes.loading}>
        <Box className={`${classes.dot} ${classes.dot1}`}></Box>
        <Box className={`${classes.dot} ${classes.dot2}`}></Box>
        <Box className={`${classes.dot} ${classes.dot3}`}></Box>
        <Box className={`${classes.dot} ${classes.dot4}`}></Box>
        <Box className={`${classes.dot} ${classes.dot5}`}></Box>
      </Box>
    </Box>
  );
};

export default Loading;

import { FormHelperText, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddData from '../../../../../../shared/components/AddData';
import Loading from '../../../../../../shared/components/loading/Loading';
import ModalAddData from '../../../../../../shared/components/modal/ModalAddData';
import WarningModal from '../../../../../../shared/components/modal/warning/WarningModal';
import ShowData from '../../../../../../shared/components/ShowData';
import {
  EMPTY_OBJECT,
  EMPTY_STRING, QUOTE_STEP,
  WARNING_ICON
} from '../../../../../../shared/constants/constants';
import {
  CONFIRM_DELETE,
  CONFIRM_INTERMEDIATE_MESSAGE
} from '../../../../../../shared/constants/modalMessagesConstants';
import {
  ADD_INTERMEDIATE,
  BROKER,
  POLICYOWNER
} from '../../../../../../shared/constants/personConstants';
import { TRANSMITTED } from '../../../../../../shared/enums/statusEnum';
import proposalInteremediatesManager from '../../../../manager/insurance/proposal/intermediate/proposalIntermediatesManager';
import {
  analyzeActions,
  errorsActions,
  proposalActions,
  quizActions,
  quizOperations
} from '../../../../redux';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import { useAnalyze } from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import {
  getAnalyzeBroker,
  getAnalyzePolicyOwner
} from '../../../../redux/insurance/analyze/analyzeSelectors';
import { usePolicy } from '../../../../redux/insurance/policy/policyCustomHooks';
import {
  useProposal,
  useProposalCommissionedAgentsIndex
} from '../../../../redux/insurance/proposal/proposalCustomHooks';
import { useLoading } from '../../../../redux/quizCustomHooks';
import ProposalIntermediateCard from './ProposalIntermediateCard';

const ProposalIntermediates = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [formsBlock, setFormsBlock] = useState(false);
  const proposal = useProposal();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const errors = useErrors();
  const [index, setIndex] = useState(useProposalCommissionedAgentsIndex());
  const [person, setPerson] = useState(EMPTY_OBJECT);
  const [confirmModal, setConfirmModal] = useState(false);
  const isLoading = useLoading();
  const [deleteIntermediate, setDeleteIntermediate] = useState(false);
  const analyzeModal =
    person?.role?.toUpperCase() === POLICYOWNER ||
    (person?.role?.toUpperCase() === BROKER &&
      person.documentNumber === getAnalyzeBroker(analyze?.commissionedAgents)?.documentNumber);
  const analyzeModalPolicyOwner = analyzeModal && person?.role?.toUpperCase() === POLICYOWNER;
  const disabled = (analyze?.status === TRANSMITTED || policy?.id);

  const cleanErrors = () => {
    dispatch(
      errorsActions.setErrors({
        ...errors,
        name: EMPTY_STRING,
        documentNumber: EMPTY_STRING,
        insureds: { required: errors.insureds?.required }
      })
    );
  };

  const deleteClickProposal = value => {
    const commissionedIndex = proposal.commissionedAgents.findIndex(
      c => c.personId === value.personId
    );
    const newProposal = {
      ...proposal,
      commissionedAgents: [
        ...proposal.commissionedAgents.slice(0, commissionedIndex),
        ...proposal.commissionedAgents.slice(commissionedIndex + 1)
      ]
    };
    dispatch(proposalActions.setProposal(newProposal));
    setPerson(EMPTY_OBJECT);
    setOpenModal(false);
    setFormsBlock(false);
  };

  const addClick = () => {
    cleanErrors();
    setPerson(null);
    setOpenModal(true);
  };

  const saveClickProposal = () => {
    const newProposal = {
      ...proposal,
      commissionedAgents: proposalInteremediatesManager.getCommissionedAgentsUpdated(
        proposal,
        person
      )
    };

    setOpenModal(false);
    setPerson(EMPTY_OBJECT);
    dispatch(proposalActions.setProposal(newProposal));
  };

  const saveClickAnalyze = () => {
    const newCommissionedAgents = analyze?.commissionedAgents.map((p, idx) =>
      idx === index ? person : { ...p }
    );
    dispatch(analyzeActions.setAnalyze({ ...analyze, commissionedAgents: newCommissionedAgents }));
    setOpenModal(false);
    setPerson(EMPTY_OBJECT);
  };

  const showClick = () => {
    cleanErrors();
    setIndex(index);
    setOpenModal(true);
  };

  const showClickAnalyze = position => {
    showClick();
    setPerson(analyze?.commissionedAgents[position]);
  };

  const showClickProposal = position => {
    showClick();
    setFormsBlock(true);
    setPerson(proposal.commissionedAgents[position]);
  };

  const cancelClick = () => {
    cleanErrors();
    setOpenModal(false);
    setPerson(EMPTY_OBJECT);
  };

  return (
    <>
      {isLoading && <Loading />}
      <WarningModal
        id={'modalWarningDelete'}
        image={WARNING_ICON}
        message={CONFIRM_DELETE}
        actions={[
          {
            text: 'Excluir',
            isConfirm: true
          }
        ]}
        title="Excluir intermediário?"
        open={deleteIntermediate}
        onClick={() => {
          deleteClickProposal(person);
          setDeleteIntermediate(false);
        }}
        onClose={() => {
          setDeleteIntermediate(false);
        }}
        cancel
      />
      <WarningModal
        id={'modalWarning'}
        image={WARNING_ICON}
        message={CONFIRM_INTERMEDIATE_MESSAGE}
        actions={[
          {
            text: 'Confirmar',
            isConfirm: true
          }
        ]}
        title="Editar estipulante"
        open={confirmModal}
        onClick={() => {
          saveClickAnalyze();
          dispatch(quizOperations.activateStep(QUOTE_STEP));
          dispatch(quizActions.setIntermediateChanged(true));
        }}
        onClose={() => {
          setConfirmModal(false);
        }}
        cancel
      />
      <ModalAddData
        id={`ModalAddProposalIntermediates-${index}`}
        open={openModal && !confirmModal}
        textButton={analyzeModal ? 'Salvar Alterações' : 'Incluir Intermediario'}
        children={
          <ProposalIntermediateCard
            person={person}
            setPerson={setPerson}
            formsBlock={analyzeModalPolicyOwner ? false : analyzeModal ? true : formsBlock}
          />
        }
        disabledForm={
          analyzeModalPolicyOwner &&
            person.documentNumber !==
            getAnalyzePolicyOwner(analyze?.commissionedAgents)?.documentNumber
            ? false
            : analyzeModal
              ? true
              : formsBlock
        }
        cancelClick={cancelClick}
        saveClick={() => (analyzeModalPolicyOwner ? setConfirmModal(true) : saveClickProposal())}
        deleteClick={() => {
          setDeleteIntermediate(true);
        }}
        index={0}
        deleteBtn={
          formsBlock && person?.role?.toUpperCase() !== POLICYOWNER && !disabled
        }
        insurance={proposal}
      />
      <Grid container item={true} justify="center">
        <Grid item xs={12} md={8}>
          <Grid item xs={12}>
            {analyze?.commissionedAgents?.map(
              (commissionedAgent, idx) =>
                commissionedAgent?.name?.length > 0 && (
                  <ShowData
                    id={`showDataIntermediate-${commissionedAgent.documentNumber}`}
                    key={`showDataIntermediate-${commissionedAgent.documentNumber}`}
                    data={commissionedAgent}
                    index={idx}
                    textTitle={'INTERMEDIÁRIO'}
                    deleteClick={() => {
                      setDeleteIntermediate(true);
                      setPerson(commissionedAgent);
                    }}
                    openClick={showClickAnalyze}
                    type={commissionedAgent.role.toUpperCase()}
                    block={commissionedAgent.role.toUpperCase() === BROKER}
                  />
                )
            )}
            {proposal?.commissionedAgents?.map(
              (commissionedAgent, idx) =>
                commissionedAgent?.name?.length > 0 && (
                  <ShowData
                    id={`showDataIntermediate-${commissionedAgent.documentNumber}`}
                    key={`showDataIntermediate-${commissionedAgent.documentNumber}`}
                    data={commissionedAgent}
                    index={idx}
                    textTitle={'INTERMEDIÁRIO'}
                    deleteClick={() => {
                      setDeleteIntermediate(true);
                      setPerson(commissionedAgent);
                    }}
                    openClick={showClickProposal}
                    type={commissionedAgent.role.toUpperCase()}
                    deleteBtn={!disabled}
                  />
                )
            )}
            <>
              {!disabled && (
                <AddData
                  id={`AddDataProposalIntermediates-${index}`}
                  text={ADD_INTERMEDIATE}
                  addClick={addClick}
                  error={errors?.intermediates?.required ? true : false}
                />
              )}
              {errors?.intermediates?.required && (
                <FormHelperText error>{errors.intermediates.required}</FormHelperText>
              )}
            </>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProposalIntermediates;

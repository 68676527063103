import { BROKER_MAX } from '../../../../../../shared/constants/formConstants';
import { BROKER } from '../../../../../../shared/constants/personConstants';
import {
  requiredValidator,
  validateDocument,
  validateMinCaracteres
} from '../../../../../../shared/helper/formValidators';
import utilManager from '../../../../../util/services/utilManager';
import { errorsActions } from '../../../../redux';

class ProposalIntermediatesManager {
  changeDocument = (document, intermediatesPersons, brokers, setPerson) => async (
    dispatch,
    getState
  ) => {
    let filter = intermediatesPersons?.filter(i => i.documentNumber.indexOf(document) >= 0);

    const {
      errors: { errors }
    } = getState();

    if (filter.length === 1) {
      setPerson(filter[0]);
      if (filter[0]?.role?.toUpperCase() === BROKER && brokers?.length === 3) {
        dispatch(
          errorsActions.setErrors({
            ...errors,
            name: BROKER_MAX,
            insureds: { required: errors?.insureds?.required }
          })
        );
      }
    } else {
      setPerson({ documentNumber: document });
    }
  };

  getCommissionedAgentsUpdated = (proposal, person) => {
    if (proposal?.commissionedAgents && proposal?.commissionedAgents.length > 0) {
      return proposal?.commissionedAgents.concat([person]);
    } else {
      return [person];
    }
  };

  validateErrors = (name, documentNumber) => {
    const errors = {};
    errors.name = validateMinCaracteres(name, 5);
    errors.name = requiredValidator(name);
    errors.documentNumber = requiredValidator(documentNumber);
    errors.documentNumber = validateDocument(documentNumber, errors.documentNumber);
    return utilManager.validErrors(errors) ? null : errors;
  };
}

export default new ProposalIntermediatesManager();

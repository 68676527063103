import { EMPTY_STRING } from '../../../../../shared/constants/constants';
import { FILE_REQUIRED } from '../../../../../shared/constants/formConstants';
import { requiredValidator } from '../../../../../shared/helper/formValidators';
import utilManager from '../../../../util/services/utilManager';
import insuredManager from '../../../manager/insurance/proposal/insured/insuredManager';
import paymentManager from '../../../manager/insurance/proposal/payment/paymentManager';
import filesServiceManager from '../../../services/files/filesServiceManager';

class ProposalManager {
  formValidate = (proposal, quote) => {
    let errors = {};
    errors.insureds = {};
    errors.payment = {};
    errors.insureds.required = insuredManager.validateInsureds(proposal);
    errors.payment = paymentManager.validatePayment(proposal, quote);
    errors = this.validateComplianceLetter(errors, proposal);

    const validPayment = utilManager.validErrors(errors.payment.coverageConditions.correctionIndex)
      ? utilManager.validErrors(errors.payment.paymentCard.installments)
        ? utilManager.validErrors(errors.payment.paymentCard.installments)
        : false
      : false;

    return utilManager.validErrors(errors.insureds) && validPayment ? null : errors;
  };

  validateErrors = errors => {
    return utilManager.validErrors(errors.insureds) &&
      utilManager.validErrors(errors.payment.commission) &&
      utilManager.validErrors(errors.payment.coverageConditions)
      ? null
      : errors;
  };

  getInsuredAmount = coverages => {
    const insuredAmount =
      coverages?.length > 0 && coverages?.some(a => a)
        ? coverages?.reduce((a, b) => a + (b.insuredAmount.value || 0), 0)
        : 0;
    return insuredAmount;
  };

  validateComplianceLetter = (errors, proposal) => {
    errors.hasComplianceLetter = requiredValidator(proposal?.hasComplianceLetter, EMPTY_STRING);

    if (proposal?.hasComplianceLetter && !proposal?.complianceLetterFile) {
      errors.complianceLetterFile = requiredValidator(
        proposal?.complianceLetterFile,
        errors.complianceLetterFile,
        FILE_REQUIRED
      );
    }

    return errors;
  };

  getComplianceLetterFile = async proposal => {
    let fileInsurance = null;

    if (proposal?.hasComplianceLetter) {
      fileInsurance = await filesServiceManager.get(proposal?.ComplianceLetter);
    }

    return fileInsurance;
  };
}

export default new ProposalManager();

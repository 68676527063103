import makeStyles from '@material-ui/styles/makeStyles';

const Styles = makeStyles({
  card: {
    position: 'relative',
    margin: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 4px #00000029',
    borderRadius: 15
  },
  activated: {
    border: '1px solid #f26623'
  },
  container: {
    position: 'relative'
  }
});

export default Styles;

import exposureServiceManager from '../../services/exposure/exposureServiceManager';
import * as quizActions from '../quizActions';

export const getExposure = (documentNumber, insuredAmount) => async dispatch => {
  try {
    return await exposureServiceManager.getExposure(documentNumber, insuredAmount);
  } catch {
    dispatch(quizActions.setError(true));
  }
};

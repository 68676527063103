import { makeStyles } from '@material-ui/core/styles';

const Styles = () =>
  makeStyles({
    gridContainer: {
      width: '100%',
      height: '100%',
      display: 'grid'
    }
  });

export default Styles;

export const Status = [
  { status: 'Approved', class: '#77CCC2', iconText: 'Aprovado', image: 'analyze-icon' },
  { status: 'Denied', class: '#C3284C', iconText: 'Recusada', image: 'analyze-icon' },
  {
    status: 'UnderAnalysis',
    class: '#A0B3BE',
    iconText: 'Em análise',
    image: 'analyze-icon'
  },
  { status: 'Pending', class: '#FBC775', iconText: 'Pendente', image: 'quote-icon' },
  { status: 'Expired', class: '#C3284C', iconText: 'Expirado', image: 'analyze-icon' },
  { status: 'QuoteOpened', class: '#FBC775', iconText: 'Em Aberto', image: 'quote-icon' },
  { status: 'ProposalOpened', class: '#FBC775', iconText: 'Em Aberto', image: 'proposal-icon' },
  {
    status: 'Transmitted',
    class: '#77CCC2',
    iconText: 'Transmitida',
    image: 'proposal-icon'
  },
  { status: 'Issued', class: '#77CCC2', iconText: 'Emitida', image: 'policy-icon' }
];

export const InsuranceSteps = [
  { step: 'Analyze', text: 'ANÁLISE DE CRÉDITO' },
  { step: 'Quote', text: 'COTAÇÃO' },
  { step: 'Proposal', text: 'PROPOSTA' },
  { step: 'Policy', text: 'APÓLICE' }
];

export const InsuranceStepsTitle = [
  { step: 'analyze', text: 'Aprovado' },
  { step: 'quote', text: 'Limite Aprovado: ' }
];

export const SubscriptionStatus = {
  APPROVED: 'Approved',
  DENIED: 'Denied',
  UNDERANALYSIS: 'UnderAnalysis'
};

export const ANALYZE = 'analyze';
export const QUOTE = 'quote';
export const PROPOSAL = 'proposal';
export const STEP_POLICY = 'policy';
export const TRANSMITTED = 'Transmitted';

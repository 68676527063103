/* eslint-disable no-unused-expressions */
import { Box, Checkbox, FormHelperText, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../../../../shared/components/loading/Loading';
import Modal from '../../../../../shared/components/modal/Modal';
import InternalComponentGoBack from '../../../../../shared/components/modal/modalInfo/InternalComponentGoBack';
import ModalInfo from '../../../../../shared/components/modal/modalInfo/ModalInfo';
import {
  APPROVAL_TEMPLATE,
  DISABLE_ALL_FIELDS,
  DISABLE_SOME_FIELDS,
  EMPTY_STRING,
  GO_BACK, SAVE_AND_GO_BACK,
  STATUS_TITLE_SUBSCRIPTION
} from '../../../../../shared/constants/constants';
import {
  APPROVED_IMAGE,
  DENIED_IMAGE,
  GOBACK_IMAGE,
  UNDERANALYSIS_IMAGE
} from '../../../../../shared/constants/imagesconstants';
import { ERR_INCORRECT_FORM_DATA } from '../../../../../shared/constants/modalMessagesConstants';
import { MESSAGE_SAME_DOCUMENT } from '../../../../../shared/constants/personConstants';
import { routes } from '../../../../../shared/constants/routes';
import { StatusModalTextButton } from '../../../../../shared/enums/documentEnums';
import { QUOTE, SubscriptionStatus, TRANSMITTED } from '../../../../../shared/enums/statusEnum';
import analyzeManager from '../../../manager/insurance/analyze/analyzeManager';
import quizManager from '../../../manager/quizManager';
import {
  analyzeActions,
  analyzeOperations,
  errorsActions,
  insuranceOperations,
  personsOperations, quizOperations, riskObjectOperations
} from '../../../redux';
import { useErrors } from '../../../redux/errors/errorsCustomHooks';
import {
  useAnalizePolicyHolder,
  useAnalyze
} from '../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../redux/insurance/policy/policyCustomHooks';
import { useProposal } from '../../../redux/insurance/proposal/proposalCustomHooks';
import { useQuote } from '../../../redux/insurance/quote/quoteCustomHooks';
import * as quizActions from '../../../redux/quizActions';
import { useHasError, useLoading, useSteps } from '../../../redux/quizCustomHooks';
import FormClasses from '../classes/FormClasses';
import Footer from '../Footer';
import QuestionFormHeader from '../QuestionFormHeader';
import QuestionHeader from '../QuestionHeader';
import Expenses from './expenses/Expenses';
import PolicyHolder from './policyHolder/PolicyHolder';
import RiskLocation from './riskLocation/RiskLocation';

function getFormErrors(analyze, proposal, errors) {
  return analyzeManager.formValidate(analyze, proposal, errors);
}

const Analyze = ({ quizStep }) => {
  const classes = FormClasses();
  const dispatch = useDispatch();
  const analyze = useAnalyze();
  const quote = useQuote();
  const proposal = useProposal();
  const policy = usePolicy();
  const disabled =
    (analyze?.status === TRANSMITTED || policy?.id) ? DISABLE_ALL_FIELDS : quote?.id ? DISABLE_SOME_FIELDS : 0;
  const isLoading = useLoading();
  const hasError = useHasError();
  const policyHolder = useAnalizePolicyHolder();
  const errors = useErrors();
  const [goBack, setGoBack] = useState(false);
  const [openDenied, setOpenDenied] = useState(false);
  const [modalProps, setModalProps] = useState(quizManager.getInitialModalProps());
  const statusDescription = analyzeManager.getStatusDescription(analyze);
  const [modalStatus, setModalStatus] = useState(EMPTY_STRING);
  const [modalClosed, setModalClosed] = useState(false);
  const { insuranceId } = useParams();
  const history = useHistory();
  const steps = useSteps();
  const goToQuote =
    (insuranceId &&
      analyze?.status !== SubscriptionStatus.DENIED &&
      analyze?.status !== SubscriptionStatus.UNDERANALYSIS) ||
    !insuranceId;
  const showApprovedLimit =
    analyze?.id &&
    analyze?.underwritingStatus &&
    analyze?.underwritingStatus !== SubscriptionStatus.DENIED &&
    analyze?.underwritingStatus !== SubscriptionStatus?.UNDERANALYSIS;
  const statusSettings = {
    modalStatus: modalStatus,
    setModalStatus: setModalStatus,
    goBack: goBack,
    setGoBack: setGoBack,
    openDenied: openDenied,
    setOpenDenied: setOpenDenied
  };
  const exitButtonOnClick = async () => {
    analyzeManager.exit(statusSettings);
  };
  const modalSettings = {
    exitButtonOnClick: exitButtonOnClick,
    isLoading: isLoading,
    analyze: analyze
  };

  const scrollToTop = () => {
    document.getElementById('header')?.scrollIntoView();
  };

  const returnToHome = useCallback(async () => {
    dispatch(insuranceOperations.clearInsurance());
    history.push(routes.home.path);
  }, [dispatch, history]);

  const saveAndReturnToHome = async () => {
    batch(async () => {
      dispatch(quizActions.setLoading(true));
      await dispatch(insuranceOperations.put(true));
      dispatch(insuranceOperations.clearInsurance());
      history.push(routes.home.path);
    });
  };

  useEffect(() => {
    analyzeManager.getInitialErrorModal(hasError, modalProps.isOpen, setModalProps);

    analyzeManager.getInitialStatusModal(
      analyze,
      quote?.id,
      quizStep.step,
      statusSettings,
      modalClosed
    );

    if (modalClosed) {
      scrollToTop();
    }

    if (quizStep.step.saved) {
      dispatch(quizActions.setInitialStep());
      returnToHome();
    } else if (insuranceId && !quote?.id && !isLoading && !hasError) {
      dispatch(insuranceOperations.get(insuranceId));
    }

    if (modalStatus !== EMPTY_STRING) {
      scrollToTop();
    }
  }, [
    analyze,
    quote,
    modalStatus,
    insuranceId,
    modalClosed,
    isLoading,
    hasError,
    statusSettings,
    quizStep.step,
    modalProps.isOpen,
    dispatch,
    returnToHome
  ]);

  const continueClick = async () => {
    if (modalStatus === SubscriptionStatus.DENIED) {
      analyzeManager.continueDeniedClick(statusSettings);
    } else {
      setModalStatus(EMPTY_STRING);
      setModalClosed(true);
      dispatch(
        quizOperations.updateStep(quizStep.index, STATUS_TITLE_SUBSCRIPTION, statusDescription)
      );
      dispatch(analyzeActions.setAnalyze({ ...analyze, step: QUOTE }));
    }
  };

  const updatePolicyHolderEmail = async () => {
    dispatch(quizActions.setLoading(true));
    const emailId = await dispatch(personsOperations.setEmailPerson(policyHolder));
    dispatch(
      analyzeActions.setAnalyze({
        ...analyze,
        policyHolder: {
          ...analyze?.policyHolder,
          email: { ...analyze?.policyHolder.email, id: emailId }
        }
      })
    );
  };

  const createSubscription = async () => {
    const coverage = await dispatch(analyzeOperations.updatePlansAndGetCoverage());
    const riskObjectId = await dispatch(riskObjectOperations.create());
    dispatch(insuranceOperations.create(coverage, riskObjectId));
  };

  const submitSubscription = async update => {
    if (!getFormErrors(analyze, proposal, errors)) {
      await updatePolicyHolderEmail();
      if (!hasError) {
        if (!update) {
          await createSubscription();
        } else {
          dispatch(quizActions.setLoading(false));
          const newSteps = quizManager.updateSteps(
            steps,
            quizStep.index,
            STATUS_TITLE_SUBSCRIPTION,
            statusDescription
          );
          dispatch(analyzeActions.setAnalyze({ ...analyze, step: QUOTE }));
          dispatch(quizActions.setSteps(newSteps));
          scrollToTop();
        }
      }
    } else {
      if (
        getFormErrors(analyze, proposal, errors)?.policyHolder?.documentNumber ===
        MESSAGE_SAME_DOCUMENT
      ) {
        dispatch(
          analyzeActions.setAnalyze({
            ...analyze,
            policyHolder: {
              ...analyze?.policyHolder,
              documentNumber: policyHolder.documentNumber,
              name: EMPTY_STRING,
              email: EMPTY_STRING,
              personId: null
            }
          })
        );
      }
      setModalProps(quizManager.getModalProps(ERR_INCORRECT_FORM_DATA, false));
    }
    dispatch(errorsActions.setErrors(getFormErrors(analyze, proposal, errors)));
  };

  const approvedModal = analyzeManager.getModal(
    SubscriptionStatus.APPROVED,
    modalStatus,
    APPROVED_IMAGE,
    continueClick,
    modalSettings
  );

  const deniedModal = analyzeManager.getModal(
    SubscriptionStatus.DENIED,
    modalStatus,
    DENIED_IMAGE,
    continueClick,
    modalSettings
  );

  const analyzeModal = analyzeManager.getModal(
    SubscriptionStatus.UNDERANALYSIS,
    modalStatus,
    UNDERANALYSIS_IMAGE,
    returnToHome,
    modalSettings
  );

  const onClose = () => {
    setModalProps({ isOpen: false });
    if (hasError) {
      dispatch(quizActions.setError(false));
    }
  };

  const goBackCancelClick = async () => {
    analyzeManager.goBackCancelClick(statusSettings);
  };

  return (
    <>
      {isLoading && <Loading />}
      <Grid item xs={12} className={classes.container}>
        <QuestionHeader title="Análise de Crédito" />
        <Box id={0} key={0} xs={12} className={classes.form}>
          <QuestionFormHeader title={'Dados do Locatário'} />
          <Box pt={3}>
            <PolicyHolder disabled={disabled} />
          </Box>
        </Box>
        <Box id={1} key={1} xs={12} className={classes.form}>
          <QuestionFormHeader title={'Dados do Imóvel'} />
          <Box pt={3}>
            <RiskLocation disabled={disabled} />
          </Box>
        </Box>
        <Box id={2} key={2} xs={12} className={classes.form}>
          <QuestionFormHeader title={'Aluguel e Encargos'} showApprovedLimit={showApprovedLimit} />
          <Box pt={3}>
            <Expenses disabled={disabled} />
          </Box>
        </Box>
        <Box id="varacityBox" key="varacityBox" xs={12} className={classes.form}>
          <Grid container item justify="center">
            <Grid item xs={12} md={8}>
              <Box textAlign="left" fontSize={14} color="#4E596E" pb={4} pt={4}>
                <Checkbox
                  id="veracity"
                  value={analyze?.veracity}
                  checked={analyze?.veracity ?? false}
                  onChange={() =>
                    dispatch(
                      analyzeActions.setAnalyze({ ...analyze, veracity: !analyze?.veracity })
                    )
                  }
                  onBlur={e => {
                    dispatch(
                      errorsActions.setErrors({
                        ...errors,
                        veracity: analyzeManager.getVeracityError(e.target.checked)
                      })
                    );
                  }}
                  name="checkConfirm"
                  className={classes.checkbox}
                  disabled={disabled ? true : false}
                />
                Confirmo a veracidade dos dados e autorizo a consulta nos órgãos responsáveis
                {errors?.veracity && <FormHelperText error>{errors.veracity}</FormHelperText>}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {analyze?.id ? (
          <Footer
            titleButton="CONTINUAR PARA COTAÇÃO"
            titleGoBackButton={SAVE_AND_GO_BACK}
            onClick={() => (goToQuote ? submitSubscription(true) : {})}
            goBackClick={() => setGoBack(true)}
            documentName={analyze?.documentLetter}
            endpointRequest={`${window.env.RENTAL_SURETY}/Documents/download?DocumentName=${analyze?.documentLetter}&TemplateKey=${APPROVAL_TEMPLATE}`}
            show={true}
            textButton={StatusModalTextButton.APPROVED}
          />
        ) : (
          <Grid container item justify="center">
            <Footer
              id="footer-analyze"
              titleButton="SOLICITAR ANÁLISE DE CRÉDITO"
              titleGoBackButton={GO_BACK}
              onClick={() => submitSubscription()}
              goBackClick={() => setGoBack(true)}
            />
          </Grid>
        )}
        <Modal
          id={'modal'}
          icon={modalProps.icon}
          message={modalProps.message}
          actions={modalProps.actions}
          open={modalProps.isOpen}
          onClose={onClose}
        />
        {approvedModal}
        {deniedModal}
        {analyzeModal}
        <ModalInfo
          id={'modalGoBack'}
          open={goBack}
          exitButtonOnClick={exitButtonOnClick}
          image={GOBACK_IMAGE}
          blur
          showExitButton={true}
          children={
            <InternalComponentGoBack
              id={'internalComponentGoBack'}
              cancelClick={goBackCancelClick}
              goBackClick={saveAndReturnToHome}
              insurance={analyze}
              openDenied={openDenied}
            />
          }
        />
      </Grid>
    </>
  );
};
Analyze.propTypes = {
  quizStep: PropTypes.object.isRequired
};
export default Analyze;

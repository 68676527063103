import { useState } from 'react';
import { authManager } from './authManager.ig';

const userManager = authManager.getUserManager();

export const useLoginStatus = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  
  if (loggedIn) return true;

  authManager.getUser().then(user => {
    if (!user || user.expires_in <= 0) return userManager.signinRedirect();

    return setLoggedIn(true);
  });
};

import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutoCompleteForm from '../../../../../../shared/components/form/AutocompleteForm';
import CpfCnpjInput from '../../../../../../shared/components/form/CpfCnpjInput';
import { EMPTY_OBJECT, EMPTY_STRING } from '../../../../../../shared/constants/constants';
import { BROKER_MAX, REQUIRED } from '../../../../../../shared/constants/formConstants';
import {
  BROKER,
  ID_DOCUMENT_INPUT,
  PERSON_ROLES
} from '../../../../../../shared/constants/personConstants';
import { TRANSMITTED } from '../../../../../../shared/enums/statusEnum';
import { useIntermediates } from '../../../../../intermediatePerson/redux/intermediatePersonCustomHooks';
import intermediatePersonManager from '../../../../../intermediatePerson/service/intermediatePersonManager';
import utilManager from '../../../../../util/services/utilManager';
import proposalIntermediatesManager from '../../../../manager/insurance/proposal/intermediate/proposalIntermediatesManager';
import { errorsActions, intermediatePersonActions } from '../../../../redux';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import {
  useAnalyze,
  useAnalyzeBroker,
  useAnalyzePolicyOwner
} from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../redux/insurance/policy/policyCustomHooks';
import {
  useProposal,
  useProposalBrokers
} from '../../../../redux/insurance/proposal/proposalCustomHooks';
import personServiceManager from '../../../../services/persons/personsServiceManager';
import InsuredCardClasses from '../insured/classes/InsuredCardClasses';

const ProposalIntermediateCard = ({ person, setPerson, formsBlock }) => {
  const classes = InsuredCardClasses();
  const brokers = useProposalBrokers();
  const intermediates = useIntermediates();
  const leadPolicyOwner = useAnalyzePolicyOwner();
  const analyze = useAnalyze();
  const proposal = useProposal();
  const policy = usePolicy();
  const errors = useErrors();
  const [intermediatesPerson, setIntermediatesPerson] = useState([]);
  const broker = useAnalyzeBroker();
  const dispatch = useDispatch();
  const disabled = (analyze?.status === TRANSMITTED || policy?.id || formsBlock);

  useEffect(() => {
    (async () => {
      if (intermediates.length === 0) {
        const policyOwnersByBroker = await personServiceManager.getLinkedOrganizationsByBrokerId(
          broker?.personId
        );
        await utilManager.sleep(1e3);

        dispatch(intermediatePersonActions.setIntermediatePersons(policyOwnersByBroker));
        setIntermediatesPerson(policyOwnersByBroker);
      } else {
        const broker = analyze?.commissionedAgents?.find(
          c => c.role.toUpperCase() === BROKER && c.personId
        );
        const personFilters = intermediatePersonManager.getPersonsFilter(
          leadPolicyOwner,
          person,
          intermediates,
          broker,
          brokers
        );
        if (!utilManager.compareArrays(intermediatesPerson, personFilters)) {
          setIntermediatesPerson(personFilters);
        }
      }
    })();
  }, [
    analyze,
    intermediates,
    intermediatesPerson,
    broker,
    person,
    proposal,
    brokers,
    leadPolicyOwner,
    dispatch
  ]);

  const updateErrorsOnBlur = inputValue => {
    const newErrors = proposalIntermediatesManager.validateErrors(
      inputValue,
      person?.documentNumber
    );
    dispatch(
      errorsActions.setErrors({
        ...newErrors,
        insureds: { required: errors?.insureds?.required }
      })
    );
  };

  const clearPerson = () => {
    setPerson(EMPTY_OBJECT);
  };

  const changeDocument = document => {
    dispatch(
      proposalIntermediatesManager.changeDocument(document, intermediates, brokers, setPerson)
    );
  };

  const onChangeAutoComplete = value => {
    if (value) {
      setPerson({ ...person, role: value.key });
    }
    clearPerson();
  };

  const onChangeIntermediateName = value => {
    if (value) {
      if (value?.role?.toUpperCase() === BROKER && brokers?.length === 3) {
        clearPerson();
        dispatch(
          errorsActions.setErrors({
            ...errors,
            name: BROKER_MAX,
            insureds: { required: errors?.insureds?.required }
          })
        );
      } else {
        setPerson(value);
        if (errors?.documentNumber === REQUIRED) {
          dispatch(
            errorsActions.setErrors({
              ...errors,
              documentNumber: EMPTY_STRING
            })
          );
        }
      }
    } else {
      clearPerson();
    }
  };

  return (
    <>
      <Grid>
        <Grid container>
          <Grid item xs={8}>
            <Box pb={1} pl={5}>
              <p className={classes.header}>INTERMEDIÁRIO</p>
              <h2 className={classes.title}>Dados do Intermediário</h2>
            </Box>
          </Grid>
          <Grid container className={classes.Grid} spacing={6}>
            <Grid item xs={5}>
              <Box pl={5} pr={5} pb={4}>
                <CpfCnpjInput
                  value={person?.documentNumber}
                  onChange={value => {
                    setPerson({ ...person, documentNumber: value });
                    changeDocument(value);
                  }}
                  id={`intermediate-${ID_DOCUMENT_INPUT}`}
                  onBlur={updateErrorsOnBlur}
                  error={errors?.documentNumber}
                  label="CPF/CNPJ"
                  disabled={disabled}
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box pl={1}>
                <AutoCompleteForm
                  value={
                    person?.role
                      ? PERSON_ROLES.find(
                        personRole =>
                          personRole.key.toUpperCase().indexOf(person.role.toUpperCase()) >= 0
                      )
                      : null
                  }
                  options={PERSON_ROLES}
                  disabled={true}
                  id={'autocompleteTypePerson'}
                  onChange={onChangeAutoComplete}
                  label="Tipo"
                  attribute="name"
                  width={500}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={7} className={classes.formName}>
            <Box pl={5} pb={4} pr={5}>
              <AutoCompleteForm
                value={person}
                options={intermediatesPerson}
                disabled={disabled}
                id={'autocompleteNamePolicyOwner'}
                onChange={onChangeIntermediateName}
                error={errors?.name}
                label="Nome"
                attribute="name"
                width={891}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ProposalIntermediateCard.propTypes = {
  person: PropTypes.object,
  setPerson: PropTypes.func.isRequired,
  formsBlock: PropTypes.bool
};

export default ProposalIntermediateCard;

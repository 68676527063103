import { ThemeProvider } from '@material-ui/styles';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppClasses from './AppClasses';
import CallbackPage from './features/auth/views/CallbackPage';
import ErrorLogin from './features/auth/views/ErrorLogin';
import Login from './features/auth/views/Login';
import SignoutCallbackPage from './features/auth/views/SignoutCallbackPage';
import SilentRenewPage from './features/auth/views/SilentRenewPage';
import QuizPage from './features/quiz/views/QuizPage';
import RequestsPage from './features/requests/views/RequestsPage';
import SinisterPage from './features/sinister/views/SinisterPage';
import SubscriptionListPage from './features/requests/views/SubscriptionListPage';
import Welcome from './features/welcome/views/Welcome';
import ErrorBoundary from './shared/components/ErrorBoundary.ig';
import { routes } from './shared/constants/routes';
import withHeader from './shared/hoc/withHeader';
import WithHelperButton from './shared/hoc/withHelperButton';
import { muiTheme } from './shared/styles/theme';
import configureStore, { history } from './store';

const store = configureStore();

const App = function() {
  const classes = AppClasses();

  return (
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        <div className={classes.root}>
          <ConnectedRouter history={history}>
            <ErrorBoundary>
              <Switch>
                <Route exact path="/callback" component={CallbackPage} />
                <Route exact path="/error-login" component={ErrorLogin} />
                <Route exact path="/signout-callback" component={SignoutCallbackPage} />
                <Route exact path="/silent-renew" component={SilentRenewPage} />
                <Route path="*">
                  <Login>
                    <Switch>
                      <Route exact path={routes.home.path} render={withHeader(Welcome)} />
                      <Route
                        exact
                        path={routes.quiz.path}
                        render={WithHelperButton(withHeader(QuizPage))}
                      />
                      <Route
                        exact
                        path={`${routes.quiz.path}/${routes.insurance.params.insuranceId}`}
                        render={WithHelperButton(withHeader(QuizPage))}
                      />
                      <Route
                        exact
                        path={routes.requests.path}
                        render={WithHelperButton(withHeader(RequestsPage))}
                      />
                      <Route
                        exact
                        path={routes.sinister.path}
                        render={WithHelperButton(withHeader(SinisterPage))}
                      />
                      <Route
                        exact
                        path={routes.subscriptionList.path}
                        render={WithHelperButton(withHeader(SubscriptionListPage))}
                      />
                      <Redirect path="*" to={routes.home.path} />
                    </Switch>
                  </Login>
                </Route>
              </Switch>
            </ErrorBoundary>
          </ConnectedRouter>
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default App;

import { moneyFormat } from '../../../../../../shared/components/format/MoneyFormat';
import { BANK_SLIP, EMPTY_STRING, INVOICE } from '../../../../../../shared/constants/constants';
import { COMMISSION_REDUCE, REQUIRED } from '../../../../../../shared/constants/formConstants';
import {
  BROKER,
  COMMISSION_ID,
  POLICYOWNER
} from '../../../../../../shared/constants/personConstants';
import { MONTHLY } from '../../../../../../shared/constants/planConstants';
import { PROPOSAL } from '../../../../../../shared/enums/statusEnum';
import { requiredValidator } from '../../../../../../shared/helper/formValidators';
import { errorsActions } from '../../../../redux';
import calculateManager from '../../../calculate/calculateManager';

class PaymentManager {
  getCoverageConditionsErrors = errors => {
    return errors?.payment?.coverageConditions
      ? { ...errors }
      : errors?.payment
      ? { ...errors.payment, coverageConditions: {} }
      : errors
      ? { ...errors, payment: { coverageConditions: {} } }
      : {
          payment: { coverageConditions: {} }
        };
  };

  getPaymentCardErrors = errors => {
    return errors?.payment?.paymentCard
      ? { ...errors }
      : errors?.payment
      ? { ...errors.payment, paymentCard: {} }
      : errors
      ? { ...errors, payment: { paymentCard: {} } }
      : {
          payment: { paymentCard: {} }
        };
  };

  getNewErrors = errors => {
    return errors?.payment?.commission
      ? { ...errors }
      : errors?.payment
      ? { ...errors.payment, commission: {} }
      : errors
      ? { ...errors, payment: { commission: {} } }
      : {
          payment: { commission: {} }
        };
  };

  getNewPolicyOwners = (insurance, personId, value) => {
    return insurance.policyOwners.map(policyOwner =>
      policyOwner.personId === personId
        ? { ...policyOwner, commission: { ...policyOwner.commission, commissionPercentage: value } }
        : { ...policyOwner }
    );
  };

  getNewBrokers = (insurance, personId, value) => {
    return insurance.brokers.map(broker =>
      broker.personId === personId
        ? { ...broker, commission: { ...broker.commission, commissionPercentage: value } }
        : { ...broker }
    );
  };

  getNewCommissionedAgents = (commissionedAgents, commissionedAgent, premium, value) => {
    return commissionedAgents.map(person =>
      person.personId === commissionedAgent.personId
        ? {
            ...person,
            commission: {
              ...person.commission,
              commissionPercentage: value,
              commission: calculateManager.getCommissionByPercent(
                premium.commercialPremium,
                parseFloat(value)
              )
            }
          }
        : { ...person }
    );
  };

  convertComissionAmount = value => {
    return parseFloat(value) / 100;
  };

  deconvertComissionAmount = value => {
    return value * 100;
  };

  getParticipantCommission = (value, insurance) => {
    return ((value * insurance.plan.commission) / 100).toFixed(2);
  };

  getTotalCommission = (commissionedAgents, personId, value, insurance) => {
    const insuranceCommissionedAgents = personId
      ? commissionedAgents.filter(commissionedAgent => commissionedAgent.personId !== personId)
      : commissionedAgents;

    return (
      insuranceCommissionedAgents.reduce(
        (total, commissionedAgent) =>
          total +
          parseFloat(
            commissionedAgent?.commission?.commissionPercentage
              ? commissionedAgent?.commission?.commissionPercentage
              : 0
          ),
        0
      ) + this.convertComissionAmount(parseFloat(value))
    );
  };

  getBrokercommissionPercentage = (coBrokers, analyze, quote, leadBroker) => {
    if (analyze?.step === PROPOSAL) {
      return leadBroker?.commision?.defaultValue;
    } else if (coBrokers.length === 0) {
      return 1;
    } else {
      return quote?.plan?.commissionPercentage ? 1 : EMPTY_STRING;
    }
  };

  getBrokerCommission = (coBrokers, analyze, quote, leadBroker) => {
    if (analyze?.step === PROPOSAL) {
      return calculateManager.getCommissionByPercent(
        leadBroker?.commision?.defaultValue,
        parseFloat(quote?.plan?.commissionPercentage)
      );
    } else if (coBrokers.length === 0) {
      return calculateManager.getCommissionByPercent(
        quote?.plan?.commission,
        parseFloat(quote?.plan?.commissionPercentage)
      );
    } else {
      return 0;
    }
  };

  getCommissionedAgents = (analyze, quote, proposal) => {
    const policyOwners = analyze?.commissionedAgents
      ? analyze?.commissionedAgents?.filter(c => c.role.toUpperCase() === POLICYOWNER)
      : [];

    const coBrokers = proposal?.commissionedAgents
      ? proposal?.commissionedAgents?.filter(c => c.role.toUpperCase() === BROKER)
      : [];

    const leadBroker = analyze?.commissionedAgents?.find(c => c.role.toUpperCase() === BROKER);

    policyOwners.forEach(policyOwner => {
      policyOwner.commission.minValue = 0;
    });

    coBrokers.forEach(broker => {
      broker.role = BROKER;
    });

    return [
      ...policyOwners,
      ...coBrokers,
      {
        ...leadBroker,
        role: BROKER,
        commission: {
          ...leadBroker?.commission,
          commissionPercentage: this.getBrokercommissionPercentage(
            coBrokers,
            analyze,
            quote,
            leadBroker
          ),
          commission: this.getBrokerCommission(coBrokers, analyze, quote, leadBroker)
        }
      }
    ];
  };

  getContainReduceError = (commission, key) => {
    return commission[key]?.indexOf(COMMISSION_REDUCE) >= 0;
  };

  getCleanErrors = (newErrors, errors, idx) => {
    for (var key in errors?.payment?.commission) {
      if (this.getContainReduceError(errors.payment.commission, key) || key.indexOf(idx) >= 0) {
        newErrors.payment.commission[key] = EMPTY_STRING;
      }
    }
    return newErrors;
  };

  getCommissionReduceError = (newErrors, idx, totalCommission, errors) => async dispatch => {
    if (totalCommission === 1) {
      dispatch(errorsActions.setErrors(this.getCleanErrors(newErrors, errors, idx)));
    } else {
      dispatch(
        this.updateErrors(newErrors, idx, totalCommission !== 1 ? COMMISSION_REDUCE : EMPTY_STRING)
      );
    }
  };

  getPaymentMethodOptions = (hasPolicyOwner, quote) => {
    return quote?.plan?.type === MONTHLY
      ? [{ description: INVOICE, value: true }]
      : hasPolicyOwner
      ? [
          { description: BANK_SLIP, value: true },
          { description: INVOICE, value: false }
        ]
      : [{ description: BANK_SLIP, value: true }];
  };

  getNumberInstallmentsSelected = (optionsAutocomplete, text) => {
    const options = [];
    let numberInstallment = 0;
    for (var i = 0; i < optionsAutocomplete.length; i++) {
      options.push({
        text: optionsAutocomplete[i],
        number: i + 1
      });
    }

    options.forEach(option => {
      if (option.text === text) {
        numberInstallment = option.number;
      }
    });

    return numberInstallment;
  };

  getOptionsInstallmentsText = (totalValue, installments) => {
    return (
      'x de ' +
      moneyFormat(calculateManager.getDivision(totalValue, installments)) +
      ' sem juros. Total: ' +
      moneyFormat(totalValue)
    );
  };

  getOptionsInstallments = (maxInstallment, totalValue) => {
    return Array.from(Array(maxInstallment), (_, i) =>
      (i + 1).toString().concat(this.getOptionsInstallmentsText(totalValue, i + 1))
    );
  };

  updateErrors = (newErrors, idx, text) => async dispatch => {
    dispatch(
      errorsActions.setErrors({
        ...newErrors,
        payment: {
          ...newErrors.payment,
          commission: { ...newErrors.payment.commission, [`${COMMISSION_ID}${idx}`]: text }
        }
      })
    );
  };

  changeCommission = (commissionedAgent, verifySum, idx, value, newErrors) => dispatch => {
    const commission = value ? value : 0;
    if (commissionedAgent?.role?.toUpperCase() === POLICYOWNER) {
      verifySum(idx, commissionedAgent.personId, commission);
    } else {
      if (commission === 0) {
        dispatch(this.updateErrors(newErrors, idx, REQUIRED));
      } else {
        verifySum(idx, commissionedAgent.personId, value);
      }
    }
  };

  verifyCommissionReduce = errors => {
    let commission = {};

    for (var key in errors?.payment?.commission) {
      if (this.getContainReduceError(errors.payment.commission, key)) {
        commission[key] = COMMISSION_REDUCE;
      }
    }

    return JSON.stringify(commission) === JSON.stringify({}) ? null : commission;
  };

  validatePayment = (proposal, quote) => {
    const newErrors = { coverageConditions: {}, paymentCard: {} };

    newErrors.coverageConditions.correctionIndex = requiredValidator(
      proposal?.payment?.correctionIndex,
      EMPTY_STRING
    );

    newErrors.coverageConditions.correctionIndex = requiredValidator(
      proposal?.payment?.correctionIndex,
      EMPTY_STRING
    );

    newErrors.paymentCard.installments = requiredValidator(quote?.installments, EMPTY_STRING);

    return newErrors;
  };
}

export default new PaymentManager();

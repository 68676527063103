// Fixed Values
export const CPF_SIZE = 11;
export const CNPJ_SIZE = 14;
export const ZIP_CODE_SIZE = 9;
export const PHONE_SIZE = 10;
export const CELLPHONE_SIZE = 11;

// Errors
export const ERR_EXPENSES = 'Valor dos encargos não pode ser superior ao limite aprovado: ';

export const ERR_DATE_CONTRACT = {
  LIMIT_MAXIMUM_DAYS_CONTRACT: 1826,
  LIMIT_MINIMUM_DAYS_CONTRACT: 365,
  ERR_MINIMUM_CONTRACT: 'A vigência do contrato deve ser maior ou igual a 12 meses.',
  ERR_MAXIMUM_CONTRACT: 'A vigência do contrato deve ser menor ou igual a 60 meses.'
};

export const ERR_DATE_INSURANCE = {
  LIMIT_MINIMUM_DAYS_INSURANCE: 182,
  ERR_MINIMUM_INSURANCE: 'A vigência do seguro deve ser maior ou igual a 6 meses.',
  ERR_INITIAL_VALIDITY: 'A data de início do seguro deve ser superior ou igual a de análise',
  ERR_DIFFERENT_CONTRACT_LEASE: 'O início da vigência do seguro deve ser igual a do contrato'
};

export const REQUIRED = 'Campo Obrigatório';
export const COVERAGE_MAXIMUM = 'Múltiplo máximo é de ';
export const COVERAGE_MINIMUM = 'Múltiplo mínimo é de ';
export const FILE_REQUIRED = 'Carta de adimplência é obrigatória quando a locação esta em vigor';
export const COMMISSION_REDUCE = 'A soma das participações deve ser de 100%.';
export const BROKER_MAX = 'Quantidade máxima de co-corretoras atingida.';
export const MAX_PERCENTAGE = 99.9;

import { useSelector } from 'react-redux';

export const useQuote = () => {
  return useSelector(state => state.insurance?.quote);
};

export const useInstallments = () => {
  return useSelector(state => state.insurance?.quote?.installments);
};

export const mapperToRentalAgreement = quote => {
  return {
    id: quote?.rentalAgreementId,
    startLeaseContract: quote?.startLeaseContract,
    endLeaseContract: quote?.endLeaseContract
  };
};

import { EMPTY_STRING } from './constants';

export const PERSON_ROLES = [
  { name: 'Estipulante', key: 'PolicyOwner' },
  { name: 'Corretor', key: 'Broker' }
];

export const ROLE_POLICYOWNER = 'Estipulante';

export const INTERMEDIATEPERSONINITIAL = [
  {
    personId: EMPTY_STRING,
    name: EMPTY_STRING,
    documentNumber: EMPTY_STRING,
    role: EMPTY_STRING,
    commission: {
      defaultValue: 0,
      maxValue: 0,
      minValue: 0
    }
  }
];

export const BROKER = 'BROKER';
export const POLICYOWNER = 'POLICYOWNER';
export const POLICYHOLDER = 'POLICYHOLDER';
export const INSURED = 'INSURED';
export const SOLIDARY = 'SOLIDARY';
export const NAME_ID = 'name';
export const ID_DOCUMENT_INPUT = 'documentNumber';
export const ID_NAME_INPUT = 'name';
export const ID_EMAIL_INPUT = 'email';
export const ID_PHONE_INPUT = 'phoneNumber';
export const ID_INHABITED_INPUT = 'inhabited';
export const ID_INCOME_INPUT = 'income';
export const ID_COMPOSEINCOME_INPUT = 'composeIncome';
export const INTERMEDIATE = 'intermediate-';
export const INTERMEDIATE_PERSON_IMAGE = '/assets/images/intermediate.png';
export const SOLIDARY_TITLE = 'LOCATÁRIO SOLIDÁRIO';
export const INSURED_TITLE = 'LOCADOR';
export const ADD_MORE_SOLIDARY = 'Adicionar mais um solidário';
export const ADD_SOLIDARY = 'Incluir Solidario';
export const ADD_HOLDER = 'Incluir Locador';
export const ADD_MORE_INSURED = 'Adicionar mais um locador';
export const ADD_INTERMEDIATE = 'Adicionar mais um intermediário';
export const INSURED_ID = 'Insured';
export const BENEFICIARY_ID = 'Beneficiary';
export const SOLIDARY_ID = 'solidary';
export const POLICYHOLDER_ID = 'policyHolder';
export const POLICYOWNER_ID = 'PolicyOwner';
export const EMPTY_INSURED = {
  name: EMPTY_STRING,
  email: EMPTY_STRING,
  phone: EMPTY_STRING,
  documentNumber: EMPTY_STRING,
  personId: null
};
export const EMPTY_SOLIDARY = {
  documentNumber: EMPTY_STRING,
  name: EMPTY_STRING,
  email: EMPTY_STRING,
  personId: null,
  main: false
};
export const MESSAGE_SAME_DOCUMENT = 'Documento deve ser diferente entre participantes';
export const SOLIDARIES_TITLE = 'Solidários';
export const COMMISSION_ID = 'commissionPercentage-';

import { Box, Grid, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { moneyFormat } from '../../../../../../../shared/components/format/MoneyFormat';
import { EMPTY_GUID, EMPTY_STRING, HUNDRED_MULTIPLIER } from '../../../../../../../shared/constants/constants';
import calculateManager from '../../../../../manager/calculate/calculateManager';
import paymentManager from '../../../../../manager/insurance/proposal/payment/paymentManager';
import { useAnalyze } from '../../../../../redux/insurance/analyze/analyzeCustomHooks';
import { useProposal } from '../../../../../redux/insurance/proposal/proposalCustomHooks';
import { useQuote } from '../../../../../redux/insurance/quote/quoteCustomHooks';
import PaymentClasses from '../classes/PaymentClasses';

const Commission = () => {
  const dispatch = useDispatch();
  const classes = PaymentClasses();
  const quote = useQuote();
  const analyze = useAnalyze();
  const proposal = useProposal();
  const insuranceCommissionedAgents = paymentManager.getCommissionedAgents(analyze, quote, proposal);
  const [commissionedAgents, setCommissionedAgents] = useState([]);
  const commissionValue = quote?.plan?.id !== EMPTY_GUID ?
    moneyFormat(quote?.plan?.commission)
    : EMPTY_STRING;

  useEffect(() => {
    if (commissionedAgents.length !== insuranceCommissionedAgents.length) {
      setCommissionedAgents(insuranceCommissionedAgents);
    }
  }, [quote, commissionedAgents, insuranceCommissionedAgents, dispatch]);

  return (
    <Paper className={classes.rightCards}>
      <Grid item xs={12}>
        <Box pt={3} px={3} py={3} className={classes.titleCards}>
          Comissão Total <br />
          <Box pt={3}>
            <span className={classes.name}>{(calculateManager.getMultiply(quote?.plan?.commissionPercentage ?? 0, HUNDRED_MULTIPLIER)).toString()}% - {commissionValue}</span>
          </Box>
        </Box>
      </Grid>
    </Paper>
  );
};

export default Commission;

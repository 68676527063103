import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuote } from '../../../../features/quiz/redux/insurance/quote/quoteCustomHooks';
import { useLoading } from '../../../../features/quiz/redux/quizCustomHooks';
import InternalInfoHeader from '../../../../features/quiz/views/questions/analyze/cardsAnalyzeResult/InternalInfoHeader';
import Loading from '../../../../shared/components/loading/Loading';
import { CANCEL, GO_BACK, SAVE_AND_GO_BACK } from '../../../constants/constants';

const useStyles = makeStyles({
  gridButton: {
    position: 'absolute',
    bottom: 20,
    width: '100%'
  },
  text: {
    font: 'normal normal normal 16px/24px Open Sans',
    letterSpacing: 0,
    color: '#5F6675',
    textAlign: 'justify',
    height: '55%'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    fontFamily: 'Open Sans',
    borderRadius: 30,
    fontSize: 12,
    textTransform: 'none',
    fontWeight: 'normal',
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none'
    }
  },
  buttonCardSelect: {
    background: '#FD5D2B',
    color: '#FFFFFF',
    minWidth: 155,
    marginLeft: 25
  },
  buttonCardCancel: {
    background: 'transparent',
    color: '#FD5D2B',
    marginLeft: 115,
    letterSpacing: 1.2,
    fontWeight: 'bold'
  }
});

const InternalComponentGoBack = ({ cancelClick, goBackClick, openDenied }) => {
  const classes = useStyles();
  const isLoading = useLoading();
  const quote = useQuote();

  return (
    <>
      {isLoading && <Loading />}
      <InternalInfoHeader title={"Voltar ao início?"} />
      <Box px={5} pt={10} justify="center" className={classes.text} overflow="auto">
        {quote?.id || openDenied ?
          "A solicitação atual está salva e pode ser consultada na seção \"Minhas Solicitações\""
          : "Ao voltar para a tela inicial o preenchimento do formulário atual será perdido"
        }
      </Box>
      <Grid item xs={12} className={classes.gridButton}>
        <Box px={3} py={3} display="flex" flexDirection="row-reverse">
          <Button
            id="btn-goBack"
            variant="contained"
            className={`${classes.button} ${classes.buttonCardSelect}`}
            onClick={() => goBackClick()}
          >
            {quote?.id && !openDenied ? SAVE_AND_GO_BACK : GO_BACK}
          </Button>
          <Button
            id="btn-cancel"
            variant="contained"
            className={`${classes.button} ${classes.buttonCardCancel}`}
            onClick={() => cancelClick()}
          >
            {CANCEL}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

InternalComponentGoBack.propTypes = {
  cancelClick: PropTypes.func,
  goBackClick: PropTypes.func,
  openDenied: PropTypes.bool.isRequired
};

export default InternalComponentGoBack;

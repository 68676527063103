import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import { EMPTY_STRING } from '../../constants/constants';
import { emailValidator, requiredValidator } from '../../helper/formValidators';
import InputClasses from './InputClasses';

export const validateEmailInput = (value, required) => {
  let msg = EMPTY_STRING;
  if (required) msg = requiredValidator(value, EMPTY_STRING);
  if (!msg) msg = emailValidator(value, EMPTY_STRING);
  return msg;
};

export default function EmailInput({
  value,
  onChange,
  onBlur,
  label,
  disabled,
  required,
  id,
  error
}) {
  const classes = InputClasses();
  const hasError = !!error;

  return (
    <FormControl className={classes.formControl}>
      {label && (
        <InputLabel error={hasError} required={required}>
          {label}
        </InputLabel>
      )}
      <Input
        id={id}
        error={hasError}
        value={value}
        onChange={e => {
          onChange(id, e.target.value);
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur(id, validateEmailInput(value, required));
          }
        }}
        disabled={disabled}
      />
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </FormControl>
  );
}

EmailInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.string
};

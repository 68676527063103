import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import { moneyFormat } from '../../../../../../../shared/components/format/MoneyFormat';
import calculateManager from '../../../../../manager/calculate/calculateManager';
import InstallmentClasses from '../classes/InstallmentClasses';

const Installment = ({ months, value }) => {
  const classes = InstallmentClasses();
  const installments = months === 0 ? months : calculateManager.getDivision(value, months);


  return (
    <Grid item xs={12} align="right" margin={1}>
      <Paper className={classes.card}>
        <Grid item className={classes.title}>
          PARCELAMENTO
        </Grid>
        <b className={classes.monthly}>
          {moneyFormat(installments)}/mês
        </b>
        <Grid item
          className={classes.installment}
        >
          {months} parcelas de {moneyFormat(installments)} <br /> Sem juros. Total {moneyFormat(value)}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Installment;

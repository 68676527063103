import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import HeaderPlansCard from '../../../quote/plans/card/HeaderPlansCard';
import BodyPaymentCard from './BodyPaymentCard';
import FooterPaymentCard from './FooterPaymentCard';

const PaymentCard = ({ installments, rentalPlan }) => {
  return (
    <>
      <Grid container justify="center" alignItems="center" direction="column">
        <HeaderPlansCard rentalPlan={rentalPlan} installments={installments} />
        <BodyPaymentCard rentalPlan={rentalPlan} />
        <FooterPaymentCard />
      </Grid>
    </>
  );
};

PaymentCard.propTypes = {
  installments: PropTypes.number.isRequired,
  rentalPlan: PropTypes.object.isRequired
};

export default PaymentCard;

export const SET_QUOTE = 'insurance/SET_QUOTE';
export const SET_INITIAL_STATE_QUOTE = 'insurance/SET_INITIAL_STATE_QUOTE';

export const setQuote = quote => ({
  type: SET_QUOTE,
  payload: quote
});

export const setInitialStateQuote = () => ({
  type: SET_INITIAL_STATE_QUOTE
});

import { Box, Grid, IconButton, Paper, Radio } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { moneyFormat } from '../../../../../../../shared/components/format/MoneyFormat';
import InternalComponentCoverage from '../../../../../../../shared/components/modal/modalInfo/InternalComponentCoverage';
import ModalInfo from '../../../../../../../shared/components/modal/modalInfo/ModalInfo';
import {
  BASE_IMG,
  COVERAGES_IMAGES,
  COVERAGE_DEFAULT_IMAGE
} from '../../../../../../../shared/constants/coverageConstants';
import ListCoverageClasses from '../classes/ListCoverageClasses';

const ListCoverage = ({ coverage, coveragesSettings, setPrecedingCoverageSelected, option }) => {
  const [openModal, setOpenModal] = useState(false);
  const classes = ListCoverageClasses();
  const coverageImage = COVERAGES_IMAGES.find(c => c.name === coverage.name);
  const img = coverageImage ? coverageImage.img : COVERAGE_DEFAULT_IMAGE;

  const helpCoverageClick = () => {
    setOpenModal(true);
  };

  const exitButtonOnClick = () => {
    setOpenModal(false);
  };

  const changeSelectCoverage = planCoverage => {
    setPrecedingCoverageSelected(coveragesSettings.coverageSelected);
    coveragesSettings.setCoverageSelected(planCoverage);
  };

  return (
    <>
      <ModalInfo
        id="ModalInfo-ListCoverage"
        open={openModal}
        children={<InternalComponentCoverage coverage={coverage} cancelClick={exitButtonOnClick} />}
        image={`${BASE_IMG}${img}.png`}
        blur={false}
        showExitButton
        exitButtonOnClick={exitButtonOnClick}
      />
      <Grid item xs={12}>
        <Box pb={3}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={1}>
                <Box pt={4} textAlign={'center'}>
                  <img
                    className={classes.imgCoverage}
                    alt={'Cobertura'}
                    src={`${process.env.PUBLIC_URL}/assets/images/coverages_icons/${coverage.icon}.svg`}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <p className={classes.fontTitle}>
                    {' '}
                    {coverage.basicCoverage ? 'COBERTURA BÁSICA' : 'COBERTURA ADICIONAL'}
                  </p>
                  <p className={classes.descriptionCoverage}>
                    {coverage.name}
                    <IconButton id="btn-info-coverage" color="primary" onClick={helpCoverageClick}>
                      <HelpIcon className={classes.icon} />
                    </IconButton>
                  </p>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box>
                  <p className={classes.fontTitle}>VALOR DA COBERTURA</p>
                  <p className={classes.description}>{moneyFormat(coverage.grossPremium)}</p>
                </Box>
              </Grid>
              {/* <Grid item xs={2}>
                <Box pl={3}>
                  <p className={classes.fontTitle}>FRANQUIA</p>
                  <p className={classes.description}>
                    {coverage.franchise > 0 ? moneyFormat(coverage.franchise) : 'Sem franquia'}
                  </p>
                </Box>
              </Grid> */}
              <Grid item xs={2}>
                <Box>
                  <p className={classes.fontTitle}>PRÊMIO LÍQUIDO</p>
                  <p className={classes.description}>{moneyFormat(coverage.commercialPremium)}</p>
                </Box>
              </Grid>
              {option && (
                <Grid item xs={1}>
                  <Radio
                    id="radio-Coverage"
                    className={classes.radio}
                    checked={coveragesSettings.coverageSelected.id === coverage.id ? true : false}
                    onChange={() => changeSelectCoverage(coverage)}
                    value={coverage.id}
                    name="radio-button-demo"
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </>
  );
};

ListCoverage.propTypes = {
  coverage: PropTypes.object.isRequired,
  coveragesSettings: PropTypes.object,
  setPrecedingCoverageSelected: PropTypes.func,
  option: PropTypes.bool
};

export default ListCoverage;

import { AppBar, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { insuranceOperations } from '../../../features/quiz/redux';
import { LIST_HEADER } from '../../constants/constants';
import { routes } from '../../constants/routes';
import HeaderClasses from './classes/HeaderClasses';
import { useUser } from './redux/userCustomHooks';
import UserData from './UserData';

const Header = function () {
  const classes = HeaderClasses()();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();

  const changeMenuItem = (path) => {
    dispatch(insuranceOperations.clearInsurance());
    history.push(path);
  };

  return (
    <>
      {user.personId && (
        <Grid item xs={12} id="header" className={classes.container}>
          <AppBar direction="row" position="static" className={classes.root}>
            <Box pl={10} pt={2}>
              <img alt="Logo Pottencial" src={'/assets/images/logo_pottencial.svg'} />
            </Box>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              item
              xs={11}
              xl={9}
            >
              <Grid id="container" container item xs={12} md={6} lg={5}>
                <Grid container direction="row" item >
                  {LIST_HEADER.map(({ id, description, path }) => (
                    <Box key={`menuItem-${id}`} id={`menuItem-${id}`} px={3} py={2} className={`${classes.menu} 
                    ${history.location.pathname === path ||
                        (history.location.pathname.indexOf(routes.quiz.path) >= 0 && path === routes.home.path)
                        ? classes.menuEnabled : classes.menuDisabled}`}
                      onClick={() => { changeMenuItem(path) }}>
                      {description}
                    </Box>
                  ))}
                </Grid>
              </Grid>
              <UserData user={user} />
            </Grid>
          </AppBar>
        </Grid>
      )}
    </>
  );
};

export default Header;

export const STEPS = [
  {
    id: 1,
    title: 'Análise de Crédito',
    description: 'Inclusão de análise de crédito',
    statusTitle: 'Analisar',
    statusDescription: 'Preencha os dados de análise',
    activated: true,
    completed: false,
    submitted: false,
    saved: false,
    name: 'analyze'
  },
  {
    id: 2,
    title: 'Cotação',
    description: 'Inclusão de cotação',
    statusTitle: 'Cotar',
    statusDescription: 'Selecione as coberturas',
    activated: false,
    completed: false,
    submitted: false,
    saved: false,
    name: 'quote'
  },
  {
    id: 3,
    title: 'Proposta',
    description: 'Proposta',
    statusTitle: 'Emitir',
    statusDescription: 'Confira e emita a proposta',
    activated: false,
    completed: false,
    submitted: false,
    saved: false,
    name: 'proposal'
  }
];
export const POLICY = {
  title: 'Apólice',
  statusTitle: 'Emitida',
  statusDescription: 'Apólice emitida em '
};

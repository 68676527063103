import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useUser } from '../../../shared/components/header/redux/userCustomHooks';
import Loading from '../../../shared/components/loading/Loading';
import SinisterPageClasses from './classes/SinisterPageClasses';

const SinisterPage = () => {
  const user = useUser();
  const classes = SinisterPageClasses()();

  return (
    <>
      <Grid container direction="row" item className={classes.gridContainer}>
        {user.personId === null && <Loading />}
        {user.personId && (
          <Grid container alignItems="center" spacing={4} key="quiz">
            <Grid item xs={12} md={8}>
              <h1>SINISTRO</h1>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SinisterPage;

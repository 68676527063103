import { makeStyles } from '@material-ui/core';

const Styles = makeStyles(theme => ({
  wrap: {
    backgroundColor: 'rgba(151,158,169, 0.1)',
    zIndex: 10,
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0
  },
  dot1: {
    backgroundColor: '#f8b144',
    animation: `$dots 0.2s ${theme.transitions.easing.easeInOut}`
  },
  dot2: {
    backgroundColor: '#fd742b',
    animation: `$dots 0.4s ${theme.transitions.easing.easeInOut}`
  },
  dot3: {
    backgroundColor: '#fd742b',
    animation: `$dots 0.6s ${theme.transitions.easing.easeInOut}`
  },
  dot4: {
    backgroundColor: '#d35b08',
    animation: `$dots 0.8s ${theme.transitions.easing.easeInOut}`
  },
  dot: {
    display: 'inline-block',
    width: 15,
    height: 15,
    borderRadius: '50%',
    marginRight: '.4em',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out'
  },
  loading: {
    position: 'absolute',
    left: '48%',
    top: '50%'
  },
  '@keyframes dots': {
    '50%': {
      opacity: 0,
      transform: 'scale(0.7) translateY(10px)'
    }
  }
}));

export default Styles;

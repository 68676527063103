import { EMPTY_STRING } from '../../../../shared/constants/constants';
import { MESSAGE_SAME_DOCUMENT } from '../../../../shared/constants/personConstants';
import { validateDocument } from '../../../../shared/helper/formValidators';
import personsServiceManager from '../../services/persons/personsServiceManager';

class PersonsManager {
  validDocument = (show, value, setDisabledForm) => {
    if (!show) {
      if (value && !validateDocument(value, EMPTY_STRING)) {
        setDisabledForm(false);
        return true;
      }
    }
    return false;
  };

  verifySameDocument = (value, proposal, policyHolder, analyzeStep) => {
    const policyHoldersWithSameDocument = proposal?.policyHolders?.filter(
      policyHolder => policyHolder.documentNumber === value
    );

    const insuredsWithSameDocument = proposal?.insureds?.filter(
      insured => insured.documentNumber === value
    );

    const err =
      policyHoldersWithSameDocument?.length > 0 ||
      insuredsWithSameDocument?.length > 0 ||
      (!analyzeStep && value === policyHolder.documentNumber)
        ? MESSAGE_SAME_DOCUMENT
        : EMPTY_STRING;
    return err;
  };

  getTenantsUpdated = async riskObject => {
    if (riskObject?.tenants?.length > 0) {
      let solidaries = [];
      await Promise.all(
        riskObject.tenants.map(async t => {
          const person = await personsServiceManager.getPersonById(t.personId);
          solidaries.push({
            ...t,
            name: person.name
          });
        })
      );
      return solidaries;
    }
    return [];
  };
}

export default new PersonsManager();

import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    marginTop: 10
  },
  title: {
    textAlign: 'left',
    letterSpacing: 0.6,
    color: '#575F6E',
    font: 'normal normal normal 12px/13px Open Sans'
  },
  active: {
    fontWeight: 'bold'
  },
  breadcrumb: {
    height: 60,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderBottom: '1px solid #E5E5E5'
  },
  icon: {
    color: '#F26522'
  },
  policyOwner: {
    color: '#F26522',
    font: 'normal normal 600 13px/13px Open Sans',
    letterSpacing: 0.65
  },
  text: {
    font: 'normal normal normal 13px/13px Open Sans',
    letterSpacing: 0.65,
    color: '#575F6E'
  },
  iconButton: {
    marginBottom: 3
  }
}));

export default Styles;

import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { CANCEL, READ_AND_ACCEPT } from '../../../../../../shared/constants/constants';

const useStyles = makeStyles({
  gridButton: {
    position: 'absolute',
    bottom: 20,
    width: '100%'
  },
  button: {
    fontFamily: 'Open Sans',
    borderRadius: 30,
    fontSize: 12,
    textTransform: 'none',
    fontWeight: 'normal',
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none'
    }
  },
  buttonCardSelect: {
    background: '#FD5D2B',
    color: '#FFFFFF',
    minWidth: 220,
    marginLeft: 25
  },
  buttonCardCancel: {
    background: 'transparent',
    color: '#FD5D2B',
    marginLeft: 115,
    letterSpacing: 1.2,
    fontWeight: 'bold'
  }
});

const InternalFooterAcceptModal = ({ continueClick, closeClick }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} className={classes.gridButton}>
        <Box px={3} py={3} display="flex" flexDirection="row-reverse">
          <>
            <Button
              id="btn-continue"
              variant="contained"
              onClick={continueClick}
              className={`${classes.button} ${classes.buttonCardSelect}`}
            >
              {READ_AND_ACCEPT}
            </Button>
            <Button
              id="btn-cancel"
              variant="contained"
              className={`${classes.button} ${classes.buttonCardCancel}`}
              onClick={closeClick}
            >
              {CANCEL}
            </Button>
          </>
        </Box>
      </Grid>
    </>
  );
};

InternalFooterAcceptModal.propTypes = {
  continueClick: PropTypes.func.isRequired,
  closeClick: PropTypes.func.isRequired
};

export default InternalFooterAcceptModal;

import { useSelector } from 'react-redux';
import { nameFormatCapitalize } from '../../../../../shared/components/format/NameFormat';
import { BROKER } from '../../../../../shared/constants/personConstants';

export const getBeneficiariesPercent = beneficiaries => {
  return beneficiaries?.map(function(b) {
    return { ...b, percentage: 1 / beneficiaries.length };
  });
};

export const useProposal = () => {
  return useSelector(state => state.insurance?.proposal);
};

export const useProposalCommissionedAgentsIndex = () => {
  return useSelector(state => state.insurance?.proposal?.commissionedAgents)?.length;
};

export const useProposalBrokers = () => {
  return useSelector(state =>
    state.insurance?.proposal?.commissionedAgents?.filter(
      c => c.personId !== null && c.role.toUpperCase() === BROKER
    )
  );
};

export const usePorposalInsuredsNames = () => {
  const insureds = useSelector(state => state.insurance?.proposal?.insureds);
  if (insureds && insureds.length > 0) {
    const insuredsNames = insureds.map(function(i) {
      return nameFormatCapitalize(i.name);
    });
    return insuredsNames.toString().replace(/,/g, ' e ');
  }

  return null;
};

export const useCreateProposalRequest = (analyze, userId, quote, proposal) => {
  return {
    insuranceId: analyze?.id,
    quoteId: quote?.id,
    documentPeriodStart: quote?.startInsuranceContract,
    documentPeriodEnd: quote?.endInsuranceContract,
    userId: userId,
    payment: proposal.payment,
    participants: proposal?.insureds?.concat(getBeneficiariesPercent(proposal?.beneficiaries))
  };
};

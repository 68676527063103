import { Box, Grid, IconButton, Paper } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PropTypes from 'prop-types';
import React from 'react';
import requestPageManager from '../../../features/requests/requestPageManager';
import { getFormatCpfOrCnpj } from '../form/CpfCnpjInput';
import InsuranceItemClasses from '../classes/InsuranceItemClasses';

const InsuranceItem = ({ insuranceView, onClickInsuranceItem }) => {
  const classes = InsuranceItemClasses(
    requestPageManager.gettypeInsuranceView(insuranceView.status, insuranceView.step).class
  )();

  return (
    <>
      <Grid item xs={12}>
        <Box pb={3}>
          <Paper className={`${classes.paper} ${classes.borderCard}`}>
            <Grid container>
              <Grid item xs={1}>
                <Box pt={2} pb={2} textAlign={'center'}>
                  <img
                    className={classes.imgStatus}
                    alt={'Etapa'}
                    src={`${process.env.PUBLIC_URL}/assets/images/status_icons/${
                      requestPageManager.gettypeInsuranceView(
                        insuranceView.status,
                        insuranceView.step
                      ).image
                    }.svg`}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box fontSize={12}>
                  <p className={classes.fontDate}>
                    {requestPageManager.getConvertDate(insuranceView.createdAt)}
                  </p>
                  <p className={classes.fontDescription}>
                    {
                      requestPageManager.gettypeInsuranceView(
                        insuranceView.status,
                        insuranceView.step
                      ).titleText
                    }
                  </p>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box pt={2} fontSize={14}>
                  <p className={classes.fontDate}>
                    {requestPageManager.sizeName(insuranceView.policyHolder.name, false)}
                  </p>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box pt={2} fontSize={14}>
                  <p className={classes.fontDate}>
                    {getFormatCpfOrCnpj(insuranceView.policyHolder.documentNumber)}
                  </p>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box pt={2} fontSize={14}>
                  <p className={classes.fontDate}>
                    {requestPageManager.sizeName(
                      insuranceView.policyOwner ? insuranceView.policyOwner.name : 'Não Possui',
                      true
                    )}
                  </p>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box pt={2} fontSize={14}>
                  <p className={classes.fontDate}>Aluguel</p>
                </Box>
              </Grid>
              <Grid container justify={'center'} alignItems={'center'} item xs={1}>
                <Grid item className={classes.iconStatus}>
                  <Box pt={1} className={classes.textStatus}>
                    {requestPageManager.gettypeInsuranceView(insuranceView.status).iconText}
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <Box pt={2.4} pl={5} fontSize={14}>
                  <IconButton onClick={() => onClickInsuranceItem(insuranceView.id)}>
                    <ArrowForwardIcon className={classes.arrowIcon} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </>
  );
};

InsuranceItem.propTypes = {
  insuranceView: PropTypes.object.isRequired
};

export default InsuranceItem;

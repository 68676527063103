import { EMPTY_OBJECT } from '../../../../../shared/constants/constants';
import * as proposalActions from '../proposal/proposalActions';

export const INITIAL_STATE = {
  hasComplianceLetter: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case proposalActions.SET_PROPOSAL:
      return {
        ...state,
        ...action.payload
      };
    case proposalActions.SET_INITIAL_STATE_PROPOSAL:
      return EMPTY_OBJECT;
    default:
      return state;
  }
};

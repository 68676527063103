import {
  EMPTY_GUID,
  EMPTY_STRING,
  RENT_EXPENSE
} from '../../../../../../shared/constants/constants';
import {
  COVERAGE_MAXIMUM,
  COVERAGE_MINIMUM
} from '../../../../../../shared/constants/formConstants';
import {
  MONTHLY,
  MULTIPLE_ID,
  PLAN_TYPES,
  TRADITIONAL
} from '../../../../../../shared/constants/planConstants';
import { isNaNValidator, requiredValidator } from '../../../../../../shared/helper/formValidators';
import utilManager from '../../../../../util/services/utilManager';
import { errorsActions } from '../../../../redux';
import {
  getAnalyzeBroker,
  getAnalyzePolicyOwner
} from '../../../../redux/insurance/analyze/analyzeSelectors';
import coverageManager from './coverageManager';

class PlansManager {
  bordersMultipleValidator = (coverage, textError) => {
    let msg = EMPTY_STRING;
    if (coverage?.multiple) {
      if (coverage.limitMultiple?.multipleMax < coverage.multiple) {
        msg = `${COVERAGE_MAXIMUM} ${coverage.limitMultiple?.multipleMax}x`;
      } else if (coverage.limitMultiple?.multipleMin > coverage.multiple) {
        msg = `${COVERAGE_MINIMUM} ${coverage.limitMultiple?.multipleMin}x`;
      }
    }

    return msg ? msg : textError;
  };

  validateMultiples = plan => {
    const errorsMultiple = {};
    if (plan?.coverages) {
      plan.coverages.forEach(coverage => {
        errorsMultiple[`${MULTIPLE_ID}${coverage.id}`] = requiredValidator(
          coverage.multiple,
          errorsMultiple[`${MULTIPLE_ID}${coverage.id}`]
        );

        errorsMultiple[`${MULTIPLE_ID}${coverage.id}`] = this.bordersMultipleValidator(
          coverage,
          errorsMultiple[`${MULTIPLE_ID}${coverage.id}`]
        );
      });
    }
    return utilManager.validErrors(errorsMultiple) ? null : errorsMultiple;
  };

  validatePlan = plan => {
    const errors = {};

    errors.commissionPercentage = requiredValidator(plan?.commissionPercentage, EMPTY_STRING);
    errors.commissionPercentage = !errors.commissionPercentage
      ? isNaNValidator(plan?.commissionPercentage, EMPTY_STRING)
      : errors.commissionPercentage;
    if (plan?.type === TRADITIONAL) {
      errors.multiples = this.validateMultiples(plan);
    }
    errors.id = requiredValidator(plan?.id, EMPTY_STRING, '** Favor selecionar um plano');
    return utilManager.validErrors(errors) ? null : errors;
  };

  getPlanTypesByPolicyOwner = (quote, analyze, policyOwner) => {
    let types = PLAN_TYPES;
    const totalExpenses = analyze?.expenses
      ?.filter(expense => expense.description !== RENT_EXPENSE)
      .reduce((total, expense) => total + expense.value, 0);
    const rental = analyze?.expenses?.find(expense => expense.description === RENT_EXPENSE)?.value;

    const traditionalType = [
      {
        id: 0,
        description: types?.find(type => type.description === TRADITIONAL).description
      }
    ];

    const monthlyType = [
      {
        id: 0,
        description: types?.find(type => type.description === MONTHLY).description
      }
    ];

    if (quote?.plan?.id !== EMPTY_GUID) {
      return quote?.plan?.type === TRADITIONAL ? traditionalType : monthlyType;
    }

    return totalExpenses >= rental * 2 || !policyOwner?.personId ? traditionalType : monthlyType;
  };

  getPlanSelected = async (quote, analyze, rentalPlan, coverages, coverageSelected) => {
    let coveragesPlan = coverageManager.getCoveragesByPlan(coverages, true);
    const broker = getAnalyzeBroker(analyze?.commissionedAgents);

    if (coverageManager.getCoveragesByPlan(coverages, false).length > 0) {
      coveragesPlan.push(coverageSelected);
    }

    return {
      id: rentalPlan.id,
      coverages: coveragesPlan,
      name: rentalPlan.title,
      multiple: quote?.plan.multiple,
      commissionPercentage: quote?.plan?.commissionPercentage
        ? quote?.plan?.commissionPercentage
        : broker.commission.defaultValue,
      commission: rentalPlan.commission,
      type: MONTHLY,
      grossPremium: rentalPlan.grossPremium,
      commercialPremium: rentalPlan.commercialPremium
    };
  };

  exitModalPlan = (quote, setOpenModalPlan, setCoverageSelected) => {
    if (coverageManager.getCoveragesByPlan(quote?.plan?.coverages, false)?.length > 0) {
      setCoverageSelected(coverageManager.getCoveragesByPlan(quote?.plan?.coverages, false)[0]);
    }

    setOpenModalPlan(false);
  };

  choosePlan = (setSelectedPlan, rentalPlan) => async (dispatch, getState) => {
    const {
      errors: { errors }
    } = getState();

    setSelectedPlan(rentalPlan.id);
    if (errors?.plan) {
      dispatch(
        errorsActions.setErrors({
          ...errors,
          plan: { ...errors.plan, id: EMPTY_STRING }
        })
      );
    }
  };

  getPlanType = (quote, analyze) => {
    if (quote?.plan?.type) {
      return quote?.plan?.type;
    } else if (getAnalyzePolicyOwner(analyze?.commissionedAgents)) {
      return MONTHLY;
    } else {
      return TRADITIONAL;
    }
  };
}

export default new PlansManager();

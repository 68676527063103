import makeStyles from '@material-ui/styles/makeStyles';
import { Orange, Gray } from '../../../../../../../shared/constants/colorConstants';

const Styles = makeStyles({
    card: {
        borderRadius: 8,
        direction: 'column',
        alignItems: 'center',
        padding: 5,
        textAlign: 'center',
        width: 185,
        margin: 15
    },
    monthly: {
        font: 'normal normal bold 600 Open Sans',
        fontSize: 19,
        letterspacing: -0.84,
        color: Orange,
    },
    title: {
        marginBottom: 8,
        color: Gray,
    },
    installment: {
        fontSize: 10,
        marginTop: 4,
        lineHeight: 1.5
    }
});

export default Styles;

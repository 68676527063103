import calculateServiceManager from '../../services/calculate/calculateServiceManager';

class CalculateManager {
  getCommissionByPercent = (value, percent) => {
    return Math.round((percent / 100) * parseFloat(value) * 100) / 100;
  };

  getReduce = (array, parameter) => {
    return array?.length > 0 && array?.some(a => a)
      ? array?.reduce((a, b) => a + (b[parameter] || 0), 0)
      : 0;
  };

  getDivision = (divider, dividend) => {
    return Math.floor((divider / dividend) * 100) / 100;
  };

  getMultiply = (value, multiplier) => {
    return value * multiplier;
  };

  getSubtraction = (value, sub) => {
    return value - sub;
  };

  getInsuredAmount = (
    monthlyPlans,
    multipleBasic,
    expenses,
    setinsuredAmountCalculated,
    getInsuredAmount,
    dispatch
  ) => {
    setinsuredAmountCalculated(false);

    if (monthlyPlans?.length > 0) {
      monthlyPlans.map(async (plan, indexPlan) => {
        if (plan.coverages.length > 0) {
          plan.coverages.map(async (coverage, indexCoverage) => {
            if (coverage.basicCoverage) {
              coverage.multiple = multipleBasic;
              coverage.insuredAmount = { min: { value: 0 } };
            }
            const response = await calculateServiceManager.getInsuredAmount(
              expenses,
              coverage,
              plan
            );
            dispatch(getInsuredAmount(response, indexPlan, indexCoverage));
          });
        } else {
          setinsuredAmountCalculated(true);
        }
      });
    }
  };
}

export default new CalculateManager();

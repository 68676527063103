import makeStyles from '@material-ui/styles/makeStyles';

const Styles = () =>
  makeStyles({
    formControl: {
      width: 65
    },
    input: {
      border: '1px solid #BEBEBE',
      borderRadius: 4,
      font: 'normal normal 600 14px/21px Poppins',
      letterSpacing: 0.13,
      color: '#393F4A',
      textAlign: 'center',
      paddingLeft: 10
    },
    error: {
      width: 200
    }
  });

export default Styles;

import makeStyles from '@material-ui/styles/makeStyles';

const Styles = makeStyles({
  icon: {
    color: '#c4dce1'
  },
  list: {
    paddingBottom: 150
  },
  description: {
    fontFamily: 'Open Sans',
    fontSize: 12,
    letterSpacing: 0.1,
    color: '#282F3C'
  },
  info: {
    width: 17,
    color: '#505661',
    fontWeight: 'normal'
  },
  text: {
    font: 'normal normal bold 14px/19px Open Sans',
    letterSpacing: 0.28,
    color: '#393F4A'
  },
  textComisison: {
    font: 'normal normal normal 14px/19px Open Sans',
    letterSpacing: 0,
    color: '#393F4A'
  },
  percent: {
    marginLeft: 40
  }
});

export default Styles;

import riskObjectService from './riskObjectsService';

class RiskObjectsServiceManager {
  create = async riskObject => {
    return await riskObjectService.create(riskObject);
  };

  get = async id => {
    return await riskObjectService.get(id);
  };

  put = async riskObject => {
    return await riskObjectService.put(riskObject);
  };

  putOrCreated = async (updateRiskObject, riskObject) => {
    if (updateRiskObject) {
      await this.put(riskObject);
    } else {
      updateRiskObject = await this.create(riskObject);
    }

    return updateRiskObject;
  };
}

export default new RiskObjectsServiceManager();

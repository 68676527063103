import { makeStyles } from '@material-ui/core/styles';
const openSans = 'Open Sans';
const orange = '#FD5D2B';

const Styles = makeStyles({
    header: {
        font: 'normal normal 600 11px Open Sans',
        letterSpacing: 1.2,
        color: '#393F4A',
        textTransform: 'uppercase',
        opacity: 1
    },
    title: {
        font: 'normal normal bold 30px Poppins',
        letterspacing: -0.84,
        color: '#282F3C',
        opacity: 1
    },
    lineTitle: {
        height: 3,
        border: 0,
        backgroundColor: '#f26623',
        width: '8%',
        marginLeft: 0,
        marginBottom: '5%'
    },
    text: {
        font: 'normal normal normal 14px/24px Open Sans',
        letterSpacing: 0,
        color: '#393F4A',
        textAlign: 'justify',
        height: '55%'
    },
    gridButton: {
        position: 'absolute',
        bottom: 20,
        width: '100%'
    },
    button: {
        fontFamily: openSans,
        borderRadius: 30,
        fontSize: 12,
        textTransform: 'none',
        fontWeight: 'normal',
        boxShadow: 'none',
        '&:hover, &:focus': {
            boxShadow: 'none'
        }
    },
    buttonCardSelect: {
        background: orange,
        color: '#FFF',
        minWidth: 155,
        marginLeft: 25
    },
    link: {
        font: 'italic normal 600 14px/24px Open Sans',
        letterSpacing: 0,
        color: '#FD5D2B',
        textDecoration: 'none'        
    }
});

export default Styles;

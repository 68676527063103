export const SET_ANALYZE = 'insurance/SET_ANALYZE';
export const SET_INITIAL_STATE_ANALYZE = 'insurance/SET_INITIAL_STATE_ANALYZE';
export const SET_USER_COMMISSIONED_AGENT = 'insurance/SET_USER_COMMISSIONED_AGENT';

export const setAnalyze = analyze => ({
  type: SET_ANALYZE,
  payload: analyze
});

export const setInitialStateAnalyze = () => ({
  type: SET_INITIAL_STATE_ANALYZE
});

export const setUserCommissionedAgent = commissionedAgent => ({
  type: SET_USER_COMMISSIONED_AGENT,
  payload: commissionedAgent
});

import * as insurancesActions from './insurancesActions';

export const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case insurancesActions.SET_LIST_INSURANCES:
      return [...state, ...action.payload];
    case insurancesActions.CLEAR_LIST_INSURANCES:
      return INITIAL_STATE;
    default:
      return state;
  }
};

import { plansActions } from '../..';
import { MONTHLY, TRADITIONAL } from '../../../../../shared/constants/planConstants';
import calculateServiceManager from '../../../services/calculate/calculateServiceManager';
import plansServiceManager from '../../../services/plans/plansServiceManager';
import { getAnalyzePolicyOwner } from './analyzeSelectors';

export const updatePlansAndGetCoverage = () => async (dispatch, getState) => {
  const {
    insurance: { analyze }
  } = getState();

  const expenses = analyze?.expenses.filter(e => e.value > 0);
  const planType = getAnalyzePolicyOwner(analyze?.commissionedAgents) ? MONTHLY : TRADITIONAL;
  const rentalPlans = await plansServiceManager.getPlan(planType);
  let coverage = rentalPlans.plans[0].coverages[0];
  const insuredAmount = await calculateServiceManager.getInsuredAmount(
    expenses,
    coverage,
    rentalPlans.plans[0]
  );
  coverage.insuredAmount = { value: insuredAmount };

  dispatch(plansActions.setPlans(rentalPlans));

  return coverage;
};

import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddData from '../../../../../../shared/components/AddData';
import ModalAddData from '../../../../../../shared/components/modal/ModalAddData';
import WarningModal from '../../../../../../shared/components/modal/warning/WarningModal';
import ShowData from '../../../../../../shared/components/ShowData';
import { EMPTY_OBJECT, WARNING_ICON } from '../../../../../../shared/constants/constants';
import { CONFIRM_DELETE } from '../../../../../../shared/constants/modalMessagesConstants';
import {
  ADD_MORE_SOLIDARY,
  ADD_SOLIDARY,
  POLICYHOLDER_ID,
  SOLIDARY,
  SOLIDARY_TITLE
} from '../../../../../../shared/constants/personConstants';
import { TRANSMITTED } from '../../../../../../shared/enums/statusEnum';
import policyHolderManager from '../../../../manager/insurance/analyze/policyHolder/policyHolderManager';
import { errorsActions, proposalActions } from '../../../../redux';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import { useAnalyze } from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../redux/insurance/policy/policyCustomHooks';
import { useProposal } from '../../../../redux/insurance/proposal/proposalCustomHooks';
import { useLoading } from '../../../../redux/quizCustomHooks';
import SolidaryPolicyHolderCard from './SolidaryPolicyHolderCard';

const SolidaryPolicyHolder = () => {
  const dispatch = useDispatch();
  const proposal = useProposal();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const errors = useErrors();
  const [openModal, setOpenModal] = useState(!analyze?.id);
  const [lastIndex, setLastIndex] = useState(
    proposal?.policyHolders ? proposal?.policyHolders.length : 0
  );
  const [index, setIndex] = useState(0);
  const [solidaryShow, setSolidaryShow] = useState(false);
  const [disabledForm, setDisabledForm] = useState(!analyze?.id ? false : true);
  const loading = useLoading();
  const [deleteSolidary, setDeleteSolidary] = useState(false);
  const [person, setPerson] = useState(EMPTY_OBJECT);
  const disabled = (analyze?.status === TRANSMITTED || policy?.id);

  const deleteClick = value => {
    const newProposal = {
      ...proposal,
      policyHolders: [
        ...proposal.policyHolders.slice(0, value),
        ...proposal.policyHolders.slice(value + 1)
      ]
    };
    dispatch(proposalActions.setProposal(newProposal));
    setPerson(EMPTY_OBJECT);
    policyHolderManager.delete(lastIndex, setLastIndex, setOpenModal);
  };

  const addClick = () => {
    policyHolderManager.add(lastIndex, setIndex, setLastIndex, setOpenModal, setSolidaryShow);
  };

  const showClick = position => {
    dispatch(
      errorsActions.setErrors({
        ...errors,
        insureds: { required: errors.insureds?.required }
      })
    );
    policyHolderManager.show(position, setSolidaryShow, setIndex, setOpenModal);
    setPerson(proposal?.policyHolders[position]);
  };

  const cancelClick = () => {
    dispatch(policyHolderManager.cancel(setOpenModal, setDisabledForm));

    dispatch(errorsActions.setErrors({ ...errors, solidaries: {} }));

    if (!disabledForm) {
      deleteClick(index);
    }
  };

  const saveClick = () => {
    const newProposal = {
      ...proposal,
      policyHolders: policyHolderManager.getPolicyHoldersUpdated(proposal, person, index)
    };
    dispatch(
      policyHolderManager.save(
        person,
        proposal,
        analyze?.policyHolder,
        index,
        setOpenModal,
        setDisabledForm,
        setIndex
      )
    );
    setPerson(EMPTY_OBJECT);
    dispatch(proposalActions.setProposal(newProposal));
  };

  return (
    <>
      <WarningModal
        id={'modalWarning'}
        image={WARNING_ICON}
        message={CONFIRM_DELETE}
        actions={[
          {
            text: 'Excluir',
            isConfirm: true
          }
        ]}
        title="Excluir locatário solidário?"
        open={deleteSolidary}
        onClick={() => {
          deleteClick(index ?? 0);
          setDeleteSolidary(false);
        }}
        onClose={() => {
          setDeleteSolidary(false);
        }}
        cancel
      />
      <>
        {!deleteSolidary && (
          <ModalAddData
            id={`ModalAdd-${index}`}
            open={openModal}
            textButton={ADD_SOLIDARY}
            children={
              <SolidaryPolicyHolderCard
                key={`${POLICYHOLDER_ID}-${index}`}
                index={index ?? 0}
                solidaryShow={solidaryShow}
                disabledForm={disabledForm}
                setDisabledForm={setDisabledForm}
                setPerson={setPerson}
                person={person}
              />
            }
            disabledForm={disabledForm || loading}
            cancelClick={cancelClick}
            saveClick={saveClick}
            deleteClick={() => {
              setDeleteSolidary(true);
            }}
            index={index ?? 0}
            type={SOLIDARY}
            deleteBtn={!disabled && solidaryShow}
            block={disabled}
          />
        )}

        <Grid container item={true} justify="center">
          <Grid item xs={12} md={8}>
            <Grid item xs={12}>
              {proposal?.policyHolders?.map((solidary, idx) => (
                <ShowData
                  id={`${POLICYHOLDER_ID}-${solidary.documentNumber}`}
                  key={`${POLICYHOLDER_ID}-${solidary.documentNumber}`}
                  data={solidary}
                  index={idx}
                  textTitle={SOLIDARY_TITLE}
                  deleteClick={() => {
                    setIndex(idx);
                    setDeleteSolidary(true);
                  }}
                  openClick={showClick}
                  type={SOLIDARY}
                  block={disabled}
                  deleteBtn={!disabled}
                />
              ))}
              {!disabled && (
                <AddData id={`AddData-${index}`} text={ADD_MORE_SOLIDARY} addClick={addClick} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default SolidaryPolicyHolder;

import { Box, Button, FormHelperText, Grid, IconButton, Paper } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import RadioButton from '../../../../../../../shared/components/form/RadioButton';
import SelectForm from '../../../../../../../shared/components/form/SelectForm';
import {
  COMPLIANCE_FOLDER,
  CORRECTION_INDEX,
  CORRECTION_INDEX_OPTIONS,
  CURRENT_LEASE,
  EMPTY_STRING
} from '../../../../../../../shared/constants/constants';
import { FILE_REQUIRED } from '../../../../../../../shared/constants/formConstants';
import { TRANSMITTED } from '../../../../../../../shared/enums/statusEnum';
import documentsManager from '../../../../../manager/documents/documentsManager';
import paymentManager from '../../../../../manager/insurance/proposal/payment/paymentManager';
import proposalManager from '../../../../../manager/insurance/proposal/proposalManager';
import { errorsActions, proposalActions } from '../../../../../redux';
import { useErrors } from '../../../../../redux/errors/errorsCustomHooks';
import { useAnalyze } from '../../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../../redux/insurance/policy/policyCustomHooks';
import { useProposal } from '../../../../../redux/insurance/proposal/proposalCustomHooks';
import PaymentClasses from '../classes/PaymentClasses';

const CoverageConditions = () => {
  const dispatch = useDispatch();
  const classes = PaymentClasses();
  const proposal = useProposal();
  const analyze = useAnalyze();
  const errors = useErrors();
  const [fileNames, setFileNames] = useState(
    proposal?.complianceLetterFile ? proposal?.complianceLetterFile.fileName : []
  );
  const [pdf, setPdf] = useState(EMPTY_STRING);
  const [base64, setBase64] = useState(EMPTY_STRING);
  const newErrors = paymentManager.getCoverageConditionsErrors(errors);
  const policy = usePolicy();
  const disabled = (analyze?.status === TRANSMITTED || policy?.id);

  useEffect(() => {
    if (base64 && !pdf) {
      const fileUrl = documentsManager.getFileUrl(base64, proposal?.complianceLetterFile?.type);
      if (fileUrl) {
        setPdf(fileUrl);
      }
    }
  }, [base64, pdf, setPdf, proposal, dispatch]);

  const updateFileValues = rentalFile => {
    dispatch(
      proposalActions.setProposal({
        ...proposal,
        complianceLetter: rentalFile.id,
        complianceLetterFile: rentalFile
      })
    );
  };

  const updateValues = (name, value) => {
    dispatch(
      proposalActions.setProposal({ ...proposal, payment: { ...proposal.payment, [name]: value } })
    );
    dispatch(
      errorsActions.setErrors({
        ...newErrors,
        payment: {
          ...newErrors.payment,
          coverageConditions: { ...newErrors.payment?.coverageConditions, [name]: EMPTY_STRING }
        }
      })
    );
  };

  const updatecomplianceLetter = value => {
    if (!value) {
      setFileNames([]);
      const fileId = proposal?.complianceLetterFile?.id ? proposal?.complianceLetterFile.id : null;
      dispatch(
        proposalActions.setProposal({
          ...proposal,
          complianceLetter: fileId,
          complianceLetterFile: { id: fileId },
          hasComplianceLetter: value
        })
      );
    } else {
      dispatch(proposalActions.setProposal({ ...proposal, hasComplianceLetter: value }));
    }
    dispatch(
      errorsActions.setErrors(
        proposalManager.validateComplianceLetter(newErrors, {
          ...proposal,
          hasComplianceLetter: value
        })
      )
    );
  };

  const cleanFile = () => {
    setFileNames([]);
    setBase64(EMPTY_STRING);
    setPdf(EMPTY_STRING);
  };

  const handleDrop = acceptedFiles => {
    cleanFile();
    setFileNames(acceptedFiles.map(file => file.name));
    documentsManager.toBase64(
      proposal?.complianceLetterFile?.id,
      acceptedFiles[0],
      setBase64,
      updateFileValues,
      COMPLIANCE_FOLDER
    );
    dispatch(errorsActions.setErrors({ ...newErrors, complianceLetterFile: EMPTY_STRING }));
  };

  const removeFile = () => {
    cleanFile();
    const fileId = proposal?.complianceLetterFile?.id ? proposal?.complianceLetterFile.id : null;
    dispatch(proposalActions.setProposal({ ...proposal, complianceLetterFile: { id: fileId } }));
    dispatch(
      errorsActions.setErrors({
        ...newErrors,
        payment: {
          ...newErrors.payment,
          coverageConditions: { ...newErrors.payment?.coverageConditions, file: FILE_REQUIRED }
        }
      })
    );
  };
  return (
    <Grid item xs={12}>
      <Paper className={classes.rightCards}>
        <Grid item xs={12}>
          <Box pt={3} px={3} className={classes.titleCards}>
            Condições da cobertura
          </Box>
          <Box px={3} py={3}>
            <Grid container direction="row">
              <Grid item xs={12} md={2}>
                <Box px={0} pt={5} className={classes.label}>
                  ÍNDICE DE CORREÇÃO DO ALUGUEL
                </Box>
                <SelectForm
                  value={proposal?.payment?.correctionIndex}
                  onChange={(_event, value) => {
                    updateValues(CORRECTION_INDEX, value);
                  }}
                  label={EMPTY_STRING}
                  required
                  itemsSelect={CORRECTION_INDEX_OPTIONS}
                  id={CORRECTION_INDEX}
                  width={'100%'}
                  error={errors?.payment?.coverageConditions?.correctionIndex}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Box px={2} pt={8} textAlign="center">
                  <RadioButton
                    id={CURRENT_LEASE}
                    label="Locação em vigor?"
                    required
                    itemsRadio={[
                      { description: 'Sim', value: true },
                      { description: 'Não', value: false }
                    ]}
                    onChange={(_event, value) => updatecomplianceLetter(value)}
                    error={errors?.hasComplianceLetter}
                    valueRadio={proposal?.hasComplianceLetter}
                    bool
                    disabled
                    row={false}
                    bold
                    alignCenter
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                {proposal?.hasComplianceLetter && (
                  <>
                    <Box pt={0} pb={1} className={classes.fileTitle}>
                      Adicionar carta de adimplência
                    </Box>
                    <Dropzone id="dropFile" onDrop={handleDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <Box
                          mr={2}
                          py={0}
                          textAlign="center"
                          className={`${errors?.complianceLetterFile ? classes.errorGrid : classes.gridDash
                            } ${classes.gridFile}`}
                        >
                          <div
                            className={classes.dropzone}
                            {...getRootProps({ className: 'dropzone' })}
                          >
                            <input
                              accept=".pdf"
                              id="file-drop"
                              className={classes.inputFile}
                              {...getInputProps()}
                            />
                            <Box py={2}>
                              {fileNames.length > 0 ? (
                                <img
                                  alt=""
                                  src="/assets/images/icon_uploadok.svg"
                                  className={classes.icon}
                                />
                              ) : (
                                <BackupIcon className={classes.icon} />
                              )}
                            </Box>
                            Arraste o arquivo ou
                          </div>
                          {fileNames.length > 0 && (
                            <>
                              <p id="fileName">
                                <b
                                  className={classes.fileName}
                                  onClick={() =>
                                    window.open(
                                      proposal?.complianceLetterFile.id
                                        ? `${window.env.RENTAL_SURETY}/Files/download/${proposal.complianceLetterFile.id}`
                                        : pdf
                                    )
                                  }
                                >
                                  {fileNames}
                                </b>
                                <IconButton
                                  className={classes.IconButton}
                                  id="btn-delete"
                                  onClick={() => removeFile()}
                                  color="primary"
                                >
                                  <img
                                    alt="Deletar Arquivo"
                                    src="/assets/images/icon_trash.svg"
                                    className={classes.IconButton}
                                  />
                                </IconButton>
                              </p>
                            </>
                          )}
                          <div
                            className={classes.dropzone}
                            {...getRootProps({ className: 'dropzone' })}
                          >
                            <input
                              accept=".pdf"
                              id="file-select"
                              className={classes.inputFile}
                              {...getInputProps()}
                            />
                            <Box pb={2}>
                              <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                component="span"
                              >
                                {fileNames.length > 0
                                  ? 'Substituir por novo arquivo'
                                  : 'Selecione do seu computador'}
                              </Button>
                            </Box>
                          </div>
                        </Box>
                      )}
                    </Dropzone>
                    {errors?.complianceLetterFile && (
                      <FormHelperText error>{errors.complianceLetterFile}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default CoverageConditions;

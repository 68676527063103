import { EMPTY_STRING, RESIDENTIAL } from '../../../../../../shared/constants/constants';
import {
  requiredValidator,
  validateMinCaracteres
} from '../../../../../../shared/helper/formValidators';
import utilManager from '../../../../../util/services/utilManager';

class RiskLocationManager {
  validateRiskLocation = analyze => {
    const errors = {};
    errors.occupation = requiredValidator(analyze?.occupation, EMPTY_STRING);
    errors.zipCode = requiredValidator(analyze?.riskLocation?.zipCode, errors.zipCode);
    errors.street = validateMinCaracteres(analyze?.riskLocation?.street, EMPTY_STRING, 3);
    errors.street = requiredValidator(analyze?.riskLocation?.street, EMPTY_STRING);
    errors.number = requiredValidator(analyze?.riskLocation?.number, EMPTY_STRING);
    errors.city = requiredValidator(analyze?.riskLocation?.city, EMPTY_STRING);
    errors.district = validateMinCaracteres(analyze?.riskLocation?.district, EMPTY_STRING, 2);
    errors.district = requiredValidator(analyze?.riskLocation?.district, EMPTY_STRING);
    errors.state = requiredValidator(analyze?.riskLocation?.state, EMPTY_STRING);
    errors.inhabited =
      analyze?.occupation === RESIDENTIAL
        ? requiredValidator(analyze?.policyHolder?.inhabited, EMPTY_STRING)
        : null;
    return utilManager.validErrors(errors) ? null : errors;
  };
}

export default new RiskLocationManager();

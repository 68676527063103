import plansService from './plansService';

class PlansServiceManager {
  getPlan = async type => {
    let plans = await plansService.getPlans(type);

    return plans;
  };
}

export default new PlansServiceManager();

import { Box, Button, Grid, IconButton, Paper } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { QUOTE_STEP } from '../../../../../../../shared/constants/constants';
import { TRANSMITTED } from '../../../../../../../shared/enums/statusEnum';
import { quizOperations } from '../../../../../redux';
import { useAnalyze } from '../../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../../redux/insurance/policy/policyCustomHooks';
import ListCoverage from '../../../quote/plans/listCoverage/ListCoverage';
import ModalCoveragesPaymentClasses from '../classes/ModalCoveragesPaymentClasses';

const ModalCoveragesPayment = ({ rentalPlan, open, setOpenModal }) => {
  const classes = ModalCoveragesPaymentClasses();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const dispatch = useDispatch();
  const disabled = (analyze?.status === TRANSMITTED || policy?.id);

  return (
    <Modal className={classes.modal} open={open} BackdropProps={{ timeout: 500 }}>
      <Fade in={open}>
        <Box className={classes.box}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={8}>
                <Box p={1} pl={5}>
                  <p className={classes.header}>PLANOS / MENSALIZADO</p>
                  <h2 className={classes.title}>{rentalPlan?.name}</h2>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box p={1} textAlign="end">
                  <IconButton
                    id="btn-close"
                    className={classes.buttonClose}
                    onClick={() => setOpenModal(false)}
                    color="primary"
                  >
                    <CancelIcon className={classes.icon} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Box pl={5} pr={5} pt={3} pb={3}>
                  {rentalPlan?.coverages?.map(coverage => (
                    <ListCoverage key={`listCoverage-${coverage.name}`} coverage={coverage} />
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box p={3} textAlign="end">
                <Button
                  id="btn-exit"
                  className={`${classes.button} ${classes.buttonEmpty} ${classes.buttonReturn}`}
                  onClick={() => setOpenModal(false)}
                >
                  {'Voltar'}
                </Button>
                <Button
                  id="btn-edit"
                  variant="contained"
                  disabled={disabled}
                  className={`${classes.button} ${classes.buttonSelect}`}
                  onClick={() => dispatch(quizOperations.activateStep(QUOTE_STEP))}
                >
                  {'Editar'}
                </Button>
              </Box>
            </Grid>
          </Paper>
        </Box>
      </Fade>
    </Modal>
  );
};

ModalCoveragesPayment.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  rentalPlan: PropTypes.object.isRequired
};

export default ModalCoveragesPayment;

import { makeStyles } from '@material-ui/core/styles';

const Styles = () =>
  makeStyles(theme => ({
    root: {
      backgroundColor: '#f26623',
      float: 'right',
      top: 0,
      right: 0,
      width: '100%',
      boxShadow: 'none',
      flexGrow: 1,
      height: 63,
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        flexDirection: 'column'
      },
      justifyContent: 'space-around'
    },
    container: {
      zIndex: 5
    },
    menu: {
      font: 'normal normal normal 13px/18px Open Sans',
      letterSpacing: 0.39,
      color: '#FFFFFF',
      textTransform: 'Capitalize',
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer ',
        pointerEvents: 'auto',
        textDecoration: 'underline'
      }
    },
    menuDisabled: {
      opacity: 0.5
    },
    menuEnabled: {
      opacity: 1
    }
  }));

export default Styles;

import { makeStyles } from '@material-ui/core';

const Styles = (image, valueBlur) =>
  makeStyles(theme => ({
    modal: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outlineColor: 'transparent'
    },
    paper: {
      padding: theme.spacing(0),
      position: 'relative',
      background: '#F7F7F7',
      borderRadius: 15,

      [theme.breakpoints.up('md')]: {
        width: 957,
        height: 581
      },
      [theme.breakpoints.down('sm')]: {
        width: 750,
        height: 400
      },
      [theme.breakpoints.down('xs')]: {
        width: 400,
        height: 200
      }
    },
    image: {
      background: valueBlur + 'url(' + image + ') no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'right top'
    },
    grid: {
      backgroundColor: '#F7F7F7',
      height: '100%',
      width: '67%',
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
      margin: 0,
      position: 'absolute'
    },
    iconButtonExit: {
      fontSize: '1.8rem'
    },
    gridClose: {
      position: 'absolute',
      width: '100%'
    },
    buttonExit: {
      backgroundColor: '#FFFFFF',
      width: 18,
      height: 18
    }
  }));

export default Styles;

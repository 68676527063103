import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import React from 'react';
import { EMPTY_STRING } from '../../constants/constants';
import { requiredValidator } from '../../helper/formValidators';
import SelectFormClasses from './classes/SelectFormClasses';

export const validadeSelectForm = (value, required) => {
  if (required) return requiredValidator(value, EMPTY_STRING);
  return EMPTY_STRING;
};

export default function SelectForm({
  value,
  onChange,
  onBlur,
  label,
  disabled,
  required,
  itemsSelect,
  id,
  error,
  width
}) {
  const classesSelect = SelectFormClasses(width)();
  const hasError = !!error;

  return (
    <FormControl className={classesSelect.formControl}>
      <InputLabel error={hasError} required={required}>
        {label}
      </InputLabel>
      <Select
        className={classesSelect.select}
        id={id}
        error={hasError}
        value={value}
        onChange={e => {
          onChange(id, e.target.value);
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur(id, validadeSelectForm(value, required));
          }
        }}
        disabled={disabled}
        required={required}
      >
        {itemsSelect.map(({ key, itemValue }) => {
          return (
            <MenuItem key={key} value={itemValue}>
              {itemValue}
            </MenuItem>
          );
        })}
      </Select>
      {
        hasError && (
          <FormHelperText className={classesSelect.error} error={hasError}>
            {error}
          </FormHelperText>
        )
      }
    </FormControl >
  );
}

SelectForm.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  itemsSelect: PropTypes.array,
  id: PropTypes.string,
  error: PropTypes.string
};

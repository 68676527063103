import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import CpfCnpjInput from '../../../../../../shared/components/form/CpfCnpjInput';
import InputForm from '../../../../../../shared/components/form/InputForm';
import RadioButton from '../../../../../../shared/components/form/RadioButton';
import { nameFormat } from '../../../../../../shared/components/format/NameFormat';
import LoadingClasses from '../../../../../../shared/components/loading/classes/LoadingClasses';
import Loading from '../../../../../../shared/components/loading/Loading';
import { EMPTY_OBJECT, EMPTY_STRING } from '../../../../../../shared/constants/constants';
import { CPF_SIZE } from '../../../../../../shared/constants/formConstants';
import {
  ID_DOCUMENT_INPUT,
  ID_INHABITED_INPUT,
  ID_NAME_INPUT,
  SOLIDARY_ID
} from '../../../../../../shared/constants/personConstants';
import { TRANSMITTED } from '../../../../../../shared/enums/statusEnum';
import personManager from '../../../../manager/persons/personsManager';
import { errorsActions, personsOperations } from '../../../../redux';
import { useErrors } from '../../../../redux/errors/errorsCustomHooks';
import { useAnalyze } from '../../../../redux/insurance/analyze/analyzeCustomHooks';
import { usePolicy } from '../../../../redux/insurance/policy/policyCustomHooks';
import { useProposal } from '../../../../redux/insurance/proposal/proposalCustomHooks';
import { useLoading } from '../../../../redux/quizCustomHooks';
import SolidaryPolicyHolderCardClasses from '../../classes/SolidaryPolicyHolderCardClasses';

const SolidaryPolicyHolderCard = ({
  index,
  solidaryShow,
  disabledForm,
  setDisabledForm,
  setPerson,
  person
}) => {
  const classes = SolidaryPolicyHolderCardClasses();
  const loadingClasses = LoadingClasses();
  const isLoading = useLoading();
  const proposal = useProposal();
  const analyze = useAnalyze();
  const policy = usePolicy();
  const errors = useErrors();
  const solidary = SOLIDARY_ID + index;
  const dispatch = useDispatch();
  const disabled = (analyze?.status === TRANSMITTED || policy?.id || solidaryShow);

  const cleanPolicyHolder = () => {
    setPerson(EMPTY_OBJECT);
  };

  const verifySameDocument = (name, document, policyHolder) => {
    const error = personManager.verifySameDocument(document, proposal, policyHolder);
    if (error) {
      cleanPolicyHolder();

      dispatch(
        errorsActions.setErrors({
          ...errors,
          solidaries: {
            ...errors.solidaries,
            [solidary]: { ...errors?.solidaries?.[solidary], [name]: error }
          }
        })
      );
    }
    return error;
  };

  const updateErrorsOnBlur = async (name, value) => {
    dispatch(
      errorsActions.setErrors({
        ...errors,
        solidaries: {
          ...errors.solidaries,
          [solidary]: { ...errors?.solidaries?.[solidary], [name]: value }
        }
      })
    );
  };

  const updateValuePolicyHolders = async (name, value) => {
    setPerson({ ...person, [name]: value });
  };

  const changeDocument = async value => {
    if (!value && !disabledForm) {
      cleanPolicyHolder();
    } else if (value.length === CPF_SIZE) {
      if (
        !verifySameDocument(`${ID_DOCUMENT_INPUT}${index}`, value, analyze?.policyHolder) &&
        personManager.validDocument(solidaryShow, value, setDisabledForm)
      ) {
        const person = await dispatch(personsOperations.getPersonByDocument(value));
        setPerson(person);
        dispatch(
          errorsActions.setErrors({
            ...errors,
            solidaries: {
              ...errors.solidaries,
              [solidary]: {}
            }
          })
        );
      }
    }
  };

  return (
    <>
      {isLoading && (
        <Grid className={loadingClasses.gridContainer}>
          <Loading />
        </Grid>
      )}
      <Grid>
        <Grid container>
          <Grid item xs={8}>
            <Box pb={1} pl={5}>
              <p className={classes.header}>PLANOS / MENSALIZADO</p>
              <h2 className={classes.title}>Adicione um locatário solidário</h2>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <Box pl={5} pr={15} pb={4}>
              <CpfCnpjInput
                id={`${ID_DOCUMENT_INPUT}${index}`}
                key={`${ID_DOCUMENT_INPUT}-${index}`}
                value={person?.documentNumber ?? EMPTY_STRING}
                onChange={changeDocument}
                onBlur={updateErrorsOnBlur}
                disabled={disabled}
                error={
                  errors?.solidaries?.[solidary] &&
                    errors.solidaries?.[solidary]?.[`${ID_DOCUMENT_INPUT}${index}`]
                    ? errors.solidaries?.[solidary]?.[`${ID_DOCUMENT_INPUT}${index}`]
                    : EMPTY_STRING
                }
                label="CPF"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box pb={4} pr={5}>
              <InputForm
                id={`${ID_NAME_INPUT}${index}`}
                key={`${ID_NAME_INPUT}${index}`}
                label="Nome do Locatário"
                value={person?.name ? nameFormat(person?.name) : EMPTY_STRING}
                error={
                  errors?.solidaries?.[solidary] &&
                    errors.solidaries?.[solidary]?.[`${ID_NAME_INPUT}${index}`]
                    ? errors.solidaries?.[solidary]?.[`${ID_NAME_INPUT}${index}`]
                    : EMPTY_STRING
                }
                required
                numberRestriction={true}
                caractersRestriction={5}
                disabled={true}
              />
            </Box>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <Box pl={5} pb={4}>
                <RadioButton
                  id={`${ID_INHABITED_INPUT}${index}`}
                  key={`${ID_INHABITED_INPUT}${index}`}
                  label="Este solidário irá morar no imóvel?"
                  disabled={disabled}
                  required
                  itemsRadio={[
                    { description: 'Sim', value: true },
                    { description: 'Não', value: false }
                  ]}
                  onChange={(_value, target) => {
                    updateValuePolicyHolders(ID_INHABITED_INPUT, target);
                  }}
                  onBlur={updateErrorsOnBlur}
                  error={
                    errors?.solidaries?.[solidary] &&
                      errors.solidaries?.[solidary]?.[`${ID_INHABITED_INPUT}${index}`]
                      ? errors.solidaries?.[solidary]?.[`${ID_INHABITED_INPUT}${index}`]
                      : EMPTY_STRING
                  }
                  valueRadio={person?.inhabited}
                  bool
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

SolidaryPolicyHolderCard.propTypes = {
  index: PropTypes.number.isRequired,
  solidaryShow: PropTypes.bool.isRequired,
  disabledForm: PropTypes.bool.isRequired,
  setDisabledForm: PropTypes.func.isRequired,
  analyze: PropTypes.bool
};

export default SolidaryPolicyHolderCard;

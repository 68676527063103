import { EMPTY_STRING } from '../../../../shared/constants/constants';
import locationService from './locationService';

class LocationServiceManager {
  getLocation = async zipCode => {
    const { cep, logradouro, bairro, localidade, uf } =
      zipCode !== EMPTY_STRING ? await locationService.getLocation(zipCode) : EMPTY_STRING;

    return {
      zipCode: cep ? cep.replace('-', EMPTY_STRING) : zipCode,
      street: logradouro ?? EMPTY_STRING,
      district: bairro ?? EMPTY_STRING,
      city: localidade ?? EMPTY_STRING,
      state: uf ?? EMPTY_STRING
    };
  };
}

export default new LocationServiceManager();

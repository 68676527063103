import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ApprovedLimitInfo from './analyze/cardsAnalyzeResult/ApprovedLimitInfo';
import FormClasses from './classes/FormClasses';

const QuestionFormHeader = ({ title, showApprovedLimit }) => {
    const classes = FormClasses();

    return (
        <Grid container item justify="center">
            <Grid container item xs={12} md={8} direction="row">
                <Grid item xs={12} md={6}>
                    <p className={classes.titleForm}>{title}</p>
                    <hr className={classes.lineTitle}></hr>
                </Grid>
            </Grid>
            {showApprovedLimit && (
                <Grid item xs={12} md={6}>
                    <Box pt={6}>
                        <ApprovedLimitInfo
                            lineWidth={150}
                            componentWidth={424}
                            componentMargin={3}
                        />
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

QuestionFormHeader.propTypes = {
    title: PropTypes.string.isRequired
};

export default QuestionFormHeader;

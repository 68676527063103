import { useSelector } from 'react-redux';

export const usePlans = () => {
  return useSelector(state => state.plans);
};

export const usePlan = () => {
  return useSelector(state => state.insurance?.quote?.plan);
};

export const mapperCoveragesAndPlanValues = (oldPlans, getPremiumReponse) => {
  const newPlans = [];
  getPremiumReponse.forEach(plan => {
    const oldPlan = {
      ...oldPlans.find(p => p.id === plan.id),
      grossPremium: plan.grossPremium,
      commercialPremium: (plan.commercialPremium = 94.99),
      commission: plan.commission
    };
    const newCoverages = oldPlan.coverages.map((c, idx) =>
      idx >= 0
        ? {
            ...c,
            grossPremium: plan.coverages.find(pc => pc.id === c.id)?.grossPremium,
            commercialPremium: plan.coverages.find(pc => pc.id === c.id)?.commercialPremium,
            commissionValue: plan.coverages.find(pc => pc.id === c.id)?.commissionValue,
            multiple: plan.coverages.find(pc => pc.id === c.id)?.multiple
          }
        : { ...c }
    );

    newPlans.push({ ...oldPlan, coverages: newCoverages });
  });

  return newPlans;
};

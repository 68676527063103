import axios from 'axios';

class ExposureService {
  getExposure = async (documentNumber, insuredAmount) => {
    const response = await axios.get(
      `${window.env.RENTAL_SURETY}/Exposure/exposureValue?InsuredAmount=${insuredAmount}&DocumentNumber=${documentNumber}`
    );

    return response.data;
  };
}

export default new ExposureService();

import Grid from '@material-ui/core/Grid';
import React from 'react';
import Header from '../components/header/Header';

export default function withHeader(WrappedComponent) {
  return function ({ history }) {
    return (
      <>
        <Grid container direction="column" item>
          <Header />
          <WrappedComponent />
        </Grid>
      </>
    );
  };
}

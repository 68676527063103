import { Box, Grid, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import requestPageManager from '../../../features/requests/requestPageManager';
import { HEADER_FILTER_REQUEST_PAGE } from '../../../shared/constants/constants';
import { EMPTY_STRING } from '../../constants/constants';
import HeaderFilterClasses from '../classes/HeaderFilterClasses';

const HeaderFilter = ({ insurancesList, numberCurrentPage, numberOfPages, setters }) => {
  const classes = HeaderFilterClasses();
  const [HeaderFilterData, SetHeaderFilterData] = useState(HEADER_FILTER_REQUEST_PAGE);

  return (
    <>
      <Box direction="column">
        <Grid className={classes.grid} container alignItems="center" direction="row">
          {HeaderFilterData.map(filter => (
            <Grid key={`itemOrdenate-${filter.id}`} item xs={filter.xs}>
              <Box>
                <Box className={classes.text} pl={filter.pl}>
                  {filter.name}
                  <IconButton
                    id={`closeOrdenate-${filter.id}`}
                    onClick={() =>
                      insurancesList
                        ? requestPageManager.clickOrdenate(
                            filter,
                            insurancesList,
                            { numberPage: numberCurrentPage, numberOfPages: numberOfPages },
                            HeaderFilterData,
                            {
                              setNumberCurrentPage: setters.setNumberCurrentPage,
                              setCurrentPage: setters.setCurrentPage,
                              setInsurancesList: setters.setInsurancesList,
                              SetHeaderFilterData: SetHeaderFilterData
                            }
                          )
                        : EMPTY_STRING
                    }
                  >
                    {filter.orderBy && <ExpandLess className={classes.icon} />}
                    {!filter.orderBy && <ExpandMore className={classes.icon} />}
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

HeaderFilter.propTypes = {
  insurancesList: PropTypes.array,
  numberCurrentPage: PropTypes.number,
  setNumberCurrentPage: PropTypes.func,
  numberOfPages: PropTypes.number,
  setters: PropTypes.object.isRequired
};

export default HeaderFilter;

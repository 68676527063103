import { useSelector } from "react-redux";

export const usePolicy = () => {
  return useSelector(state => state.insurance?.policy);
};

export const useCreatePolicyRequest = (insuranceId, proposalId, userId) => {
  return {
    insuranceId: insuranceId,
    proposalId: proposalId,
    userId: userId
  };
};
